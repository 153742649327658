//RESET
export const RESET_STORE = "RESET_STORE";

//FETCHING
export const UPDATE_IS_FETCHING = "UPDATE_IS_FETCHING";
export const UPDATE_IS_FETCHING_QUOTES = "UPDATE_IS_FETCHING_QUOTES";
export const UPDATE_IS_FETCHING_PRIOR_LIMITS =
  "UPDATE_IS_FETCHING_PRIOR_LIMITS";

//TOKEN
export const ADD_TOKEN = "ADD_TOKEN";

//SESSION
export const ADD_SESSION = "ADD_SESSION";

//CONTACT
export const ADD_CONTACT = "ADD_CONTACT";
export const CLEAR_CONTACT = "CLEAR_CONTACT";

//PROPERTY
export const ADD_PROPERTY = "ADD_PROPERTY";
export const CLEAR_PROPERTY = "CLEAR_PROPERTY";

//DRIVER
export const ADD_DRIVERS = "ADD_DRIVERS";

//VEHICLE
export const ADD_VEHICLES = "ADD_VEHICLES";

//COVERAGE
export const ADD_COVERAGES = "ADD_COVERAGES";

//AGENT
export const ADD_AGENT = "ADD_AGENT";
export const ADD_AGENT_REVIEWS = "ADD_AGENT_REVIEWS";

//UUID
export const ADD_UUID = "ADD_UUID";

//USER
export const ADD_USER = "ADD_USER";
//QUOTE
export const ADD_RENT = "ADD_RENT";
export const ADD_QUOTES = "ADD_QUOTES";

export const UPDATE_QUOTE_RESPONSE = "UPDATE_QUOTE_RESPONSE";

export const UPDATE_IS_PRIMARY_RESIDENCE = "UPDATE_IS_PRIMARY_RESIDENCE";
export const HOME_OCCUPY = "HOME_OCCUPY";
export const ADD_PACKAGE = "ADD_PACKAGE";
export const ADD_ACCIDENT = "ADD_ACCIDENT";

//FINAL QUOTE
export const UPDATE_FINAL_QUOTES = "UPDATE_FINAL_QUOTES";

//RECALCULATING
export const UPDATE_IS_RECALCULATING = "UPDATE_IS_RECALCULATING";

export const SET_COVERAGES_MODAL = "SET_COVERAGES_MODAL";
