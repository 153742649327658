import { ADD_PROPERTY, CLEAR_PROPERTY } from "../constants";

import produce from "immer";

export type Property = {
  street_number?: string;
  route?: string;
  locality?: string;
  administrative_area_level_2?: string;
  administrative_area_level_1?: string;
  country?: string;
  postal_code?: string;
  unitNumber?: string;
  hasPool?: boolean;
  squareFoot?: number;
  yearBuilt?: number;
  numStories?: string;
  exteriorWalls?: string;
  roofShape?: string;
  roofReplaced?: number;
  roofMaterial?: string;
  lastSalesDate?: string;
  landUseDescription?: string;
  foundationType?: string;
  occupancy?: string;
  effectiveDate?: string;
  dwellingCoverage?: number;
  valueJewelry?: string;
  aopDeductible?: string;
  windHailDeductible?: string;
  personalPropertyCoverage?: number;
  newPurchase?: string;
  roof_deck?: any;
  roof_wall?: any;
  openingProtection?: any;
  predominant_roof?: any;
  roof_design?: string;
  floodZone?: string;
  heating?: string;
  plumbing?: string;
  electrical?: string;
};

const initialState: Property = {};

export const propertyReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_PROPERTY:
      draft = { ...draft, ...action.payload };
      return draft;
    case CLEAR_PROPERTY:
      draft = {};
      return draft;
    default:
      return draft;
  }
}, initialState);
