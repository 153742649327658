// @ts-ignore
import * as AppTypes from "../../app.d.ts"; // eslint-disable-line no-unused-vars

// @ts-check
import { BUTTON_BG_COLOR, BUTTON_TEXT_COLOR } from "../../assets/theme";
import { qtiBranchId, security } from "../../constants";

export const doNothing = () => undefined;

/**
 * @param {{
 *   companyClientId?: string;
 *   isCarrierQtiEnabled: boolean;
 *   qti_id?: string | null;
 *   quoteResponse?: Pick<AppTypes.QuoteResponse>;
 * }} input
 */
export const getIsBind = ({
  companyClientId,
  isCarrierQtiEnabled,
  qti_id,
  quoteResponse,
}) => {
  const isBranch = getIsBranch({ company_client_id__c: companyClientId });

  if (
    isBranch &&
    qti_id &&
    quoteResponse?.premium_total__c &&
    quoteResponse?.quote_type !== "RC1" &&
    isCarrierQtiEnabled
  ) {
    return true;
  }

  if (!isBranch && quoteResponse?.premium_total__c && isCarrierQtiEnabled) {
    return true;
  }

  return false;
};

/**
 * @param {{
 *   isBind?: boolean;
 *   isBridged?: boolean;
 *   isLoginError?: boolean;
 *   lob?: string;
 *   prospectId?: string;
 *   qtiQuoteExists?: boolean;
 * }} [input]
 */
export const getButtonProperties = ({
  isBind,
  isBridged,
  isLoginError,
  lob,
  prospectId,
  qtiQuoteExists,
} = {}) => {
  const getButtonTitle = () => {
    if (lob === security) {
      return prospectId ? "View" : "Refer";
    }
    if (isLoginError) {
      return "Update";
    } else if (qtiQuoteExists) {
      return "Resume";
    } else if (isBind) {
      return "Next";
    } else {
      return "Bridge";
    }
  };

  const bgColor = (BUTTON_BG_COLOR[getButtonTitle()] ??
    BUTTON_BG_COLOR.default)[isBridged ? "clicked" : "unclicked"];

  const textColor = (BUTTON_TEXT_COLOR[getButtonTitle()] ??
    BUTTON_TEXT_COLOR.default)[isBridged ? "clicked" : "unclicked"];

  return {
    title: getButtonTitle(),
    bgColor,
    textColor,
  };
};

/** @param {Pick<AppTypes.QuoteResponse, "company_client_id__c">} quoteResponse */
export const getIsBranch = ({ company_client_id__c }) =>
  company_client_id__c === qtiBranchId;
