import "../../assets/css/LocationSearchInput.css";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomInput from "../styled/inputs/CustomInput";
import LoadingDots from "../LoadingDots";
import PropTypes from "prop-types";
import { addUUID } from "../../redux/actions/uuid";
import { setFieldsEdited } from "../../utils";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const LocationInputWrapper = styled.div`
  position: relative;
`;

const InputRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.8rem;
`;

const AutoCompleteList = styled.ul`
  position: absolute;
  top: 5.5rem;
  left: 0px;
  z-index: 999;
  width: 100%;
  padding: ${(props) => (props.modal ? "8px" : "29px")};
  padding-top: 3px;

  & > li {
    font-weight: 400;
    padding: 0.4rem 0.6rem;
    :hover {
      background-color: ${(props) => props.theme.tertiary_accent} !important;
      color: ${(props) => props.theme.white};
      cursor: pointer;
    }
    :focus {
      background-color: ${(props) => props.theme.tertiary_accent} !important;
      color: white;
    }
  }
`;

const LocationInput = ({
  setAddressData,
  placeholder,
  label,
  setShowManualAddress,
  forwardToManualAddress,
  dark,
  modal,
  initialValue,
  googleService,
  initialSearchValue = "",
  autoFocus = true,
}) => {
  const uuid = useSelector((store) => store.uuid);
  const isFetching = useSelector((store) => store.isFetching);
  const [autocompleteService, setAutocompleteService] = useState({});
  const [placeService, setPlaceService] = useState({});
  const [googleSessionToken, setGoogleSessionToken] = useState({});
  const [debouncer, setDebouncer] = useState(null);
  const dispatch = useDispatch();
  const locationPredictions = {
    pred_0: useRef(null),
    pred_1: useRef(null),
    pred_2: useRef(null),
    pred_3: useRef(null),
    pred_4: useRef(null),
    pred_5: useRef(null),
  };
  const locationInputRef = useRef(null);
  const customInputRef = useRef(null);
  const [predIndex, setPredIndex] = useState(-1);
  const [predictions, setPredictions] = useState([]);
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [initialMountWithValue, setInitialMountWithValue] = useState(false);

  /**
   * @param {event} e - Keyboard event
   * @returns None
   *
   *   - This function updates prediction index making sure edge cases are handled
   *       properly and performs click event on Enter.
   */
  const handleKeyUp = (e) => {
    if (e.key === "ArrowUp" && predIndex > 0) {
      if (predIndex === 0) {
        locationPredictions[`pred_${predIndex}`].current.classList.add(
          "hovered"
        );
      } else {
        locationPredictions[`pred_${predIndex}`].current.classList.remove(
          "hovered"
        );
        setPredIndex(predIndex - 1);
        locationPredictions[`pred_${predIndex - 1}`].current.classList.add(
          "hovered"
        );
      }
    } else if (e.key === "ArrowDown" && predIndex < 6) {
      if (predIndex === 5) {
        locationPredictions[`pred_${predIndex}`].current.classList.add(
          "hovered"
        );
      } else {
        if (locationPredictions[`pred_${predIndex}`]) {
          locationPredictions[`pred_${predIndex}`].current.classList.remove(
            "hovered"
          );
        }
        setPredIndex(predIndex + 1);
        locationPredictions[`pred_${predIndex + 1}`].current.classList.add(
          "hovered"
        );
      }
    } else if (
      e.key === "Enter" &&
      locationPredictions[`pred_${predIndex}`] &&
      locationPredictions[`pred_${predIndex}`].current
    ) {
      locationPredictions[`pred_${predIndex}`].current.click();
    }
  };

  /**
   * @param {event} e - OnChange event
   * @returns None
   *
   *   - This function gets and sets the top 5 predictions based on the input.
   */
  const debounceAutocomplete = (input, debouceTime = 200) => {
    clearTimeout(debouncer);
    setDebouncer(
      setTimeout(async () => {
        try {
          if (input) {
            const response = await autocompleteService.getPredictions({
              input,
              sessionToken: googleSessionToken,
              componentRestrictions: { country: "us" },
            });
            setPredictions(response.predictions);
          } else {
            setPredictions([]);
            setAddressData({});
          }
          setPredIndex(-1);
        } catch (error) {
          console.log("Something went wrong", error);
        }
      }, debouceTime)
    );
  };
  const handleChange = (e) => {
    setFieldsEdited();
    if (initialMountWithValue) {
      setInitialMountWithValue(false);
      return;
    }
    const input = e.target.value;
    setSearchValue(input);
    debounceAutocomplete(input);
  };

  const handleGetPlaceDetails = (placeId, sessionToken) => {
    return new Promise((resolve, reject) => {
      placeService.getDetails(
        {
          placeId,
          fields: [
            "address_component",
            "adr_address",
            "formatted_address",
            "types",
          ],
          sessionToken,
        },
        (placeData, placeStatus) => {
          if (placeStatus === "OK") resolve(placeData);
          else reject(placeStatus);
        }
      );
    });
  };

  /**
   * @param {number} index - Index of the prediction (ranges from 0 to 4)
   * @returns None
   *
   *   - This function appropriately fills all the address fields and calls the
   *       setAddress function with that data.
   */

  const handleSelect = async (index) => {
    try {
      clearTimeout(debouncer);
      const addressFields = [
        "street_number",
        "street_address",
        "route",
        "locality",
        "sublocality_level_1",
        "country",
        "administrative_area_level_1",
        "administrative_area_level_2",
        "postal_code",
        "neighborhood",
      ];
      const { place_id } = predictions[index];

      const response = await handleGetPlaceDetails(
        place_id,
        googleSessionToken
      );
      const addressData = response.address_components.reduce((acc, address) => {
        if (address.types.some((a) => addressFields.includes(a))) {
          if (address.types.includes("route")) {
            acc["street_number"] =
              (acc["street_number"] || parseInt(searchValue)) +
              " " +
              address.short_name;
          }
          if (address.types.includes("sublocality_level_1")) {
            acc["locality"] = address.short_name;
          } else if (
            address.types.includes("neighborhood") &&
            !acc["locality"]
          ) {
            acc["locality"] = address.short_name;
          } else {
            acc[address.types[0]] = address.short_name;
          }
        }
        return acc;
      }, {});
      setGoogleSessionToken(
        new googleService.maps.places.AutocompleteSessionToken()
      );
      setSearchValue(response.formatted_address);
      setPredictions([]);
      setAddressData((prev) => ({ ...prev, ...addressData }));
      forwardToManualAddress(addressData);
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  /**
   * @param {string} event - Click event
   * @returns None
   *
   *   - This function clears all the predictions when clicked outside of the
   *       location input.
   */
  const handleClickOutsideLocationInput = (event) => {
    if (
      locationInputRef.current &&
      !locationInputRef.current.contains(event.target)
    )
      setPredictions([]);
  };
  const getGoogleService = async (inputRef) => {
    try {
      if (Object.keys(googleService).length !== 0) {
        setPlaceService(new googleService.maps.places.PlacesService(inputRef));
        setAutocompleteService(
          new googleService.maps.places.AutocompleteService()
        );
        setGoogleSessionToken(
          new googleService.maps.places.AutocompleteSessionToken()
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!uuid) {
      dispatch(addUUID(uuidv4()));
    }

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (
      Object.keys(autocompleteService).length === 0 &&
      customInputRef.current
    ) {
      getGoogleService(customInputRef.current);
    }
    // eslint-disable-next-line
  }, [customInputRef, googleService]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideLocationInput);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideLocationInput
      );
    };
    // eslint-disable-next-line
  }, [locationInputRef]);

  useEffect(() => {
    if (initialValue) {
      setInitialMountWithValue(true);
      setSearchValue(initialValue);
    }
  }, [initialValue]);

  return isFetching && !searchValue ? (
    <LoadingDots />
  ) : (
    <LocationInputWrapper ref={locationInputRef}>
      <InputRow>
        <CustomInput
          dark={dark}
          className="form-control py-4"
          wrapperClassName="mb-0 mx-0 flex-grow-1"
          type="text"
          name="address"
          value={searchValue}
          placeholder={placeholder || "What’s your home address?"}
          label={label}
          aria-label="Address"
          handleChange={(e) => handleChange(e)}
          onKeyUp={handleKeyUp}
          autoFocus={autoFocus}
          autoComplete="off"
        />
        <CustomInput
          dark={dark}
          className="form-control py-4"
          wrapperClassName="mb-0 mx-0"
          type="text"
          name="unit"
          placeholder="Unit #"
          label=""
          aria-label="Unit"
          handleChange={(e) => {
            setFieldsEdited();
            setAddressData((prev) => ({
              ...prev,
              unitNumber: e.target.value || undefined,
            }));
          }}
          onKeyUp={handleKeyUp}
          autoComplete="off"
        />
      </InputRow>
      {predictions && predictions.length > 0 && (
        <AutoCompleteList label={label} modal={modal}>
          {predictions.map((prediction, index) => {
            return (
              <li
                ref={locationPredictions[`pred_${index}`]}
                className="list-group-item prediction-li text-center"
                name={prediction.place_id}
                onClick={() => handleSelect(index)}
                key={prediction.place_id}
              >
                {prediction.description}
              </li>
            );
          })}

          <li
            ref={locationPredictions[`pred_${predictions.length}`]}
            className="list-group-item prediction-li text-center"
            onClick={() => setShowManualAddress(true)}
          >
            {`Can't find your address?`}
          </li>
        </AutoCompleteList>
      )}
      <div ref={customInputRef} className="d-none"></div>
    </LocationInputWrapper>
  );
};

LocationInput.propTypes = {
  setAddressData: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  setShowManualAddress: PropTypes.func.isRequired,
  forwardToManualAddress: PropTypes.func.isRequired,
  dark: PropTypes.bool,
  modal: PropTypes.bool,
  initialValue: PropTypes.string,
};

export default LocationInput;
