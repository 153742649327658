import styled from "styled-components";

const DisclaimerText = styled.small`
  font-size: ${(props) => props.theme.fontSize.xs};
  max-width: 1100px;
  display: block;
`;

const Disclaimer = ({ text, centered }) => {
  return (
    <DisclaimerText
      className={`text-muted text-break m-auto ${centered && "text-center"}`}
    >
      {text}
    </DisclaimerText>
  );
};

export default Disclaimer;
