import {
  AvaButton,
  FeedbackButton,
  MarketShareButton,
  NotesButton,
  Popup,
} from "./FooterButtons";
import { useSelector, useDispatch } from "../redux/hooks";
import { addContact } from "../redux/actions/contact";
import { ActiveTab } from "./types";
import { MARKET_SHARE } from "../constants";
import { getMarketShareData } from "./MarketShare/utils";
import { isResponsesPage } from "../utilsMisc";
import styled from "styled-components";
import { useState } from "react";
import { MarketShareType } from "./MarketShare/types";
import { isDwelling } from "../utils";

const StyledFooter = styled.footer`
  background: white;
  position: sticky;
  top: calc(100vh - 32px);
  bottom: 0;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  gap: 10px;
  padding: 0 10px;
  border-top: solid 1px #d8d8d8;
  z-index: 1000;
`;

const Footer = () => {
  const contact = useSelector((store) => store.contact);

  const [activeTab, setActiveTab] = useState<ActiveTab>();
  const close = () => setActiveTab(undefined);
  const toggleVisibility = (tab: ActiveTab) => {
    if (activeTab === tab) {
      close();
    } else {
      setActiveTab(tab);
    }
  };

  const hasAva = contact.user && window.location.pathname !== "/";
  const hasNotesFeedback =
    contact.user &&
    !["/", "/address", "/login"].includes(window.location.pathname);
  const hasPopover = hasAva || hasNotesFeedback;
  const property = useSelector((store) => store.property) ?? {};
  const line_of_business =
    useSelector((store) => store.session.line_of_business) ?? "";
  const { administrative_area_level_1: state } = property ?? {};

  //CRM tool initial chart data
  const dispatch = useDispatch();
  const [crmData, setCrmData] = useState<MarketShareType>({});
  /**
   * When the marketsharetool button from footer is clicked the first time, only
   * then should we be making the API call. No need to make an API call if the
   * agent never uses the tool. loadInitalChart is called from line 100 below.
   * the default as requested by product is, success quotes count, for 90 days
   * based on State level data
   */
  const loadInitialCharts = async () => {
    if (!state) {
      return;
    }
    try {
      /**
       * Use isFetchingMarketShare in the marketShare component to show a
       * spinner while the data is being fetched this is useful for first time
       * when api calls take longer time to fetch data
       */
      dispatch(addContact({ ...contact, isFetchingMarketShare: true }));
      const newData = await getMarketShareData({
        state,
        line_of_business: isDwelling() ? "Dwelling" : line_of_business,
        isForThirtyDays: false,
        isErroredPolicies: false,
      });
      dispatch(addContact({ ...contact, isFetchingMarketShare: false }));
      if (newData.auto || newData.home) {
        setCrmData(newData);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
      dispatch(addContact({ ...contact, isFetchingMarketShare: false }));
    }
  };
  const loadMarketTool = () => {
    toggleVisibility(MARKET_SHARE);
    state && loadInitialCharts();
  };
  return (
    <StyledFooter>
      {isResponsesPage() && (
        <MarketShareButton
          disabled={contact?.isFetchingMarketShare}
          $isOpen={activeTab === MARKET_SHARE}
          onClick={() => loadMarketTool()}
        />
      )}
      {hasNotesFeedback && (
        <>
          <NotesButton
            $isOpen={activeTab === "notes"}
            onClick={() => toggleVisibility("notes")}
          />
          <FeedbackButton
            $isOpen={activeTab === "feedback"}
            onClick={() => toggleVisibility("feedback")}
          />
        </>
      )}
      {hasAva && (
        <AvaButton
          $isOpen={activeTab === "ava"}
          onClick={() => toggleVisibility("ava")}
        />
      )}
      {hasPopover && (
        <Popup activeTab={activeTab} close={close} crmData={crmData} />
      )}
    </StyledFooter>
  );
};

export default Footer;
