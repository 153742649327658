import {
  NAME_REGEX,
  Tenant,
  Vacant,
  lineOfBusiness,
  mapOccupancyToPolicyForm,
  ssnPattern,
} from "../constants";
import {
  createAccountAndPrimaryContact,
  createSpouseContact,
  deleteContact,
  getAccountDetailsinSf,
  getAccountInSf,
  updateAccountInSf,
  updateAccountinPg,
  updateContact,
  updateContactsInPgByAccountId,
} from "../services/account";
import {
  createFeatureFlagContext,
  getAgentIdFromParam,
} from "../services/utils";
import { createPropertyinPg, updateProperty } from "../services/property";
import { createQuote, deleteQuote, updateQuote } from "../services/quote";
import {
  getAgentDetails,
  getAgentLicense,
  sendEmailToAgent,
} from "../services/agent";
import { getDateFormat, getLastSalesDate } from "../utils/dates";
import {
  getPagenumber,
  isPastDate,
  isValidDate,
  removeCountyText,
  validEmailRegex,
  validPhoneRegex,
} from "../utils/strings";
import { isDwelling, isFromContactPage } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import ContactInfo from "../components/ContactInfo";
import CustomButton from "../components/styled/buttons/CustomButton";
import { CustomText } from "../components/styled/typography";
import Disclaimer from "../components/Disclaimer";
import Heading from "../components/Heading";
import LoadingDots from "../components/LoadingDots";
import { Table } from "react-bootstrap";
import { TextContainer } from "../components/styled/wrappers";
import { aboutYouDisclosure } from "../utils/constants";
import { addAgent } from "../redux/actions/agent";
import { addClientDataToLogRocket } from "../services/logRocket";
import { addContact } from "../redux/actions/contact";
import { addCoverages } from "../redux/actions/coverages";
import { addDrivers } from "../redux/actions/driver";
import { addProperty } from "../redux/actions/property";
import { addVehicles } from "../redux/actions/vehicle";
import { clearSwitchPrimaryDoneCarrierIdsInRedux } from "../utils/switchPrimary";
import { createDriversFromContacts } from "../utils/driver";
import { createErrorLogger } from "../utils/errors";
import { getDriversAndVehicles } from "../services/drivers";
import { handleUpdateSession } from "../services/utils";
import moment from "moment";
import { resetQuotesStatus } from "../utils/resubmit";
import { sanitizeCoverage } from "../utils/reshop";
import { scrollToTop } from "../utils/layout";
import store from "../redux/store";
import { updateIsFetching } from "../redux/actions/fetching";
import { useFeatureFlag } from "../services/featureFlag";
import { useNavigate } from "react-router-dom";
import validator from "validator";

const logError = createErrorLogger({ file: "AboutYou.js" });
const isProduction = process.env.REACT_APP_ENV === "production";

const AboutYou = ({ handleNext }) => {
  const session = useSelector((store) => store.session);
  const property = useSelector((store) => store.property);
  const contact = useSelector((store) => store.contact);
  const coverages = useSelector((store) => store.coverages);
  const drivers = useSelector((store) => store.drivers);
  const quote = useSelector((store) => store.quote);
  const agent = useSelector((store) => store.agent);

  const isFetching = useSelector((store) => store.isFetching);

  const featureFlags = useFeatureFlag();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const lob = session.line_of_business;

  const [contactData, setContactData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    maritalStatus: "",
    suffix: "",
    gender: "",
    ssn: "",
    language: "",
  });

  const [errors, setErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    dobError: "",
    maritalStatusError: "",
    genderError: "",
    ssnError: "",
  });

  const [spouseContactData, setSpouseContactData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    suffix: "",
    ssn: "",
    language: "",
  });

  const [spouseErrors, setSpouseErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    dobError: "",
    genderError: "",
    ssnError: "",
  });

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const hasSpouse = () =>
    ["Married", "Domestic Partner"].includes(contactData.maritalStatus);

  const getDwellingUsageType = () => {
    if (session.line_of_business.includes("Home")) {
      if (property.occupancy === Tenant) {
        return "Landlord/Rental";
      }
      if (property.occupancy === Vacant) {
        return "Vacant";
      }
    }
    if (
      session.line_of_business.includes("Condo") &&
      [Tenant, Vacant].includes(property.occupancy)
    ) {
      return "Condo";
    }
    return null;
  };

  const handleAccountAndPrimaryContact = async (
    agentId,
    accountId,
    contact
  ) => {
    try {
      addClientDataToLogRocket(
        `${contactData.firstName} ${contactData.lastName}`,
        contactData.email
      );
      const primaryContactResponse = await createAccountAndPrimaryContact({
        ...contactData,
        currentStreetNumber: contact.street_number,
        currentUnitNumber: contact.unitNumber,
        currentLocality: contact.locality,
        currentState: contact.administrative_area_level_1,
        currentPostalCode: contact.postal_code,
        currentCounty: removeCountyText(
          contact.administrative_area_level_2 ?? ""
        ),
        propertyStreetNumber: property.street_number,
        propertyUnitNumber: property.unitNumber,
        propertyLocality: property.locality,
        propertyState: property.administrative_area_level_1,
        propertyPostalCode: property.postal_code,
        agentSfid: agentId ? agentId : session.agent_sfid,
        accountId: contact.sfAccountId ? contact.sfAccountId : accountId,
        splitOwner: contact.splitOwner,
        splitOwnerEmail: contact.splitOwnerEmail,
        rs: session.uuid,
        parentAccountSfId: contact.parentAccountSfId,
      });
      const { accountHerokuId, contactHerokuId } = primaryContactResponse.data;

      dispatch(
        addContact({
          ...contactData,
          accountId: accountHerokuId,
          contactId: contactHerokuId,
        })
      );

      return { accountHerokuId, contactHerokuId };
    } catch (error) {
      dispatch(addContact({ ...contactData }));
      const responseDataErrors = error.response.data.errors;
      if (responseDataErrors && responseDataErrors.length > 0) {
        let responseDataErrorMap = {};
        responseDataErrors.forEach((err) => {
          responseDataErrorMap[`${err.param}Error`] = err.msg;
        });
        setErrors({ ...responseDataErrorMap });
      }
      dispatch(updateIsFetching(false));
      logError({
        fn: "handleAccountAndPrimaryContact",
        message: "Something went wrong",
        error,
      });
      throw error;
    }
  };

  const handleSpouseContact = async (agentId, accountId) => {
    try {
      const spouseContactResponse = await createSpouseContact({
        ...spouseContactData,
        maritalStatus: contactData.maritalStatus,
        currentStreetNumber: contact.street_number,
        currentLocality: contact.locality,
        currentState: contact.administrative_area_level_1,
        currentPostalCode: contact.postal_code,
        currentCounty: removeCountyText(
          contact.administrative_area_level_2 ?? ""
        ),
        propertyStreetNumber: property.street_number,
        propertyUnitNumber: property.unitNumber,
        propertyLocality: property.locality,
        propertyState: property.administrative_area_level_1,
        propertyPostalCode: property.postal_code,
        agentSfid: agentId ? agentId : session.agent_sfid,
        accountId: accountId,
        leadSource: contact.leadSource || "Direct",
      });

      const { heroku_id } = spouseContactResponse.data.spouseData[0];

      const spouseHerokuId = heroku_id;

      dispatch(
        addContact({
          ...mapToSpouseContactData(spouseContactData),
          spouseId: spouseHerokuId,
        })
      );

      return {
        spouseHerokuId,
      };
    } catch (error) {
      dispatch(addContact(mapToSpouseContactData(spouseContactData)));
      const responseDataErrors = error.response.data.errors;
      if (responseDataErrors && responseDataErrors.length > 0) {
        let responseDataErrorMap = {};
        responseDataErrors.forEach((err) => {
          responseDataErrorMap[`${err.param}Error`] = err.msg;
        });
        setSpouseErrors({ ...responseDataErrorMap });
      }
      dispatch(updateIsFetching(false));
      logError({
        fn: "handleSpouseContact",
        message: "Something went wrong",
        error,
      });
      throw error;
    }
  };

  const isPrimaryContact = (sfAccountDetails) => {
    const { FirstName, LastName, Birthdate } =
      sfAccountDetails?.primaryContact ?? {};

    return (
      FirstName === contactData?.firstName &&
      LastName === contactData?.lastName &&
      Birthdate === contactData?.dob
    );
  };

  const validate = (data, handleErrors, spouse) => {
    let isValid = true;

    let firstNameError = data.firstName ? "" : "Please update First Name";
    firstNameError =
      // Allow Latin characters, spaces, and ,.'’-
      // = /^[\p{L} ,.'’-]+$/ in PHP
      data.firstName && !NAME_REGEX.test(data.firstName)
        ? "Please use English letters or ,.'’-"
        : firstNameError;

    let lastNameError = data.lastName ? "" : "Please update Last Name";
    lastNameError =
      // Allow Latin characters, spaces, and ,.'’-
      // = /^[\p{L} ,.'’-]+$/ in PHP
      data.lastName && !NAME_REGEX.test(data.lastName)
        ? "Please use English letters or ,.'’-"
        : lastNameError;

    let dobError = "";
    if (!data.dob) {
      dobError = "Please enter Date of Birth (MM/DD/YYYY)";
    } else {
      const dateFormat = getDateFormat(data.dob);
      if (!isValidDate(data.dob, dateFormat)) {
        dobError = "Please enter a valid date (MM/DD/YYYY)";
      } else if (!isPastDate(data.dob, dateFormat)) {
        dobError = "Date of Birth cannot be in future";
      }
    }

    const maritalStatusError = spouse
      ? ""
      : data.maritalStatus && !validator.isEmpty(data.maritalStatus || "")
      ? ""
      : "Please select marital status";
    const genderError =
      data.gender && !validator.isEmpty(data.gender || "")
        ? ""
        : "Please select gender";

    const ssnError =
      data.ssn && !ssnPattern.test(data.ssn)
        ? "Please enter valid Social Security Number"
        : "";

    let phoneError = "";
    if (!data.phone && !spouse) {
      phoneError = "Please enter your Mobile Number";
    }
    if (
      data.phone &&
      (!validPhoneRegex.test(data.phone) ||
        !validator.isMobilePhone(data.phone, "en-US"))
    ) {
      phoneError = "Please enter a valid mobile number";
    }

    let emailError = "";
    if (
      data.email &&
      (!validEmailRegex.test(data.email) || !validator.isEmail(data.email))
    ) {
      emailError = "Please enter a valid email address";
    }
    if (data.email && data.email.toLowerCase().includes("goosehead.com")) {
      emailError = "Goosehead emails are not allowed";
    }

    if (
      firstNameError ||
      lastNameError ||
      dobError ||
      maritalStatusError ||
      genderError ||
      ssnError ||
      phoneError ||
      emailError
    ) {
      isValid = false;
    }

    handleErrors({
      firstNameError,
      lastNameError,
      dobError,
      maritalStatusError,
      genderError,
      ssnError,
      phoneError,
      emailError,
    });

    return isValid;
  };

  const handleSession = async (payload) => {
    try {
      await handleUpdateSession({
        uuid: session.uuid,
        ...payload,
      });
    } catch (error) {
      logError({
        fn: "handleSession",
        message: "Something went wrong",
        error,
      });
    }
  };

  const handleDriversAndVehicles = async (
    contactId,
    spouseId,
    autoQuoteId,
    sfAccountId
  ) => {
    try {
      dispatch(updateIsFetching(true));
      const primaryContacts = {
        contactId: contactId,
        spouseId: spouseId,
        quoteId: autoQuoteId,
      };
      const discoveredDriversResponse = await getDriversAndVehicles({
        ...primaryContacts,
        ...(sfAccountId && { sfAccountId: sfAccountId }),
        ...(contact.selectedAutoPolicy && {
          autoPolicy: contact.selectedAutoPolicy,
        }),
        skipLexisNexisForExistingAccount:
          !!contact.skipLexisNexisForExistingAccount,
      }).catch((error) => {
        logError({
          fn: "handleDriversAndVehicles",
          msg: "failed to getDriversAndVehicles, creating drivers from session",
          error,
        });

        return {
          data: {
            drivers: createDriversFromContacts(),
            vehicles: [],
          },
        };
      });

      const sortedDrivers = discoveredDriversResponse.data.drivers
        .map((driver) => ({
          ...driver,
          isFromContactPage: isFromContactPage(driver),
        }))
        .sort((a, b) => {
          if (a.primary) {
            return -1;
          }
          return 1;
        });
      dispatch(addDrivers(sortedDrivers));

      const vehicles = [...discoveredDriversResponse.data.vehicles];
      dispatch(addVehicles(vehicles));

      return {
        drivers: sortedDrivers,
        vehicles,
      };
    } catch (error) {
      logError({
        fn: "handleDriversAndVehicles",
        message: "Something went wrong",
        error,
      });
      dispatch(updateIsFetching(false));
    }
  };

  const createQuoteRequests = async (
    accountHerokuId,
    contactHerokuId,
    spouseHerokuId,
    createHomeQuoteRequest = false,
    createAutoQuoteRequest = false
  ) => {
    let propertyResponse;
    const sessionBody = {};
    try {
      let homeQuoteId = null;
      let autoQuoteId = null;
      const lastSalesDate = getLastSalesDate();
      if (createHomeQuoteRequest) {
        const agentId = getAgentIdFromParam();
        const quoteResponse = await createQuote({
          lob: lob.replace("Auto", ""),
          agentSfid: agentId || contact.user,
          accountId: accountHerokuId,
          contactId: contactHerokuId,
          spouseId: spouseHerokuId,
          propertyState: property.administrative_area_level_1,
          zipcode: property.postal_code,
          squareFoot: property.squareFoot,
          dwelling_limit__c: property.dwellingCoverage,
          personalLiability: property.personal_liability,
          ...quote,
          willpurchase: session.willpurchase,
          quote_as_package__c: lob.indexOf("Auto") > 0,
          ...(property.occupancy && session.line_of_business.includes("Home")
            ? { policyForm: mapOccupancyToPolicyForm[property.occupancy] }
            : {}),
          quote_source__c: "Aviator",
          rater_session__c: session.uuid,
          aviator_start_time__c: contact.aviator_start_time__c,
        });
        const quoteData = quoteResponse.data.quoteData[0];

        dispatch(
          addContact({
            homeQuoteId: quoteData.heroku_id,
          })
        );

        homeQuoteId = quoteData.heroku_id;

        sessionBody.home_quote_id = quoteData.heroku_id;

        dispatch(
          addCoverages({
            dwelling: quoteData.dwelling_limit__c,
            personal_liability: quoteData.personal_liability__c,
            wind: quoteData.wind_hail_deductible__c,
            hurricane: quoteData.hurricane_deductible__c,
            aop: quoteData.all_other_deductible__c,
            personal_property: quoteData.personal_property__c,
            suddenWaterDamage: "Yes",
            waterBackup: quoteData.water_backup__c && "Included",
            replacementDwelling: quoteData.replacement_cost_dwelling__c,
            replacementContents: quoteData.replacement_cost_contents__c,
            homeEffectiveDate: quoteData.effective_date__c,
          })
        );

        propertyResponse = await createPropertyinPg({
          lob: lob.replace("Auto", ""),
          agentSfid: contact.user,
          accountId: accountHerokuId,
          quoteId: quoteData.heroku_id,
          willpurchase: session.willpurchase,
          property: { ...property, lastSalesDate },
        });

        const propertyData = propertyResponse.data.propertyData[0];
        dispatch(
          addContact({
            propertyId: propertyData.heroku_id,
          })
        );
        if (getDwellingUsageType()) {
          await updateProperty({
            heroku_id: propertyData.heroku_id,
            dwelling_usage__c: getDwellingUsageType(),
            policy_form__c: mapOccupancyToPolicyForm[property.occupancy],
          });
        }
        if (lob.includes("Home")) {
          dispatch(
            addProperty({
              roof_deck: propertyData.roof_deck_attachment__c,
              roof_wall: propertyData.roof_wall_connection__c,
              openingProtection: propertyData.opening_protection__c,
              predominant_roof: propertyData.predominant_roof_covering__c,
              roof_design: propertyData.roof_design__c,
            })
          );
        }
        let updatedQuoteDetails = {
          quoteId: quoteData.heroku_id,
          dwelling_limit__c: property.dwellingCoverage,
          effective_date__c: property.effectiveDate,
          expiration_date__c: moment(property.effectiveDate)
            .add(1, "y")
            .format("YYYY-MM-DD"),
          jewelry__c: property.valueJewelry,
          all_other_deductible__c: property.aopDeductible,
          wind_hail_deductible__c: property.windHailDeductible,
        };
        if (!session.willpurchase)
          updatedQuoteDetails["prior_policy_expiration_date__c"] =
            property.effectiveDate;

        if (session.line_of_business.includes("Renter")) {
          updatedQuoteDetails["personal_property__c"] =
            property.personalPropertyCoverage;
        }

        updatedQuoteDetails = sanitizeCoverage(updatedQuoteDetails);

        if (Object.keys(updatedQuoteDetails).length > 0) {
          let updatedQuote = await updateQuote(updatedQuoteDetails);
          updatedQuote = updatedQuote.data[0];

          dispatch(
            addCoverages({
              dwelling: updatedQuote.dwelling_limit__c,
              homeEffectiveDate: updatedQuote.effective_date__c,
              personal_property: updatedQuote.personal_property__c,
              aop: updatedQuote.all_other_deductible__c,
              wind: updatedQuote.wind_hail_deductible__c,
            })
          );
        }
      }
      if (createAutoQuoteRequest && !isDwelling()) {
        const agentId = getAgentIdFromParam();
        const quoteResponse = await createQuote({
          lob: "Auto",
          agentSfid: agentId || contact.user,
          accountId: accountHerokuId,
          contactId: contactHerokuId,
          spouseId: spouseHerokuId,
          propertyState: property.administrative_area_level_1,
          squareFoot: property.squareFoot,
          personalLiability: property.personal_liability,
          ...quote,
          willpurchase: session.willpurchase,
          quote_as_package__c: lob.indexOf("Auto") > 0,
          quote_source__c: "Aviator",
          rater_session__c: session.uuid,
          aviator_start_time__c: contact.aviator_start_time__c,
        });
        const quoteData = quoteResponse.data.quoteData[0];
        dispatch(
          addContact({
            autoQuoteId: quoteData.heroku_id,
          })
        );

        autoQuoteId = quoteData.heroku_id;

        sessionBody.auto_quote_id = quoteData.heroku_id;

        dispatch(
          addCoverages({
            bodily_injury: quoteData.bodily_injury__c,
            property_damage: quoteData.property_damage__c,
            uim: quoteData.uninsured_motorist__c,
            uim_property_damage:
              quoteData.uninsured_motorist_property_damage__c,
            pip: quoteData.pip__c,
            pipDeductible: quoteData.pip_deductible__c,
            pipType: quoteData.pip_type__c,
            wageLoss: quoteData.income_loss__c,
            pipLimit: quoteData.pip_limit__c,
            pipStacking: quoteData.pip_stacking__c,
            medPay: quoteData.medical_payment_auto__c,
            tort: quoteData.tort__c,
            apip: quoteData.apip__c,
            pipWorkLossWaiver: "No Coverage",
            stackedUM: quoteData.stacked_um__c,
            stackedUIM: quoteData.stacked_uim__c,
            medBenefits: quoteData.first_party_medical_benefits__c,
            priorCarrier: quoteData.prior_policy_insurer_name__c,
            yearsWithPrior: quoteData.years_with_prior_auto_carrier__c,
            monthsWithPrior: quoteData.months_with_prior_auto_carrier__c,
            priorLimits: quoteData.prior_liability_limits__c,
            autoEffectiveDate: quoteData.effective_date__c,
          })
        );
        let propertyData;
        if (!contact.autoPropertyId) {
          propertyResponse = await createPropertyinPg({
            lob: "Auto",
            agentSfid: contact.user,
            accountId: accountHerokuId,
            quoteId: quoteData.heroku_id,
            property: { ...property, lastSalesDate },
            willpurchase: session.willpurchase,
          });
          propertyData = propertyResponse?.data?.propertyData[0];
        }
        dispatch(
          addContact({
            autoPropertyId: propertyData.heroku_id,
          })
        );

        let updatedQuoteDetails = {
          quoteId: quoteData.heroku_id,
          ...(coverages.autoCoveragesForReshop ?? {}),
        };
        if (Object.keys(updatedQuoteDetails).length > 0) {
          let updatedQuote = await updateQuote(updatedQuoteDetails);
          updatedQuote = updatedQuote.data[0];
          dispatch(
            addCoverages({
              ...store.getState().coverages,
              autoEffectiveDate: updatedQuote.effective_date__c,
              bodily_injury: updatedQuote.bodily_injury__c,
            })
          );
        }
      }

      handleSession(sessionBody);
      return { homeQuoteId, autoQuoteId };
    } catch (error) {
      logError({ fn: "createQuoteRequests", error });
      return false;
    }
  };

  const requestAccountAccess = async () => {
    try {
      const currentUserDetailsResponse = await getAgentDetails(contact.user);
      const currentUserDetails = currentUserDetailsResponse.data.agentData[0];
      const response = await sendEmailToAgent({
        currentUser: {
          firstName: currentUserDetails.firstname,
          lastName: currentUserDetails.lastname,
          phone: currentUserDetails.phone,
          id: currentUserDetails.sfid,
        },
        accountOwnerId: session.agent_sfid,
        contact: {
          sfAccountId: contact.sfAccountId,
          name: `${contact.firstName} ${contact.lastName}`,
          street: contact.street_number,
          city: contact.locality,
          state: contact.administrative_area_level_1,
          zip: contact.postal_code,
        },
      });
      if (response.data.isSent) {
        dispatch(
          addContact({
            requestedAccess: true,
          })
        );
      }
      // return response.data;
    } catch (error) {
      logError({ fn: "requestAccountAccess", error });
      return false;
    }
  };

  const updateAccountNotesinPgAndSf = async (accountHerokuId, agentNotes) => {
    const accountResponseData = await updateAccountinPg(accountHerokuId, {
      quote_discovery_notes__c: agentNotes,
    });

    const accountData = accountResponseData.data.accountData;

    if (accountData.id) {
      await updateAccountInSf(accountData.id, {
        quote_discovery_notes__c: agentNotes,
      });
    }
  };

  const next = async () => {
    try {
      setSubmitButtonDisabled(true);
      if (contact.requestedAccess && !contact.isBlocked) {
        isDwelling() ? handleSubmitDwelling() : handleNext();
      } else {
        let updatedContact = contact;
        if (contact.createNewAccount && !contact.parentAccountSfId) {
          const parentAccountSfId = contact.sfAccountId;
          updatedContact = {
            ...contact,
            sfAccountId: null,
            parentAccountSfId: parentAccountSfId,
          };
          dispatch(addContact(updatedContact));
          handleUpdateSession({
            contact_data: JSON.stringify(updatedContact),
          });
        }

        let validContact = true;
        let validSpouseContact = true;
        validContact = validate(contactData, setErrors, false);
        if (hasSpouse()) {
          validSpouseContact = validate(
            spouseContactData,
            setSpouseErrors,
            true
          );
        }
        if (!(validContact && validSpouseContact)) {
          scrollToTop();
          setSubmitButtonDisabled(false);
          return;
        }
        dispatch(updateIsFetching(true));

        const agentNpnNumber = (
          await getAgentLicense({
            state: property.administrative_area_level_1,
            agent_sfid: session.logged_in_user,
          })
        )?.data?.agentLicenseData?.NPN;
        dispatch(addContact({ ...contactData, agentNpnNumber }));

        if (hasSpouse()) {
          dispatch(addContact(mapToSpouseContactData(spouseContactData)));
        }

        let isBlocked = false;
        let agentNotes = null;

        const { firstName, lastName, dob, phone, email } = contactData;
        const accountResponse = await getAccountInSf({
          firstName,
          lastName,
          dob,
          city: contact.locality,
          currentAgentId: contact.user,
          isAgentRater: true,
          zip: contact.postal_code,
          phone,
          email,
          state: contact.administrative_area_level_1,
          accountQuotedFromSf: !!contact.accountQuotedFromSf,
          ...(hasSpouse() && {
            spouseFirstName: spouseContactData.firstName,
            spouseLastName: spouseContactData.lastName,
            spouseDob: spouseContactData.dob,
            spousePhone: spouseContactData.phone,
            spouseEmail: spouseContactData.email,
          }),
        });
        const accountData = accountResponse.data.accountData;
        if (contact.createNewAccount) {
          accountData.parentAccountId = accountData.accountId;
          accountData.accountId = null;
        }

        if (Object.keys(accountData).length && accountData.isBlock) {
          isBlocked = true;
          dispatch(
            addContact({
              isBlocked: true,
              blockReason: accountData.reason,
            })
          );
        }

        if (accountData.agentId) {
          handleUpdateSession({
            agent_sfid: accountData.agentId,
            assignmentType: null,
          });
          const agentDetails = await getAgentDetails(accountData.agentId);
          dispatch(addAgent(agentDetails.data.agentData[0]));
        }

        if (accountData.accountId) {
          if (!contact.sfAccountId && !contact.accountIdFromUrl) {
            dispatch(
              addContact({
                sfAccountId: accountData.accountId,
              })
            );
          }
        }

        if (accountData.accountId || updatedContact.sfAccountId) {
          let sfAccountDetails = await getAccountDetailsinSf(
            updatedContact.sfAccountId
              ? updatedContact.sfAccountId
              : accountData.accountId
          );

          sfAccountDetails = sfAccountDetails.data;

          dispatch(
            addContact({
              isPrimaryContact: isPrimaryContact(sfAccountDetails),
            })
          );

          dispatch(
            addContact({
              phone: sfAccountDetails.primary_contact_mobile__c,
              email: sfAccountDetails.primary_contact_email_address__c,
            })
          );

          if (sfAccountDetails.quote_discovery_notes__c) {
            agentNotes = sfAccountDetails.quote_discovery_notes__c;

            if (contact.agentNotes) {
              if (contact.updatedNotesFromSf) {
                agentNotes = contact.agentNotes;
              } else if (
                sfAccountDetails.quote_discovery_notes__c !== contact.agentNotes
              ) {
                agentNotes =
                  sfAccountDetails.quote_discovery_notes__c +
                  "\n" +
                  contact.agentNotes;
              }
            }
            dispatch(
              addContact({
                agentNotes: agentNotes,
              })
            );
          }
        }

        const createAutoQuote = async (
          accountHerokuId,
          contactHerokuId,
          spouseHerokuId,
          currentDrivers = drivers
        ) => {
          const result = {};
          result.autoQuoteId = (
            await createQuoteRequests(
              accountHerokuId,
              contactHerokuId,
              spouseHerokuId,
              lob.indexOf("Auto") !== 0 && !contact.homeQuoteId,
              lob.includes("Auto") && !contact.autoQuoteId
            )
          ).autoQuoteId;

          if (result.autoQuoteId && !currentDrivers.length) {
            const driversAndVehicles = await handleDriversAndVehicles(
              contactHerokuId,
              spouseHerokuId,
              result.autoQuoteId,
              contact.sfAccountId || accountData.accountId
            );
            Object.assign(result, driversAndVehicles);
          }

          return result;
        };

        let accountHerokuId = contact.accountId;
        // quoting this contact for the first time
        if (!contact.accountId) {
          const resp = await handleAccountAndPrimaryContact(
            accountData.agentId,
            accountData.accountId,
            updatedContact
          );

          const { contactHerokuId } = resp;
          accountHerokuId = resp.accountHerokuId;

          let spouseHerokuId = null;
          if (hasSpouse()) {
            const spouseDetails = await handleSpouseContact(
              accountData.agentId,
              accountHerokuId
            );
            spouseHerokuId = spouseDetails.spouseHerokuId;
          } else {
            dispatch(
              addContact({
                spouseDob: undefined,
                spouseId: undefined,
                spousefirstName: undefined,
                spouselastName: undefined,
                spouseSuffix: undefined,
                spouseSsn: undefined,
                spouseLanguage: undefined,
              })
            );
          }

          await createAutoQuote(
            accountHerokuId,
            contactHerokuId,
            spouseHerokuId
          );
        } else {
          // contact already exists
          let driversToUpdate = drivers.map((driver) => ({
            ...driver,
            isFromContactPage: isFromContactPage(driver),
          }));
          if (agentNotes) {
            await updateAccountNotesinPgAndSf(contact.accountId, agentNotes);
          }
          const contactUpdate = {
            ...contactData,
            dob: contactData.dob.indexOf("**") !== -1 ? null : contactData.dob,
            contactId: contact.contactId,
            id: null, // this will minimize SF sync failures due to duplicate IDs
            email: contactData.email ?? "",
            // this is a hack, deleting the email won't do anything as it's undefined
            // will need to use Formik when we refactor this
          };
          if (contactUpdate.dob == null) {
            delete contactUpdate.dob;
          }
          await updateContact(contactUpdate);
          dispatch(
            addContact({
              ...contactData,
            })
          );
          let spouseHerokuId = null;
          if (hasSpouse()) {
            if (!contact.spouseId) {
              const spouseDetails = await handleSpouseContact(
                session.agent_sfid,
                contact.accountId
              );
              spouseHerokuId = spouseDetails.spouseHerokuId;
              dispatch(addContact({ spouseId: spouseHerokuId }));
              if (contact.homeQuoteId) {
                await updateQuote({
                  quoteId: contact.homeQuoteId,
                  spouseId: spouseHerokuId,
                });
              }

              if (contact.autoQuoteId) {
                await updateQuote({
                  quoteId: contact.autoQuoteId,
                  spouseId: spouseHerokuId,
                });
              }
              const spouseDriver = {
                firstName: spouseContactData.firstName,
                lastName: spouseContactData.lastName,
                suffix: spouseContactData.suffix || "",
                dob: spouseContactData.dob,
                gender: spouseContactData.gender,
                maritalStatus: contactData.maritalStatus || "Married",
                driverLicense: "",
                ssn: spouseContactData.ssn ?? null,
                language: spouseContactData.language,
                relationship: "Spouse",
                dLState: property.administrative_area_level_1,
                contactId: spouseDetails.spouseHerokuId,
                selected: true,
                spouse: true,
                isFromContactPage: true,
              };

              driversToUpdate = driversToUpdate.map((driver) => {
                if (driver.primary) {
                  return {
                    ...driver,
                    firstName: contactData.firstName,
                    lastName: contactData.lastName,
                    dob: contactData.dob,
                    maritalStatus: contactData.maritalStatus,
                    suffix: contactData.suffix,
                    gender: contactData.gender,
                    ssn:
                      contactData.ssn && driver.ssn !== contactData.ssn
                        ? contactData.ssn
                        : driver.ssn,
                    language: contactData.language,
                  };
                }

                if (driver.spouse && hasSpouse()) {
                  return {
                    ...driver,
                    firstName: spouseContactData.firstName,
                    lastName: spouseContactData.lastName,
                    dob: spouseContactData.dob,
                    maritalStatus: contactData.maritalStatus || "Married",
                    suffix: spouseContactData.suffix,
                    gender: spouseContactData.gender,
                    ssn:
                      spouseContactData.ssn &&
                      driver.ssn !== spouseContactData.ssn
                        ? spouseContactData.ssn
                        : driver.ssn,
                    language: spouseContactData.language,
                  };
                }

                return driver;
              });

              driversToUpdate = driversToUpdate.filter(
                (driver) =>
                  !(
                    driver.firstName?.toLowerCase() ===
                      spouseDriver.firstName?.toLowerCase() &&
                    moment(driver?.dob)?.toLocaleString() ===
                      moment(spouseDriver?.dob).toLocaleString()
                  )
              );
              driversToUpdate.splice(1, 0, spouseDriver);
            } else {
              const spouseContactUpdate = {
                ...spouseContactData,
                dob:
                  spouseContactData.dob.indexOf("**") !== -1
                    ? null
                    : spouseContactData.dob,
                maritalStatus: contact.maritalStatus,
                contactId: contact.spouseId,
                id: null, // this will minimize SF sync failures due to duplicate IDs
                email: spouseContactData.email ?? "",
                // this is a hack, deleting the email won't do anything as it's undefined
                // will need to use Formik when we refactor this
              };
              if (spouseContactUpdate.dob == null) {
                delete spouseContactUpdate.dob;
              }
              if (String(spouseContactUpdate.phone).trim() === "") {
                delete spouseContactUpdate.phone;
              }
              await updateContact(spouseContactUpdate).catch((err) => {
                err._meta = {
                  isSpouse: true,
                };
                throw err;
              });
              spouseHerokuId = contact.spouseId;
              dispatch(addContact(mapToSpouseContactData(spouseContactData)));
            }
          } else {
            if (contact.spouseId) {
              if (contact.spouseHomeQuoteId) {
                await deleteQuote(contact.spouseHomeQuoteId);
              }

              if (contact.spouseAutoQuoteId) {
                await deleteQuote(contact.spouseAutoQuoteId);
              }

              if (contact.homeQuoteId) {
                await updateQuote({
                  quoteId: contact.homeQuoteId,
                  heroku_spouse_contact_id: null,
                });
              }

              if (contact.autoQuoteId) {
                await updateQuote({
                  quoteId: contact.autoQuoteId,
                  heroku_spouse_contact_id: null,
                });
              }

              await deleteContact(contact.spouseId);
              dispatch(
                addContact({
                  spouseDob: undefined,
                  spouseId: undefined,
                  spousefirstName: undefined,
                  spouselastName: undefined,
                  spouseSuffix: undefined,
                  spouseSsn: undefined,
                  spouseLanguage: undefined,
                  spouseHomeQuoteId: undefined,
                  spouseAutoQuoteId: undefined,
                })
              );
              driversToUpdate = driversToUpdate.filter(
                (driver) => !driver.spouse
              );
            }
          }

          if (!contact.autoQuoteId) {
            const autoQuote = await createAutoQuote(
              contact.accountId,
              contact.contactId,
              spouseHerokuId,
              driversToUpdate
            );
            if (
              driversToUpdate.length === 0 &&
              autoQuote?.drivers?.length > 0
            ) {
              driversToUpdate = [...autoQuote.drivers];
            }
          }

          driversToUpdate = driversToUpdate.map((driver) => {
            if (driver.primary) {
              return {
                ...driver,
                firstName: contactData.firstName,
                lastName: contactData.lastName,
                dob: contactData.dob,
                maritalStatus: contactData.maritalStatus,
                suffix: contactData.suffix,
                gender: contactData.gender,
                ssn:
                  contactData.ssn && driver.ssn !== contactData.ssn
                    ? contactData.ssn
                    : driver.ssn,
                language: contactData.language,
              };
            }

            if (driver.spouse && hasSpouse()) {
              return {
                ...driver,
                firstName: spouseContactData.firstName,
                lastName: spouseContactData.lastName,
                dob: spouseContactData.dob,
                maritalStatus: contactData.maritalStatus || "Married",
                suffix: spouseContactData.suffix,
                gender: spouseContactData.gender,
                ssn:
                  spouseContactData.ssn && driver.ssn !== spouseContactData.ssn
                    ? spouseContactData.ssn
                    : driver.ssn,
                language: spouseContactData.language,
              };
            }

            return driver;
          });

          dispatch(addDrivers([...driversToUpdate]));

          let accountDataToUpdate = {};
          let contactDataToUpdate = {};

          if (accountData.accountId) {
            accountDataToUpdate = {
              ...accountDataToUpdate,
              id: contact.sfAccountId || accountData.accountId,
              name: `${contactData.lastName} Household`,
            };
          }
          if (accountData.agentId) {
            accountDataToUpdate = {
              ...accountDataToUpdate,
              sf_ownerid: accountData.agentId,
            };
            contactDataToUpdate = {
              ...contactDataToUpdate,
              sf_ownerid: accountData.agentId,
            };
          }
          if (Object.keys(accountDataToUpdate).length) {
            await updateAccountinPg(contact.accountId, accountDataToUpdate);
          }

          if (Object.keys(contactDataToUpdate).length) {
            await updateContactsInPgByAccountId(
              contact.accountId,
              contactDataToUpdate
            );
          }
          const hasAuto = session.line_of_business.includes("Auto");
          if (!hasAuto) {
            // skip the auto pages, go to the results page
            await resetQuotesStatus();
          }
        }

        if (agentNotes) {
          await updateAccountNotesinPgAndSf(accountHerokuId, agentNotes);
        }

        if (isBlocked) {
          dispatch(updateIsFetching(false));
        } else {
          if (!contact.addSkippedHomeQuote) {
            isDwelling() ? handleSubmitDwelling() : handleNext();
          }
        }
      }
    } catch (error) {
      logError({
        fn: "next",
        message: "Something went wrong",
        error,
      });
      dispatch(
        addContact({
          ...contactData,
          ...mapToSpouseContactData(spouseContactData),
        })
      );
      const responseDataErrors = error.response.data.errors;
      if (responseDataErrors && responseDataErrors.length > 0) {
        const createResponseErrorMapping = (overrides = {}) =>
          createErrorMapping({
            errorList: responseDataErrors,
            ...overrides,
          });

        switch (error.response.config.url) {
          case "/api/sfaccountpcontact":
          case "/api/sfaccountcheck":
            if (error._meta?.isSpouse) {
              setSpouseErrors({
                ...spouseErrors,
                ...createResponseErrorMapping({
                  getFieldName: (err) => err?.context?.key,
                  getMessage: (err) => "Invalid value",
                }),
              });
            } else {
              setErrors({
                ...errors,
                ...createResponseErrorMapping({
                  getFieldName: (err) => err?.context?.key,
                  getMessage: (err) => "Invalid value",
                }),
              });
            }

            break;
          case "/api/addspouse":
            setSpouseErrors({
              ...spouseErrors,
              ...createResponseErrorMapping({
                getFieldName: (err) => err?.param,
                getMessage: (err) => err?.msg,
              }),
            });
            break;
          default:
            break;
        }
      }
      dispatch(updateIsFetching(false));
    } finally {
      setSubmitButtonDisabled(false);
    }
  };

  const handleContinueToAuto = async () => {
    await next();
    await createQuoteRequests(
      contact.accountId,
      contact.contactId,
      contact.spouseId,
      true,
      false
    );

    isDwelling() ? handleSubmitDwelling() : handleNext();
  };

  const handleSubmitHome = async () => {
    await next();
    const { homeQuoteId } = await createQuoteRequests(
      contact.accountId,
      contact.contactId,
      contact.spouseId,
      true,
      false
    );

    if (homeQuoteId) {
      await updateQuote({
        quoteId: homeQuoteId,
        quote_as_package__c: isDwelling()
          ? "No"
          : session.line_of_business.includes("Auto")
          ? "Yes"
          : "No",
      });
    }

    if (contact.autoQuoteId && !isDwelling()) {
      await updateQuote({
        quoteId: contact.autoQuoteId,
        quote_as_package__c: session.line_of_business.includes("Auto")
          ? "Yes"
          : "No",
      });
    }
    await resetQuotesStatus();

    await handleUpdateSession({
      uuid: session.uuid,
      line_of_business: isDwelling()
        ? lineOfBusiness.HOME
        : session.line_of_business,
      page_stopped: getPagenumber(
        isDwelling() ? lineOfBusiness.HOME : session.line_of_business,
        "Responses"
      ),
    });

    const featureFlagContext = createFeatureFlagContext();
    if (featureFlagContext.key) {
      await featureFlags.initialize(featureFlagContext);
    }
    clearSwitchPrimaryDoneCarrierIdsInRedux();

    navigate(`/agent/${session.line_of_business}/Responses`);
  };

  const handleSubmitDwelling = async () => {
    await updateQuote({
      quoteId: store.getState().contact.homeQuoteId,
      quote_as_package__c: "No",
    });

    await resetQuotesStatus();

    await handleUpdateSession({
      uuid: session.uuid,
      line_of_business: lineOfBusiness.HOME,
      page_stopped: getPagenumber(lineOfBusiness.HOME, "Responses"),
    });

    navigate(`/agent/${session.line_of_business}/Responses`);
  };

  useEffect(() => {
    dispatch(updateIsFetching(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (contact.isBlocked) {
      const checkAccess = async () => {
        const accountResponse = await getAccountInSf({
          firstName: contact.firstName,
          lastName: contact.lastName,
          dob: contact.dob,
          state: contact.administrative_area_level_1,
          city: contact.locality,
          currentAgentId: contact.user,
          isAgentRater: true,
          zip: contact.postal_code,
          phone: contact.phone,
          email: contact.email,
          accountQuotedFromSf: !!contact.accountQuotedFromSf,
          ...(hasSpouse() && {
            spouseFirstName: spouseContactData.firstName,
            spouseLastName: spouseContactData.lastName,
            spouseDob: spouseContactData.dob,
            spousePhone: spouseContactData.phone,
            spouseEmail: spouseContactData.email,
            state: contact.administrative_area_level_1,
          }),
        });
        const accountData = accountResponse.data.accountData;

        if (Object.keys(accountData).length && !accountData.isBlock) {
          dispatch(
            addContact({
              isBlocked: false,
            })
          );
        }
      };
      checkAccess();
    }
    // eslint-disable-next-line
  }, [contact.accountId]);

  return isFetching ? (
    <LoadingDots />
  ) : contact.isBlocked ? (
    <>
      <div className="m-5 p-5">
        <Heading
          text={`This account has been locked as a possible duplicate.
          The agent who originally quoted this client will be able to unlock the quote, please reach out and resolve.
          If you are unable to resolve, contact your sales manager or Agent Support for assistance.`}
          semibold
        />
      </div>
      <div className="m-5 px-5 text-center">
        <Table>
          <thead>
            <tr>
              <th>ACCOUNT OWNER</th>
              <th>OWNER PHONE</th>
              <th>NAME</th>
              <th>MAILING ADDRESS</th>
              <th>POLICY STATUS</th>
              {!isProduction && <th>REASON FOR BLOCK</th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{`${agent.firstname} ${agent.lastname}`}</td>
              <td>{agent.phone}</td>
              <td>{`${contact.firstName} ${contact.lastName}`}</td>
              <td>{`${contact.street_number}., ${contact.locality}, ${contact.administrative_area_level_1} ${contact.postal_code}`}</td>
              <td>Current Client</td>
              {!isProduction && <td>{contact.blockReason}</td>}
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="m-3 text-center">
        <CustomButton
          handleClick={requestAccountAccess}
          title="Request Access"
          disabled={contact.requestedAccess}
        />
      </div>
      {contact.requestedAccess && (
        <div className="m-3 text-center">
          <CustomText color="red">Request Sent</CustomText>
        </div>
      )}
    </>
  ) : (
    <>
      <ContactInfo
        contactData={contactData}
        setContactData={setContactData}
        errors={errors}
        setErrors={setErrors}
      />
      {hasSpouse() && (
        <ContactInfo
          spouse={true}
          contactData={spouseContactData}
          maritalStatus={contactData.maritalStatus}
          setContactData={setSpouseContactData}
          errors={spouseErrors}
          setErrors={setSpouseErrors}
        />
      )}
      {!contact.addSkippedHomeQuote ? (
        <div className="mt-4 mb-3 justify-content-center d-flex">
          <CustomButton
            handleClick={next}
            title={isDwelling() ? "Submit" : "Continue"}
            disabled={submitButtonDisabled}
          />
        </div>
      ) : (
        <>
          {!isDwelling() && (
            <div className="mt-4 mb-3 justify-content-center d-flex">
              <CustomButton
                handleClick={handleContinueToAuto}
                title="Continue To Auto"
              />
            </div>
          )}

          <div className="mt-3 mb-3 justify-content-center d-flex">
            <CustomButton
              handleClick={handleSubmitHome}
              title={`Submit ${
                isDwelling() ? "Dwelling" : lob.replace("Auto", "")
              }${lob.includes("Renter") ? "'s" : ""}`}
              confirmButton
              light
            />
          </div>
        </>
      )}
      <TextContainer maxWidth="900px">
        <Disclaimer text={aboutYouDisclosure} centered={true} />
      </TextContainer>
    </>
  );
};

export default AboutYou;

function createErrorMapping({
  errorList = [],
  getFieldName = () => undefined,
  getMessage = () => "Invalid value",
} = {}) {
  return errorList.reduce((acc, err) => {
    const fieldName = getFieldName(err);
    const message = getMessage(err);

    if (fieldName) {
      acc[`${fieldName}Error`] = message;
    }

    return acc;
  }, {});
}

function mapToSpouseContactData(contactData) {
  return {
    spouseDob: contactData.dob,
    spouseEmail: contactData.email,
    spousefirstName: contactData.firstName,
    spouseGender: contactData.gender,
    spouseLanguage: contactData.language,
    spouselastName: contactData.lastName,
    spousePhone: contactData.phone,
    spouseSsn: contactData.ssn,
    spouseSuffix: contactData.suffix,
  };
}
