import * as LDClient from "launchdarkly-js-client-sdk";

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { shallowEqual, useSelector } from "react-redux";

import axios from "axios";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useMutation } from "@tanstack/react-query";

const defaultProvider = {
  isInitialized: false,
  flags: {},
  getFlag: (flag, context) => false,
};

const launchDarklyClientID = process.env.REACT_APP_LAUNCHDARKLY_CLIENTID;
const FeatureFlagContext = createContext(defaultProvider);
let client = null;

export const useFeatureFlag = () => useContext(FeatureFlagContext);

/**
 * FeatureFlagProvider is a React component that provides a context for feature
 * flags. It initializes the LaunchDarkly client and provides methods to get
 * feature flag values.
 *
 * @param {Object} props - The props for the component.
 * @returns {JSX.Element} The FeatureFlagProvider component.
 */
export const FeatureFlagProvider = (props) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [flags, setFlags] = useState(defaultProvider.flags);
  const [context, setContext] = useState({});

  const getLdHashMutation = useMutation({
    mutationFn: async (context) => {
      const response = await axios.post("/api/featureFlag", context);
      return response.data;
    },
  });

  const session = useSelector((store) => store.session, shallowEqual);

  /** Initializes the LaunchDarkly client with the provided context. */
  const initialize = useCallback(async () => {
    try {
      if (!client && context && context.key) {
        console.log(
          "🚨Initializing LaunchDarkly client with context:",
          context
        );

        const hashResponse = await getLdHashMutation.mutateAsync(context);

        client = LDClient.initialize(launchDarklyClientID, context, {
          hash: hashResponse.data,
        });

        client.waitUntilReady();

        setIsInitialized(true);

        console.log("🚨LaunchDarkly client initialized");
      } else {
        console.log("Identifying LaunchDarkly client with context:", context);

        client.identify(context);

        await client.waitUntilReady();
      }
    } catch (error) {
      console.error("Error initializing LaunchDarkly client:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  /**
   * Gets the value of the specified feature flag.
   *
   * @param {string} flag - The name of the feature flag to get the value of.
   * @returns {any} The value of the feature flag.
   * @throws {Error} Throws an error if the Feature Flag service is not
   *   initialized.
   */
  const handleGetFlag = useCallback(
    (flag, defaultValue) => {
      try {
        if (!client || !isInitialized) {
          throw new Error("Feature Flag service is not initialized.");
        }

        return client.variation(flag, defaultValue);
      } catch (error) {
        console.error("Error getting feature flag value:", error);

        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInitialized, client]
  );

  /** Listen for changes to feature flags and update the context. */
  useEffect(() => {
    if (isInitialized && client) {
      const changeHandler = (updatedFlags) => {
        setFlags(updatedFlags);
      };

      client.on("change", changeHandler);

      return () => client.off("change", changeHandler);
    }
  }, [isInitialized]);

  /** Initialize the Feature Flag service when the context is set. */
  useDeepCompareEffect(() => {
    if (context && context.key) {
      initialize();
    }
  }, [context]);

  /** Sync session data with context. */
  useEffect(() => {
    if (!session) return;

    const contact =
      session.contact_data && typeof session.contact_data === "string"
        ? JSON.parse(session.contact_data)
        : session.contact_data ?? {};

    const property =
      session.property_data && typeof session.property_data === "string"
        ? JSON.parse(session.property_data)
        : session.property_data ?? {};
    const key = [
      contact?.agentUserId,
      session?.logged_in_user,
      session?.agent_sfid,
      contact?.sfAccountId,
      contact?.accountId,
    ].find(
      (val) =>
        val != null && (typeof val === "string" ? val.trim().length > 0 : true)
    );

    const _context = {
      kind: "user",
      type: "agent",
      anonymous: false,
      key,
      street: property?.street_number,
      zip: property?.postal_code,
      state: property?.administrative_area_level_1,
      city: property?.locality,
      leadEmail: contact?.email,
      name: contact?.agentName,
      leadDob: contact?.dob,
      leadName: contact?.firstName
        ? `${contact?.firstName} ${contact?.lastName}`
        : "",
      agentName: contact?.agentName,
      agentEmail: contact?.agentEmail,
      agentState: contact?.agentState,
      agentProfileId: contact?.agentProfileId,
      loggedInUser: contact?.agentUserId,
    };

    setContext(_context);
  }, [session]);

  const value = {
    initialize: () => {},
    getFlag: handleGetFlag,
    isInitialized,
    flags,
  };

  return (
    <FeatureFlagContext.Provider value={value}>
      {props.children}
    </FeatureFlagContext.Provider>
  );
};
