import { ReactComponent as AutoRequired } from "../../../assets/images/components/response-page/AutoRequired.svg";
import { ReactComponent as CondoRequired } from "../../../assets/images/components/response-page/CondoRequired.svg";
import { ReactComponent as HomeRequired } from "../../../assets/images/components/response-page/HomeRequired.svg";
import { LineOfBusinessC } from "../../../types";
import { QuoteAlert } from "./QuoteAlert";
import React from "react";
import { ReactComponent as RentersRequired } from "../../../assets/images/components/response-page/RentersRequired.svg";
import { isAutoOnly } from "../../../utilsMisc";
import { useSelector } from "../../../redux/hooks";

const PACKAGE_REQUIRED_ICON_MAP: Record<string, React.JSX.Element> = {
  HomeAuto: <HomeRequired />,
  CondoAuto: <CondoRequired />,
  RenterAuto: <RentersRequired />,
};

const PackageRequiredAlertIcon = ({
  quoteLob,
}: {
  quoteLob: LineOfBusinessC;
}) => {
  const session = useSelector((store) => store.session);
  const sessionLob = session.line_of_business;
  if (sessionLob === "Auto") {
    return <HomeRequired />;
  }
  if (quoteLob === "Auto" && sessionLob) {
    return PACKAGE_REQUIRED_ICON_MAP[sessionLob] || null;
  }
  return <AutoRequired />;
};

export const PackageRequiredAlert = ({
  quoteLob,
}: {
  quoteLob: LineOfBusinessC;
}) => {
  const session = useSelector((store) => store.session);
  const sessionLob = session.line_of_business;
  const lobNeeded = isAutoOnly()
    ? // If it's auto only, we need home quoted to make a bundle
      "Home"
    : quoteLob === "Auto"
    ? // If it's a bundle (can't be home only) and this quote is Auto
      // we need the home/renters/condo/dwelling to make a bundle
      sessionLob?.replace("Auto", "")
    : // If it's home only, we need auto quoted to make a bundle
      "Auto";
  const tooltipText = `Packaging with a supporting ${lobNeeded} is required. The “package discount” is included in this rate.`;

  return (
    <QuoteAlert
      tooltipText={tooltipText}
      alertIcon={<PackageRequiredAlertIcon quoteLob={quoteLob} />}
      alertText="required"
      $backgroundColor="#ffdda3"
      $color="#794e03"
      $paddingX="3px"
    />
  );
};
