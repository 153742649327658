import { useEffect, useState } from "react";

import { CLIENT_ADDRESS_TYPE } from "../constants";
import FormContainer from "./styled/forms/FormContainer";
import Heading from "./Heading";
import LocationInput from "./inputs/LocationInput";
import ManualAddress from "./ManualAddress";
import { Row } from "react-bootstrap";
import { googleServiceLoader } from "../services/address";
import { isValidAddress } from "../utils/strings";

const ClientAddress = ({
  handleAddressData,
  setAddressData,
  addressData,
  addressError,
  isSelectedAddressInValid,
  setIsSelectedAddressInValid,
  address = "",
  addressType,
  includeActions = addressType === CLIENT_ADDRESS_TYPE.CURRENT,
}) => {
  const [showManualAddress, setShowManualAddress] = useState(false);
  const headingText =
    addressType === CLIENT_ADDRESS_TYPE.CURRENT
      ? "What is your client's current address?"
      : "What is your client's mailing address?";
  const [googleService, setGoogleService] = useState({});

  const forwardToManualAddress = (addressData) => {
    if (!isValidAddress(addressData)) {
      setIsSelectedAddressInValid(false);
      setShowManualAddress(true);
    }
  };

  const loadGoogleService = async () => {
    const { google } = await googleServiceLoader.loadScript();
    if (google && Object.keys(googleService).length === 0) {
      setGoogleService(google);
    }
  };

  useEffect(() => {
    loadGoogleService();
    //eslint-disable-next-line
  }, []);

  const hasErrors =
    Object.values(addressError).some(Boolean) || isSelectedAddressInValid;
  const errorMessage = !hasErrors
    ? ""
    : Object.values(addressError).find(Boolean) ??
      "Invalid Address provided. Please update missing fields.";

  return showManualAddress ? (
    <Row>
      <ManualAddress
        dark={false}
        includeCancel={includeActions}
        includeContinue={includeActions}
        setAddressData={setAddressData}
        addressData={addressData}
        errors={addressError}
        handleAddressData={handleAddressData}
        setShowManualAddress={setShowManualAddress}
        isSelectedAddressInValid={isSelectedAddressInValid}
        Header={() => <Heading text={headingText} bold />}
      />
    </Row>
  ) : (
    <>
      <Heading text={headingText} bold />
      <div className="justify-content-center d-flex">
        <FormContainer big>
          <Row
            className="my-4 justify-content-center d-flex flex-column mx-auto"
            style={{ maxWidth: 600 }}
          >
            <LocationInput
              label="Address"
              initialValue={address}
              aria-label="Current Address"
              setAddressData={setAddressData}
              placeholder="123 Main St., Dallas, TX 75201"
              setShowManualAddress={setShowManualAddress}
              forwardToManualAddress={forwardToManualAddress}
              googleService={googleService}
              onBlur={handleAddressData}
              autoFocus={false}
            />
            {hasErrors && (
              <small className="text-left fw-light font-italic text-danger mt-1">
                {errorMessage}
              </small>
            )}
          </Row>
        </FormContainer>
      </div>
    </>
  );
};

export default ClientAddress;
