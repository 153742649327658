// @ts-check

import { getAxiosErrorDetails, isAxiosError } from "./http";

/**
 * Use to:
 *
 * - Avoid nested try/catch blocks
 * - Don't stop execution on error
 *
 * @param {() => any | Error} fn
 * @param {(error: Error) => any} handleError
 * @param {() => any} [finallyFn]
 */
export function tryCatch(fn, handleError, finallyFn) {
  try {
    return fn();
  } catch (error) {
    return handleError(error);
  } finally {
    finallyFn?.();
  }
}

/** @param {object} defaults */
export function createErrorLogger(defaults) {
  return ({ error, ...rest }) =>
    logError({
      error,
      ...defaults,
      ...rest,
    });
}

export function logError({ error, err, ...rest }) {
  error = error ?? err;

  console.error({
    ...rest,
    error,
    ...(isAxiosError(error) ? getAxiosErrorDetails(error) : {}),
  });
}
