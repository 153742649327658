import produce from "immer";
import { ADD_CONTACT, CLEAR_CONTACT } from "../constants";
import { Contact } from "./types";

const initialState: Contact = {};

export const contactReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_CONTACT:
      draft = { ...draft, ...action.payload };
      return draft;
    case CLEAR_CONTACT:
      draft = {};
      return draft;
    default:
      return draft;
  }
}, initialState);
