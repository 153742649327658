import styled from "styled-components";

export const Table = styled.table`
  padding: 0;
  border-spacing: 0;
`;

export const TableRow = styled.tr`
  padding: 0;
`;

export const TableCol = styled.td`
  text-align: center;
  width: ${(props) => (props.width ? props.width : "")};
`;

export const TableHeader = styled.th`
  font-weight: 300;
  padding: 0;
  text-align: center;
`;

export const DeleteButton = styled.button`
  max-height: 25px;
  max-width: 25px;
  color: #6cb545;
  background-color: transparent;
  border: none;
  transform: translateY(3rem);
  & :hover {
    transform: scale(1.1);
  }
`;

export const ActionButton = styled.button`
  background: #6cb545;
  color: white;
  height: 30px;
  border: none;
  border-radius: 20px;
  padding: 0 15px;
`;

export const customTableStyle = {
  borderBottom: "1px solid #2D424D",
};
