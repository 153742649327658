import { ADD_AGENT, ADD_AGENT_REVIEWS } from "../constants";

import produce from "immer";

const initialState = {};
export const agentReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_AGENT:
      draft = { ...draft, ...action.payload };
      return draft;
    default:
      return draft;
  }
}, initialState);

export const agentReviewsReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_AGENT_REVIEWS:
      draft = { ...draft, ...action.payload };
      return draft;
    default:
      return draft;
  }
}, initialState);
