import { agentReducer, agentReviewsReducer } from "./agentReducer";
import { fetchingPriorLimitsReducer, fetchingReducer } from "./fetchingReducer";

import { RESET_STORE } from "../constants";
import { combineReducers } from "redux";
import { contactReducer } from "./contactReducer";
import { coverageReducer } from "./coverageReducer";
import { coveragesModalReducer } from "./coveragesModalReducer";
import { driversReducer } from "./driversReducer";
import { fetchingQuotesReducer } from "./fetchingQuotesReducer";
import { finalQuotesReducer } from "./QTI/finalQuotesReducer";
import { propertyReducer } from "./propertyReducer";
import { quoteReducer } from "./quoteReducer";
import { recalculatingReducer } from "./QTI/recalculatingReducer";
import { sessionReducer } from "./sessionReducer";
import { tokenReducer } from "./tokenReducer";
import { userReducer } from "./userReducer";
import { uuidReducer } from "./uuidReducer";
import { vehiclesReducer } from "./vehiclesReducer";

const appReducer = combineReducers({
  session: sessionReducer,
  contact: contactReducer,
  property: propertyReducer,
  drivers: driversReducer,
  vehicles: vehiclesReducer,
  coverages: coverageReducer,
  agent: agentReducer,
  agentReviews: agentReviewsReducer,
  uuid: uuidReducer,
  isFetching: fetchingReducer,
  isFetchingQuotes: fetchingQuotesReducer,
  isFetchingPriorLimits: fetchingPriorLimitsReducer,
  token: tokenReducer,
  user: userReducer,
  quote: quoteReducer,
  finalQuotes: finalQuotesReducer,
  isRecalculating: recalculatingReducer,
  isCoveragesModalOpen: coveragesModalReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};
