import * as salesForce from "../../utils/sales-force";

import { Accordion, Button } from "react-bootstrap";
import {
  CarrierLogoNameWrapper,
  StyledCheckoutInfo,
  StyledIcon,
  StyledQuoteCard,
  StyledQuoteCardList,
  StyledQuoteLobContainer,
} from "./styled";
import {
  DEFAULT_UMBRELLA_COVERAGE,
  UMBRELLA_LINE_OF_BUSINESS,
  VIVINT_SMART_HOME_DEVICES,
} from "../../constants";
import { LineOfBusinessC, MonolineLob, Quote } from "../../types";
import React, {
  Children,
  MouseEvent,
  ReactNode,
  useRef,
  useState,
} from "react";
import { capitalizeFirstChar, getPagenumber } from "../../utils/strings";
import {
  formatDeductible,
  formatNumberToDollars,
  getCheckoutInfo,
  getErrorMessage,
  getFloodZoneInfo,
  getLobIcon,
  getQuoteCardNameInfo,
  isEligibleForCopilot,
  isFieldVisible,
  padNumbersWithZero,
  storeDataInCopilot,
  trimStringToMaxLength,
} from "../../utilsMisc";
import { syncQuoteResponses, updateQuoteResponse } from "../../services/quote";
import { useDispatch, useSelector } from "../../redux/hooks";

import CARRIER_LOGOS_NEW from "../../utils/carrierLogosNew";
import { ReactComponent as CaretRight } from "../../assets/images/components/response-page/caretRight.svg";
import { CopilotAlert } from "./alerts/CopilotAlert";
import { ReactComponent as CopyOutline } from "../../assets/images/components/copy-outline.svg";
import { ReactComponent as DollarSign } from "../../assets/images/components/dollar_sign.svg";
import { PackageRequiredAlert } from "./alerts/PackageRequiredAlert";
import { QuoteAlert } from "./alerts/QuoteAlert";
import { QuoteSpouseButton } from "./QuoteSpouseButton";
import addAutoImage from "../../assets/images/components/response-page/AddAuto.png";
import { addContact } from "../../redux/actions/contact";
import addHomeImage from "../../assets/images/components/response-page/AddHome.png";
import { getCKBs } from "../../services/carrier";
import { getHomeQuotesTitle } from "../../utils/quotes";
import { handleUpdateSession } from "../../services/utils";
import { url as sfDomain } from "../../utils/sales-force";
import { useFeatureFlag } from "../../services/featureFlag";
import { useNavigate } from "react-router-dom";
import { useQti } from "./useQti";
import { useQuery } from "@tanstack/react-query";
import { useQuoteResponsesContext } from "../../contexts/QuoteResponsesContext";

type CheckoutInfoProps = {
  quote: Quote;
  lob: MonolineLob;
};

const getCallToAction = ({
  checkoutText,
  hasCopilot,
  isBind,
}: {
  checkoutText: string;
  hasCopilot: boolean;
  isBind: boolean;
}) => {
  if (checkoutText === "Quote") {
    return hasCopilot ? "Bridged with Copilot" : "Clicked Quote";
  } else if (checkoutText === "Update") return "Clicked Update";
  else if (isBind) return "Clicked QTI";
  else if (checkoutText === "Bridge") return "Clicked Bridge";
  else return "Clicked Price (non-qti)";
};

export const CheckoutInfo = ({ quote, lob }: CheckoutInfoProps) => {
  const featureFlags = useFeatureFlag();
  const contact = useSelector((store) => store.contact);
  const copilotFlag = featureFlags.getFlag("Aviator-chrome-extension") ?? false;

  // TODO: make sure this is not null
  const setShowNewSmartHomePackageSelectionModal =
    useQuoteResponsesContext()?.setShowNewSmartHomePackageSelectionModal;

  const { checkout, shouldRenderNull, isBind, bridged } = useQti({
    quote,
    lob,
  });

  const isSpouse = quote.isSpouseQuote ?? false;

  const {
    checkoutBgColor,
    checkoutTextColor,
    checkoutBorder,
    infoTextColor,
    checkoutText,
    infoText,
    hasButton,
  } = getCheckoutInfo(quote, isBind, bridged);

  if (shouldRenderNull) {
    return null;
  }

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (lob === "security") {
      if (contact.prospectId) {
        const redirectURL =
          // TODO: fix getFlag type
          (featureFlags.getFlag("vivint-redirect-url") as unknown as string) ||
          `${salesForce.url}${contact.prospectId}`;
        const bridgeWindow = window.open(redirectURL, "_blank");
        bridgeWindow?.focus();
      }
      setShowNewSmartHomePackageSelectionModal?.(true);
      return;
    }
    if (isEligibleForCopilot(quote) && copilotFlag) {
      storeDataInCopilot({
        carrierId: quote.company_client_id__c,
        isSpouse,
      });
    }

    checkout();

    if (hasButton) {
      const call_to_action__c = getCallToAction({
        checkoutText,
        hasCopilot: isEligibleForCopilot(quote) && copilotFlag,
        isBind,
      });
      await updateQuoteResponse({
        heroku_id: quote.heroku_id,
        call_to_action__c,
      });
    }

    syncQuoteResponses({ quoteId: quote.quote_request_heroku_id });
  };

  return (
    <StyledCheckoutInfo
      $checkoutBgColor={checkoutBgColor}
      $checkoutTextColor={checkoutTextColor}
      $infoTextColor={infoTextColor}
      $checkoutBorder={checkoutBorder}
    >
      {hasButton ? (
        <>
          <button
            className="checkout"
            onClick={handleClick}
            id={lob === "security" ? "security-view-button" : ""}
          >
            {checkoutText}
            <CaretRight />
          </button>
          <span className="info">{infoText}</span>
        </>
      ) : (
        <>
          <div className="checkout">{checkoutText}</div>
          <span className="info">{infoText}</span>
        </>
      )}
    </StyledCheckoutInfo>
  );
};

export const AutoQuoteInfo = () => {
  const quoteFromRedux = useSelector((store) => store.quote);
  const autoCoverages = quoteFromRedux.quotes?.autoCoverages;
  if (!autoCoverages) return null;
  return (
    <>
      <div>
        <span>Liability</span>
        <span>{autoCoverages.liability}</span>
      </div>
      {isFieldVisible("umuimumpd") && (
        <div>
          <span>Uninsured</span>
          <span>{autoCoverages.umuimumpd}</span>
        </div>
      )}
      {isFieldVisible("umuim") && (
        <div>
          <span>Uninsured</span>
          <span>{autoCoverages.umuim}</span>
        </div>
      )}
      {isFieldVisible("pip") && (
        <div>
          <span>PIP</span>
          <span>{formatNumberToDollars(autoCoverages.pip)}</span>
        </div>
      )}
      {isFieldVisible("medPay") && (
        <div>
          <span>Med Pay</span>
          <span>{formatNumberToDollars(autoCoverages.medPay)}</span>
        </div>
      )}
      <div>
        <span>Rental</span>
        <span>{autoCoverages.rental}</span>
      </div>
      <div>
        <span>Roadside</span>
        <span>{autoCoverages.towing}</span>
      </div>
    </>
  );
};

const FloodQuoteInfo = () => {
  const quoteFromRedux = useSelector((store) => store.quote);
  const homeCoverages = quoteFromRedux.quotes?.homeCoverages;
  if (!homeCoverages) return null;
  return (
    <>
      <div>
        <span>Dwelling</span>
        <span>{formatNumberToDollars(homeCoverages.dwelling)}</span>
      </div>
      <div>
        <span>Other Structures</span>
        <span>{formatNumberToDollars(homeCoverages.otherStructures)}</span>
      </div>
      <div>
        <span>Contents</span>
        <span>{formatNumberToDollars(homeCoverages.personal_property)}</span>
      </div>
      <div>
        <span>Loss of Use</span>
        <span>{formatNumberToDollars(homeCoverages.lossOfUse)}</span>
      </div>
      <div>
        <span>Deductible</span>
        <span>{homeCoverages.aop}</span>
      </div>
    </>
  );
};

type HomeQuoteInfoProps = { quote: Quote };
const HomeQuoteInfo = ({ quote }: HomeQuoteInfoProps) => {
  const isShownDwelling = quote.line_of_business__c !== "Renter's";
  const isShownWindHailDeductible = quote.line_of_business__c !== "Renter's";
  const isShownOtherStructures = !(
    ["Condo", "Renter's"] as LineOfBusinessC[]
  ).includes(quote.line_of_business__c);
  const isShownContents = quote.line_of_business__c !== "Dwelling";
  return (
    <>
      {isShownDwelling && (
        <div>
          <span>Dwelling</span>
          <span>{formatNumberToDollars(quote.dwelling_limit__c)}</span>
        </div>
      )}
      {isShownOtherStructures && (
        <div>
          <span>Other Structures</span>
          <span>{formatNumberToDollars(quote.other_structures__c)}</span>
        </div>
      )}
      {isShownContents && (
        <div>
          <span>Contents</span>
          <span>{formatNumberToDollars(quote.personal_property__c)}</span>
        </div>
      )}
      <div>
        <span>Loss of Use</span>
        <span>{formatNumberToDollars(quote.loss_of_use__c)}</span>
      </div>
      <div>
        <span>Liability</span>
        <span>{formatNumberToDollars(quote.liability__c)}</span>
      </div>
      <div>
        <span>Med Pay</span>
        <span>{formatNumberToDollars(quote.medical_payments__c)}</span>
      </div>
      <div>
        <span>All Other Ded.</span>
        <span>
          {formatDeductible(quote.aop_deductible__c, quote.dwelling_limit__c)}
        </span>
      </div>
      {isShownWindHailDeductible && isFieldVisible("w_h_deductible__c") && (
        <div>
          <span>Wind/Hail Ded.</span>
          <span>
            {formatDeductible(
              quote.wind_hail_deductible__c ?? quote.w_h_deductible__c,
              quote.dwelling_limit__c
            )}
          </span>
        </div>
      )}
    </>
  );
};

type CondoQuoteInfoProps = { quote: Quote };
const CondoQuoteInfo = ({ quote }: CondoQuoteInfoProps) => {
  return (
    <>
      <div>
        <span>Dwelling</span>
        <span>{formatNumberToDollars(quote.dwelling_limit__c)}</span>
      </div>
      <div>
        <span>Contents</span>
        <span>{formatNumberToDollars(quote.personal_property__c)}</span>
      </div>
      <div>
        <span>Loss of Use</span>
        <span>{formatNumberToDollars(quote.loss_of_use__c)}</span>
      </div>
      <div>
        <span>Liability</span>
        <span>{formatNumberToDollars(quote.liability__c)}</span>
      </div>
      <div>
        <span>Med Pay</span>
        <span>{formatNumberToDollars(quote.medical_payments__c)}</span>
      </div>
      <div>
        <span>All Other Ded.</span>
        <span>
          {formatDeductible(quote.aop_deductible__c, quote.dwelling_limit__c)}
        </span>
      </div>
      <div>
        <span>Wind/Hail Ded.</span>
        <span>
          {formatDeductible(
            quote.wind_hail_deductible__c ?? quote.w_h_deductible__c,
            quote.dwelling_limit__c
          )}
        </span>
      </div>
    </>
  );
};

type DwellingQuoteInfoProps = { quote: Quote };
const DwellingQuoteInfo = ({ quote }: DwellingQuoteInfoProps) => {
  return (
    <>
      <div>
        <span>Dwelling</span>
        <span>{formatNumberToDollars(quote.dwelling_limit__c)}</span>
      </div>
      <div>
        <span>Other Structures</span>
        <span>{formatNumberToDollars(quote.other_structures__c)}</span>
      </div>
      <div>
        <span>Loss of Use</span>
        <span>{formatNumberToDollars(quote.loss_of_use__c)}</span>
      </div>
      <div>
        <span>Liability</span>
        <span>{formatNumberToDollars(quote.liability__c)}</span>
      </div>
      <div>
        <span>Med Pay</span>
        <span>{formatNumberToDollars(quote.medical_payments__c)}</span>
      </div>
      <div>
        <span>All Other Ded.</span>
        <span>
          {formatDeductible(quote.aop_deductible__c, quote.dwelling_limit__c)}
        </span>
      </div>
      <div>
        <span>Wind/Hail Ded.</span>
        <span>
          {formatDeductible(
            quote.wind_hail_deductible__c ?? quote.w_h_deductible__c,
            quote.dwelling_limit__c
          )}
        </span>
      </div>
    </>
  );
};

type RentersQuoteInfoProps = { quote: Quote };
const RentersQuoteInfo = ({ quote }: RentersQuoteInfoProps) => {
  return (
    <>
      <div>
        <span>Contents</span>
        <span>{formatNumberToDollars(quote.personal_property__c)}</span>
      </div>
      <div>
        <span>Loss of Use</span>
        <span>{formatNumberToDollars(quote.loss_of_use__c)}</span>
      </div>
      <div>
        <span>Liability</span>
        <span>{formatNumberToDollars(quote.liability__c)}</span>
      </div>
      <div>
        <span>Med Pay</span>
        <span>{formatNumberToDollars(quote.medical_payments__c)}</span>
      </div>
      <div>
        <span>All Other Ded.</span>
        <span>
          {formatDeductible(quote.aop_deductible__c, quote.dwelling_limit__c)}
        </span>
      </div>
    </>
  );
};

type LifeQuoteInfoProps = { quote: Quote };
const LifeQuoteInfo = ({ quote }: LifeQuoteInfoProps) => {
  return (
    <>
      <div>
        <span>Category</span>
        <span>Term life</span>
      </div>
      <div>
        <span>Coverage</span>
        <span>{formatNumberToDollars(quote.life_coverage__c)}</span>
      </div>
      <div>
        <span>Term</span>
        <span>{quote.term__c} Years</span>
      </div>
    </>
  );
};

const JewelryQuoteInfo = () => {
  const property = useSelector((store) => store.property);
  return (
    <>
      <div>
        <span>Coverage</span>
        <span>{formatNumberToDollars(property.valueJewelry)}</span>
      </div>
    </>
  );
};

const UmbrellaQuoteInfo = () => {
  return (
    <>
      <div>
        <span>Coverage</span>
        <span>{formatNumberToDollars(DEFAULT_UMBRELLA_COVERAGE)}</span>
      </div>
    </>
  );
};

const SmartHomeQuoteInfo = () => {
  return (
    <div className="smart-home-info">
      <span className="heading">Includes installation and monitoring of:</span>
      <section className="security-quote-details-right">
        {VIVINT_SMART_HOME_DEVICES.map((device, index) => (
          <span key={index}>{device}</span>
        ))}
      </section>
    </div>
  );
};

const getLogoUrl = (carrierId: string) => {
  return `/carrier-logos/${carrierId}.jpg`;
};

type CarrierPlaceholderNameProps = { carrierName: string };
const CarrierPlaceholderName = ({
  carrierName,
}: CarrierPlaceholderNameProps) => {
  const initial = carrierName[0].toUpperCase();
  return (
    <CarrierLogoNameWrapper>
      <div className="initial">{initial}</div>
      {carrierName}
    </CarrierLogoNameWrapper>
  );
};

type CarrierLogoNameProps = {
  carrierId: string;
  carrierName: string;
};
export const CarrierLogoName = ({
  carrierId,
  carrierName,
}: CarrierLogoNameProps) => {
  const carrierLogoUrl = CARRIER_LOGOS_NEW.get(parseInt(carrierId));

  if (!carrierLogoUrl) {
    return <CarrierPlaceholderName carrierName={carrierName} />;
  }
  return (
    <CarrierLogoNameWrapper>
      <img src={carrierLogoUrl} alt={carrierName} className="logo" />
      {carrierName}
    </CarrierLogoNameWrapper>
  );
};

type QuoteListContainerProps = {
  header: React.JSX.Element;
  children: React.JSX.Element | React.JSX.Element[];
};
export const QuoteListContainer = ({
  header,
  children,
}: QuoteListContainerProps) => {
  return (
    <StyledQuoteLobContainer className="quote-list-container">
      <div className="header">{header}</div>
      {children}
    </StyledQuoteLobContainer>
  );
};

type QuoteCardListProps = { quoteType?: "Quote Manually"; children: ReactNode };
export const QuoteCardList = ({ quoteType, children }: QuoteCardListProps) => {
  if (Children.count(children) === 0) return null;
  return (
    <StyledQuoteCardList>
      {quoteType === "Quote Manually" && <h3>{quoteType}</h3>}
      <section className="quotes">{children}</section>
    </StyledQuoteCardList>
  );
};

type MonolineHeaderProps = {
  lob: MonolineLob;
  numItems: number;
};
export const MonolineHeader = ({ lob, numItems }: MonolineHeaderProps) => {
  const Icon = getLobIcon(lob);
  return (
    <>
      <span className="lob-icon-text">
        {!!Icon && <Icon />}
        <h2 id={lob === "security" ? "security-section-heading" : ""}>
          {lob === "home" ? getHomeQuotesTitle() : capitalizeFirstChar(lob)}
        </h2>
      </span>
      <span>{`(${padNumbersWithZero(numItems)})`}</span>
    </>
  );
};

type QuoteCardProps = {
  isBundle?: boolean;
  children: React.JSX.Element | React.JSX.Element[];
  collapsable: React.JSX.Element | null;
  lob?: MonolineLob;
  hasDropdown?: boolean;
};
export const QuoteCard = ({
  isBundle = false,
  children,
  collapsable,
  lob,
  hasDropdown = true,
}: QuoteCardProps) => {
  const accordionBodyPaddingRight = isBundle
    ? "154px"
    : lob === "security"
    ? "10px"
    : "65px";

  const QuoteCardButtonOrDiv = hasDropdown ? Accordion.Button : "div";

  return (
    <StyledQuoteCard
      flush
      $accordionBodyPaddingRight={accordionBodyPaddingRight}
    >
      <QuoteCardButtonOrDiv
        className="quote-card"
        id={hasDropdown && lob === "security" ? "security-dropdown-button" : ""}
      >
        {children}
        {!hasDropdown && <span className="accordion-button-filler" />}
      </QuoteCardButtonOrDiv>
      <Accordion.Body>{collapsable}</Accordion.Body>
    </StyledQuoteCard>
  );
};

type QuoteInfoProps = { quote: Quote };
export const QuoteInfo = ({ quote }: QuoteInfoProps) => {
  return (
    <>
      {quote.line_of_business__c === "Security" ? (
        <SmartHomeQuoteInfo />
      ) : (
        <section className="quote-details-right">
          {quote.line_of_business__c === "Home" && (
            <HomeQuoteInfo quote={quote} />
          )}
          {quote.line_of_business__c === "Condo" && (
            <CondoQuoteInfo quote={quote} />
          )}
          {quote.line_of_business__c === "Dwelling" && (
            <DwellingQuoteInfo quote={quote} />
          )}
          {quote.line_of_business__c === "Renter's" && (
            <RentersQuoteInfo quote={quote} />
          )}
          {quote.line_of_business__c === "Auto" && <AutoQuoteInfo />}
          {quote.line_of_business__c === "Flood" && <FloodQuoteInfo />}
          {quote.line_of_business__c === "Life" && (
            <LifeQuoteInfo quote={quote} />
          )}
          {quote.line_of_business__c === "Collections / Personal Articles" && (
            <JewelryQuoteInfo />
          )}{" "}
          {quote.line_of_business__c === UMBRELLA_LINE_OF_BUSINESS && (
            <UmbrellaQuoteInfo />
          )}
        </section>
      )}
    </>
  );
};

type QuoteFooterProps = { quote: Quote };
export const QuoteFooter = ({ quote }: QuoteFooterProps) => {
  return (
    <>
      <section className="quote-footer">
        {!!quote.company_quote_number__c && (
          <span className="company_quote_number">
            Quote # {quote.company_quote_number__c}
          </span>
        )}
      </section>
    </>
  );
};

type CarrierGuidelinesLinkProps = { carrierId: string };
const CarrierGuidelinesLink = ({ carrierId }: CarrierGuidelinesLinkProps) => {
  const property = useSelector((store) => store.property);
  const state = property.administrative_area_level_1 ?? "TX";

  const { data: carrierKnowledgeBase, isLoading } = useQuery({
    queryKey: ["ckb", state],
    queryFn: () => getCKBs(state),
    enabled: !!state,
    staleTime: Infinity,
  });
  const carrierSalesforceId = carrierKnowledgeBase?.data?.[carrierId]?.sfid;

  return isLoading ? (
    <span className="carrier-guidelines">Loading</span>
  ) : !carrierSalesforceId ? (
    <span className="carrier-guidelines">Carrier Guidelines Missing</span>
  ) : (
    <a
      href={`${sfDomain}${carrierSalesforceId}`}
      className="carrier-guidelines"
      target="_blank"
      rel="noreferrer"
    >
      Carrier Guidelines
    </a>
  );
};

type QuoteDetailsProps = {
  quote: Quote;
  firstName?: string;
  hasNameInDropdown: boolean;
};
export const QuoteDetails = ({
  quote,
  firstName,
  hasNameInDropdown,
}: QuoteDetailsProps) => {
  const [showQuoteNumberTooltip, setShowQuoteNumberTooltip] = useState(false);
  const quoteNumberCopyButtonRef = useRef(null);
  const hasDropdown = quote.error_type__c !== "Quote Manually";
  if (!hasDropdown) return null;
  const errorMessage = quote.premium_total__c ? null : getErrorMessage(quote);

  const copyQuoteNumber = (quoteNumber: string) => {
    setShowQuoteNumberTooltip(true);
    navigator.clipboard.writeText(quoteNumber);
    setTimeout(() => {
      setShowQuoteNumberTooltip(false);
    }, 2000);
  };

  return errorMessage ? (
    <div className="quote-details">
      <div className="quotes-with-errors">
        {hasNameInDropdown && !!firstName && (
          <div className="dropdown-first-name">{firstName}</div>
        )}
        <div className="error-message">{errorMessage}</div>
        <div className="quote-details-main">
          <CarrierGuidelinesLink carrierId={quote.company_client_id__c} />
          {!!quote.company_quote_number__c && (
            <div className="d-flex">
              <QuoteAlert
                tooltipText={quote.company_quote_number__c}
                $color="#004f18"
                $backgroundColor="#d3f59e"
              >
                <span className="company_quote_number mt-1">
                  {trimStringToMaxLength(quote.company_quote_number__c, 10)}
                </span>
              </QuoteAlert>
              <QuoteAlert
                tooltipText="Copied to Clipboard!"
                $color="#004f18"
                $backgroundColor="#d3f59e"
                trigger="click"
                show={showQuoteNumberTooltip}
              >
                <CopyOutline
                  className="align-self-center pt-1"
                  role="button"
                  ref={quoteNumberCopyButtonRef}
                  onClick={() =>
                    copyQuoteNumber(quote.company_quote_number__c || "")
                  }
                />
              </QuoteAlert>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="quote-details">
      <div className="quote-details-main">
        <div className="quote-details-left">
          {hasNameInDropdown && !!firstName && (
            <div className="dropdown-first-name">{firstName}</div>
          )}
          <CarrierGuidelinesLink carrierId={quote.company_client_id__c} />
          {quote.line_of_business__c !== "Security" &&
            !!quote.company_quote_number__c && (
              <div className="d-flex">
                <QuoteAlert
                  tooltipText={quote.company_quote_number__c}
                  $color="#004f18"
                  $backgroundColor="#d3f59e"
                >
                  <span className="company_quote_number mt-1">
                    {trimStringToMaxLength(quote.company_quote_number__c, 10)}
                  </span>
                </QuoteAlert>
                <QuoteAlert
                  tooltipText="Copied to Clipboard!"
                  $color="#004f18"
                  $backgroundColor="#d3f59e"
                  trigger="click"
                  show={showQuoteNumberTooltip}
                >
                  <CopyOutline
                    className="align-self-center pt-1"
                    role="button"
                    ref={quoteNumberCopyButtonRef}
                    onClick={() =>
                      copyQuoteNumber(quote.company_quote_number__c || "")
                    }
                  />
                </QuoteAlert>
              </div>
            )}
        </div>
        <QuoteInfo quote={quote} />
      </div>
    </div>
  );
};

type MonolineCollapsableProps = {
  quote: Quote;
  firstName?: string;
  hasNameInDropdown: boolean;
};
const MonolineCollapsable = ({
  quote,
  firstName,
  hasNameInDropdown,
}: MonolineCollapsableProps) => {
  return (
    <div className="monoline-dropdown-container">
      <QuoteDetails
        quote={quote}
        firstName={firstName}
        hasNameInDropdown={hasNameInDropdown}
      />
    </div>
  );
};

type MonolineQuoteCardProps = {
  quote: Quote;
  lob: MonolineLob;
};
export const MonolineQuoteCard = ({ quote, lob }: MonolineQuoteCardProps) => {
  const { firstName, hasNameInDropdown, hasNameInMiddle } =
    getQuoteCardNameInfo(quote, lob);
  const { hasFloodZone, floodZone, floodZoneStyles } = getFloodZoneInfo(quote);

  const hasDropdown = quote.error_type__c !== "Quote Manually";
  return (
    <QuoteCard
      collapsable={
        <MonolineCollapsable
          quote={quote}
          firstName={firstName}
          hasNameInDropdown={hasNameInDropdown}
        />
      }
      lob={lob}
      hasDropdown={hasDropdown}
    >
      <div
        id={`quote-card-info-${quote.line_of_business__c.toLowerCase()}-${quote.carrier__c.toLowerCase()}-${
          quote.package_rate__c
        }`}
        className="quote-card-info"
      >
        <div className="quote-card-left">
          <CarrierLogoName
            carrierId={quote.company_client_id__c}
            carrierName={quote.carrier__c}
          />
          <QuoteSpouseButton quote={quote} />
        </div>
        {hasNameInMiddle && !!firstName && (
          <span className="name-in-middle">{firstName}</span>
        )}
        <div className="right-section">
          {quote.package_rate__c && (
            <PackageRequiredAlert quoteLob={quote.line_of_business__c} />
          )}
          <CopilotAlert quote={quote} />
          {hasFloodZone && (
            <StyledIcon
              $color={floodZoneStyles.color}
              $backgroundColor={floodZoneStyles.backgroundColor}
            >
              <span>{floodZone}</span>
            </StyledIcon>
          )}
          {lob === "security" && (
            <StyledIcon $backgroundColor="#d3f59e">
              <DollarSign />
            </StyledIcon>
          )}
          <CheckoutInfo quote={quote} lob={lob} />
        </div>
      </div>
    </QuoteCard>
  );
};

type AddLobProps = { lob: MonolineLob };
export const AddLob = ({ lob }: AddLobProps) => {
  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = async () => {
    if (lob === "auto") {
      dispatch(
        addContact({
          ...contact,
          autoQuoteId: session.auto_quote_id,
          addSkippedAutoQuote: true,
        })
      );
      await handleUpdateSession({
        uuid: session.uuid,
        line_of_business: `${session.line_of_business}Auto`,
        page_stopped: getPagenumber(
          `${session.line_of_business}Auto`,
          "AboutYou"
        ),
        contact_data: JSON.stringify({
          ...contact,
          autoQuoteId: session.auto_quote_id,
          addSkippedAutoQuote: true,
        }),
      });
      navigate(`/agent/${session.line_of_business}Auto/AboutYou`);
    } else {
      dispatch(
        addContact({
          ...contact,
          addSkippedHomeQuote: true,
        })
      );
      await handleUpdateSession({
        uuid: session.uuid,
        line_of_business: "HomeAuto",
        page_stopped: getPagenumber("HomeAuto", "PolicyType"),
        contact_data: JSON.stringify({
          ...contact,
          addSkippedHomeQuote: true,
        }),
      });
      navigate("/agent/HomeAuto/PolicyType");
    }
  };
  return (
    <div className="d-flex py-4 ps-1 pe-3">
      <div className="flex-1 px-3 d-flex flex-column justify-content-center">
        <img
          src={lob === "auto" ? addAutoImage : addHomeImage}
          alt="quote"
          width={80}
          height={80}
        />
      </div>
      <div className="flex-2 px-1">
        <span className="addLobText">
          Packaging can save your client money and earn you more revenue!
        </span>
        <span className="addLobSubText">{`Start here to run ${lob} quotes`}</span>
      </div>

      <div className="flex-1 d-flex flex-column justify-content-center">
        <Button onClick={handleClick} className="addLobButton">
          {`Quote ${capitalizeFirstChar(lob)}`}
        </Button>
      </div>
    </div>
  );
};
