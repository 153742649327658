// @ts-check
import {
  qtiAutoCarriers,
  qtiFloodCarriers,
  qtiHomeCarriers,
} from "../../constants";

import { isDwelling } from "../../utils";
import { useFeatureFlag } from "../../services/featureFlag";

/**
 * @param {{
 *   companyClientId?: string;
 *   lob?: string;
 *   quoteToBind?: string;
 * }} [input]
 */
export const useIsCarrierQtiEnabled = ({
  quoteToBind,
  companyClientId,
  lob,
} = {}) => {
  const featureFlags = useFeatureFlag();

  if (!companyClientId) {
    return false;
  }

  const isHomeEnabled =
    companyClientId && featureFlags.getFlag(qtiHomeCarriers[companyClientId]);
  const isAutoEnabled =
    companyClientId && featureFlags.getFlag(qtiAutoCarriers[companyClientId]);
  const isFloodEnabled =
    companyClientId && featureFlags.getFlag(qtiFloodCarriers[companyClientId]);

  if (quoteToBind === "bundle") {
    return isHomeEnabled && isAutoEnabled;
  }

  if (lob === "home" && !isDwelling()) {
    return isHomeEnabled;
  }

  if (lob === "auto") {
    return isAutoEnabled;
  }

  if (lob === "flood") {
    return isFloodEnabled;
  }

  return false;
};
