import { getCarrierMarketShare } from "../../services/carrierMarketShare";
import { MarketShareType, MarketShareApiInput } from "./types";

export const getMarketShareData = async (
  args: MarketShareApiInput
): Promise<MarketShareType> => {
  try {
    const data = (await getCarrierMarketShare(args)).data;
    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
};
