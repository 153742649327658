import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "../../redux/hooks";
import { addContact } from "../../redux/actions/contact";
import { ReactComponent as CaretDown } from "../../assets/images/components/response-page/caretDownWhiteFill.svg";
import { Chart } from "./Chart";
import { Dropdown } from "react-bootstrap";
import Error from "./Error";
import { ReactComponent as LocationIcon } from "../../assets/images/main/location-outline-20.svg";
import { ReactComponent as LocationMissing } from "../../assets/images/components/response-page/location-error.svg";
import { ReactComponent as MarketShareIcon } from "../../assets/images/components/footer/CarrierMarketShare.svg";
import { MinimizeButton } from "../ChatBot/styled";
import { ReactComponent as NoDataWarning20 } from "../../assets/images/components/response-page/warning-20.svg";
import { ReactComponent as NoDataWarning40 } from "../../assets/images/components/response-page/warning-40.svg";
import { ReactComponent as OpenInNewTab } from "../../assets/images/components/response-page/openInNewTab.svg";
import { StyledDropdown } from "../new_design/filters/styled";
import { StyledFooterToolContainer } from "../new_design/styled";
import { isDwelling } from "../../utils";
import { getMarketShareData } from "./utils";
import {
  getSessionPropertyLob,
  useDropdown,
  isAutoOnly,
  hasAutoQuote,
} from "../../utilsMisc";
import { removeCountyText } from "../../utils/strings";
import { statesFull } from "../../constants";
import { StyledMarketShareTool } from "./styled";
import { TOTAL_POLICIES_LABEL, ERROR_CONTENT } from "./constants";
import { MarketShareType } from "./types";
import Spinner from "../styled/Spinner";

type MarketShareProps = {
  isActive?: boolean;
  close: () => void;
  initialChartData: MarketShareType;
};

/** Component for displaying policies sold as charts. */
const MarketShare = ({
  isActive = false,
  close,
  initialChartData,
}: MarketShareProps) => {
  const dispatch = useDispatch();
  const contact = useSelector((store) => store.contact) ?? {};
  const { homeQuoteId, autoQuoteId, isFetchingMarketShare = false } = contact;

  const line_of_business =
    useSelector((store) => store.session.line_of_business) ?? "";
  const property = useSelector((store) => store.property) ?? {};
  const {
    administrative_area_level_1: state,
    administrative_area_level_2: county,
  } = property;

  /** Property type - condo, renter's, dwelling or Home */
  const propertyType = getSessionPropertyLob() ?? "Home";

  /** Chart default view is success quotes, 90 days with state in context */
  const [chartData, setChartData] = useState<MarketShareType>(
    initialChartData ?? {}
  );
  useEffect(() => {
    setChartData(initialChartData);
  }, [initialChartData]);

  const { clickOutsideRef } = useDropdown({
    onClose: () => {
      dispatch(addContact({ ...contact, isFetchingMarketShare: false }));
      setAdministrativeArea("state");
      setTerm({ length: null, show: false });
      setShowPolicyDropdown(false);
      setQuoteState("success");
      isActive && close();
    },
  });

  /** By default show policies generated on successful quotes */
  const [quoteState, setQuoteState] = useState<"success" | "failed">("success");

  /** Controls 90 days and 30 days term filter */
  const [term, setTerm] = useState<{ length: 90 | 30 | null; show: boolean }>({
    length: null,
    show: false,
  });

  /** Controls display of policies sold dropdown */
  const [showPolicyDropdown, setShowPolicyDropdown] = useState(false);

  /** Controls state and county filter */
  const [administrativeArea, setAdministrativeArea] = useState<
    "state" | "county"
  >("state");

  /** We need at least State to build the graphs */
  if (!state) return null;

  /**
   * This function calls the backend only as long as a certain filter is clicked
   * the firs time. data is loaded onto store and from there we read it from
   * store. and update the local chartState that will re-render the graphs.
   */
  const updateChartData = async (
    administrativeArea: "state" | "county",
    type: "failed" | "success",
    term: 90 | 30
  ) => {
    try {
      dispatch(addContact({ ...contact, isFetchingMarketShare: true }));
      const newData = await getMarketShareData({
        state,
        county: administrativeArea === "county" ? county : "",
        line_of_business: isDwelling() ? "Dwelling" : line_of_business,
        isForThirtyDays: term === 30,
        isErroredPolicies: type === "failed",
      });
      dispatch(addContact({ ...contact, isFetchingMarketShare: false }));
      if (newData) {
        setChartData(newData);
      }
    } catch (error) {
      setChartData({});
      dispatch(addContact({ ...contact, isFetchingMarketShare: false }));
      console.error("Error fetching state data:", error);
    }
  };
  const errorBoxHeight = autoQuoteId && homeQuoteId ? "378px" : "210px";
  return (
    <StyledFooterToolContainer
      $display={isActive ? "flex" : "none"}
      ref={clickOutsideRef}
    >
      <StyledMarketShareTool height={autoQuoteId ? "500px" : "350px"}>
        <header>
          <div className="left totalPoliciesFilter">
            <MarketShareIcon />
            <StyledDropdown
              show={showPolicyDropdown}
              $width={"200px"}
              $menuWidth={"50px"}
              backgroundColor={term.length ? "#d0fcc9cc" : "#fff"}
              border={"none"}
            >
              <Dropdown.Toggle
                onClick={() => setShowPolicyDropdown(!showPolicyDropdown)}
                style={{ width: "100%" }}
              >
                <span style={{ paddingRight: "10px" }}>
                  {TOTAL_POLICIES_LABEL[quoteState]}
                </span>
                <CaretDown />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ minWidth: "300px" }}>
                <button
                  id="success"
                  disabled={isFetchingMarketShare}
                  type="button"
                  className="success"
                  onClick={() => {
                    setQuoteState("success");
                    setAdministrativeArea("state");
                    setTerm({ length: term.length ? 90 : null, show: false });
                    updateChartData("state", "success", 90);
                    setShowPolicyDropdown(false);
                  }}
                >
                  {TOTAL_POLICIES_LABEL["success"]}
                </button>
                <button
                  id="failedQuotes"
                  disabled={isFetchingMarketShare}
                  type="button"
                  className="failed"
                  onClick={() => {
                    setQuoteState("failed");
                    setAdministrativeArea("state");
                    setTerm({ length: term.length ? 90 : null, show: false });
                    updateChartData("state", "failed", 90);
                    setShowPolicyDropdown(false);
                  }}
                >
                  {TOTAL_POLICIES_LABEL["failed"]}
                </button>
              </Dropdown.Menu>
            </StyledDropdown>
          </div>
          <MinimizeButton
            width="28px"
            height="28px"
            onClick={() => {
              setAdministrativeArea("state");
              setTerm({ length: null, show: false });
              setShowPolicyDropdown(false);
              setQuoteState("success");
              updateChartData("state", "success", 90);
              close();
            }}
            aria-label="minimize crm tool"
          />
        </header>
        <section className="body">
          <div className="filter administrativeLevel">
            <div
              className={`col-md-6 ${
                administrativeArea === "state" ? "active" : ""
              }`}
            >
              <button
                id="state"
                disabled={isFetchingMarketShare}
                type="button"
                onClick={() => {
                  setTerm({
                    ...term,
                    length: term.length ? 90 : null,
                    show: false,
                  });
                  setAdministrativeArea("state");
                  setShowPolicyDropdown(false);
                  updateChartData("state", quoteState, 90);
                }}
              >
                State
              </button>
            </div>
            <div
              className={`col-md-6 ${
                administrativeArea === "county" ? "active" : ""
              }`}
            >
              <button
                id="county"
                disabled={isFetchingMarketShare}
                type="button"
                onClick={() => {
                  setShowPolicyDropdown(false);
                  setAdministrativeArea("county");
                  setTerm({
                    ...term,
                    length: term.length ? 90 : null,
                    show: false,
                  });
                  updateChartData("county", quoteState, 90);
                }}
              >
                County
              </button>
            </div>
          </div>
          {administrativeArea === "county" && !county ? (
            <Error
              content={ERROR_CONTENT["countyUndefined"]}
              height={errorBoxHeight}
              icon={<LocationMissing />}
            />
          ) : (
            <>
              <div className="filter">
                <div className="col-md-8 location">
                  <LocationIcon />
                  {administrativeArea === "state"
                    ? statesFull[state as keyof typeof statesFull] ?? state
                    : removeCountyText(county)}
                </div>
                <div className="col-md-4 term">
                  <StyledDropdown
                    show={term.show}
                    $backgroundColor={term.length ? "#d0fcc9cc" : "#fff"}
                    $borderColor={term.length ? "#118f00" : "#d6d6d6"}
                    $color={term.length ? "#094d00" : "#222"}
                    $width={"100px"}
                    $menuWidth={"50px"}
                  >
                    <Dropdown.Toggle
                      onClick={() => setTerm({ ...term, show: !term.show })}
                      style={{
                        border: `solid 1px ${
                          term.length ? "#118F00" : "#d6d6d6"
                        } !important`,
                      }}
                    >
                      <span>{`${term.length ?? 90} Days`}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <button
                        id="90Days"
                        disabled={isFetchingMarketShare}
                        type="button"
                        hidden={term.length === 90 || !term.length}
                        onClick={() => {
                          setShowPolicyDropdown(false);
                          setTerm({
                            ...term,
                            length: term.length ? 90 : null,
                            show: false,
                          });
                          updateChartData(administrativeArea, quoteState, 90);
                        }}
                      >
                        90 Days
                      </button>
                      <button
                        id="30Days"
                        disabled={isFetchingMarketShare}
                        type="button"
                        hidden={term.length === 30}
                        onClick={() => {
                          setShowPolicyDropdown(false);
                          setTerm({ ...term, length: 30, show: false });
                          updateChartData(administrativeArea, quoteState, 30);
                        }}
                      >
                        30 Days
                      </button>
                    </Dropdown.Menu>
                  </StyledDropdown>
                </div>
              </div>
              {!chartData?.auto?.length && !chartData?.home?.length ? (
                isFetchingMarketShare ? (
                  <div style={{ height: errorBoxHeight }}>
                    <Spinner justifyContent={"center"} display={"flex"} />
                  </div>
                ) : (
                  <Error
                    content={ERROR_CONTENT["noPolicies"]}
                    height={errorBoxHeight}
                    icon={<NoDataWarning40 />}
                  />
                )
              ) : (
                <>
                  {homeQuoteId && !isAutoOnly() && (
                    <div className="chart">
                      <div className="chartLabel">
                        {!chartData?.home?.length && !isFetchingMarketShare && (
                          <NoDataWarning20 />
                        )}
                        {propertyType === "Renter's" ? "Renters" : propertyType}
                      </div>
                      <div className="graph">
                        {isFetchingMarketShare ? (
                          <Spinner justifyContent={"center"} display={"flex"} />
                        ) : chartData?.home?.length ? (
                          <Chart
                            key={"home"}
                            data={chartData?.home}
                            yAxisKey="carrierName"
                            xAxisKey="count"
                          />
                        ) : (
                          <Error
                            content={ERROR_CONTENT["noPoliciesSingleLob"]}
                            height={"auto"}
                            singleLobError={true}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {autoQuoteId && hasAutoQuote() && (
                    <div className="chart">
                      <div className="chartLabel">
                        {!chartData?.auto?.length && !isFetchingMarketShare && (
                          <NoDataWarning20 />
                        )}
                        Auto
                      </div>
                      <div className="graph">
                        {isFetchingMarketShare ? (
                          <Spinner justifyContent={"center"} display={"flex"} />
                        ) : chartData?.auto?.length ? (
                          <Chart
                            key={"auto"}
                            data={chartData?.auto}
                            yAxisKey="carrierName"
                            xAxisKey="count"
                          />
                        ) : (
                          <Error
                            content={ERROR_CONTENT["noPoliciesSingleLob"]}
                            height={"auto"}
                            singleLobError={true}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </section>
        <div className="footer">
          <a
            target="_blank"
            rel="noreferrer"
            className="salesForceCMS"
            href="https://goosehead.lightning.force.com/analytics/dashboard/0FK3c000000CtF0GAK"
          >
            <OpenInNewTab />
            <span style={{ marginLeft: "5px" }}>Salesforce CMS</span>
          </a>
        </div>
      </StyledMarketShareTool>
    </StyledFooterToolContainer>
  );
};

export default MarketShare;
