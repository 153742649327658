import { ADD_DRIVERS } from "../constants";
import { Driver } from "./types";
import produce from "immer";

const initialState: Driver[] = [];

export const driversReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_DRIVERS:
      draft = action.payload;
      return draft;
    default:
      return draft;
  }
}, initialState);
