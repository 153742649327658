import { ADD_PROPERTY, CLEAR_PROPERTY } from "../constants";

export const addProperty = (property) => {
  return {
    type: ADD_PROPERTY,
    payload: property,
  };
};

export const clearProperty = () => {
  return {
    type: CLEAR_PROPERTY,
  };
};
