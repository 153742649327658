import { ADD_UUID } from "../constants";
import produce from "immer";

const initialState = "";

export const uuidReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_UUID:
      draft = action.payload;
      return draft;
    default:
      return draft;
  }
}, initialState);
