import adirondak from "../assets/images/CarrierLogos/Adirondack_new.webp";
import alliedtrust from "../assets/images/CarrierLogos/AlliedTrust_new.webp";
import allState from "../assets/images/CarrierLogos/AllState.svg";
import americanIntegrity from "../assets/images/CarrierLogos/AmericanIntegrity_new.webp";
import americanTraditions from "../assets/images/CarrierLogos/AmericanTraditions_new.webp";
import annex_risk from "../assets/images/CarrierLogos/AnnexRisk.webp";
import aonedge from "../assets/images/CarrierLogos/AonEdge_new.webp";
import asi from "../assets/images/CarrierLogos/ASI.webp";
import bamboo from "../assets/images/CarrierLogos/Bamboo_new.webp";
import bankers from "../assets/images/CarrierLogos/Bankers_new.webp";
import branch from "../assets/images/CarrierLogos/Branch_new.webp";
import cincinnati from "../assets/images/CarrierLogos/CincinnatiInsurance_new.webp";
import clearcover from "../assets/images/CarrierLogos/Clearcover_new.webp";
import csaa from "../assets/images/CarrierLogos/CSAA_AAA_new.webp";
import cse from "../assets/images/CarrierLogos/CSE_new.webp";
import cypress from "../assets/images/CarrierLogos/Cypress_new.webp";
import dairyland from "../assets/images/CarrierLogos/Dairyland_new.webp";
import delos from "../assets/images/CarrierLogos/Delos_Logo_X.webp";
import edison from "../assets/images/CarrierLogos/Edison_new.webp";
import elephant from "../assets/images/CarrierLogos/Elephant_new.webp";
import encompass from "../assets/images/CarrierLogos/Encompass_new.webp";
import ethos from "../assets/images/CarrierLogos/Ethos.webp";
import fednat from "../assets/images/CarrierLogos/FedNat_new.webp";
import floridaFamily from "../assets/images/CarrierLogos/FloridaFamily_new.webp";
import floridaPeninsula from "../assets/images/CarrierLogos/FloridaPeninsula_new.webp";
import foremost from "../assets/images/CarrierLogos/Foremost_new.webp";
import grange from "../assets/images/CarrierLogos/Grange_new.webp";
import hanover from "../assets/images/CarrierLogos/Hanover_new.webp";
import heritage from "../assets/images/CarrierLogos/Heritage_new.webp";
import infinity from "../assets/images/CarrierLogos/Infinity_new.webp";
import jewelers_mutual from "../assets/images/CarrierLogos/Jewelers_Mutual.webp";
import johnson_and_johnson from "../assets/images/CarrierLogos/Johnson & Johnson.webp";
import kemper_specialty from "../assets/images/CarrierLogos/KemperSpecialty_new.webp";
import lemonade from "../assets/images/CarrierLogos/Lemonade_new.webp";
import logo113 from "../assets/images/CarrierLogos/HOAIC_new.webp";
import logo13 from "../assets/images/CarrierLogos/Travelers_new.webp";
import logo25 from "../assets/images/CarrierLogos/Progressive_new.webp";
import logo301 from "../assets/images/CarrierLogos/StateAuto_new.webp";
import logo35 from "../assets/images/CarrierLogos/Safeco_new.webp";
import logo436 from "../assets/images/CarrierLogos/Wellington_new.webp";
import wellingtonsurplus from "../assets/images/CarrierLogos/WellingtonSelectSurplusLines.webp";
import logo447 from "../assets/images/CarrierLogos/Nationwide_new.webp";
import logo45 from "../assets/images/CarrierLogos/Kemper_new.webp";
import logo470 from "../assets/images/CarrierLogos/BerkshireHathaway_new.webp";
import logo491 from "../assets/images/CarrierLogos/Hippo_new.webp";
import logo87 from "../assets/images/CarrierLogos/Mercury_new.webp";
import metlife from "../assets/images/CarrierLogos/Metlife_new.webp";
import metromile from "../assets/images/CarrierLogos/Metromile_new.webp";
import monarch from "../assets/images/CarrierLogos/MonarchNational_new.webp";
import mutual_of_enumclaw from "../assets/images/CarrierLogos/mutual_of_enumclaw.svg";
import mileAuto from "../assets/images/CarrierLogos/Mile_Auto_new.jpg"
import national_advantage from "../assets/images/CarrierLogos/NatGen_Advantage_Logo.webp";
import national_general from "../assets/images/CarrierLogos/NationalGeneral_new1.webp";
import national_value from "../assets/images/CarrierLogos/NatGen_Value_logo.webp";
import nbic from "../assets/images/CarrierLogos/NBIC_new.webp";
import neptune from "../assets/images/CarrierLogos/Neptune.svg";
import njs from "../assets/images/CarrierLogos/NJSkylands_new.webp";
import olympus from "../assets/images/CarrierLogos/Olympus_new.webp";
import openly from "../assets/images/CarrierLogos/Openly_new.webp";
import orion from "../assets/images/CarrierLogos/Orion180_new.webp";
import pacific_specialty from "../assets/images/CarrierLogos/PacificSpecialty_new.webp";
import palomar from "../assets/images/CarrierLogos/Palomar_Logo.webp";
import penn from "../assets/images/CarrierLogos/PennNational_new.webp";
import philadelphiacontributionship from "../assets/images/CarrierLogos/PhiladelphiaContributionship_new.webp";
import plymouth from "../assets/images/CarrierLogos/PlymouthRock_new.webp";
import root from "../assets/images/CarrierLogos/RootInsurance.svg";
import sage_sure_elevate from "../assets/images/CarrierLogos/Sagesure_Elevate.webp";
import sage_sure from "../assets/images/CarrierLogos/SageSure_new.webp";
import sage_sure_safeport from "../assets/images/CarrierLogos/SageSure_Safeport.webp";
import sage_sure_sure from "../assets/images/CarrierLogos/SageSure_Sure.webp";
import sage_sure_occidental from "../assets/images/CarrierLogos/SageSure_Occidental.webp";
import sage_sure_vave from "../assets/images/CarrierLogos/SageSure_Vave.webp";
import sage_sure_markel from "../assets/images/CarrierLogos/SageSure_Markel.webp";
import sage_sure_wilshire from "../assets/images/CarrierLogos/SageSure_Wilshire.webp";
import security_first from "../assets/images/CarrierLogos/SecurityFirst_new.webp";
import southern_oak from "../assets/images/CarrierLogos/SouthernOak_new.webp";
import stillwater from "../assets/images/CarrierLogos/Stillwater_new.webp";
import stjohns from "../assets/images/CarrierLogos/St.Johns_new.webp";
import towerhill from "../assets/images/CarrierLogos/TowerHill_new.webp";
import typtap from "../assets/images/CarrierLogos/TypTap.webp";
import united from "../assets/images/CarrierLogos/United_new1.webp";
import universal_na from "../assets/images/CarrierLogos/UniversalNorthAmerica_new.webp";
import upcic from "../assets/images/CarrierLogos/UniversalPropertyCasualty_new.webp";
import utica from "../assets/images/CarrierLogos/UticaNational_new.webp";
import velocity from "../assets/images/CarrierLogos/Velocity_new.webp";
import vivint from "../assets/images/CarrierLogos/Vivint.webp";
import westfield from "../assets/images/CarrierLogos/Westfield_new.webp";
import weston from "../assets/images/CarrierLogos/WestonSpecialty_new.webp";
import wright from "../assets/images/CarrierLogos/Wright.webp";
import wright_private from "../assets/images/CarrierLogos/Wright_Private.webp";
import mission_select from "../assets/images/CarrierLogos/MissionSelect.webp";
import msi from "../assets/images/CarrierLogos/MSI.webp";
import aspire_general from "../assets/images/CarrierLogos/AspireGeneral.webp";
import american_modern from "../assets/images/CarrierLogos/AmericanModern.webp";
import agia from "../assets/images/CarrierLogos/AGIA.webp";
import nnva from "../assets/images/CarrierLogos/NNVA.webp";
import sage_sure_trium from "../assets/images/CarrierLogos/SageSureTrium.svg";
import trexis from "../assets/images/CarrierLogos/Trexis.webp";

const logos = {
  637: typtap,
  425: upcic,
  421: towerhill,
  weston: weston,
  422: southern_oak,
  security_first: security_first,
  373: westfield,
  542: openly,
  // homeowners_choice: homeowners_choice,
  // geovera: geovera,
  58: logo45,
  391: allState,
  560: allState,
  476: velocity,
  562: velocity,
  532: clearcover,
  442: bankers,
  305: utica,
  114: penn,
  133: njs,
  148: cincinnati,
  992: root,
  334: dairyland,
  998: lemonade,
  7: metlife,
  479: metlife,
  35: logo35,
  13: logo13,
  377: logo25,
  25: logo25,
  491: logo491,
  301: logo301,
  76: logo301,
  45: logo45,
  436: logo436,
  988: wellingtonsurplus,
  434: logo436,
  87: logo87,
  17: logo87,
  307: logo87,
  18: logo87,
  113: logo113,
  447: logo447,
  348: logo447,
  470: logo470,
  64: adirondak,
  // aig: aig,
  426: alliedtrust,
  // allrisks: allrisks,
  // americanreliable: americanreliable,
  438: americanTraditions,
  416: floridaFamily,
  379: americanIntegrity,
  // arrowhead: arrowhead,
  // asi: asi,
  501: bamboo,
  // 413: capitol,
  // chubb: chubb,
  // cna: cna,
  217: cse,
  168: cypress,
  382: cypress,
  575: cypress,
  445: edison,
  370: encompass,
  4: encompass,
  431: fednat,
  // firstComp: firstComp,
  406: floridaPeninsula,
  52: foremost,
  999: foremost,
  67: grange,
  // 420: gulfstream,
  // hagerty: hagerty,
  199: hanover,
  440: hanover,
  // hartford: hartford,
  418: heritage,
  515: mileAuto,
  523: heritage,
  // imperial: imperial,
  408: infinity,
  407: infinity,
  224: infinity,
  // jibna: jibna,
  // johnson_and_johnson: johnson_and_johnson,
  kemper_specialty: kemper_specialty,
  // liberty_mutual: liberty_mutual,
  // main_street_america: main_street_america,
  // modern_usa: modern_usa,
  430: monarch,
  361: national_general,
  201: national_general,
  366: national_value,
  986: national_advantage,
  // national_lloyds: national_lloyds,
  203: olympus,
  // orchid: orchid,
  160: pacific_specialty,
  // palomar_specialty: palomar_specialty,
  // peoples_trust: peoples_trust,
  // personal_umbrella: personal_umbrella,
  131: plymouth,
  // protective: protective,
  // pure: pure,
  // rli: rli,
  503: sage_sure_elevate,
  504: sage_sure_sure,
  507: sage_sure,
  505: sage_sure_safeport,
  502: sage_sure_occidental,
  510: sage_sure_wilshire,
  506: sage_sure_vave,
  516: sage_sure_markel,
  // 414: southern_fidelity,
  // 415: southern_fidelity,
  404: stillwater,
  // tapco: tapco,
  // texas_mutual: texas_mutual,
  // texas_windstorm: texas_windstorm,
  // tower_group: tower_group,
  // 363: tpc,
  102: universal_na,
  // us_assure: us_assure,
  472: upcic,
  543: logo45,
  574: elephant,
  494: nbic,
  994: branch,
  993: aonedge,
  363: philadelphiacontributionship,
  427: stjohns,
  489: security_first,
  456: united,
  593: metromile,
  995: jewelers_mutual,
  605: orion,
  331: csaa,
  343: mutual_of_enumclaw,
  657: annex_risk,
  990: delos,
  344: johnson_and_johnson,
  9999: vivint,
  384: mission_select,
  977: msi,
  39: american_modern,
  463: aspire_general,
  464: agia,
  980: nnva,
  514: sage_sure_trium,
  303: trexis,

  //flood_quotes and life_quotes using carrier name as ids are not returned in the response
  "Wright Flood NFIP": wright,
  "Wright Flood Private": wright_private,
  "Wright Flood": wright_private,
  Neptune: neptune,
  Ethos: ethos,
  ASI: asi,
  AON: aonedge,
  Palomar: palomar,
};

export default logos;
