export const sendUserIdToGoogleTagManager = (logged_in_user) => {
  try {
    if (logged_in_user && window.dataLayer) {
      window.dataLayer.push({
        user_id: logged_in_user,
      });
    }
  } catch (error) {
    console.log("error sending Google Tag Manager user_id", error);
  }
};
