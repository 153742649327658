import { ADD_COVERAGES } from "../constants";
import produce from "immer";
import { Coverage } from "./types";

const initialState: Coverage = {};
export const coverageReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_COVERAGES:
      draft = { ...draft, ...action.payload };
      return draft;
    default:
      return draft;
  }
}, initialState);
