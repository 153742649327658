import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { validateUser, validateUserHash } from "../services/agent";

import { Button } from "react-bootstrap";
import { deleteSession } from "../services/session";
import { getQueryParams } from "../utils/queryParams";
import { resetStore } from "../redux/actions/reset";
import styled from "styled-components";

const LoginWrapper = styled.div`
  flex-grow: 1;
`;

const Login = ({ handleSession }) => {
  const queryParams = getQueryParams(window.location.search);
  const session = useSelector((store) => store.session);
  const sessionRef = useRef(session);
  sessionRef.current = session;
  const dispatch = useDispatch();
  const sfAccountId = queryParams.sfAccountId;
  const createNewAccount = queryParams.createNewAccount === "true";
  const hash = process.env.REACT_APP_AUTH_HASH;
  const [authorized, setAuthorized] = useState(true);
  const handleValidate = async () => {
    try {
      let ip = "";
      if (hash) {
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        ip = data.ip;
      }
      const res = hash
        ? await validateUserHash(hash, ip)
        : await validateUser();

      if ((hash && res.data.authorized) || (!hash && res)) {
        setTimeout(async () => {
          if (sessionRef.current.uuid && (!queryParams.rs || sfAccountId)) {
            await deleteSession(sessionRef.current.uuid);
            dispatch(resetStore());
          }
        }, 2000);

        setTimeout(async () => {
          await handleSession(null, sfAccountId, createNewAccount);
          // sessionStorage.setItem("ssuuid", sessionRef.current.uuid);
          const windowLocation = hash
            ? `${window.location.origin}/address?user=${process.env.REACT_APP_DEFAULT_USER}`
            : res.data;
          window.open(windowLocation, "_self").focus();
        }, 3000);
      }
    } catch (e) {
      setAuthorized(false);
      console.log("Something went wrong", e);
    }
  };

  useEffect(() => {
    handleValidate();
    // eslint-disable-next-line
  }, []);

  return (
    <LoginWrapper className="d-flex flex-column align-items-center justify-content-center">
      <Button variant="light" disabled>
        {!authorized
          ? "Invalid User"
          : hash
          ? "Authenticating..."
          : "Redirecting to Salesforce Login..."}
      </Button>
    </LoginWrapper>
  );
};

export default Login;
