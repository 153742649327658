import axios from "axios";

export const addPropertyData = (payload) =>
  axios.post("/api/propertydata", payload);

export const getPropertyData = (propertyId) =>
  axios.get(`/api/qti/property/${propertyId}`);

export const updateCarrierPropertyData = (propertyId, payload) =>
  axios.put(`/api/qti/property/${propertyId}`, payload);

export const createPropertyinPg = (payload) =>
  axios.post("/api/sfproperty", payload);

export const updatePropertyinPg = (payload) =>
  axios.put("/api/sfproperty", payload);

export const updateProperty = (payload) =>
  axios.put("/api/updatepropertyinpg", payload);
