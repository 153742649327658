import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { Button, CloseButton } from "react-bootstrap";
import { ReactComponent as CaretRight } from "../../assets/images/components/response-page/caretRight.svg";

import "./AviatorModal.css";

const StyledAviatorModal = styled.div`
  font-family: Rethink Sans Variable;
  padding: 38px 30px 30px 30px;
  border-radius: 25px;
  background-color: white;
  display: flex;
  flex-direction: column;

  & .header {
    font-family: Rethink Sans Variable;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #2d2c2a;
    height: 29px;
  }

  & .content {
    margin: 20px 0 31px 0;
    display: flex;
    & > div {
      display: inline-flex;
      flex-direction: column;
      flex-grow: 0;
    }
  }

  & .closeButton {
    float: right;
    position: absolute;
    right: 30px;
    top: 30px;
  }

  & .submitButton {
    width: 200px;
    font-size: 18px;
    border-radius: 39px;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border: none;
    background-color: #157f3a;
    color: #edfdd6;
    &:disabled {
      background-color: #909090;
    }
  }

  & .formSubmitRow {
    height: 48px;
  }
`;

type AviatorModalProps = {
  closeModal: () => void;
  children?: React.ReactNode;
  heading?: string;
  show: boolean;
  hasSubmit?: boolean;
  buttonText?: string;
  isDisabled?: boolean;
  onClick?: (data: unknown) => void;
  dialogClassName?: string;
};

const AviatorModal = ({
  children = null,
  closeModal,
  heading = "",
  show = false,
  hasSubmit = false,
  buttonText = "",
  isDisabled = false,
  onClick = () => {},
  dialogClassName = "",
}: AviatorModalProps) => {
  return (
    <Modal
      show={show}
      centered
      className="aviator-common-modal"
      dialogClassName={dialogClassName}
    >
      <StyledAviatorModal>
        <div className="header">{heading}</div>
        <CloseButton onClick={closeModal} className="closeButton" />
        <div className="content">{children}</div>
        {hasSubmit && (
          <div className="formSubmitRow row justify-content-center">
            <Button
              onClick={onClick}
              className="submitButton"
              type="submit"
              disabled={isDisabled}
            >
              {buttonText}
              <CaretRight />
            </Button>
          </div>
        )}
      </StyledAviatorModal>
    </Modal>
  );
};

export default AviatorModal;
