// @ts-check

// This context is used to tap into some of the functions on `pages/Quotes.js`

// @ts-ignore
import * as AppTypes from "../app.d.ts"; // eslint-disable-line no-unused-vars
import * as ConstantTypes from "../constants"; // eslint-disable-line no-unused-vars

import { createContext, useContext } from "react";
import { isEmpty, keys } from "lodash";

const QuoteResponsesContext =
  /**
   * @typedef {{
   *   fetchAndUpdateQuotes: (
   *     homeQuoteId?: string,
   *     autoQuoteId?: string,
   *     type: ConstantTypes.PRIMARY | ConstantTypes.SPOUSE
   *   ) => Promise<void>;
   *   insertNewQuoteResponses: (input: {
   *     quoteRequestId: string;
   *     quoteResponses: Pick<
   *       AppTypes.QuoteResponse,
   *       "company_client_id__c" | "line_of_business__c"
   *     >[];
   *   }) => void;
   *   removeQuoteResponse: (input: {
   *     quoteResponseId: string;
   *     quote_request_heroku_id: string;
   *     line_of_business__c: string;
   *   }) => void;
   *   refreshQuoteResponses: () => void;
   *   setShowNewSmartHomePackageSelectionModal: (show: boolean) => void;
   * }} ContextState
   */
  /**
   * Work-around attained from:
   * https://github.com/microsoft/TypeScript/issues/27387#issuecomment-1925609832
   *
   * @type {typeof createContext<null | ContextState>}
   */
  (createContext)(null);

export const QuoteResponsesContextProvider = QuoteResponsesContext.Provider;

export const useQuoteResponsesContext = () => {
  const context = useContext(QuoteResponsesContext);

  if (!context || isEmpty(keys(context))) {
    console.error(
      "useQuoteResponsesContext must be used within a QuoteResponsesContextProvider"
    );
  }
  return context;
};
