import { orderBy, sortBy } from "lodash";

import { removeEmptyValues } from "./object";
import store from "../redux/store";

const ACTIVE_CLIENT_STATUSES = ["Quality Control Review", "Current Client"];

export const sanitizeCoverage = (coverage) => {
  const alwaysIncludeFields = ["effective_date__c"];

  const newCoverage = removeEmptyValues(coverage);

  alwaysIncludeFields.forEach((field) => {
    newCoverage[field] = coverage[field];
  });

  return newCoverage;
};

export const filterPoliciesWithAddressStateCity = (policies) =>
  policies.filter(
    (policy) =>
      policy.Property_Address__c &&
      policy.Property_City__c &&
      policy.Property_State__c
  );

export const orderPoliciesByStatus = (policies) => {
  //order by policy type and then by StageName
  //https://goosehead.atlassian.net/browse/HQ-2365
  policies = sortBy(policies, [
    "Policy_Type__c",
    (policy) => {
      const rank = {
        "Quality Control Review": 0,
        "Current Client": 1,
        "Pending Renewal": 2,
        "Pending Cancellation": 3,
        Cancelled: 4,
        Other: 5,
      };
      return rank[policy.StageName] ?? rank["Other"];
    },
  ]);

  return policies;
};

export const filterDuplicatedPolicies = (policies) => {
  // create tokenaized map of policies to search for duplicates
  // token means the concatenation of LineOfBusiness and Address
  // to identify if a policy is duplicated
  const policiesByTokenMap = policies.map((policy) => {
    const token =
      `${policy.Policy_Type__c}${policy.Property_Address__c}${policy.Property_City__c}${policy.Property_State__c}`
        .replace(/\s/g, "")
        .toLowerCase();
    return {
      id: policy.Id,
      token,
      cancel_date: policy.Cancel_Date__c ?? "1970-01-01",
      status: policy.StageName,
    };
  });

  // set default empty array for each status
  const policiesByStatus = {
    "Quality Control Review": [],
    "Current Client": [],
    "Pending Renewal": [],
    "Pending Cancellation": [],
    Cancelled: [],
  };

  // Separate policies by their statuses
  for (const policy of policiesByTokenMap) {
    policiesByStatus[policy.status]?.push(policy);
  }

  // If no policies are cancelled, pending renewal or pending cancellation, return the policies as is
  if (
    policiesByStatus["Cancelled"].length === 0 &&
    policiesByStatus["Pending Renewal"].length === 0 &&
    policiesByStatus["Pending Cancellation"].length === 0
  ) {
    //return only current client policies
    return policies.filter((policy) =>
      ACTIVE_CLIENT_STATUSES.some((status) => policy.StageName === status)
    );
  }

  // Order cancelled policies by cancel date
  policiesByStatus["Cancelled"] = orderBy(
    policiesByStatus["Cancelled"],
    ["cancel_date"],
    ["desc"]
  );

  //Remove cancelled policies if there are duplicates in "current client" policies
  const currentClientPolicies = [
    ...policiesByStatus["Quality Control Review"],
    ...policiesByStatus["Current Client"],
  ];
  const cancelledPolicies = policiesByStatus["Cancelled"];

  policiesByStatus["Cancelled"] = cancelledPolicies.filter(
    (cancelledPolicy) =>
      !currentClientPolicies.some(
        (currentPolicy) => currentPolicy.token === cancelledPolicy.token
      )
  );
  // Create an array of none active policies
  const nonActivePolicies = [
    ...policiesByStatus["Pending Renewal"],
    ...policiesByStatus["Pending Cancellation"],
    ...policiesByStatus["Cancelled"],
  ];

  // Remove duplicates based on token from noneActivePolicies
  // https://goosehead.atlassian.net/browse/HQ-2365
  const uniqueTokens = new Set();
  const noneActivePoliciesWithoutDuplicates = [];

  for (const policy of nonActivePolicies) {
    const policyToken = policy.token;
    if (!uniqueTokens.has(policyToken)) {
      uniqueTokens.add(policyToken);
      noneActivePoliciesWithoutDuplicates.push(policy);
    }
  }

  //merge tokenaized policies with current client tokenaized policies
  const deduplicatedPolicies = [
    ...currentClientPolicies,
    ...noneActivePoliciesWithoutDuplicates,
  ];

  // get policies that are in the deduplicated list from the original list
  const resultPoliciesFiltered = policies.filter((policy) => {
    const policyFound = deduplicatedPolicies.find((p) => p.id === policy.Id);
    return policyFound !== undefined;
  });

  return resultPoliciesFiltered;
};

export const getIsReshop = (state = store.getState()) => {
  return state?.contact?.isReshop;
};

export const getRoofReplacedYear = ({
  Year_Roof_Replaced__c = "",
  Roof_Replaced__c = "",
  Year_Built__c = "",
}) => {
  if (Year_Roof_Replaced__c) return Year_Roof_Replaced__c;
  if (!Roof_Replaced__c || Roof_Replaced__c === "No")
    return Year_Built__c ?? "";
  if (Roof_Replaced__c === "Yes") return "";
};

export const getHeatingPlumbingElectricalDefaults = (value = "") => {
  if (!value || isNaN(value)) return "";
  return value;
};
