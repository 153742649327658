import "../../radioGroup.css";

const CustomRadio = (props) => {
  const { handleClick, checked, name, title, value } = props;
  return (
    <label className="radio-container" aria-label={name}>
      <input
        type="radio"
        checked={checked}
        name={name}
        onClick={handleClick}
        value={value}
      />
      <span className="label-text">{title}</span>
      <span className="checkmark"></span>
    </label>
  );
};

export default CustomRadio;
