import { ChangeEvent, useState } from "react";
import { addContact } from "../../../redux/actions/contact";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { InsuredContact } from "../../../redux/reducers/types";
import { getFilterDropdownButtonStyles, useDropdown } from "../../../utilsMisc";
import Filter from "./Filter";

const ContactFilter = () => {
  const [insuredContact, setInsuredContact] = useState<InsuredContact>("best");

  const dispatch = useDispatch();
  const { spouseHomeQuoteId, spouseAutoQuoteId, firstName, spousefirstName } =
    useSelector((store) => store.contact);
  const hasSpouseQuotes = !!spouseHomeQuoteId || !!spouseAutoQuoteId;

  const { show, toggle, close, clickOutsideRef } = useDropdown();
  if (!hasSpouseQuotes) return null;

  const DEFAULT_TOGGLE_BUTTON_TEXT = "Primary Insured";

  const toggleButtonText =
    (insuredContact === "best"
      ? DEFAULT_TOGGLE_BUTTON_TEXT
      : insuredContact === "primary"
      ? firstName
      : spousefirstName) ?? DEFAULT_TOGGLE_BUTTON_TEXT;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInsuredContact(value as InsuredContact);
    dispatch(
      addContact({
        insuredContact: value,
      })
    );
  };
  const { backgroundColor, borderColor, color } = getFilterDropdownButtonStyles(
    toggleButtonText,
    DEFAULT_TOGGLE_BUTTON_TEXT
  );

  return (
    <Filter
      toggleButtonText={toggleButtonText}
      header="Primary Insured"
      show={show}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      color={color}
      close={close}
      toggle={toggle}
      ref={clickOutsideRef}
    >
      <div className="item-list">
        <div className="item-row">
          <input
            type="radio"
            id="best"
            value="best"
            name="contact"
            checked={insuredContact === "best"}
            onChange={handleChange}
          />
          <label htmlFor="best">Best</label>
        </div>
        <div className="item-row" key={firstName}>
          <input
            type="radio"
            id={firstName}
            value="primary"
            name="contact"
            checked={insuredContact === "primary"}
            onChange={handleChange}
          />
          <label htmlFor={firstName}>{firstName}</label>
        </div>
        <div className="item-row" key={spousefirstName}>
          <input
            type="radio"
            id={spousefirstName}
            value="spouse"
            name="contact"
            checked={insuredContact === "spouse"}
            onChange={handleChange}
          />
          <label htmlFor={spousefirstName}>{spousefirstName}</label>
        </div>
      </div>
    </Filter>
  );
};

export default ContactFilter;
