import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BarLoader } from "react-spinners";
import FilterBar from "./new_design/filters/FilterBar";
import ResubmitQuotesModal from "./modals/ResubmitQuotesModal";
import StatusBanner from "./StatusBanner";
import Toolbar from "./Toolbar";
import { clearLocalSessionId } from "../services/session";
import { getPageNameFromUrl } from "../utils";
import gooseheadLogo from "../assets/images/main/Logo.png";
import { profileIdsToRerunQuotes } from "../constants";
import styled from "styled-components";
import { theme } from "../assets/theme";
import { useSelector } from "../redux/hooks";

interface StyledHeaderProps {
  $position: "static" | "relative" | "absolute" | "fixed" | "sticky";
}

const StyledHeader = styled.header<StyledHeaderProps>`
  top: 0;
  position: ${({ $position }) => $position};
  z-index: 1000;
`;

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  height: 60px;
  border: solid 1px #d3d3d3;
  border-left: none;
  border-right: none;
  background-color: white;

  & img {
    width: 150px;
    height: 35px;
    object-fit: contain;
  }

  & .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
`;

type NavButtonProps = {
  $backGroundColor?: string;
  $border?: string;
  $color?: string;
};

export const NavButton = styled.button<NavButtonProps>`
  background-color: ${({ $backGroundColor }) =>
    $backGroundColor ?? "transparent"};
  border: ${({ $border }) => $border ?? "none"};
  color: ${({ $color }) => $color ?? "inherit"};
  width: max-content;
  max-width: 20rem;
  height: 35px;
  padding: 5.3px 26.5px;
  border-radius: 9999px;
  border: solid 1.5px ${({ theme }) => theme.green_new};
  font-weight: 600;
`;

type HeaderProps = {
  resetErrorBoundary?: () => void;
};

const Header: React.FC<HeaderProps> = ({ resetErrorBoundary }) => {
  const contact = useSelector((store) => store.contact);
  const isFetchingQuotes = useSelector((store) => store.isFetchingQuotes);
  const isFetching = useSelector((store) => store.isFetching);
  const navigate = useNavigate();
  const location = useLocation();
  const [showResubmitModal, setShowResubmitModal] = useState(false);
  const {
    newLoggedInUser,
    newLoggedInUserProfileId,
    channel,
    marketing_lead_source__c,
  } = contact;

  const isFrontPage = ["/", "/address"].includes(
    location.pathname.toLowerCase()
  );
  const isFrontOrLoginOrErrorPage =
    isFrontPage || location.pathname === "/login" || !!resetErrorBoundary;
  const isResponsesPage = getPageNameFromUrl() === "Responses";

  const canResubmit = isResponsesPage && !isFetchingQuotes && !isFetching;
  const position: StyledHeaderProps["$position"] = isResponsesPage
    ? "sticky"
    : "relative";

  const isLoggedInWithDifferentUser =
    newLoggedInUser && newLoggedInUser !== contact.user;

  const isEndUser =
    !!newLoggedInUserProfileId &&
    [
      profileIdsToRerunQuotes["Agent"],
      profileIdsToRerunQuotes["Sales Manager"],
      profileIdsToRerunQuotes["Franchise User"],
    ].includes(newLoggedInUserProfileId);

  const isEnterpriseSalesQuote =
    channel === "Enterprise Sales" && !!marketing_lead_source__c;

  const startOver = () => {
    clearLocalSessionId();
    navigate(`/login`);
    if (resetErrorBoundary) {
      resetErrorBoundary();
    }
  };

  return (
    <StyledHeader $position={position}>
      <Navbar>
        <img src={gooseheadLogo} alt="Goosehead" />
        <div className="right">
          {canResubmit && (
            <>
              <NavButton
                $color={theme.green_new}
                $border={`1px solid ${theme.green_new}`}
                onClick={() => setShowResubmitModal(true)}
              >
                {(isLoggedInWithDifferentUser && isEndUser) ||
                isEnterpriseSalesQuote
                  ? "Resubmit with My Logins"
                  : "Resubmit"}
              </NavButton>
              <ResubmitQuotesModal
                setShowResubmitModal={(flag) => setShowResubmitModal(flag)}
                showResubmitModal={showResubmitModal}
                resubmitWithMyLogins={
                  (isLoggedInWithDifferentUser && isEndUser) ||
                  isEnterpriseSalesQuote
                }
              />
            </>
          )}
          {!isFrontOrLoginOrErrorPage && (
            <NavButton
              $color="white"
              $backGroundColor={theme.green_new}
              onClick={startOver}
            >
              Start Over
            </NavButton>
          )}
        </div>
      </Navbar>
      {isResponsesPage && (
        <>
          <Toolbar />
          <FilterBar />
        </>
      )}
      {isFetchingQuotes && (
        <BarLoader
          width="100%"
          color={theme.green}
          height={3}
          cssOverride={{ position: "relative", top: "-1px" }}
          speedMultiplier={0.8}
        />
      )}
      <StatusBanner />
    </StyledHeader>
  );
};

export default Header;
