import produce from "immer";
import { ADD_SESSION } from "../constants";

// TODO: change the null types
export type Session = {
  uuid?: string;
  page_stopped?: number;
  line_of_business?: string;
  completed?: boolean;
  created_date?: string;
  agent_sfid?: string;
  willpurchase?: boolean;
  contact_data?: string;
  property_data?: string;
  drivers_data?: string;
  coverages_data?: string;
  vehicles_data?: string;
  leadSource?: null;
  referredBy?: null;
  leadsource_detail?: null;
  auto_quote_id?: string;
  home_quote_id?: string;
  page_stopped_name?: null;
  qti_id?: string | null;
  test_variant?: null;
  assignmentType?: null;
  da_link_used__c?: null;
  discounts?: null;
  qti_option_id?: null;
  synced?: boolean;
  marketing_type?: null;
  agent_rater_session?: boolean;
  partnership_user_type?: null;
  is_demo_session?: boolean;
  is_home_payment_success?: boolean;
  is_auto_payment_success?: boolean;
  qti_id_v2?: null;
  logged_in_user?: string;
  final_quotes_data?: null;
  session_replay?: null;
  quoteSource?: string;
};
const initialState: Session = {};

export const sessionReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_SESSION:
      draft = { ...draft, ...action.payload };
      return draft;
    default:
      return draft;
  }
}, initialState);
