import { Quote } from "../../types";
import { StyledQuoteSpouseButton } from "./styled";
import { useSelector } from "../../redux/hooks";
import { useSwitchPrimary } from "../../customHooks/useSwitchPrimary";

export const QuoteSpouseButton = ({ quote }: { quote: Quote }) => {
  const contact = useSelector((state) => state.contact);
  const { hasSwitchContactButton, handleSwitchContactButton } =
    useSwitchPrimary(quote);

  return (
    (!!contact.spouseId && hasSwitchContactButton && (
      <StyledQuoteSpouseButton
        className="btn switch-primary"
        onClick={handleSwitchContactButton}
      >
        <span>Quote</span> <span>Spouse ❯</span>
      </StyledQuoteSpouseButton>
    )) ||
    null
  );
};
