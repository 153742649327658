export const TOTAL_POLICIES_LABEL = {
  success: "Total Policies Sold",
  failed: "Policies Sold When Quote Fails",
};

export const ERROR_CONTENT = {
  countyUndefined: "Cannot determine county.",
  noPoliciesSingleLob: "No policies sold within the selected time frame.",
  noPolicies: "No policies sold within the selected\n time frame.",
};
