import axios from "axios";
import { ssnPattern } from "../constants";

export const getAccountDetails = (accountId) => {
  return axios.get(`/api/sfAccount/${accountId}`);
};
export const getAccountInSf = (payload) =>
  axios.post("/api/sfaccountcheck", payload);

export const createAccountAndPrimaryContact = (payload) =>
  axios.post("/api/sfaccountpcontact", {
    ...payload,
    ...(payload.ssn && ssnPattern.test(payload.ssn)
      ? {
          ssn: payload.ssn,
        }
      : {}),
  });

export const createSpouseContact = (payload) =>
  axios.post("/api/addspouse", {
    ...payload,
    ...(payload.ssn && ssnPattern.test(payload.ssn)
      ? {
          ssn: payload.ssn,
        }
      : {}),
  });

export const updateContact = (payload) =>
  axios.put("/api/sfaccountpcontact", {
    ...payload,
    ...(payload.ssn && ssnPattern.test(payload.ssn)
      ? {
          ssn: payload.ssn,
        }
      : {}),
  });

export const deleteContact = (id) => axios.delete(`/api/contact/${id}`);

export const updateContactInPg = (payload) =>
  axios.put("/api/updatesfcontacts", payload);

export const getProspectiveAgent = (payload) =>
  axios.post("/api/prospectiveagent", payload);

export const updateSfContactByAccountId = (payload) =>
  axios.put("/api/sfcontact", payload);

export const getReferralPartnerContact = (
  searchValue,
  referralPartnerType,
  currentUser
) => {
  return axios.get(
    `/api/contacts/referredby/?searchValue=${searchValue}&referralPartnerType=${referralPartnerType}&currentUser=${currentUser}`
  );
};

export const updateAccountinPg = (accountId, payload) => {
  return axios.put(`/api/sfAccount/${accountId}`, payload);
};

export const updateAccountInSf = (accountSfId, payload) =>
  axios.put(`/api/accountinsf/${accountSfId}`, payload);

export const syncAllData = (payload) => axios.post("/api/syncalldata", payload);

export const getAccountDetailsinSf = (accountId) => {
  return axios.get(`/api/sfaccountdetails/${accountId}`);
};

export const updateContactsInPgByAccountId = (accountId, payload) => {
  return axios.put(`/api/contact/${accountId}`, payload);
};

export const getPoliciesByAccountId = (accountId) => {
  return axios.get(`/api/policies/${accountId}`);
};

export const getDownloadsByPolicyId = (policyId) => {
  return axios.get(`/api/downloads/${policyId}`);
};

export const getMortgageeDetailsByPolicyId = (policyId) => {
  return axios.get(`/api/mortgagee/${policyId}`);
};

export const createProspectInSf = (payload) => {
  return axios.post("/api/sfprospect", payload);
};

export const getUsersInGroup = (groupId) => {
  return axios.get(`/api/group/${groupId}`);
};
