import { MAX_CHARS_AGENT_NOTES } from "../constants";
import { addAgent } from "../redux/actions/agent";
import { addSession } from "../redux/actions/session";
import { getAgentDetails } from "./agent";
import store from "../redux/store";
import { updateSession } from "./session";

/**
 * https://goosehead.atlassian.net/browse/HQ-2294 short-term fix for agentNotes
 * length issue
 */
const agentNotesCutText = (payload) => {
  const { contact_data } = payload;

  if (contact_data) {
    const payloadUpdated = { ...payload };
    const contactDataJson = JSON.parse(contact_data);
    const { agentNotes } = contactDataJson;
    if (agentNotes?.length >= MAX_CHARS_AGENT_NOTES) {
      contactDataJson.agentNotes = agentNotes.substring(
        0,
        MAX_CHARS_AGENT_NOTES
      );
      payloadUpdated.contact_data = JSON.stringify(contactDataJson);
      return payloadUpdated;
    }
  }

  return payload;
};

export const handleUpdateSession = async (payload, sessionId) => {
  try {
    const sessionUuid = sessionId || store.getState().session.uuid;
    const updatedPayload = agentNotesCutText(payload);
    const response = await updateSession({
      uuid: sessionUuid,
      ...updatedPayload,
    });
    const sessionData = response.data.sessionData[0];
    store.dispatch(addSession(sessionData));
    return sessionData;
  } catch (error) {
    console.log("Error Updating Session", error);
  }
};
export const getQueryParam = (param) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params[param];
};

/**
 * Returns the agentId if the override is enabled and 'id' query param is
 * present in url else returns null
 *
 * @returns {string | null}
 */
export const getAgentIdFromParam = () => {
  const agentId = getQueryParam("id");
  return process.env.REACT_APP_OVERRIDE_AGENT_ID === "true" ? agentId : null;
};

/**
 * @param {string} agent_sfid - SF Id of the agent
 * @param {string} sessionId - Optional: session's uuid
 * @returns None
 *
 *   - This function gets agentDetails from the backend and fills the agent object
 *       in redux and session db.
 */
export const handleAgentDetails = async (agent_sfid, sessionId) => {
  try {
    const agentResponse = await getAgentDetails(agent_sfid);
    const agentData = agentResponse.data.agentData[0];
    store.dispatch(addAgent(agentData));
    await handleUpdateSession({ agent_sfid: agent_sfid }, sessionId);
    return agentData;
  } catch (error) {
    console.log("Error getting agent details..", error);
  }
};

export const createFeatureFlagContext = () => {
  const session = store.getState().session;
  const contact = JSON.parse(session.contact_data);
  const property = JSON.parse(session.property_data);

  const key = [
    contact?.agentUserId,
    session?.logged_in_user,
    session?.agent_sfid,
    contact?.sfAccountId,
    contact?.accountId,
  ].find(
    (val) =>
      val != null && (typeof val === "string" ? val.trim().length > 0 : true)
  );

  let context = {
    kind: "user",
    type: "agent",
    anonymous: false,
    key,
    street: property?.street_number,
    zip: property?.postal_code,
    state: property?.administrative_area_level_1,
    city: property?.locality,
    leadEmail: contact?.email,
    name: contact?.agentName,
    leadDob: contact?.dob,
    leadName: contact?.firstName
      ? `${contact?.firstName} ${contact?.lastName}`
      : "",
    agentName: contact?.agentName,
    agentEmail: contact?.agentEmail,
    agentState: contact?.agentState,
    agentProfileId: contact?.agentProfileId,
    loggedInUser: contact?.agentUserId,
  };

  return context;
};
