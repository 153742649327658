import type { TypedUseSelectorHook } from "react-redux";
import {
  useDispatch as _useDispatch,
  useSelector as _useSelector,
  useStore as _useStore,
} from "react-redux";
import type { AppDispatch, AppStore, RootState } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch: () => AppDispatch = _useDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;
export const useStore: () => AppStore = _useStore;
