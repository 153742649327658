import styled, { css } from "styled-components";

import InputMask from "react-input-mask";
import { forwardRef } from "react";
import { v4 as uuidv4 } from "uuid";

const InputWrapper = styled.div`
  margin: ${({ isCentered }) => (isCentered ? "auto" : undefined)};
  position: relative;
  @media (min-width: 992px) {
    ${(props) =>
      props.seterrorheight &&
      props.error &&
      css`
        top: ${(props) =>
          props.error === "Please enter year (2019)" ? "-2px" : "-7px"};
      `}
    & > small {
      ${(props) =>
        props.seterrorheight &&
        css`
          display: inline-block;
          height: 50px;
        `}
    }
  }

  & > input {
    background: white;
    color: ${(props) => props.theme.primary};
    font-size: rem(15px);
    font-weight: 400;
    border-radius: 25px;
    padding: 12px 10px;
    border: 1px solid #707070;
    outline: none;
    height: 50px;
    width: 100%;
    ::placeholder {
      color: ${(props) => props.theme.gray};
      font-weight: 200;
      opacity: 70%;
    }
  }
`;

const Label = styled.label`
  font-size: 15px;
  margin-left: 3px;
  margin-bottom: 10px;
  color: ${(props) =>
    props.hideLabel
      ? "transparent"
      : props.dark
      ? props.theme.white
      : props.theme.primary};
  ${(props) =>
    props.showCustomTitle &&
    css`
      display: none;
    `}
`;

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef((props, ref) => {
  const { handleChange, dark, wrapperClassName, isCentered, ...rest } = props;
  const id = props.id ?? uuidv4();
  return (
    <InputWrapper
      seterrorheight={props.seterrorheight}
      error={props.error}
      isCentered={isCentered}
      className={wrapperClassName}
    >
      {props.label && (
        <Label
          htmlFor={id}
          dark={dark}
          hideLabel={props.hideLabel}
          showCustomTitle={props.showCustomTitle}
        >
          {props.label}
        </Label>
      )}
      {!props.masked ? (
        <input
          {...rest}
          id={id}
          ref={ref}
          type={props.type || "text"}
          placeholder={props.placeholder || ""}
          onChange={props.handleChange}
          min={props.min}
          max={props.max}
          onWheel={(e) => e.target.blur()}
          onKeyDown={props.handleOnKeyDown}
        />
      ) : (
        <InputMask
          {...rest}
          ref={ref}
          type={props.type || "text"}
          placeholder={props.placeholder || ""}
          onChange={props.handleChange}
          min={props.min}
          max={props.max}
          maskChar={null}
          formatChars={{
            9: "[0-9]",
            a: "[A-Za-z]",
            "*": "[A-Za-z0-9]",
            "#": "[0-9*]",
          }}
        />
      )}
      <small className="fw-light font-italic text-danger">{props.error}</small>
    </InputWrapper>
  );
});

// CustomInput.displayName = "CustomInput";

export default CustomInput;
