import {
  ERROR_TYPES_WITHOUT_SWITCH_PRIMARY,
  REFETCH_DELAY_MS,
  REFETCH_TIMES,
  addSwitchPrimaryDoneCarrierIdInRedux,
  handleSwitchPrimaryForHomeAndAuto,
  isCarrierAvailableForSwitchPrimary,
} from "../utils/switchPrimary";

import { MouseEvent } from "react";
import { Quote } from "../types";
import { sleep } from "../utils";
import { updateIsFetchingQuotes } from "../redux/actions/fetchingQuotes";
import { useDispatch } from "react-redux";
import { useQuoteResponsesContext } from "../contexts/QuoteResponsesContext";
import { useSelector } from "../redux/hooks";

export const useSwitchPrimary = (quote: Quote) => {
  const contact = useSelector((store) => store.contact);

  const { spouseHomeQuoteId, spouseAutoQuoteId } = contact;

  const dispatch = useDispatch();

  const fetchAndUpdateQuotes = useQuoteResponsesContext()?.fetchAndUpdateQuotes;

  const switchPrimaryDoneCarrierIds = contact.switchPrimaryDoneCarrierIds ?? [];

  const isValidErrorTypeToSwitchPrimary =
    !ERROR_TYPES_WITHOUT_SWITCH_PRIMARY.includes(quote.error_type__c);

  const isSwitchPrimaryNotDone =
    isCarrierAvailableForSwitchPrimary(quote) &&
    !switchPrimaryDoneCarrierIds.includes(quote.company_client_id__c);

  const hasSwitchContactButton =
    isSwitchPrimaryNotDone && isValidErrorTypeToSwitchPrimary;

  const handleSwitchContactButton = async (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    try {
      dispatch(updateIsFetchingQuotes(true));
      addSwitchPrimaryDoneCarrierIdInRedux(quote.company_client_id__c);
      await handleSwitchPrimaryForHomeAndAuto(quote);
      for (let i = 0; i < REFETCH_TIMES; i++) {
        await sleep(REFETCH_DELAY_MS);
        await fetchAndUpdateQuotes?.(
          spouseHomeQuoteId,
          spouseAutoQuoteId,
          "spouse"
        );
      }
    } catch (error) {
      console.log("Error switching primary", error);
    } finally {
      dispatch(updateIsFetchingQuotes(false));
    }
  };
  return { hasSwitchContactButton, handleSwitchContactButton };
};
