import styled from "styled-components";

export const StyledIframe = styled.iframe`
  border: none !important;
  line-height: 0px;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
