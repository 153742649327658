import styled from "styled-components";
import { FeedbackContainerProps } from "../types/FeedbackContainerProps";

export const StyledPopupForm = styled.form<FeedbackContainerProps>`
  display: ${({ $display }) => $display};
  flex-direction: column;
  border: solid 1px #dcdcdc;
  border-radius: 8px 8px 0 0;
  background-color: white;

  & header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    padding: 15px;
    background-color: #006b67;
    border-bottom: solid 1px #d8d8d8;
    border-radius: 8px 8px 0 0;
    font-family: "Rethink Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    & .left {
      display: flex;
      align-items: center;
      gap: 10px;
      & svg {
        color: #fff;
      }
    }
    & .btn-close {
      background-size: 12px 12px;
      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(53, 53, 53, 0.25);
      }
    }
  }

  & .feedback-body {
    padding-bottom: 0;
    flex-grow: 1;
    & textarea {
      padding: 10px 15px;
      height: 100%;
      border: solid 1px #dcdcdc;
      font-family: "Rethink Sans", sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #4c4c4c;
      resize: none;
      width: 100%;
      &:focus-visible {
        outline: none;
      }
    }
  }
`;
