import { Dropdown } from "react-bootstrap";
import caretDown from "../../../assets/images/components/response-page/caretDown.svg";
import styled from "styled-components";

export const StyledFilterBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: "Rethink Sans Variable", sans-serif;
  background-color: #f8f8f8;
  height: 41px;
  padding: 0 12px;

  & .filter-label {
    color: #535353;
    font-size: 14px;
    font-weight: 500;
  }
`;

type StyledDropdownProps = {
  $backgroundColor: string;
  $borderColor: string;
  $color: string;
  $width?: string;
  $menuWidth?: string;
};
export const StyledDropdown = styled(Dropdown)<StyledDropdownProps>`
  & .dropdown-toggle {
    width: ${({ $width }) => $width ?? "150px"};
    height: 30px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 9999px;
    padding: 5px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* override Bootstrap styles */
    color: #004c18 !important;
    background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
    border: solid 1px ${({ $borderColor }) => $borderColor} !important;
    color: ${({ $color }) => $color ?? "#004c18"} !important;
    &:focus,
    &:active,
    &:hover {
      box-shadow: 0 0 0 1px #353535 !important;
    }

    &::after {
      /* arrow is a background image in Bootstrap */
      background-image: url(${caretDown as string});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 15px 15px;
      transform: translateX(5px);
    }
  }

  & .dropdown-menu {
    padding: 18px 10px 10px 10px;
    width: ${({ $menuWidth }) => $menuWidth ?? "231px"};
    border-radius: 10px;
    flex-direction: column;
    gap: 10px;
    border: solid 1px #d5d5d5;
    background-color: #fff;
    color: #2d2c2a;
    max-height: calc(100vh - 200px);

    &.show {
      display: flex;
    }

    & .header {
      display: flex;
      align-items: center;
      & legend {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }
      .btn-close {
        &:focus,
        &:active,
        &:hover {
          box-shadow: 0 0 0 1px #353535 !important;
        }
      }
    }
    & .carriers {
      display: flex;
      flex-direction: column;
      gap: 5px;
      min-height: 0;
      & .all-carrier-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
      }
      & .item-list {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 0 5px;
        overflow-y: auto;
        & .item-row {
          display: flex;
          align-items: center;
          font-size: 14px;

          & input[type="checkbox"],
          & input[type="radio"] {
            accent-color: #157f3a;
          }

          & label {
            padding-left: 10px;
          }
        }
      }
    }
  }
`;
