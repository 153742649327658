import * as errorUtils from "../utils/errors";
import * as httpUtils from "../utils/http";

import {
  CLIENT_ADDRESS_TYPE,
  DEFAULT_FLORIDA_PROPERTY_PROPS,
  Owner,
  Tenant,
  Vacant,
  mapOccupancyToPolicyForm,
  purchasingConstants,
  stateSpecificAopOptions,
  stateSpecificWindHailOptions,
} from "../constants";
import { Col, Row } from "react-bootstrap";
import {
  clearPropertyOwnerDetails,
  getDwellingCoverage,
  getFullMailingAddr,
  isPropertyThirtyYearsOld as isPropertyThirtyYearsOldFn,
} from "../utils/propertyProcess";
import {
  convertCurrencyToNumber,
  convertToCurrency,
  convertToDollars,
  includesIntegers,
  includesOnlyIntegers,
  removeCountyText,
} from "../utils/strings";
import {
  createFeatureFlagContext,
  handleUpdateSession,
} from "../services/utils";
import { getDefaultEffectiveDate, isDwelling, setFieldsEdited } from "../utils";
import { getRaterDefaults, updateQuote } from "../services/quote";
import {
  updateAccountinPg,
  updateContactsInPgByAccountId,
} from "../services/account";
import { updateProperty, updatePropertyinPg } from "../services/property";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import ClientAddress from "../components/ClientAddress";
import CustomButton from "../components/styled/buttons/CustomButton";
import CustomInput from "../components/styled/inputs/CustomInput";
import CustomSelect from "../components/styled/inputs/CustomSelect";
import DatePicker from "../components/DatePicker";
import FloridaPropertyForm from "../components/FloridaPropertyForm";
import FormContainer from "../components/styled/forms/FormContainer";
import Heading from "../components/Heading";
import LoadingDots from "../components/LoadingDots";
import { addContact } from "../redux/actions/contact";
import { addCoverages } from "../redux/actions/coverages";
import { addProperty } from "../redux/actions/property";
import { getHomeQuotesTitle } from "../utils/quotes";
import { getPropertyValidationSchema } from "../utils/validation";
import { handleIntInputKeyDown } from "../utils/misc";
import moment from "moment";
import { scrollToTop } from "../utils/layout";
import { updateIsFetching } from "../redux/actions/fetching";
import { useFeatureFlag } from "../services/featureFlag";
import { useFormik } from "formik";

const PropertyInfo = ({ handleNext }) => {
  const featureFlags = useFeatureFlag();
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const isFetching = useSelector((store) => store.isFetching);
  const property = useSelector((store) => store.property);

  const [clientMailingData, setClientMailingData] = useState(
    contact.clientMailingData ?? {}
  );

  const [isSelectedAddressInValid, setIsSelectedAddressInValid] =
    useState(false);
  const [addressError, setAddressError] = useState({
    street_numberError: "",
    unit_numberError: "",
    localityError: "",
    administrative_area_level_1Error: "",
    postal_codeError: "",
  });

  const showFoundationfield =
    featureFlags.getFlag("show-foundation-field") ?? false;
  const [debouncer, setDebouncer] = useState(null);

  const {
    squareFoot,
    exteriorWalls,
    yearBuilt,
    numStories,
    roofMaterial,
    roofReplaced,
    electrical,
    plumbing,
    heating,
    effectiveDate,
    dwellingCoverage,
    valueJewelry,
    aopDeductible,
    windHailDeductible,
    occupancy,
    personalPropertyCoverage,
    administrative_area_level_1: state,
    postal_code: zipcode,
    newPurchase,
    foundationType,
  } = property;

  const ephDefaultYear = moment().subtract(10, "years").get("year");
  const isAddressInFlorida = state === "FL";
  const isRenterPolicy = session.line_of_business.includes("Renter");
  const isCondoPolicy = session.line_of_business.includes("Condo");
  const isDwellingQuote = isDwelling();

  // For Dwelling, client mailing address field is mandatory, so it will always have data
  // for Home, if its not a new purchase, we will copy the property into mailing and billing
  // otherwise read it from the agent entry for new purchases.
  const getBillingData = () => {
    if (isDwellingQuote || propertyData.newPurchase === "Yes") {
      return {
        billingcity: clientMailingData?.locality,
        billingstate: clientMailingData?.administrative_area_level_1,
        billingstreet: clientMailingData?.street_number,
        billingpostalcode: clientMailingData?.postal_code,
        billingcounty: removeCountyText(
          clientMailingData?.administrative_area_level_2 ?? ""
        ),
      };
    } else
      return {
        billingcity: property.locality,
        billingstate: property.administrative_area_level_1,
        billingstreet: property.street_number,
        billingunit: property.unitNumber,
        billingpostalcode: property.postal_code,
        billingcounty: removeCountyText(
          property.administrative_area_level_2 ?? ""
        ),
      };
  };

  const getMailingData = () => {
    if (isDwellingQuote || propertyData.newPurchase === "Yes") {
      return {
        mailing_state: clientMailingData?.administrative_area_level_1,
        mailing_city: clientMailingData?.locality,
        mailing_street: clientMailingData?.street_number,
        mailing_postal_code: clientMailingData?.postal_code,
        mailing_county: removeCountyText(
          clientMailingData?.administrative_area_level_2 ?? ""
        ),
      };
    } else
      return {
        mailing_state: property.administrative_area_level_1,
        mailing_city: property.locality,
        mailing_street: property.street_number,
        mailing_postal_code: property.postal_code,
        mailing_county: removeCountyText(
          property.administrative_area_level_2 ?? ""
        ),
      };
  };

  const { isReshop } = contact;

  const handleAddressData = async () => {
    if (!validateAddress()) {
      return;
    }

    if (contact.accountId) {
      await updateAccountinPg(contact.accountId, getBillingData());
      await updateContactsInPgByAccountId(contact.accountId, getMailingData());
    }
    dispatch(addContact({ ...clientMailingData, clientMailingData }));
  };

  const updateQuoteandPropertyDetails = async (
    homeQuoteDetails,
    autoQuoteDetails,
    homePropertyDetails
  ) => {
    if (Object.keys(homeQuoteDetails).length) {
      await updateQuote({
        quoteId: contact.homeQuoteId,
        ...homeQuoteDetails,
      });
    }

    if (Object.keys(autoQuoteDetails).length) {
      await updateQuote({
        quoteId: contact.autoQuoteId,
        ...autoQuoteDetails,
      });
    }

    if (Object.keys(homePropertyDetails).length) {
      await updateProperty({
        heroku_id: contact.propertyId,
        ...homePropertyDetails,
      });
    }
  };

  const handlePropertyPurchase = async () => {
    try {
      let updatedHomeQuoteDetails = {};
      let updatedHomePropertyDetails = {};
      let updatedAutoQuoteDetails = {};
      dispatch(updateIsFetching(true));
      if (propertyData.newPurchase === "Yes") {
        if (!contact.accountId && !contact.sfAccountId) {
          clearPropertyOwnerDetails();
        }
        if (contact.accountId && !session.willpurchase) {
          if (contact.homeQuoteId) {
            updatedHomeQuoteDetails = {
              ...updatedHomeQuoteDetails,
              different_property_address__c: true,
              different_mailing_address__c: purchasingConstants.Yes,
            };

            updatedHomePropertyDetails = {
              ...updatedHomePropertyDetails,
              new_purchase__c: purchasingConstants.Yes,
            };
          }
          if (contact.autoQuoteId) {
            updatedAutoQuoteDetails = {
              ...updatedAutoQuoteDetails,
              different_mailing_address__c: purchasingConstants.Yes,
            };
          }
        }
        dispatch(addContact({ ...clientMailingData, clientMailingData }));
      } else {
        // not a new purchase
        if (!isDwellingQuote) {
          dispatch(
            addContact({
              street_number: property.street_number,
              locality: property.locality,
              administrative_area_level_1: property.administrative_area_level_1,
              postal_code: property.postal_code,
              route: property.route,
              clientMailingData: {
                street_number: "",
                locality: "",
                administrative_area_level_1: "",
                administrative_area_level_2: "",
                postal_code: "",
              },
            })
          );
        }
        if (contact.accountId && session.willpurchase) {
          if (contact.homeQuoteId) {
            updatedHomeQuoteDetails = {
              ...updatedHomeQuoteDetails,
              different_property_address__c: false,
              different_mailing_address__c: purchasingConstants.No,
            };

            updatedHomePropertyDetails = {
              ...updatedHomePropertyDetails,
              new_purchase__c: purchasingConstants.No,
            };
          }
          if (contact.autoQuoteId) {
            updatedAutoQuoteDetails = {
              ...updatedAutoQuoteDetails,
              different_mailing_address__c: purchasingConstants.No,
            };
          }

          await updateAccountinPg(contact.accountId, {
            billingcity: property.locality,
            billingstate: property.administrative_area_level_1,
            billingstreet: property.street_number,
            billingunit: property.unitNumber,
            billingpostalcode: property.postal_code,
            billingcounty: removeCountyText(
              property.administrative_area_level_2 ?? ""
            ),
          });

          await updateContactsInPgByAccountId(contact.accountId, {
            mailing_state: property.administrative_area_level_1,
            mailing_city: property.locality,
            mailing_street: property.street_number,
            mailing_unit: property.unitNumber,
            mailing_postal_code: property.postal_code,
            mailing_county: removeCountyText(
              property.administrative_area_level_2 ?? ""
            ),
          });
        }
      }

      await handleUpdateSession({
        uuid: session.uuid,
        willpurchase: propertyData.newPurchase,
      });

      await updateQuoteandPropertyDetails(
        updatedHomeQuoteDetails,
        updatedAutoQuoteDetails,
        updatedHomePropertyDetails
      );
    } catch (error) {
      console.log("Something has gone wrong ", error);
    }
  };

  const validateAddress = () => {
    let isValid = true;
    let street_numberError = "";
    if (
      !clientMailingData?.street_number ||
      clientMailingData?.street_number?.startsWith("NaN")
    ) {
      street_numberError = "Please enter your address";
    }

    let localityError = clientMailingData?.locality
      ? ""
      : "Please enter your city";
    if (includesIntegers(clientMailingData?.locality)) {
      localityError = "Please enter a valid city";
    }
    const administrative_area_level_1Error =
      clientMailingData?.administrative_area_level_1
        ? ""
        : "Please select a state";
    let postal_codeError = clientMailingData?.postal_code
      ? ""
      : "Please enter your zip code";
    if (
      (clientMailingData?.postal_code || "").length > 0 &&
      ((clientMailingData?.postal_code || "").length !== 5 ||
        !includesOnlyIntegers(clientMailingData?.postal_code))
    ) {
      postal_codeError = "Please enter a valid zip code";
    }
    if (
      street_numberError ||
      localityError ||
      administrative_area_level_1Error ||
      postal_codeError
    ) {
      isValid = false;
    }
    setAddressError({
      street_numberError,
      localityError,
      administrative_area_level_1Error,
      postal_codeError,
    });
    return isValid;
  };

  const getDwellingUsageType = () => {
    if (session.line_of_business.includes("Home")) {
      if (property.occupancy === Tenant) {
        return "Landlord/Rental";
      }
      if (property.occupancy === Vacant) {
        return "Vacant";
      }
    }
    if (
      session.line_of_business.includes("Condo") &&
      [Tenant, Vacant].includes(property.occupancy)
    ) {
      return "Condo";
    }
    return null;
  };

  const fullAddress = [
    property.street_number,
    property.locality,
    property.administrative_area_level_1,
    property.postal_code,
  ].join(" | ");

  useEffect(() => {
    async function prefillPropertyData() {
      if (!session.uuid || !state || !zipcode) return;
      const realDwellingCoverage = squareFoot
        ? await getDwellingCoverage({
            squareFoot,
            state,
            zipcode,
          })
        : dwellingCoverage;

      const raterdefaults = (await getRaterDefaults(state, zipcode)).data;

      formik.setValues(
        (prev) => ({
          ...prev,
          dwellingCoverage: dwellingCoverage || realDwellingCoverage,
          aopDeductible:
            aopDeductible || raterdefaults.all_other_deductibles__c,
          windHailDeductible:
            windHailDeductible || raterdefaults.wind_hail_deductible__c,
        }),
        false // do not validate on redux set values
      );
    }

    prefillPropertyData();
    // eslint-disable-next-line
  }, [session.uuid, fullAddress]);

  const handleSubmit = async (values) => {
    values = {
      ...values,
      valueJewelry: [Tenant, Vacant].includes(values.occupancy)
        ? null
        : values.valueJewelry,
    };

    if (contact.selectedPolicy === "Dwelling") {
      delete values.valueJewelry;
    }
    try {
      dispatch(updateIsFetching(true));
      dispatch(
        addProperty({
          ...property,
          ...values,
        })
      );
      await handleUpdateSession({
        uuid: session.uuid,
        property_data: JSON.stringify({
          ...property,
          ...values,
        }),
      });
      if (contact.propertyId && contact.homeQuoteId) {
        if (getDwellingUsageType()) {
          await updateProperty({
            heroku_id: contact.propertyId,
            dwelling_usage__c: getDwellingUsageType(),
            policy_form__c: mapOccupancyToPolicyForm[propertyData.occupancy],
          });
        }
        let updatedQuoteDetails = {
          quoteId: contact.homeQuoteId,
          propertyState: property.administrative_area_level_1,
          squareFoot: propertyData.squareFoot,
          dwelling_limit__c:
            propertyData.dwellingCoverage === ""
              ? 0
              : propertyData.dwellingCoverage,
          effective_date__c: propertyData.effectiveDate,
          expiration_date__c: moment(propertyData.effectiveDate)
            .add(1, "y")
            .format("YYYY-MM-DD"),
          jewelry__c: [Tenant, Vacant].includes(propertyData.occupancy)
            ? null
            : propertyData.valueJewelry,
          all_other_deductible__c: propertyData.aopDeductible,
          wind_hail_deductible__c: propertyData.windHailDeductible,
          ...(propertyData.occupancy
            ? {
                policy_form__c:
                  mapOccupancyToPolicyForm[propertyData.occupancy],
              }
            : {}),
        };
        if (!session.willpurchase)
          updatedQuoteDetails["prior_policy_expiration_date__c"] =
            propertyData.effectiveDate;

        if (isRenterPolicy) {
          updatedQuoteDetails["personal_property__c"] =
            propertyData.personalPropertyCoverage;
        }

        Object.keys(updatedQuoteDetails).forEach((k) => {
          if (updatedQuoteDetails[k] == null || updatedQuoteDetails[k] === "")
            delete updatedQuoteDetails[k];
        });

        if (Object.keys(updatedQuoteDetails).length > 0) {
          let updatedQuote = await updateQuote(updatedQuoteDetails);
          updatedQuote = updatedQuote.data[0];
          if (updatedQuote) {
            dispatch(
              addCoverages({
                dwelling: updatedQuote.dwelling_limit__c,
                personal_liability: updatedQuote.personal_liability__c,
                wind: updatedQuote.wind_hail_deductible__c,
                hurricane: updatedQuote.hurricane_deductible__c,
                aop: updatedQuote.all_other_deductible__c,
                personal_property: updatedQuote.personal_property__c,
                suddenWaterDamage: "Yes",
                waterBackup: updatedQuote.water_backup__c && "Included",
                replacementDwelling: updatedQuote.replacement_cost_dwelling__c,
                replacementContents: updatedQuote.replacement_cost_contents__c,
                homeEffectiveDate: updatedQuote.effective_date__c,
              })
            );
          }
          let updatedPropertyDetails = {
            propertyId: contact.propertyId,
            quoteId: contact.homeQuoteId,
            squareFoot: propertyData.squareFoot,
            yearBuilt: propertyData.yearBuilt,
            numStories: propertyData.numStories,
            exteriorWalls: propertyData.exteriorWalls,
            roofMaterial: propertyData.roofMaterial,
            roofReplaced: propertyData.roofReplaced,
            foundationType: propertyData.foundationType,
            electrical: propertyData.electrical,
            plumbing: propertyData.plumbing,
            heating: propertyData.heating,
            roof_deck_attachment__c: propertyData.roof_deck_attachment__c,
            roof_wall_connection__c: propertyData.roof_wall_connection__c,
            wind_speed__c: propertyData.wind_speed__c,
            opening_protection__c: propertyData.opening_protection__c,
            secondary_water_resistance__c:
              propertyData.secondary_water_resistance__c,
          };

          Object.keys(updatedPropertyDetails).forEach((k) => {
            if (
              updatedPropertyDetails[k] == null ||
              updatedPropertyDetails[k] === ""
            )
              delete updatedPropertyDetails[k];
          });

          if (Object.keys(updatedPropertyDetails).length) {
            await updatePropertyinPg(updatedPropertyDetails).catch((error) => {
              error.message = [
                "Failed to update property",
                error.message,
                error?.response?.data?.message,
              ]
                .filter(Boolean)
                .join(": ");

              if (httpUtils.isBadParams(error)) {
                const errors = errorUtils.tryCatch(
                  () => httpUtils.getBadParamsErrors(error),
                  () => ({})
                );

                if (errors && Object.values(errors).length > 0) {
                  formik.setErrors(errors);
                }
              }

              throw error;
            });
          }
        }
      }
      await handlePropertyPurchase();
      const featureFlagContext = createFeatureFlagContext();
      if (featureFlagContext.key) {
        await featureFlags.initialize(featureFlagContext);
      }
      handleNext();
    } catch (error) {
      dispatch(addProperty({ ...property, ...values }));
      console.error("Something went wrong", error);
      dispatch(updateIsFetching(false));
    }
  };

  const isPropertyThirtyYearsOldAccordingToPrefilledData =
    isPropertyThirtyYearsOldFn(yearBuilt);

  const initialValues = {
    squareFoot: squareFoot ?? "",
    exteriorWalls: exteriorWalls ?? "",
    yearBuilt: yearBuilt ?? "",
    numStories: numStories ?? "",
    roofMaterial: roofMaterial ?? "",
    roofReplaced: roofReplaced ?? "",
    electrical:
      electrical ||
      (isPropertyThirtyYearsOldAccordingToPrefilledData ? ephDefaultYear : ""),
    plumbing:
      plumbing ||
      (isPropertyThirtyYearsOldAccordingToPrefilledData ? ephDefaultYear : ""),
    heating:
      heating ||
      (isPropertyThirtyYearsOldAccordingToPrefilledData ? ephDefaultYear : ""),
    effectiveDate: isReshop
      ? effectiveDate
      : effectiveDate || getDefaultEffectiveDate(),
    valueJewelry: valueJewelry,
    occupancy: occupancy || Owner,
    personalPropertyCoverage: personalPropertyCoverage || 50000,
    newPurchase: newPurchase || session.willpurchase,
    foundationType: foundationType || "",
    dwellingCoverage: dwellingCoverage ?? "",
    aopDeductible: aopDeductible ?? "",
    windHailDeductible: windHailDeductible ?? "",
    ...(isAddressInFlorida && {
      roof_deck_attachment__c:
        property.roof_deck_attachment__c ??
        DEFAULT_FLORIDA_PROPERTY_PROPS.roof_deck_attachment__c,
      roof_wall_connection__c:
        property.roof_wall_connection__c ??
        DEFAULT_FLORIDA_PROPERTY_PROPS.roof_wall_connection__c,
      wind_speed__c:
        property.wind_speed__c ?? DEFAULT_FLORIDA_PROPERTY_PROPS.wind_speed__c,
      opening_protection__c:
        property.opening_protection__c ??
        DEFAULT_FLORIDA_PROPERTY_PROPS.opening_protection__c,
      secondary_water_resistance__c:
        property.secondary_water_resistance__c ??
        DEFAULT_FLORIDA_PROPERTY_PROPS.secondary_water_resistance__c,
    }),
  };

  const isHome = ["Home", "HomeAuto"].includes(session.line_of_business);
  const validationSchema = useMemo(
    () =>
      getPropertyValidationSchema({
        isHome,
        isRenterPolicy,
        isCondoPolicy,
        showFoundationfield,
      }),
    [isHome, isRenterPolicy, isCondoPolicy, showFoundationfield]
  );

  const {
    values: propertyData,
    handleChange,
    errors,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const handleFieldChange = (event) => {
    setFieldsEdited();
    handleChange(event);
  };

  const debounceDwellingCoverage = (value, debouceTime = 500) => {
    clearTimeout(debouncer);
    setDebouncer(
      setTimeout(async () => {
        try {
          if (state && zipcode) {
            const newDwellingCoverage = await getDwellingCoverage({
              squareFoot: value,
              state,
              zipcode,
            });
            formik.setFieldValue("dwellingCoverage", newDwellingCoverage);
          }
        } catch (error) {
          console.log("Something went wrong", error);
        }
      }, debouceTime)
    );
  };
  const isNewPurchase = propertyData.newPurchase === "Yes";
  const isPropertyThirtyYearsOld = isPropertyThirtyYearsOldFn(
    propertyData.yearBuilt
  );

  const shouldDisplayAddress = isDwellingQuote || isNewPurchase;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (shouldDisplayAddress && !validateAddress()) return;
    scrollToTop();
    formik.handleSubmit(e);
    handleAddressData();
  };

  return isFetching ? (
    <LoadingDots />
  ) : (
    <form onSubmit={handleFormSubmit}>
      {!isRenterPolicy && (
        <>
          <div className="mt-5">
            <Heading text={`${getHomeQuotesTitle()} Details`} bold />
          </div>
          <div className="justify-content-center d-flex">
            <FormContainer big>
              <Row>
                <Col
                  className="mb-4"
                  lg={session.line_of_business.indexOf("Condo") !== -1 ? 6 : 4}
                >
                  <CustomInput
                    className="form-control"
                    id="squareFoot"
                    type="number"
                    name="squareFoot"
                    placeholder="#"
                    value={propertyData.squareFoot}
                    handleChange={(event) => {
                      setFieldsEdited();
                      debounceDwellingCoverage(event.target.value);
                      handleChange(event);
                    }}
                    handleOnKeyDown={handleIntInputKeyDown}
                    label="Square Footage"
                    aria-label="Square Footage"
                    error={errors.squareFoot}
                    inputMode="numeric"
                  />
                </Col>
                <Col
                  className="mb-4 "
                  lg={session.line_of_business.indexOf("Condo") !== -1 ? 6 : 4}
                >
                  <CustomInput
                    className="form-control"
                    id="yearBuilt"
                    type="number"
                    name="yearBuilt"
                    placeholder="YYYY"
                    value={propertyData.yearBuilt}
                    handleChange={handleFieldChange}
                    handleOnKeyDown={handleIntInputKeyDown}
                    label="Year Built"
                    aria-label="Year Built"
                    error={errors.yearBuilt}
                    inputMode="numeric"
                  />
                </Col>
                <Col
                  className={`mb-4`}
                  lg={session.line_of_business.indexOf("Condo") !== -1 ? 6 : 4}
                >
                  <CustomSelect
                    className="form-control"
                    id="numStories"
                    type="text"
                    name="numStories"
                    value={propertyData.numStories}
                    handleChange={handleFieldChange}
                    label="Number of Stories"
                    aria-label="Number of Stories"
                    error={errors.numStories}
                  >
                    <option value="">-Select-</option>
                    <option value="1">1</option>
                    <option value="1.5">1.5</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </CustomSelect>
                </Col>
                <Col
                  className="mb-4 "
                  lg={session.line_of_business.indexOf("Condo") !== -1 ? 6 : 4}
                >
                  <CustomSelect
                    className="form-control"
                    id="exteriorWalls"
                    type="text"
                    name="exteriorWalls"
                    value={propertyData.exteriorWalls}
                    handleChange={handleFieldChange}
                    label="Exterior Walls"
                    aria-label="Exterior Walls"
                    error={errors.exteriorWalls}
                  >
                    <option value="">-Select-</option>
                    <option value="Brick Veneer">Brick or Stone</option>
                    <option value="Frame">Wood or Vinyl</option>
                    <option value="Hardiboard">Hardiboard</option>
                    <option value="Stucco">Stucco</option>
                  </CustomSelect>
                </Col>
                {session.line_of_business.indexOf("Condo") === -1 && (
                  <>
                    <Col className="mb-4 " lg={4}>
                      <CustomSelect
                        className="form-control"
                        id="roofMaterial"
                        type="text"
                        name="roofMaterial"
                        value={propertyData.roofMaterial}
                        handleChange={handleFieldChange}
                        label="Roof Materials"
                        aria-label="Roof Materials"
                        error={errors.roofMaterial}
                      >
                        <option value="">-Select-</option>
                        <option value="Shingles">Shingles</option>
                        <option value="Tile">Tile</option>
                        <option value="Metal">Metal</option>
                        <option value="Other">Other</option>
                      </CustomSelect>
                    </Col>
                    <Col className="mb-4" lg={4}>
                      <CustomInput
                        className="form-control"
                        id="roofReplaced"
                        type="number"
                        name="roofReplaced"
                        placeholder="YYYY"
                        value={propertyData.roofReplaced}
                        handleChange={handleFieldChange}
                        handleOnKeyDown={handleIntInputKeyDown}
                        label="Roof Constructed / Replaced"
                        aria-label="Roof Replaced Year"
                        error={errors.roofReplaced}
                        inputMode="numeric"
                        maxLength={4}
                      />
                    </Col>
                    {showFoundationfield && (
                      <Col className="mb-4" lg={4}>
                        <CustomSelect
                          className="form-control"
                          id="foundationType"
                          type="text"
                          name="foundationType"
                          value={propertyData.foundationType}
                          handleChange={handleFieldChange}
                          label="Foundation Type"
                          aria-label="Foundation Type"
                          error={errors.foundationType}
                        >
                          <option value="">-Select-</option>
                          <option value="Slab">Slab</option>
                          <option value="Pier & Beam">Pier & Beam</option>
                          <option value="Basement">Basement</option>
                          <option value="Crawlspace">Crawlspace</option>
                        </CustomSelect>
                      </Col>
                    )}
                  </>
                )}
                {isPropertyThirtyYearsOld && (
                  <>
                    <Col className="mb-4" lg={4}>
                      <CustomInput
                        className="form-control"
                        id="electrical"
                        type="number"
                        name="electrical"
                        placeholder="2019"
                        value={propertyData.electrical}
                        handleChange={handleFieldChange}
                        handleOnKeyDown={handleIntInputKeyDown}
                        label="Electrical"
                        aria-label="Electrical"
                        error={errors.electrical}
                        inputMode="numeric"
                        maxLength={4}
                      />
                    </Col>
                    <Col className="mb-4" lg={4}>
                      <CustomInput
                        className="form-control"
                        id="plumbing"
                        type="number"
                        name="plumbing"
                        placeholder="2019"
                        value={propertyData.plumbing}
                        handleChange={handleFieldChange}
                        handleOnKeyDown={handleIntInputKeyDown}
                        label="Plumbing"
                        aria-label="Plumbing"
                        error={errors.plumbing}
                        inputMode="numeric"
                        maxLength={4}
                      />
                    </Col>
                    <Col className="mb-4" lg={4}>
                      <CustomInput
                        className="form-control"
                        id="heating"
                        type="number"
                        name="heating"
                        placeholder="2019"
                        value={propertyData.heating}
                        handleChange={handleFieldChange}
                        handleOnKeyDown={handleIntInputKeyDown}
                        label="Heating"
                        aria-label="Heating"
                        error={errors.heating}
                        inputMode="numeric"
                        maxLength={4}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <div>
                {isAddressInFlorida && (
                  <FloridaPropertyForm
                    values={propertyData}
                    onChange={handleFieldChange}
                  />
                )}
              </div>
            </FormContainer>
          </div>
        </>
      )}
      <div className="mt-5">
        <Heading text={`${getHomeQuotesTitle()} Policy Coverages`} bold />
      </div>
      <div className="justify-content-center d-flex mt-4">
        <FormContainer big>
          <Row>
            {!isRenterPolicy && (
              <>
                <Col className="mb-4 mt-lg-4" lg={4}>
                  <CustomInput
                    className="form-control"
                    id="dwellingCoverage"
                    type="text"
                    name="dwellingCoverage"
                    placeholder="$400,000"
                    value={
                      propertyData.dwellingCoverage
                        ? `$${convertToCurrency(propertyData.dwellingCoverage)}`
                        : ""
                    }
                    handleOnKeyDown={handleIntInputKeyDown}
                    handleChange={(event) => {
                      setFieldsEdited();
                      const { value } = event.target;
                      formik.setFieldValue(
                        "dwellingCoverage",
                        convertCurrencyToNumber(value)
                      );
                    }}
                    label="Dwelling Coverage"
                    aria-label="Dwelling Coverage"
                    error={errors.dwellingCoverage}
                    inputMode="numeric"
                  />
                </Col>
                {stateSpecificAopOptions[state]?.length > 0 && (
                  <Col className="mb-4 mt-lg-4" lg={4}>
                    <CustomSelect
                      className="form-control"
                      id="aopDeductible"
                      type="text"
                      name="aopDeductible"
                      value={propertyData.aopDeductible}
                      handleChange={handleFieldChange}
                      label="All Peril Deductible"
                      aria-label="All Peril Deductible"
                      error={errors.aopDeductible}
                    >
                      <option value="">-Select-</option>
                      {stateSpecificAopOptions[state].map((option) => {
                        return (
                          <option value={option.value} key={option.label}>
                            {option.label}
                          </option>
                        );
                      })}
                    </CustomSelect>
                  </Col>
                )}
                {stateSpecificWindHailOptions[state]?.length > 0 && (
                  <Col className="mb-4 mt-lg-4" lg={4}>
                    <CustomSelect
                      className="form-control"
                      id="windHailDeductible"
                      type="text"
                      name="windHailDeductible"
                      value={propertyData?.windHailDeductible}
                      handleChange={handleFieldChange}
                      label="Wind/Hail Deductible"
                      aria-label="Wind Hail Deductible"
                      error={errors.windHailDeductible}
                    >
                      <option value="">-Select-</option>
                      {stateSpecificWindHailOptions[state].map((option) => {
                        return (
                          <option value={option.value} key={option.label}>
                            {option.label}
                          </option>
                        );
                      })}
                    </CustomSelect>
                  </Col>
                )}
              </>
            )}
            <Col className="mb-4 mt-lg-4" lg={4}>
              <DatePicker
                id="effectiveDate"
                name="effectiveDate"
                label="Effective Date"
                width="100%"
                value={propertyData.effectiveDate}
                onChange={handleFieldChange}
                error={errors.effectiveDate}
              />
            </Col>
            {!isRenterPolicy && !isReshop && (
              <Col className="mb-4 mt-lg-4" lg={4}>
                <CustomSelect
                  className="form-control"
                  id="newPurchase"
                  type="text"
                  name="newPurchase"
                  value={propertyData.newPurchase}
                  handleChange={handleFieldChange}
                  label={`New ${getHomeQuotesTitle()} Purchase`}
                  aria-label="NewPurchase"
                  error={errors.newPurchase}
                >
                  <option value="">-Select-</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </CustomSelect>
              </Col>
            )}
            {isDwellingQuote ? (
              <Col className="mb-4 mt-lg-4" lg={4}>
                <CustomSelect
                  className="form-control"
                  id="occupancy"
                  type="text"
                  name="occupancy"
                  value={propertyData.occupancy}
                  handleChange={handleFieldChange}
                  label="Occupancy"
                  aria-label="Occupancy"
                  error={errors.occupancy}
                >
                  <option value="tenant">Tenant</option>
                  <option value="vacant">Vacant</option>
                </CustomSelect>
              </Col>
            ) : isRenterPolicy ? (
              <Col className="mb-4 mt-lg-4" lg={4}>
                <CustomInput
                  className="form-control"
                  id="personalPropertyCoverage"
                  type="text"
                  name="personalPropertyCoverage"
                  placeholder="$30,000"
                  value={convertToDollars(
                    propertyData.personalPropertyCoverage,
                    false
                  )}
                  handleChange={(event) => {
                    setFieldsEdited();
                    const { value } = event.target;
                    formik.setFieldValue(
                      "personalPropertyCoverage",
                      convertCurrencyToNumber(value)
                    );
                  }}
                  label="Personal Property"
                  aria-label="Personal Property"
                  error={errors.personalPropertyCoverage}
                  inputMode="numeric"
                />
              </Col>
            ) : null}
            {!isDwellingQuote && (
              <Col className="mb-4 mt-lg-4" lg={4}>
                <CustomInput
                  className="form-control"
                  id="valueJewelry"
                  type="text"
                  name="valueJewelry"
                  placeholder="$0"
                  value={
                    propertyData.valueJewelry
                      ? `$${convertToCurrency(propertyData.valueJewelry)}`
                      : ""
                  }
                  handleChange={(event) => {
                    setFieldsEdited();
                    const { value } = event.target;
                    formik.setFieldValue(
                      "valueJewelry",
                      convertCurrencyToNumber(value)
                    );
                  }}
                  handleOnKeyDown={handleIntInputKeyDown}
                  label="Value of Jewelry"
                  aria-label="Value of Jewelry"
                  error={errors.valueJewelry}
                  inputMode="numeric"
                />
              </Col>
            )}
          </Row>
        </FormContainer>
      </div>
      {shouldDisplayAddress && (
        <div className="mt-5">
          <ClientAddress
            handleAddressData={handleAddressData}
            addressData={clientMailingData}
            setAddressData={setClientMailingData}
            addressError={addressError}
            isSelectedAddressInValid={isSelectedAddressInValid}
            setIsSelectedAddressInValid={setIsSelectedAddressInValid}
            addressType={
              isDwellingQuote
                ? CLIENT_ADDRESS_TYPE.MAILING
                : CLIENT_ADDRESS_TYPE.CURRENT
            }
            includeActions={false}
            address={getFullMailingAddr(clientMailingData)}
          />
        </div>
      )}
      <div className="pt-4 d-flex justify-content-center mb-5 pb-5">
        <CustomButton size="lg" type="submit" title="Continue" />
      </div>
    </form>
  );
};

export default PropertyInfo;
