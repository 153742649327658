import { UPDATE_IS_RECALCULATING } from "../../constants";
import produce from "immer";

const initialState = false;

export const recalculatingReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_IS_RECALCULATING:
      draft = action.payload;
      return draft;
    default:
      return draft;
  }
}, initialState);
