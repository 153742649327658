import "@fontsource-variable/rethink-sans";

import Paths from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AviatorErrorBoundary } from "./pages/Error";

function App() {
  return (
    <Router>
      <AviatorErrorBoundary>
        <Paths />
      </AviatorErrorBoundary>
    </Router>
  );
}

export default App;
