import { ADD_AGENT, ADD_AGENT_REVIEWS } from "../constants";

export const addAgent = (agent) => {
  return {
    type: ADD_AGENT,
    payload: agent,
  };
};

export const addAgentReviews = (reviews) => {
  return {
    type: ADD_AGENT_REVIEWS,
    payload: reviews,
  };
};
