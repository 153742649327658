// @ts-check
import { curry } from "lodash";
import * as ENV from "./env";

const LOG_LEVELS = /** @type {const} */ (["debug", "info", "warn", "error"]);
/** @typedef {typeof LOG_LEVELS[number]} LOG_LEVEL */

export const LOG_LEVEL = /** @type {LOG_LEVEL} */ (ENV.LOG_LEVEL);

/**
 * @param {LOG_LEVEL} desiredLevel
 * @param {LOG_LEVEL} [currentLevel]
 */
export const atLogLevel = (desiredLevel, currentLevel = LOG_LEVEL) =>
  LOG_LEVELS.indexOf(desiredLevel) >= LOG_LEVELS.indexOf(currentLevel);

/** @param {{ alwaysLog?: boolean | undefined } | undefined} [options] */
export function createMaybeLog(options) {
  const canLog = (/** @type {LOG_LEVEL} */ level) =>
    options?.alwaysLog || atLogLevel(level);

  const maybeLog = curry(
    /**
     * @template T
     * @param {LOG_LEVEL} level
     * @param {T} fn
     */
    (level, fn) => (canLog(level) ? fn : () => {}),
    2
  );
  return maybeLog;
}
