import {
  UPDATE_IS_FETCHING,
  UPDATE_IS_FETCHING_PRIOR_LIMITS,
} from "../constants";

import produce from "immer";

const initialState = false;

export const fetchingReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_IS_FETCHING:
      draft = action.payload;
      return draft;
    default:
      return draft;
  }
}, initialState);

export const fetchingPriorLimitsReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_IS_FETCHING_PRIOR_LIMITS:
      draft = action.payload;
      return draft;
    default:
      return draft;
  }
}, initialState);
