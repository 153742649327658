import "./index.css";
import "./utils/axiosInterceptor";

import App from "./App";
import { FeatureFlagProvider } from "./services/featureFlag";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { AppToaster as Toaster } from "./toast";
import { ThemeProvider } from "styled-components";
import axios from "axios";
import { baseURLS } from "./constants";
import env from "./env";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { theme } from "./assets/theme";
import { injectStyle } from "react-toastify/dist/inject-style";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// CALL IT ONCE IN YOUR APP (ref: https://fkhadra.github.io/react-toastify/how-to-style#inject-style-on-demand)
injectStyle();

const environment = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : env.APP_ENV;

axios.defaults.baseURL =
  process.env.REACT_APP_SERVER_ADDRESS || baseURLS[environment];
axios.defaults.headers["Content-Type"] = "application/json";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <FeatureFlagProvider>
              <App />
              <Toaster />
            </FeatureFlagProvider>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
