import { ReactComponent as Copilot } from "../../../assets/images/components/response-page/Copilot.svg";
import { Quote } from "../../../types";
import { QuoteAlert } from "./QuoteAlert";
import { isEligibleForCopilot } from "../../../utilsMisc";
import { useFeatureFlag } from "../../../services/featureFlag";

export const CopilotAlert = ({ quote }: { quote: Quote }) => {
  const featureFlags = useFeatureFlag();
  const copilotFlag = featureFlags.getFlag("Aviator-chrome-extension") ?? false;
  const showCopilotIcon = copilotFlag && isEligibleForCopilot(quote);

  if (!showCopilotIcon) return null;

  return (
    <QuoteAlert
      tooltipText="Aviator Copilot supported"
      alertIcon={<Copilot />}
      $backgroundColor="#006b67"
      $color="white"
    />
  );
};
