import { UPDATE_FINAL_QUOTES } from "../../constants";
import produce from "immer";

const initialState = {};

export const finalQuotesReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_FINAL_QUOTES:
      draft = { ...draft, ...action.payload };
      return draft;
    default:
      return draft;
  }
}, initialState);
