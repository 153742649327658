import {
  PACKAGE_DISCOUNT_TYPE,
  carrierBundlingConfigs,
  qtiTravelersAutoId,
  qtiTravelersHomeId,
} from "../constants";

import { isQuoteSuccessful } from "../utils";
import store from "../redux/store";

/**
 * Generates a map of carriers and their associated packages and unpackaged
 * rates
 *
 * @param {Array} quotes - An array of objects containing carrier, package and
 *   unpackaged rates
 * @returns {Object} A map of carriers and their associated packages and
 *   unpackaged rates
 */
export const generateCarrierMap = (quotes) => {
  return quotes.reduce((acc, quote) => {
    acc[quote.carrier__c] = acc[quote.carrier__c] || {};
    return quote.package_rate__c
      ? (acc = {
          ...acc,
          [quote.carrier__c]: { ...acc[quote.carrier__c], packaged: quote },
        })
      : (acc = {
          ...acc,
          [quote.carrier__c]: { ...acc[quote.carrier__c], unPackaged: quote },
        });
  }, {});
};

/**
 * Checks if a county is eligible for monoline
 *
 * @param {string} currentCounty The county to check
 * @param {string[]} inEligibleCounties Array of ineligible counties
 * @returns {boolean} Returns true if the county is eligible, false otherwise
 */
export const isMonolineEligibleInCounty = (
  currentCounty = "",
  inEligibleCounties
) => {
  if (!inEligibleCounties || !currentCounty) return true;
  const countySplit = currentCounty.split(" ");
  if (countySplit.length) {
    if (countySplit[countySplit.length - 1].toLowerCase() === "county")
      countySplit.pop();
    const county = countySplit.join(" ");
    if (inEligibleCounties.includes(county.toLowerCase())) {
      return false;
    }
  }
  return true;
};

/**
 * Validates a bundle of Home and Auto quotes
 *
 * @param {Object} homeQuote - Home quote object
 * @param {Object} autoQuote - Auto quote object
 * @returns {Boolean} - Whether the bundle is valid
 */
export const validateBundle = function (homeQuote, autoQuote) {
  const isHomeQuoteSuccessful = isQuoteSuccessful(homeQuote);
  const isAutoQuoteSuccessful = isQuoteSuccessful(autoQuote);
  const isBundleSameCarrier =
    homeQuote.company_client_id__c === autoQuote.company_client_id__c ||
    homeQuote.carrier__c === autoQuote.carrier__c;

  if (
    !isBundleSameCarrier &&
    (!isHomeQuoteSuccessful || !isAutoQuoteSuccessful)
  )
    return false;

  const property = store.getState().property;
  const {
    administrative_area_level_1: state,
    administrative_area_level_2: county,
  } = property;
  const stateConfigsForBundling = carrierBundlingConfigs[state];
  const isHomePackagedRate = homeQuote?.package_rate__c;
  const homeCarrierId = homeQuote?.company_client_id__c;
  const homeCarrierName = homeQuote?.carrier__c;
  const homeMonolineIneligibleCounties = stateConfigsForBundling?.home?.[
    homeCarrierId
  ]?.monolineIneligibleCounties?.map((county) => county?.toLowerCase());
  const isHomeMonolineAcceptable =
    stateConfigsForBundling?.home?.[homeCarrierId]?.isMonolineAcceptable &&
    isMonolineEligibleInCounty(county, homeMonolineIneligibleCounties);
  const homePackageDiscountType =
    stateConfigsForBundling?.home?.[homeCarrierId]?.packageDiscountType;

  const isAutoPackagedRate = autoQuote?.package_rate__c;
  const autoCarrierId = autoQuote.company_client_id__c;
  const autoCarrierName = autoQuote?.carrier__c;
  const autoMonolineIneligibleCounties = stateConfigsForBundling?.auto?.[
    autoCarrierId
  ]?.monolineIneligibleCounties?.map((county) => county?.toLowerCase());
  const isAutoMonolineAcceptable =
    stateConfigsForBundling?.auto?.[autoCarrierId]?.isMonolineAcceptable &&
    isMonolineEligibleInCounty(county, autoMonolineIneligibleCounties);
  const autoPackageDiscountType =
    stateConfigsForBundling?.auto?.[autoCarrierId]?.packageDiscountType;

  let isValidBundle = false;

  if (isHomeMonolineAcceptable && isAutoMonolineAcceptable) {
    if (
      isHomePackagedRate &&
      isAutoPackagedRate &&
      (autoPackageDiscountType === PACKAGE_DISCOUNT_TYPE.ANY_GH_CARRIER ||
        autoPackageDiscountType === PACKAGE_DISCOUNT_TYPE.NONE) &&
      (homePackageDiscountType === PACKAGE_DISCOUNT_TYPE.ANY_GH_CARRIER ||
        homePackageDiscountType === PACKAGE_DISCOUNT_TYPE.NONE)
    ) {
      isValidBundle = true;
    } else if (
      isHomePackagedRate &&
      !isAutoPackagedRate &&
      homeCarrierId !== autoCarrierId &&
      (homePackageDiscountType === PACKAGE_DISCOUNT_TYPE.ANY_GH_CARRIER ||
        homePackageDiscountType === PACKAGE_DISCOUNT_TYPE.NONE) &&
      (autoPackageDiscountType === PACKAGE_DISCOUNT_TYPE.SAME_CARRIER_ONLY ||
        autoPackageDiscountType === PACKAGE_DISCOUNT_TYPE.NONE)
    ) {
      isValidBundle = true;
    } else if (
      !isHomePackagedRate &&
      isAutoPackagedRate &&
      homeCarrierId !== autoCarrierId &&
      (autoPackageDiscountType === PACKAGE_DISCOUNT_TYPE.ANY_GH_CARRIER ||
        autoPackageDiscountType === PACKAGE_DISCOUNT_TYPE.NONE) &&
      (homePackageDiscountType === PACKAGE_DISCOUNT_TYPE.SAME_CARRIER_ONLY ||
        homePackageDiscountType === PACKAGE_DISCOUNT_TYPE.NONE)
    ) {
      isValidBundle = true;
    } else if (
      !isHomePackagedRate &&
      !isAutoPackagedRate &&
      (autoPackageDiscountType === PACKAGE_DISCOUNT_TYPE.SAME_CARRIER_ONLY ||
        autoPackageDiscountType === PACKAGE_DISCOUNT_TYPE.NONE) &&
      (homePackageDiscountType === PACKAGE_DISCOUNT_TYPE.SAME_CARRIER_ONLY ||
        homePackageDiscountType === PACKAGE_DISCOUNT_TYPE.NONE)
    ) {
      isValidBundle = true;
    }
  }
  if (
    isHomePackagedRate &&
    isAutoPackagedRate &&
    ((homeCarrierId != null &&
      autoCarrierId != null &&
      homeCarrierId === autoCarrierId) ||
      (homeCarrierName != null &&
        autoCarrierName != null &&
        homeCarrierName === autoCarrierName))
  ) {
    isValidBundle = true;
  }

  //Bundle unpackaged Travelers Auto quote with Home Quotes in the listed states and counties
  // Refer https://goosehead.atlassian.net/browse/HQ-1879 for additional details

  if (
    autoCarrierId === qtiTravelersAutoId &&
    ["FL", "MI", "TX"].includes(state) &&
    isMonolineEligibleInCounty(county, autoMonolineIneligibleCounties) &&
    (isValidBundle || homeCarrierId === qtiTravelersHomeId)
  ) {
    isValidBundle = !isAutoPackagedRate && isHomePackagedRate;
  }

  return isValidBundle;
};
