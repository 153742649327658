import LogRocket from "logrocket";

const logRocketId = process.env.REACT_APP_LOGROCKET_APPID;

const sanitizeData = (data) => {
  if (data.birthdate) data.birthdate = "XXXX-XX-XX";
  if (data.dob) data.dob = "XXXX-XX-XX";
  if (data.spouseDob) data.spouseDob = "XXXX-XX-XX";
  if (data.driverLicense) data.driverLicense = "XXXXXXXX";
  if (data.ssn) data.ssn = "XXXXXXXXX";
  if (data.social_security_number__c)
    data.social_security_number__c = "XXXXXXXXX";
};

const sanitizeAndStringifyData = (dataKey, parsedBody) => {
  const data =
    typeof parsedBody[dataKey] === "string"
      ? JSON.parse(parsedBody[dataKey])
      : parsedBody[dataKey];

  if (Array.isArray(data)) {
    data.forEach(sanitizeData);
  } else {
    sanitizeData(data);
  }

  return JSON.stringify(data);
};

const sanitizeRequestBody = (request) => {
  let parsedBody = request.body;

  if (
    typeof request.body === "string" &&
    request.headers["Content-Type"].includes("application/json")
  ) {
    parsedBody = JSON.parse(request.body);
  }

  if (parsedBody.contact_data) {
    parsedBody.contact_data = sanitizeAndStringifyData(
      "contact_data",
      parsedBody
    );
  }

  if (parsedBody.drivers_data) {
    parsedBody.drivers_data = sanitizeAndStringifyData(
      "drivers_data",
      parsedBody
    );
  }

  if (parsedBody.drivers && Array.isArray(parsedBody.drivers)) {
    parsedBody.drivers.forEach(sanitizeData);
  }

  sanitizeData(parsedBody);

  return typeof request.body === "string"
    ? JSON.stringify(parsedBody)
    : parsedBody;
};

const sanitizeResponseBody = (response) => {
  let responseBody = response.body;

  if (
    typeof responseBody === "string" &&
    response.headers["content-type"].includes("application/json")
  ) {
    try {
      responseBody = JSON.parse(responseBody);
    } catch (e) {
      return response;
    }
  }

  if (responseBody.sessionData && Array.isArray(responseBody.sessionData)) {
    responseBody.sessionData.forEach((session) => {
      if (session.contact_data) {
        session.contact_data = sanitizeAndStringifyData(
          "contact_data",
          session
        );
      }

      if (session.drivers_data) {
        session.drivers_data = sanitizeAndStringifyData(
          "drivers_data",
          session
        );
      }
    });
  }

  if (responseBody.drivers && Array.isArray(responseBody.drivers)) {
    responseBody.drivers.forEach(sanitizeData);
  }

  if (responseBody.contactData && Array.isArray(responseBody.contactData)) {
    responseBody.contactData.forEach(sanitizeData);
  }

  return JSON.stringify(responseBody);
};

export const initializeLogRocket = async (user) => {
  try {
    LogRocket.init(logRocketId, {
      rootHostname: "goosehead.com",
      network: {
        requestSanitizer: (request) => {
          try {
            if (!request.body) return request;
            request.body = sanitizeRequestBody(request);
            return request;
          } catch (e) {
            return request;
          }
        },
        responseSanitizer: (response) => {
          try {
            if (!response.body) return response;
            response.body = sanitizeResponseBody(response);
            return response;
          } catch (e) {
            return response;
          }
        },
      },
    });

    LogRocket.identify(user.userId, {
      name: user.name,
      email: user.email,
    });
  } catch (error) {
    console.log(error);
  }
};

export const LogRocketNewResponsesEvent = ({
  isNewResponsePage,
  sessionId,
}) => {
  try {
    if (isNewResponsePage) {
      LogRocket.track("Responses 2.0 flow", {
        uuid: sessionId,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const addSessionDataToLogRocket = (key, value) => {
  try {
    LogRocket.track(key, value);
  } catch (error) {
    console.log(error);
  }
};

export const addClientDataToLogRocket = (name, email) => {
  addSessionDataToLogRocket("Client Data", {
    name: name,
    email: email,
  });
};
