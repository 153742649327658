import { useEffect, useState } from "react";
import styled from "styled-components";
import closeIcon from "../assets/images/chatIcons/close.svg";
import Cookies from "js-cookie";
import { useFeatureFlag } from "../services/featureFlag";

/**
 * StatusBanner component displays a banner with a message and a close button.
 * The banner is shown based on a feature flag value. The banner message and
 * background color are set in the feature flag. The banner is hidden if the
 * user closes it or if the feature flag is turned off.
 *
 * @component
 */
const StatusBanner = () => {
  const [isHidden, setIsHidden] = useState(true);
  const [statusBannerValue, setStatusBannerValue] = useState(null);
  const featureFlags = useFeatureFlag();

  useEffect(() => {
    try {
      if (featureFlags.isInitialized) {
        const statusBannerValue = featureFlags.getFlag("status-banner-aviator");
        setStatusBannerValue(statusBannerValue);
        const bannerMessageFromCookie = Cookies.get("statusBannerMessage");
        if (
          bannerMessageFromCookie &&
          bannerMessageFromCookie !== statusBannerValue?.message
        ) {
          Cookies.remove("statusBannerHidden");
          Cookies.remove("statusBannerMessage");
        }
        const bannerHidden = Cookies.get("statusBannerHidden");
        if (bannerHidden === "true") {
          setIsHidden(true);
        } else {
          setIsHidden(false);

          Cookies.remove("statusBannerHidden");
          Cookies.remove("statusBannerMessage");
        }
      }
    } catch (error) {
      console.error("Error in StatusBanner useEffect:", error);
    }
  }, [featureFlags]);

  const handleClose = () => {
    setIsHidden(true);
    Cookies.set("statusBannerHidden", "true", { expires: 1 / 48 });
    Cookies.set("statusBannerMessage", statusBannerValue?.message);
  };

  return (
    <BannerContainer
      isHidden={!statusBannerValue?.message || isHidden}
      className="row px-4"
      bgColor={statusBannerValue?.bgColor}
      data-testid="banner-container"
    >
      <MarqueeText className="col-11 d-flex justify-content-center">
        {statusBannerValue?.message}
      </MarqueeText>
      <CloseButton
        className="col-1 d-flex justify-content-end"
        data-testid="close-banner"
      >
        <img
          src={closeIcon}
          alt="close"
          tabIndex={0}
          height={25}
          style={{ cursor: "pointer" }}
          onClick={handleClose}
        />
      </CloseButton>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  background-color: ${(props) => props.bgColor || "salmon"};
  padding: 5px;
  display: ${(props) => (props.isHidden ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 1px ${(props) => props.bgColor};
  font-family: ${(props) => props.theme.font_secondary};
  font-size: 15px;
`;

const MarqueeText = styled.span`
  display: block;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 16px;
  margin-left: auto;
  z-index: 2;
`;

export default StatusBanner;
