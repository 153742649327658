import "../../assets/css/LocationSearchInput.css";

import { Fragment, useEffect, useRef, useState } from "react";

import CustomInput from "../styled/inputs/CustomInput";
import { Spinner } from "react-bootstrap";
import { getReferralPartnerContact } from "../../services/account";
import searchIcon from "../../assets/images/components/searchIcon.svg";
import styled from "styled-components";

const AutoCompleteList = styled.ul`
  position: absolute;
  top: 80px;
  left: -15px;
  z-index: 999;
  width: 100%;
  /* padding: 30px; */
  padding-top: 3px;
  height: 250px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    min-height: 50px;
  }
  & > li {
    font-weight: 400;
    padding: 0.4rem 0.6rem;
    :hover {
      background-color: ${(props) => props.theme.tertiary_accent} !important;
      color: ${(props) => props.theme.white};
      cursor: pointer;
    }
    :focus {
      background-color: ${(props) => props.theme.tertiary_accent} !important;
      color: white;
    }
  }
`;

const SearchIcon = styled.img`
  position: relative;
  float: right;
  top: 46px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
`;

const ReferralPartnerAutoCompleteWrapper = styled.div`
  position: relative;
`;

const CustomWrapper = styled.div`
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 100px;
`;

const ReferralPartnerAutoComplete = ({
  placeholder,
  label,
  name,
  handleSelectOption,
  referralPartnerType,
  currentUser,
}) => {
  const autoCompleteInputRef = useRef(null);
  const [predictions, setPredictions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isLoadingPredictions, setIsLoadingPredictions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const MINIMUM_CHARACTERS_ERROR_MESSAGE =
    "Please enter at least 2 characters.";

  /**
   * @returns None
   *
   *   - This function gets and sets the predictions based on the input.
   */
  const handleSearch = async () => {
    try {
      if (searchValue?.length > 1) {
        setErrorMessage("");
        setIsLoadingPredictions(true);
        const response = await getReferralPartnerContact(
          searchValue,
          referralPartnerType,
          currentUser
        );
        let predictions = response.data;
        setPredictions(predictions);
        setIsLoadingPredictions(false);
      } else {
        setPredictions([]);
        setErrorMessage(MINIMUM_CHARACTERS_ERROR_MESSAGE);
      }
    } catch (error) {
      setIsLoadingPredictions(false);

      console.log("Something went wrong", error);
    }
  };

  const handleChange = async (e) => {
    const input = e.target.value;
    setSearchValue(input);
  };

  const handleSelect = (prediction) => {
    setSearchValue(prediction.name);
    setPredictions([]);
    handleSelectOption(prediction);
  };

  /**
   * @param {Object} event - Click event
   * @returns None
   *
   *   - This function clears all the predictions when clicked outside of the
   *       location input.
   */
  const handleClickOutsideLocationInput = (event) => {
    if (
      autoCompleteInputRef.current &&
      !autoCompleteInputRef.current.contains(event.target)
    )
      setPredictions([]);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideLocationInput);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideLocationInput
      );
    };
  }, [autoCompleteInputRef]);

  return (
    <Fragment>
      <SearchIcon src={searchIcon} alt="Search" onClick={handleSearch} />
      <ReferralPartnerAutoCompleteWrapper ref={autoCompleteInputRef}>
        <CustomInput
          className="form-control py-4"
          type="text"
          name={name}
          id={name}
          value={searchValue}
          placeholder={placeholder || ""}
          label={label}
          aria-label={label}
          handleChange={(e) => handleChange(e)}
          autoComplete="off"
          error={errorMessage}
        />
        {isLoadingPredictions && (
          <CustomWrapper className="text-center my-2">
            <Spinner animation="border" size="sm" />
          </CustomWrapper>
        )}
        {predictions && predictions.length > 0 && (
          <AutoCompleteList label={label}>
            {predictions.map((prediction) => {
              return (
                <li
                  className="list-group-item prediction-li text-center"
                  name={prediction.contactId}
                  onClick={() => handleSelect(prediction)}
                  key={prediction.contactId}
                >
                  {`${prediction.name}`}
                  <br />
                  {prediction.currentCompany || prediction.email}
                </li>
              );
            })}
          </AutoCompleteList>
        )}
      </ReferralPartnerAutoCompleteWrapper>
    </Fragment>
  );
};

export default ReferralPartnerAutoComplete;
