import "../../assets/css/SmartHomePackageSelectionModal.css";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CloseButton } from "react-bootstrap";
import CustomButton from "../styled/buttons/CustomButton";
import { CustomText } from "../styled/typography";
import Modal from "react-bootstrap/Modal";
import { addContact } from "../../redux/actions/contact";
import { createProspectInSf } from "../../services/account";
import { handleUpdateSession } from "../../services/utils";
import { partnerCampaign } from "../../constants";
import styled from "styled-components";

const ModalContent = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  & > .acknowledge-container {
    display: flex;
    align-items: start;
    gap: 1rem;
    background-color: #52697a;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;

    & > input[type="checkbox"] {
      zoom: 1.3;
      transform: translateY(0.2rem);
    }
  }
`;

const SmartHomeConsentModal = ({
  showSmartHomeConsentModal,
  setShowSmartHomeConsentModal,
}) => {
  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  const disabled = isSubmitting || !isAcknowledged;

  const handleChange = (e) => {
    setIsAcknowledged(e.target.checked);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const prospectResponse = (
        await createProspectInSf({
          accountId: contact.accountId,
          contactId: contact.referredContactId,
          propertyId: contact.propertyId,
          packageSelected: contact.packageSelected,
          partnerCampaign: partnerCampaign,
          ownerId: session.logged_in_user,
        })
      ).data;
      const { isSuccess, prospectId } = prospectResponse;
      if (isSuccess) {
        await handleUpdateSession({
          uuid: session.uuid,
          contact_data: JSON.stringify({
            ...contact,
            prospectId,
          }),
        });
        dispatch(addContact({ ...contact, prospectId }));
        setIsSubmitting(false);
        setShowSmartHomeConsentModal(false);
      }
    } catch (error) {
      console.log("Error creating prospect in sf", error);
      setIsSubmitting(false);
    }
  };
  return (
    <Modal
      show={showSmartHomeConsentModal}
      centered
      className="vivintSmartHomeModal"
    >
      <Modal.Header className="text-center">
        <CustomText
          color="white"
          fontSize="medium"
          fontWeight="bold"
          fontFamily="font_primary"
        >
          Refer To Vivint Smart Home
        </CustomText>
      </Modal.Header>
      <CloseButton
        variant="white"
        onClick={() => setShowSmartHomeConsentModal(false)}
        className="closeButton"
      />
      <Modal.Body>
        <ModalContent>
          <CustomText
            color="white"
            fontSize="medium"
            fontWeight={340}
            fontFamily="font-secondary"
            className="d-flex justify-content-center"
          >
            {`“With your permission I would love to connect you with our preferred home security partner, Vivint, to discuss their home security offerings. You do not need to consent to a call to make a purchase. Do I have your permission for Vivint to email and/or give you a call?”`}
          </CustomText>
          <div className="acknowledge-container">
            <input
              id="acknowledge"
              type="checkbox"
              value={isAcknowledged}
              onChange={handleChange}
            />
            <label htmlFor="acknowledge">
              By clicking Submit below, I acknowledge that I have read the above
              to the Client verbatim and the Client has agreed to have their
              personal information sent to Vivint.
            </label>
          </div>
          <CustomButton
            title="Submit"
            dark
            handleClick={handleSubmit}
            disabled={disabled}
          />
        </ModalContent>
      </Modal.Body>
    </Modal>
  );
};
export default SmartHomeConsentModal;
