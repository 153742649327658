import { ErrorMessage, Field, Formik } from "formik";
import { updateAccountInSf, updateAccountinPg } from "../services/account";
import { useDispatch, useSelector } from "../redux/hooks";
import { ReactComponent as NotesIcon } from "../assets/images/components/footer/Notes.svg";
import { Resizable } from "re-resizable";
import { addContact } from "../redux/actions/contact";
import { handleUpdateSession } from "../services/utils";
import { notesValidationSchema } from "../utils/validation";
import styled from "styled-components";
import { MinimizeButton } from "./ChatBot/styled";

type StyledPopupProps = {
  $display: string;
};
const StyledPopupForm = styled.form<StyledPopupProps>`
  display: ${({ $display }) => $display};
  flex-direction: column;
  border: solid 1px #dcdcdc;
  border-radius: 8px 8px 0 0;
  background-color: white;

  & header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    padding: 15px;
    color: white;
    background-color: #006b67;
    border-bottom: solid 1px #d8d8d8;
    border-radius: 8px 8px 0 0;
    font-family: "Rethink Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    & .left {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    & .btn-close {
      background-size: 12px 12px;
      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(53, 53, 53, 0.25);
      }
    }
  }

  & .notes-body {
    padding: 5px;
    padding-bottom: 0;
    flex-grow: 1;
    & textarea {
      padding: 10px 15px;
      height: 100%;
      border: solid 1px #dcdcdc;
      font-family: "Rethink Sans", sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #4c4c4c;
      resize: none;
      width: 100%;
      &:focus-visible {
        outline: none;
      }
    }
  }

  & .note-submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f7f7;
    padding: 5px 15px 0 15px;
    font-family: "Rethink Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #c65252;
    text-align: center;
    & button {
      background: none;
      height: 45px;
      color: inherit;
      border: none;
      padding: 0;
      outline: inherit;
      align-self: stretch;
      font-family: "Rethink Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #006b67;
      text-decoration: underline;
    }
  }
`;

type FormValues = {
  agentNotes: string;
};
type NotesProps = {
  isActive?: boolean;
  close: () => void;
};

const Notes = ({ isActive, close }: NotesProps) => {
  const contact = useSelector((store) => store.contact);
  const session = useSelector((store) => store.session) as any;
  const dispatch = useDispatch();

  const display = isActive ? "flex" : "none";

  const initialValues: FormValues = {
    agentNotes: contact.agentNotes ?? "",
  };

  const handleNotesUpdate = async ({ agentNotes }: FormValues) => {
    await handleUpdateSession({
      uuid: session.uuid,
      contact_data: JSON.stringify({ ...contact, agentNotes }),
    });
    dispatch(
      addContact({
        agentNotes,
      })
    );

    const accountId = contact.accountId
      ? (
          await updateAccountinPg(contact.accountId, {
            quote_discovery_notes__c: agentNotes,
          })
        ).data.accountData.id ?? contact.sfAccountId
      : contact.sfAccountId;

    if (accountId) {
      await updateAccountInSf(accountId, {
        quote_discovery_notes__c: agentNotes,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={notesValidationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        handleNotesUpdate(values);
      }}
    >
      {({ handleSubmit }) => (
        <StyledPopupForm $display={display} onSubmit={handleSubmit}>
          <Resizable
            defaultSize={{
              width: 360,
              height: 471,
            }}
            minWidth={200}
            minHeight={200}
            maxWidth="50vw"
            maxHeight="80vh"
            enable={{
              top: true,
              topLeft: true,
              left: true,
            }}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <header>
              <div className="left">
                <NotesIcon />
                Notes
              </div>
              <MinimizeButton
                width="28px"
                height="28px"
                onClick={close}
                aria-label="minimize notes"
              />
            </header>
            <section className="notes-body">
              <Field
                as="textarea"
                id="agentNotes"
                name="agentNotes"
                onBlur={() => handleSubmit()}
              />
            </section>
            <section className="note-submit">
              <ErrorMessage name="agentNotes" />
            </section>
          </Resizable>
        </StyledPopupForm>
      )}
    </Formik>
  );
};

export default Notes;
