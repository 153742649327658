import styled from "styled-components";

export const IconImage = styled.img`
  width: ${(props) => (props.size ? `${props.size}px` : "25px")};
  height: ${(props) => (props.size ? `${props.size}px` : "25px")};
`;

export const TitleImage = styled.img`
  height: 100px;
  width: 250px;
`;
