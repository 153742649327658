import styled from "styled-components";

const LinkLikeButton = styled.button`
  border: none;
  background: none;
  color: ${(props) => (props.color ? props.color : props.theme.primary)};
  font-size: ${(props) =>
    props.small ? "12px" : props.agent ? "1vw" : "13px"};
  text-align: center;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "normal")};
  text-decoration: underline;
`;

const LinkButton = (props) => {
  const { small, white, green, agent } = props;

  let color = null;

  if (white) color = "white";
  if (green) color = "#358640";

  return (
    <LinkLikeButton
      className={props.className}
      small={small}
      agent={agent}
      color={color}
      onClick={props.handleClick}
      {...props}
    >
      <span>{props.text ?? props.children}</span>
    </LinkLikeButton>
  );
};

export default LinkButton;
