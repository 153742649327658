const key = process.env.REACT_APP_GOOGLE_API_KEY;

export const googleServiceLoader = {
  google: {},
  loadScript: async function () {
    return new Promise((resolve, reject) => {
      try {
        if (window.google && window.google.maps.places) {
          this.google = window.google;
          return resolve(this);
        } else if (!document.getElementById("googleMapsGH")) {
          let script = document.createElement("script");
          script.id = "googleMapsGH";
          script.type = "text/javascript";
          script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initMap`;
          script.async = true;
          document.getElementsByTagName("head")[0].appendChild(script);
        }

        window.initMap = () => {
          this.google = window.google;
          resolve(this);
        };
      } catch (e) {
        reject(e);
      }
    });
  },
};
