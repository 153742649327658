import "./popover.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import infoIcon from "../assets/images/main/Info.svg";
import infoIconModal from "../assets/images/main/Info-neon-green.svg";

const CustomPopover = ({ color, carousel }) => {
  return (
    <span>
      <OverlayTrigger
        trigger="click"
        placement="auto"
        rootClose
        overlay={
          <Popover id="carousel-popover">
            <Popover.Title as="h3" className="text-center">
              Popover
            </Popover.Title>
            <Popover.Content>Content</Popover.Content>
          </Popover>
        }
      >
        <img
          className="ml-1"
          alt="info"
          src={color === "green" ? infoIcon : infoIconModal}
        />
      </OverlayTrigger>
    </span>
  );
};

export default CustomPopover;
