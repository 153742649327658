// @ts-check

/**
 * @param {any} [error]
 * @returns {error is AxiosError}
 */
export function isAxiosError(error) {
  return Boolean(error?.isAxiosError);
}

/** @param {Error} error */
export function getAxiosErrorDetails(error) {
  if (!error || !isAxiosError(error)) {
    return {};
  }

  return {
    request: {
      path: `${error.config.method} ${error.config.url}`,
      data: error.config.data,
    },
    response: {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
    },
  };
}

/** @param {any} [error] */
export function isBadParams(error) {
  if (!isAxiosError(error)) {
    return false;
  }

  return error?.response?.data?.error === "bad_params";
}

/**
 * @param {AxiosJoiError} error
 * @returns {Record<string, string>}
 */
export function getBadParamsErrors(error) {
  if (!isBadParams(error)) {
    throw new Error("Can't parse for errors because not the right type");
  }

  return (
    error?.response?.data?.errors?.reduce((acc, err) => {
      const field = err?.context?.key;
      const message =
        err?.message?.replace(`"${err?.context?.label}"`, "")?.trim() ??
        "Invalid Value";

      if (field) {
        acc[field] = message;
      }

      return acc;
    }, {}) ?? {}
  );
}

/**
 * @typedef {import("axios").AxiosError} AxiosError
 *
 * @typedef {AxiosError & {
 *   response?: import("axios").AxiosResponse & {
 *     data?: {
 *       error?: string;
 *       message?: string;
 *       errors?: {
 *         message?: string;
 *         path?: string[];
 *         type?: string;
 *         context?: {
 *           valids?: number[];
 *           label?: string;
 *           value?: string;
 *           key?: string;
 *         };
 *       }[];
 *     };
 *   };
 * }} AxiosJoiError
 */
