import {
  ClientReferral,
  ReferralPartner,
  propertyDefaults,
} from "../constants";
import { Col, Container, Row } from "react-bootstrap";
import { CustomText, StyledText } from "../components/styled/typography";
import { addProperty, clearProperty } from "../redux/actions/property";
import {
  clearPropertyOwnerDetails,
  handleOwner,
  processImprovements,
} from "../utils/propertyProcess";
import { getAccessToken, getAgentDetails } from "../services/agent";
import {
  getAccountDetailsinSf,
  getPoliciesByAccountId,
  getUsersInGroup,
} from "../services/account";
import {
  getPageName,
  getResponsesPage,
  includesIntegers,
  includesOnlyIntegers,
  isValidStreetNumber,
} from "../utils/strings";
import { getQueryParam, handleUpdateSession } from "../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CustomButton from "../components/styled/buttons/CustomButton";
import CustomSelect from "../components/styled/inputs/CustomSelect";
import Disclaimer from "../components/Disclaimer";
import Heading from "../components/Heading";
import LoadingDots from "../components/LoadingDots";
import LocationInput from "../components/inputs/LocationInput";
import LogRocket from "logrocket";
import { LogRocketNewResponsesEvent } from "../services/logRocket";
import { MainWrapper } from "../components/styled/wrappers";
import ManualAddress from "../components/ManualAddress";
import PastSessions from "../components/PastSessions";
import ReShop from "../components/Reshops/Reshop";
import ReferralPartnerAutoComplete from "../components/inputs/ReferralPartnerAutoComplete";
import { TitleImage } from "../components/styled/images";
import TitleLogo from "../components/styled/TitleLogo";
import { addContact } from "../redux/actions/contact";
import { addPropertyData } from "../services/property";
import { addressInfoPageDisclosure } from "../utils/constants";
import { createErrorLogger } from "../utils/errors";
import { createFeatureFlagContext } from "../services/utils";
import { formatSSN } from "../utils";
import { getQueryParams } from "../utils/queryParams";
import { googleServiceLoader } from "../services/address";
import { hasValues } from "../utils/object";
import { isEmpty } from "lodash";
import raterTitle from "../assets/images/main/rater-title.svg";
import store from "../redux/store";
import { updateIsFetching } from "../redux/actions/fetching";
import { useFeatureFlag } from "../services/featureFlag";
import { useIsNewResponsePageDesign } from "../utilsMisc";

const logError = createErrorLogger({ file: "AddressInfo" });

const AddressInfo = ({
  isAddressUpdate,
  closeUpdate,
  clearQuoteDetails,
  addressFromURL,
  isAddressURLValid,
}) => {
  const queryParams = getQueryParams(window.location.search);
  const navigate = useNavigate();
  const { state } = useLocation();

  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const isFetching = useSelector((store) => store.isFetching);
  const [googleService, setGoogleService] = useState({});
  const [showManualAddress, setShowManualAddress] = useState(false);
  const [addressData, setAddressData] = useState({});
  const [addressError, setAddressError] = useState(false);
  const [errors, setErrors] = useState({
    street_numberError: "",
    unit_numberError: "",
    localityError: "",
    administrative_area_level_1Error: "",
    postal_codeError: "",
  });
  const [leadSource, setLeadSource] = useState(
    contact.leadSource || ReferralPartner
  );
  const [referredBy, setReferredBy] = useState("");

  const [mortgageeBind, setMortgageeBind] = useState({});

  const [activeTab, setActiveTab] = useState("newQuote");
  const [policies, setPolicies] = useState({});

  const [pastSessions, setPastSessions] = useState([]);

  const [isFetchingPolicies, setIsFetchingPolicies] = useState(false);

  const featureFlags = useFeatureFlag();

  const dispatch = useDispatch();

  const shouldDisplayLeadSource = !contact.sfAccountId;

  const isExistingAccount = contact.sfAccountId;

  const { quotesSubmitted, fieldsEdited } = contact;

  const newResponsePageGroupId =
    process.env.REACT_APP_NEW_RESPONSE_PAGE_GROUP_ID;

  const shouldNavigate =
    session.line_of_business &&
    session.page_stopped > 0 &&
    !isAddressUpdate &&
    !isAddressURLValid &&
    !isFetching &&
    !state?.startOver &&
    !queryParams.rs;

  // TODO: Change into validation schema
  const validateAddress = (address) => {
    const street_numberError = isValidStreetNumber(address.street_number)
      ? ""
      : "Please enter a valid address";
    let localityError = address.locality ? "" : "Please enter your city";
    if (includesIntegers(address.locality)) {
      localityError = "Please enter a valid city";
    }
    const administrative_area_level_1Error = address.administrative_area_level_1
      ? ""
      : "Please select a state";
    let postal_codeError = address.postal_code
      ? ""
      : "Please enter your zip code";
    if (
      (address.postal_code || "").length > 0 &&
      ((address.postal_code || "").length !== 5 ||
        !includesOnlyIntegers(address.postal_code))
    ) {
      postal_codeError = "Please enter a valid zip code";
    }

    return {
      street_numberError,
      localityError,
      administrative_area_level_1Error,
      postal_codeError,
    };
  };

  const submitManualAddress = () => {
    const validationResults = validateAddress(addressData);
    if (hasValues(validationResults)) {
      // if all values in validation results are empty strings, then the form is valid
      setErrors(validationResults);
      return;
    }
    // close the Manual Address modal
    setShowManualAddress(false);
  };

  /**
   * @returns {boolean} - True if all the fields are valid
   *
   *   - This function validates all the fields in manual address form and sets
   *       errors if any.
   */
  const validateForm = () => {
    let errors = validateAddress(addressData);
    let leadSourceError = "";
    let referredByError = "";

    if (shouldDisplayLeadSource && !leadSource) {
      leadSourceError = "Please select the client's lead source type";
    }

    if (leadSource && !referredBy && !isAddressUpdate) {
      if (leadSource === ReferralPartner) {
        referredByError = "Please select referral partner";
      }
      if (leadSource === ClientReferral) {
        referredByError = "Please select client contact";
      }
    }

    errors.leadSourceError = leadSourceError;
    errors.referredByError = referredByError;

    setErrors(errors);
    return !hasValues(errors);
  };

  /**
   * @param {object} address - Address details
   * @param {| import("../constants").Owner
   *   | import("../constants").Tenant
   *   | import("../constants").Vacant} occupancy
   *   - Property Occupancy
   *
   * @returns None
   *
   *   - This function gets core logic property data and fills the redux with
   *       property info.
   */
  const handleProperty = async (address, occupancy) => {
    try {
      const response = await addPropertyData(address);
      const propertyData = response.data.corePropertyData;
      if (propertyData) {
        let improvementsData = processImprovements(propertyData);
        dispatch(
          addProperty({
            ...address,
            ...improvementsData,
            occupancy,
          })
        );
        if (propertyData.PROPERTY_OWNER && !isExistingAccount) {
          const ownerData = propertyData.PROPERTY_OWNER;
          handleOwner(ownerData);
        }
      } else {
        dispatch(
          addProperty({
            ...address,
            ...propertyDefaults,
            occupancy,
          })
        );
      }
    } catch (error) {
      dispatch(
        addProperty({
          ...address,
          ...propertyDefaults,
          occupancy,
        })
      );
      dispatch(updateIsFetching(false));
      logError({
        fn: "handleProperty",
        message: "Something went wrong",
        error,
      });
    }
  };

  /**
   * @param {object} address - Address details
   * @returns None
   *
   *   - This function shows manual address modal if address is not valid
   */
  const forwardToManualAddress = (address) => {
    const errors = validateAddress(address);
    setErrors(errors);
    if (hasValues(errors)) {
      setAddressError(false);
      setShowManualAddress(true);
    }
  };

  function getSessionURL() {
    return new Promise((resolve, reject) => {
      let timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        resolve(null);
      }, 500);
      LogRocket.getSessionURL((sessionURL) => {
        clearTimeout(timeoutId);
        resolve(sessionURL);
      });
    });
  }

  const handleNext = async () => {
    try {
      const logRocketURL = await getSessionURL();
      dispatch(updateIsFetching(true));
      const sessionData = await handleUpdateSession({
        uuid: session.uuid,
        line_of_business: "HomeAuto",
        page_stopped: 1,
        agent_sfid: session.agent_sfid,
        session_replay: logRocketURL,
      });
      navigate(
        `/agent/${sessionData.line_of_business}/${getPageName(
          sessionData.line_of_business,
          sessionData.page_stopped
        )}`
      );
      dispatch(updateIsFetching(false));
    } catch (error) {
      console.log("Something went wrong", error);
      dispatch(updateIsFetching(false));
    }
  };

  /**
   * @param {object} address - Address details
   * @returns None
   *
   *   - This function clears property and owner details and fills with the new
   *       details based on given address.
   */
  const handleAddressData = async (address) => {
    try {
      const addressErrors = validateAddress(address);
      setErrors(addressErrors);
      if (hasValues(addressErrors)) {
        setAddressError(true);
        setShowManualAddress(true);
        return;
      }

      if (!validateForm()) {
        return;
      }

      if (!isExistingAccount) {
        clearPropertyOwnerDetails();
      }

      const propertyOccupancy = store.getState().property.occupancy;
      dispatch(clearProperty());
      dispatch(updateIsFetching(true));
      dispatch(addContact(address));
      handleProperty(address, propertyOccupancy);
      if (!isAddressUpdate) {
        dispatch(
          addContact({
            ...store.getState().contact,
            referredBy: referredBy,
            mortgageeBinds: !isEmpty(mortgageeBind)
              ? [{ loan_number__c: null, ...mortgageeBind }]
              : [],
            leadSource: leadSource,
          })
        );
        handleNext();
      } else {
        if (contact.accountId) {
          await clearQuoteDetails();
        }
        closeUpdate();
      }
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const getUserInfo = async () => {
    try {
      let userInfo = null;
      let loggedInUserId = contact.user;
      // let userId = null;
      if ((getQueryParam("code") || getQueryParam("user")) && session.uuid) {
        if (
          !!getQueryParam("user") &&
          getQueryParam("user") === process.env.REACT_APP_DEFAULT_USER
        ) {
          loggedInUserId = process.env.REACT_APP_DEFAULT_USER;
        } else {
          userInfo = await getAccessToken(getQueryParam("code"));
          loggedInUserId = userInfo.data.id;
        }

        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
        const previousLoggedInUser = store.getState().contact.user;
        const loggedInUserDetailsResponse = await getAgentDetails(
          loggedInUserId
        );

        const newResponsePageGroupMembers = (
          await getUsersInGroup(newResponsePageGroupId)
        ).data;
        const loggedInUserDetails =
          loggedInUserDetailsResponse?.data?.agentData[0];
        const showNewResponsePage =
          newResponsePageGroupMembers?.includes(loggedInUserId);

        if (!previousLoggedInUser) {
          await handleUpdateSession({
            agent_sfid: session.agent_sfid || loggedInUserId,
            contact_data: JSON.stringify({
              ...store.getState().contact,
              user: loggedInUserId,
              agentProfileId: loggedInUserDetails.profileid,
              agentEmail: loggedInUserDetails.email,
              agentName: loggedInUserDetails.name,
              agentState: loggedInUserDetails.state,
              agentUserId: loggedInUserId,
              channel: loggedInUserDetails.channel__c,
              showNewResponsePage,
            }),
            logged_in_user: loggedInUserId,
          });
          dispatch(
            addContact({
              user: loggedInUserId,
              agentProfileId: loggedInUserDetails.profileid,
              agentEmail: loggedInUserDetails.email,
              agentName: loggedInUserDetails.name,
              agentState: loggedInUserDetails.state,
              agentUserId: loggedInUserId,
              channel: loggedInUserDetails.channel__c,
              showNewResponsePage,
            })
          );
        } else {
          await handleUpdateSession({
            contact_data: JSON.stringify({
              newLoggedInUser: loggedInUserId,
              newLoggedInUserName: loggedInUserDetails.name,
              newLoggedInUserProfileId: loggedInUserDetails.profileid,
              agentProfileId: loggedInUserDetails.profileid,
              agentEmail: loggedInUserDetails.email,
              agentName: loggedInUserDetails.name,
              agentState: loggedInUserDetails.state,
              agentUserId: loggedInUserId,
              channel: loggedInUserDetails.channel__c,
              showNewResponsePage,
            }),
          });

          dispatch(
            addContact({
              newLoggedInUser: loggedInUserId,
              newLoggedInUserName: loggedInUserDetails.name,
              newLoggedInUserProfileId: loggedInUserDetails.profileid,
              agentProfileId: loggedInUserDetails.profileid,
              agentEmail: loggedInUserDetails.email,
              agentName: loggedInUserDetails.name,
              agentState: loggedInUserDetails.state,
              agentUserId: loggedInUserId,
              channel: loggedInUserDetails.channel__c,
              showNewResponsePage,
            })
          );
        }
      }
      if ((!loggedInUserId || queryParams.rs) && session.uuid) {
        navigate(`/login?${queryParams.rs ? `rs=${queryParams.rs}` : ""}`);
      } else if (loggedInUserId) {
        const featureFlagContext = createFeatureFlagContext();
        if (featureFlagContext.key) {
          await featureFlags.initialize(featureFlagContext);
        }

        dispatch(updateIsFetching(false));
      }
    } catch (err) {
      console.log("Error while fetching the user details", err);
    }
  };

  const loadGoogleService = async () => {
    const { google } = await googleServiceLoader.loadScript();
    if (google && Object.keys(googleService).length === 0) {
      setGoogleService(google);
    }
  };

  const getPoliciesForReshop = async (accountId) => {
    try {
      const policyData = (await getPoliciesByAccountId(accountId)).data;
      setTimeout(async () => {
        const sfAccountDetails = await getAccountDetailsfromSF(
          contact.sfAccountId
        );

        const { primaryContact, spouseContact } = sfAccountDetails;

        // separating client's current mailing fields from the property fields
        // using property fields is causing it to be wrongly available for the first time this page is loaded
        // using a flag for first time page visits, makes it unecessary complicated and a bad coding practice
        const clientMailingData = {
          street_number: sfAccountDetails.billingStreet ?? "",
          locality: sfAccountDetails.billingCity ?? "",
          administrative_area_level_1: sfAccountDetails.billingState ?? "",
          administrative_area_level_2: "",
          postal_code: sfAccountDetails.billingPostalCode ?? "",
        };

        let updatedContact = {};

        if (primaryContact) {
          updatedContact = {
            ...updatedContact,
            clientMailingData,
            phone: sfAccountDetails.primary_contact_mobile__c,
            email: sfAccountDetails.primary_contact_email_address__c,
            firstName: primaryContact.FirstName,
            lastName: primaryContact.LastName,
            dob: primaryContact.Birthdate,
            gender: primaryContact.Gender__c,
            maritalStatus: primaryContact.Marital_Status__c,
            language: primaryContact.Language__c,
            ssn: primaryContact.Social_Security_Number__c
              ? formatSSN(primaryContact.Social_Security_Number__c)
              : "",
          };
        }

        if (spouseContact) {
          updatedContact = {
            ...updatedContact,
            spousePhone: spouseContact.MobilePhone,
            spouseEmail: spouseContact.Email,
            spousefirstName: spouseContact.FirstName,
            spouselastName: spouseContact.LastName,
            spouseDob: spouseContact.Birthdate,
            spouseGender: spouseContact.Gender__c,
            spouseLanguage: spouseContact.Language__c,
            spouseSsn: spouseContact.Social_Security_Number__c
              ? formatSSN(spouseContact.Social_Security_Number__c)
              : "",
          };
        }

        dispatch(addContact(updatedContact));
        setIsFetchingPolicies(false);
        if (isExistingAccount) {
          if (
            policyData?.homePolicies?.length ||
            policyData?.autoPolicies?.length
          ) {
            setActiveTab("reShop");
          } else {
            setActiveTab("newQuote");
          }
        }
      }, 500);
      setPolicies(policyData);
    } catch (error) {
      setIsFetchingPolicies(false);
      console.log("Error while fetching the policies", error);
    }
  };

  const getAccountDetailsfromSF = async (accountId) => {
    try {
      const sfAccountDetails = (await getAccountDetailsinSf(accountId)).data;
      return sfAccountDetails;
    } catch (error) {
      console.log("Error while fetching the account details from sf", error);
    }
  };

  const isNewResponsePage = useIsNewResponsePageDesign();

  useEffect(() => {
    // Capture the agent landing time on the address page
    // only first time the user lands on the address page
    if (!contact?.aviator_start_time__c) {
      dispatch(addContact({ aviator_start_time__c: new Date().toISOString() }));
    }
    loadGoogleService();
    LogRocketNewResponsesEvent({
      isNewResponsePage,
      sessionId: session.uuid,
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserInfo();
    dispatch(updateIsFetching(false));

    //eslint-disable-next-line
  }, [session.uuid]);

  useLayoutEffect(() => {
    if (isExistingAccount && !contact?.accountId) {
      setIsFetchingPolicies(true);
      getPoliciesForReshop(contact.sfAccountId);
      setLeadSource("");
    }
    //eslint-disable-next-line
  }, [contact.sfAccountId]);

  useEffect(() => {
    if (session.uuid && isAddressURLValid && !isFetching) {
      handleAddressData(addressFromURL);
    }
    // eslint-disable-next-line
  }, [isFetching, session.uuid]);

  useEffect(() => {
    if (shouldNavigate) {
      const page_stopped = session.page_stopped || 1;
      if (quotesSubmitted || fieldsEdited) {
        navigate(`/agent/${session.line_of_business}/${getResponsesPage()}`);
        return;
      }
      navigate(
        `/agent/${session.line_of_business}/${getPageName(
          session.line_of_business,
          page_stopped
        )}`
      );
    }
    // eslint-disable-next-line
  }, [session, navigate, shouldNavigate]);

  return (
    <MainWrapper dark={showManualAddress || isAddressUpdate}>
      {isFetching || isFetchingPolicies ? (
        <LoadingDots />
      ) : !isAddressUpdate ? (
        <>
          {!showManualAddress ? (
            <>
              <Container fluid>
                <TitleImage
                  src={raterTitle}
                  alt="The many factors of insurance."
                  className="ps-2"
                />
              </Container>
              <Container fluid className="mt-3">
                <Row className="ms-5 ps-4 me-2">
                  <Col>
                    {Object.keys(contact).length > 0 && (
                      <>
                        {!isFetchingPolicies && (
                          <div>
                            <StyledText
                              fontFamily="font_primary"
                              fontSize="large"
                              className="pe-3 border-end border-1 border-dark"
                              fontWeight={
                                activeTab === "newQuote" ? "600" : "100"
                              }
                              onClick={() => {
                                setActiveTab("newQuote");
                              }}
                              decoration={
                                activeTab === "newQuote" ? "underline" : ""
                              }
                              role="button"
                            >
                              New Quote
                            </StyledText>
                            <StyledText
                              fontFamily="font_primary"
                              fontSize="large"
                              className="ps-3"
                              fontWeight={
                                activeTab === "newQuote" ? "100" : "600"
                              }
                              onClick={() => {
                                setActiveTab(
                                  isExistingAccount ? "reShop" : "recentQuotes"
                                );
                              }}
                              decoration={
                                activeTab === "newQuote" ? "" : "underline"
                              }
                              role="button"
                            >
                              {isExistingAccount ? "Reshop" : "Recent Quotes"}
                            </StyledText>
                          </div>
                        )}
                        {isExistingAccount &&
                          activeTab !== "recentQuotes" &&
                          contact.firstName &&
                          contact.lastName && (
                            <div className="mt-4">
                              <CustomText
                                color="#4C8448"
                                fontSize="large"
                                fontWeight="500"
                              >
                                {`${contact.firstName} ${contact.lastName}`}
                              </CustomText>
                            </div>
                          )}
                      </>
                    )}
                    {activeTab === "newQuote" && (
                      <>
                        <div className="mt-3">
                          <StyledText
                            fontFamily="font_primary"
                            fontSize="medium"
                            fontWeight="600"
                          >
                            Property Address
                          </StyledText>
                        </div>

                        <div className="mt-3">
                          <LocationInput
                            googleService={googleService}
                            forwardToManualAddress={forwardToManualAddress}
                            label="What is the address you want to quote?"
                            placeholder="123 Main St., Dallas, TX 75201"
                            setAddressData={setAddressData}
                            setShowManualAddress={setShowManualAddress}
                            initialSearchValue={formatAddress(addressData)}
                          />
                        </div>
                        {shouldDisplayLeadSource && (
                          <>
                            <div className="mt-4">
                              <StyledText
                                fontFamily="font_primary"
                                fontSize="medium"
                                fontWeight="600"
                              >
                                Lead Source Type
                              </StyledText>
                            </div>
                            <div className="mt-2">
                              <Row className="mt-2">
                                <Col xs={12} md={6} className="pb-4">
                                  <CustomSelect
                                    className="form-control"
                                    id="leadSource"
                                    type="text"
                                    name="leadSource"
                                    value={leadSource || ""}
                                    handleChange={(e) =>
                                      setLeadSource(e.target.value)
                                    }
                                    error={errors.leadSourceError || ""}
                                    label="Select the client’s lead source type."
                                    aria-label="Lead Source Type"
                                  >
                                    <option value="Referral Partner">
                                      Referral Partner
                                    </option>
                                    <option value="Client Referral">
                                      Client Referral
                                    </option>
                                    <option value="Other">Other</option>
                                  </CustomSelect>
                                </Col>
                                {[ReferralPartner, ClientReferral].includes(
                                  leadSource
                                ) && (
                                  <Col xs={12} md={6}>
                                    <ReferralPartnerAutoComplete
                                      placeholder="John Smith"
                                      label="Referred By"
                                      name="referredBy"
                                      handleSelectOption={(value = {}) => {
                                        setReferredBy(value.contactId);
                                        setMortgageeBind(value.mortgageeBind);
                                      }}
                                      referralPartnerType={leadSource}
                                      currentUser={contact.user}
                                    />
                                    {errors.referredByError && (
                                      <small className="fw-light font-italic text-danger">
                                        {errors.referredByError}
                                      </small>
                                    )}
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </>
                        )}
                        <div className="mt-4">
                          <CustomButton
                            title="Let’s Do This"
                            handleClick={() => handleAddressData(addressData)}
                          />
                        </div>
                        <div className="mt-4">
                          <Disclaimer
                            className="mt-5"
                            text={addressInfoPageDisclosure}
                            centered={false}
                          />
                        </div>
                      </>
                    )}

                    {activeTab === "recentQuotes" && (
                      <PastSessions
                        pastSessions={pastSessions}
                        setPastSessions={setPastSessions}
                        // page={page}
                        // limit={limit}
                        // setLimit={setLimit}
                      />
                    )}
                    {activeTab === "reShop" && (
                      <ReShop
                        policies={policies}
                        handleNext={handleNext}
                        isFetchingPolicies={isFetchingPolicies}
                      />
                    )}
                  </Col>

                  {activeTab !== "reShop" && (
                    <Col className="d-flex justify-content-center">
                      <TitleLogo />
                    </Col>
                  )}
                </Row>
              </Container>
            </>
          ) : (
            <ManualAddress
              setAddressData={setAddressData}
              addressData={addressData}
              errors={errors}
              handleAddressData={submitManualAddress}
              setShowManualAddress={setShowManualAddress}
              addressError={addressError}
            />
          )}
        </>
      ) : (
        <>
          {!showManualAddress ? (
            <>
              <Heading
                className="pt-5"
                dark
                text="What is your home address?"
                bold
              />
              <Row className="my-4 justify-content-center w-100">
                <Col xs={11} sm={9} md={7} lg={5} xl={4}>
                  <LocationInput
                    googleService={googleService}
                    setAddressData={setAddressData}
                    placeholder="123 Main St., Dallas, TX 75201"
                    forwardToManualAddress={forwardToManualAddress}
                    label="What is the address you want to quote?"
                    setShowManualAddress={setShowManualAddress}
                    dark
                    initialSearchValue={formatAddress(addressData)}
                  />
                </Col>
              </Row>
              <Row className="my-4 d-flex flex-column gap-4 justify-content-center w-100">
                <CustomButton
                  className="m-auto"
                  dark
                  title="Continue"
                  handleClick={() => handleAddressData(addressData)}
                />
                <CustomButton
                  className="m-auto"
                  dark
                  title="Cancel"
                  cancel
                  handleClick={closeUpdate}
                />
              </Row>
            </>
          ) : (
            <Row className="my-4 justify-content-center w-100">
              <ManualAddress
                setAddressData={setAddressData}
                addressData={addressData}
                errors={errors}
                handleAddressData={() => setShowManualAddress(false)}
                addressUpdate={isAddressUpdate}
                closeUpdate={closeUpdate}
                setShowManualAddress={setShowManualAddress}
                addressError={addressError}
              />
            </Row>
          )}
        </>
      )}
    </MainWrapper>
  );
};

function AddressInfoWrapper({ isAddressUpdate, ...props }) {
  const queryParams = getQueryParams(window.location.search);

  const {
    street_number,
    administrative_area_level_1,
    locality,
    sublocality_level_1,
    country,
    route,
    street_address,
    postal_code,
  } = queryParams;

  const addressFromURL =
    street_number !== undefined &&
    locality !== undefined &&
    postal_code !== undefined &&
    administrative_area_level_1 !== undefined
      ? {
          street_number: `${street_number} ${route}`,
          administrative_area_level_1,
          locality,
          sublocality_level_1,
          country,
          route,
          street_address,
          postal_code,
        }
      : {};

  const isAddressURLValid = Object.keys(addressFromURL).length > 0;

  /* Ref: https://goosehead.atlassian.net/browse/HQ-1091
  This is a hack in order to avoid a more extensive refactor.
  In order to prevent the address page from flashing on the
  screen when resuming a session, we should assume a loading
  state on the initial render. If the component shouldNavigate, it
  will happen before the timeout occurs. When arriving here for
  the first time, the 300 millisecond delay should be fairly
  imperceptable to the user. */
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 300);
  }, []);

  return loading ? (
    <LoadingDots />
  ) : (
    <AddressInfo
      isAddressUpdate={isAddressUpdate}
      isAddressURLValid={isAddressURLValid}
      addressFromURL={addressFromURL}
      {...props}
    />
  );
}

export default AddressInfoWrapper;

function formatAddress({
  street_number: street,
  locality: city,
  administrative_area_level_1: state,
  postal_code: zipcode,
  country,
} = {}) {
  let result = [street, city, state].filter(Boolean).join(", ");

  if (zipcode) {
    result += ` ${zipcode}`;
  }

  if (country) {
    result += `, ${country}`;
  }

  return result;
}
