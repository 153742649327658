import { ADD_VEHICLES } from "../constants";
import { Vehicle } from "./types";
import produce from "immer";

const initialState: Vehicle[] = [];

export const vehiclesReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_VEHICLES:
      draft = action.payload;
      return draft;
    default:
      return draft;
  }
}, initialState);
