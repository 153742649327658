const queryString = require("query-string");

export const getQueryParams = (paramString = window.location.search) =>
  queryString.parse(paramString);

/**
 * Stringify an object into a query string
 *
 * @param queryParams {object}
 * @returns {string}
 */
export const getQueryParamString = (queryParams) => {
  queryParams = Object.keys(queryParams).reduce((acc, key) => {
    if (queryParams[key]) {
      acc[key] = queryParams[key];
    }
    return acc;
  }, {});
  return queryString.stringify(queryParams ?? {});
};
