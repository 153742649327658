import axios from "axios";
import store from "../redux/store";
import LogRocket from "logrocket";

let logRocketURL;
LogRocket.getSessionURL((sessionURL) => {
  logRocketURL = sessionURL;
});

axios.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    const storeState = store.getState();
    if (config.method !== "get") {
      config.headers["csrf-token"] = storeState.token;
      config.headers["content-type"] = "application/json;charset=UTF-8";
      if (logRocketURL) {
        config.headers["X-LogRocket-URL"] = logRocketURL;
      }
    }
    if (storeState.session.uuid) {
      config.headers["X-Session-Id"] = storeState.session.uuid;
    }
    config.headers["X-App-Name"] = "Aviator";
    return config;
  },
  (e) => {
    return Promise.reject(e);
  }
);
