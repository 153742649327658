import axios from "axios";

export const getSession = (uuid) =>
  axios
    .get("/api/session", {
      params: {
        uuid,
      },
    })
    .then(tap(maybeUpdateLocalSessionId));

export const getSessionsByAgentId = (agentId, limit = 5) =>
  axios.get("/api/sessions", { params: { agent_sfid: agentId, limit } });

export const getSessionsBySfAccountId = (sfAccountId) =>
  axios.get(`/api/session/${sfAccountId}`);

export const createSession = (payload) =>
  axios.post("/api/session", payload).then(tap(maybeUpdateLocalSessionId));

export const updateSession = (payload) => axios.put("/api/session", payload);

export const deleteSession = (id) =>
  axios.delete(`/api/session/${id}`).then(tap(clearLocalSessionId));

function tap(interceptor) {
  return function (value) {
    interceptor(value);

    return value;
  };
}

function maybeUpdateLocalSessionId(val) {
  const sessionId = val?.data?.sessionData?.[0]?.uuid;
  if (sessionId) {
    saveLocalSessionId(sessionId);
  }
}

export function saveLocalSessionId(sessionId) {
  try {
    window.sessionStorage.setItem("sessionId", sessionId);
  } catch (error) {
    console.error({
      fn: "saveLocalSessionId",
      error,
    });
  }
}

export function clearLocalSessionId() {
  saveLocalSessionId("");
}

export function getLocalSessionId() {
  return window.sessionStorage.getItem("sessionId");
}
