import { useFeatureFlag } from "../../services/featureFlag";
import { v2CarrierIds, featureFlagsConst } from "../../constants";

/**
 * @param {{
 *   companyClientId?: string;
 *   lob?: string;
 * }} [input]
 */
export const useIsV3_1QtiCarrierOverride = ({ companyClientId, lob } = {}) => {
  const featureFlags = useFeatureFlag();

  if (!companyClientId || !lob || !v2CarrierIds.includes(companyClientId)) {
    return false;
  }

  const carriersWithV3Override = featureFlags.getFlag(featureFlagsConst.useV31);

  const isV3_1Carrier =
    carriersWithV3Override &&
    Array.isArray(carriersWithV3Override.carriers) &&
    carriersWithV3Override.carriers.includes(companyClientId);
  return isV3_1Carrier;
};
