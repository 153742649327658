import { CarrierLogoImg } from "../styled/QuoteResponse";
import CustomRadio from "../styled/inputs/CustomRadio";
import { convertToDollars } from "../../utils/strings";
import logos from "../../utils/carrierLogos";
import moment from "moment";
import styled from "styled-components";

const StatusIndicator = styled.span`
  width: 15px;
  height: 15px;
  display: inline-block;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
  margin: 0px 3px;
`;

const BorderedRow = styled.tr`
  border-bottom: ${(props) => (props.showBorder ? "1px solid #A9EC7E" : "")};
`;

export const statusIndicatorColor = {
  "Quality Control Review": "#66A13E",
  "Current Client": "#66A13E",
  Cancelled: "#DC3545",
  "Pending Renewal": "#FFE72F",
  "Pending Cancellation": "#FFE72F",
  Default: "#FFE72F",
};

const Policy = ({
  policy,
  index,
  selectedPolicyIndex,
  setSelectedHomePolicyIndex,
  setSelectedAutoPolicyIndex,
  showBorder,
}) => {
  const {
    Policy_Type__c,
    Insurance_Carrier__c,
    Carrier__r,
    Property_Address__c,
    Property_City__c,
    Property_State__c,
    Current_Term_Premium__c,
    Expiration_Date__c,
    StageName,
  } = policy;

  const carrierId = Carrier__r?.Client_ID__c?.split(";")[0];
  const carrierName = Insurance_Carrier__c;

  return (
    <BorderedRow showBorder={showBorder}>
      <td>
        <CustomRadio
          checked={index === selectedPolicyIndex}
          handleClick={() => {
            if (Policy_Type__c !== "Auto") {
              if (index === selectedPolicyIndex) {
                setSelectedHomePolicyIndex(null);
              } else {
                setSelectedHomePolicyIndex(index);
              }
            } else {
              if (index === selectedPolicyIndex) {
                setSelectedAutoPolicyIndex(null);
              } else {
                setSelectedAutoPolicyIndex(index);
              }
            }
          }}
        ></CustomRadio>
      </td>
      <td>{Policy_Type__c}</td>
      <td>
        <CarrierLogoImg
          src={logos[carrierId]}
          alt={carrierName}
          aria-label={carrierName}
        />
      </td>
      <td>{`${Property_Address__c}, ${Property_City__c}, ${Property_State__c}`}</td>
      <td>{convertToDollars(Current_Term_Premium__c)}</td>
      <td>{moment(Expiration_Date__c).format("M/D/YYYY")}</td>
      <td>
        <StatusIndicator
          color={
            statusIndicatorColor[StageName] ?? statusIndicatorColor["Default"]
          }
        />
        {StageName}
      </td>
    </BorderedRow>
  );
};

export default Policy;
