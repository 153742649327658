import {
  AddLob,
  MonolineHeader,
  MonolineQuoteCard,
  QuoteCardList,
  QuoteListContainer,
} from "./Quote";
import { MonolineLob, Quote, QuoteType } from "../../types";
import { auto, home } from "../../constants";

import { useSelector } from "../../redux/hooks";

type MonolineQuoteListContainerProps = {
  lob: MonolineLob;
  quotesByType: Record<QuoteType, Quote[]>;
};

export const MonolineQuoteListContainer = ({
  lob,
  quotesByType,
}: MonolineQuoteListContainerProps) => {
  const contact = useSelector((store) => store.contact);
  const numItems = Object.entries(quotesByType)
    .filter(([key, _]) => key !== "Quote Manually")
    .reduce((acc, [_, quotes]) => acc + quotes.length, 0);

  if (numItems === 0 && ![home, auto].includes(lob)) return null;

  const showAddHome = !contact.homeQuoteId && lob === home;
  const showAddAuto = !contact.autoQuoteId && lob === auto;

  return (
    <QuoteListContainer
      header={<MonolineHeader lob={lob} numItems={numItems} />}
    >
      {showAddAuto || showAddHome ? (
        <AddLob lob={lob} />
      ) : (
        <div className="quote-list-content">
          <QuoteCardList>
            {quotesByType["Monoline"].map((quote) => (
              <MonolineQuoteCard
                key={`${quote.company_client_id__c} - ${quote.heroku_id}`}
                quote={quote}
                lob={lob}
              />
            ))}
          </QuoteCardList>
          <QuoteCardList quoteType="Quote Manually">
            {quotesByType["Quote Manually"].map((quote) => (
              <MonolineQuoteCard
                key={`${quote.company_client_id__c} - ${quote.heroku_id}`}
                quote={quote}
                lob={lob}
              />
            ))}
          </QuoteCardList>
        </div>
      )}
    </QuoteListContainer>
  );
};
