import isEmpty from "lodash/isEmpty";
import type { AnyAction, Dispatch, Middleware } from "redux";
import { createStore as _createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import env from "../env";
import { rootReducer } from "./reducers";

const logger = createLogger() as Middleware<{}, any, Dispatch<AnyAction>>;

const environment = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : env.APP_ENV;

const composeEnhancers = [
  "development",
  "test",
  "dev-test",
  "uat",
  "review",
].includes(environment)
  ? composeWithDevTools({ trace: true, traceLimit: 25 })
  : compose;

let middlewares: Middleware<{}, any, Dispatch<AnyAction>>[] = [];
if (
  ["development", "test", "dev-test", "uat", "review"].includes(environment) &&
  process.env.REACT_APP_TURN_OFF_REDUX_LOGGER !== "true"
) {
  middlewares = [...middlewares, logger];
}

export function createStore(initialState?: unknown) {
  if (!initialState || isEmpty(initialState)) {
    return _createStore(
      rootReducer,
      composeEnhancers(applyMiddleware(thunk, ...middlewares))
    );
  }

  return _createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, ...middlewares))
  );
}

const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

export default store;
