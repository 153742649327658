import axios from "axios";

export const getVehicleByVin = (Vin) => axios.get(`/api/vehiclebyvin/${Vin}`);

export const getVehiclesByQuoteIdinPG = (quoteId) =>
  axios.get(`/api/qti/vehicles/${quoteId}`);

export const deleteVehicleById = (vehicleId) =>
  axios.delete(`/api/vehicles/${vehicleId}`);

export const getVehicleByType = (payload) =>
  axios.post("/api/vehiclebytype", payload);

export const saveVehiclesInPg = (payload) =>
  axios.post("/api/vehicles", payload);

export const saveVehicleUsage = (payload) =>
  axios.post("/api/vehicleusage", payload);

export const updateVehicleInPg = (vehicleId, payload) =>
  axios.put(`api/qti/vehicle/${vehicleId}`, payload);
