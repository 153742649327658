// @ts-check
import { createErrorLogger } from "../errors";
import { createMaybeLog } from "./logLevel";

/**
 * @typedef {Record<any, any>} LogObject
 * @param {string} fnName
 * @param {{ alwaysLog?: boolean }} [options]
 * @returns {{
 *   (obj: LogObject): void;
 *   debug: (obj: LogObject) => void;
 *   warn: (obj: LogObject) => void;
 *   error: (obj: LogObject) => void;
 *   label: string;
 *   fnName: string;
 *   createLogger: typeof createLogger;
 * }}
 */
export const createLogger = (fnName, options) => {
  const maybeLog = createMaybeLog(options);

  const returnFn = maybeLog("info")(
    /** @param {LogObject} obj */ (obj) => console.log({ fn: fnName, ...obj })
  );

  // todo: maybeLog("debug") is broken? It always logs even though the level is at "info"
  returnFn.debug = maybeLog("debug")(
    /** @param {LogObject} obj */ (obj) => console.debug({ fn: fnName, ...obj })
  );

  returnFn.debug = maybeLog("info")(
    /** @param {LogObject} obj */ (obj) => console.info({ fn: fnName, ...obj })
  );

  returnFn.warn = maybeLog("warn")(
    /** @param {LogObject} obj */ (obj) => console.warn({ fn: fnName, ...obj })
  );

  returnFn.error = maybeLog("error")(
    /** @param {LogObject} obj */ (obj) =>
      createErrorLogger({ fn: fnName, ...obj })
  );

  returnFn.label = fnName;
  returnFn.fnName = fnName;

  /** @type {typeof createLogger} */
  returnFn.createLogger = (subFnName, subOptions) =>
    createLogger(`${fnName}.${subFnName}`, {
      ...options,
      ...subOptions,
    });

  return returnFn;
};
