import { addContact } from "../redux/actions/contact";
import { isDwelling } from "../utils";
import store from "../redux/store";
import { switchPrimary } from "../services/quote";

const CARRIERS_SWITCH_PRIMARY_INSURED = [
  { carrierId: "13", isAvailableAllStates: true }, // Travelers
  {
    carrierId: "507",
    isAvailableAllStates: false,
    lobs: {
      Home: ["NJ", "NY", "MS", "SC"],
      Dwelling: ["NJ", "NY"],
    },
  }, // SageSure
];

/**
 * @param {Object} param
 * @param {string} param.company_client_id__c
 * @param {string} param.line_of_business__c
 * @returns {boolean}
 */
export const isCarrierAvailableForSwitchPrimary = ({
  company_client_id__c,
  line_of_business__c,
}) => {
  const lob =
    line_of_business__c === "Auto"
      ? "Auto"
      : isDwelling()
      ? "Dwelling"
      : line_of_business__c;
  const state = store.getState().property.administrative_area_level_1;

  const config = CARRIERS_SWITCH_PRIMARY_INSURED.find(
    (carrier) => carrier.carrierId === company_client_id__c
  );

  if (!config) return false;

  if (config.isAvailableAllStates) return true;

  return config.lobs[lob]?.includes(state);
};

export const ERROR_TYPES_WITHOUT_SWITCH_PRIMARY = [
  "Login Error",
  "Coverage Ineligible",
  "Location Ineligible",
  "Monoline Ineligible",
  "Property Ineligible",
  "Vehicle Ineligible",
];

/**
 * @param {string} carrierId
 * @returns {void}
 */
export const addSwitchPrimaryDoneCarrierIdInRedux = (carrierId) => {
  const { contact } = store.getState();
  if (!contact.spouseId) return;

  // Redux doesn't support Set
  const switchPrimaryDoneCarrierIds = new Set(
    contact.switchPrimaryDoneCarrierIds
  );
  switchPrimaryDoneCarrierIds.add(carrierId);
  store.dispatch(
    addContact({
      switchPrimaryDoneCarrierIds: [...switchPrimaryDoneCarrierIds],
    })
  );
};

export const clearSwitchPrimaryDoneCarrierIdsInRedux = () => {
  const { contact } = store.getState();
  if (!contact.spouseId) return;

  store.dispatch(
    addContact({
      switchPrimaryDoneCarrierIds: [],
    })
  );
};

/**
 * @param {boolean} isAuto
 * @returns {string}
 */
const getSwitchPrimaryQuoteIdKey = (isAuto) => {
  if (isAuto) return "spouseAutoQuoteId";
  return "spouseHomeQuoteId";
};

/**
 * @param {Object} param
 * @param {string} param.line_of_business__c
 * @param {string} param.company_client_id__c
 */
const handleSwitchPrimary = ({
  line_of_business__c,
  company_client_id__c: carrierId,
}) => {
  const { contact } = store.getState();

  const lob = line_of_business__c.toLowerCase();
  const isAuto = lob === "auto";
  const quoteIdKey = getSwitchPrimaryQuoteIdKey(isAuto);
  const quoteRequestId = contact[quoteIdKey];

  return switchPrimary({
    lob,
    quoteRequestId,
    carrierId,
  });
};

/**
 * @param {Object} param
 * @param {string} param.company_client_id__c
 * @returns {Promise}
 */
export const handleSwitchPrimaryForHomeAndAuto = async ({
  company_client_id__c: carrierId,
}) => {
  try {
    const { contact } = store.getState();
    const hasHome = !!contact.spouseHomeQuoteId;
    const hasAuto = !!contact.spouseAutoQuoteId;

    const payloads = [];
    if (hasHome) {
      payloads.push({
        line_of_business__c: "Home",
        company_client_id__c: carrierId,
      });
    }
    if (hasAuto) {
      payloads.push({
        line_of_business__c: "Auto",
        company_client_id__c: carrierId,
      });
    }
    return await Promise.all(payloads.map(handleSwitchPrimary));
  } catch (e) {
    console.error(e);
  }
};

/** @returns {Promise} */
export const switchPrimaryForAllCarriersAlreadyDone = async () => {
  const { contact } = store.getState();
  const { switchPrimaryDoneCarrierIds } = contact;
  await Promise.all(
    switchPrimaryDoneCarrierIds.map(async (carrierId) => {
      handleSwitchPrimaryForHomeAndAuto({
        company_client_id__c: carrierId,
      });
    })
  );
};

export const REFETCH_DELAY_MS = 15 * 1000;
export const REFETCH_TIMES = 3;
