import * as lobUtils from "./lineOfBusiness";

import { companyIds, lineOfBusiness, qtiBranchId } from "../constants";
import {
  getBranchQuoteResponses,
  recalculateQuote,
  updateBranchQuote,
} from "../services/qti";

import { getLineOfBusiness } from "./strings";
import { handleUpdateSession } from "../services/utils";
import store from "../redux/store";
import { updateFinalQuotes } from "../redux/actions/QTI/finalQuotes";
import { updateIsRecalculating } from "../redux/actions/QTI/recalculating";

const parseDiscountsFromResponses = (responses) => {
  const discounts = {};

  const [homeResponse] = responses.filter(
    (r) => r.line_of_business__c === "Home" && !r.package_rate__c
  );
  const [autoResponse] = responses.filter(
    (r) => r.line_of_business__c === "Auto" && !r.package_rate__c
  );

  const homeDiscounts =
    ((homeResponse || {}).discounts__c &&
      JSON.parse(homeResponse.discounts__c)) ||
    {};
  const autoDiscounts =
    ((autoResponse || {}).discounts__c &&
      JSON.parse(autoResponse.discounts__c)) ||
    {};
  discounts.paperless =
    ((homeDiscounts || {}).paperless && homeDiscounts.paperless > 0) ||
    ((autoDiscounts || {}).paperless && autoDiscounts.paperless > 0);
  discounts.discountDriverScore =
    autoDiscounts && autoDiscounts.discountDriverScore;
  discounts.connectedHome =
    homeDiscounts &&
    homeDiscounts.connectedHome &&
    homeDiscounts.connectedHome > 0;
  discounts.discountInventoryScore =
    homeDiscounts &&
    homeDiscounts.discountInventoryScore &&
    homeDiscounts.discountInventoryScore > 0;
  return discounts;
};

export const handleBranchQuoteResponse = async () => {
  const contact = store.getState().contact;
  const session = store.getState().session;

  try {
    let branchQuoteResponse = await getBranchQuoteResponses(contact.accountId, {
      homeCompanyId: lobUtils.isHome(session.line_of_business)
        ? companyIds["Branch"]
        : undefined,
      autoCompanyId: lobUtils.isAuto(session.line_of_business)
        ? companyIds["Branch"]
        : undefined,
    });
    branchQuoteResponse = branchQuoteResponse.data;
    store.dispatch(updateFinalQuotes({ responses: branchQuoteResponse }));
  } catch (error) {
    console.log("Something went wrong", error);
  }
};

export const handleGetBranchQuoteResponses = async (isRecalculatedFailed) => {
  const contact = store.getState().contact;
  const session = store.getState().session;
  const isQTI = window.location.href.includes("QTI");
  let branchQuoteResponse = await getBranchQuoteResponses(contact.accountId, {
    homeCompanyId: lobUtils.isHome(session.line_of_business)
      ? companyIds["Branch"]
      : undefined,
    autoCompanyId: lobUtils.isAuto(session.line_of_business)
      ? companyIds["Branch"]
      : undefined,
  });
  branchQuoteResponse = branchQuoteResponse.data;

  let updateSessionObj = {
    uuid: session.uuid,
    qti_id: null,
  };

  const responseDiscounts = parseDiscountsFromResponses(branchQuoteResponse);
  if (session.discounts || responseDiscounts) {
    const currentDiscounts = JSON.parse(session.discounts);
    updateSessionObj.discounts = JSON.stringify({
      ...currentDiscounts,
      ...responseDiscounts,
    });
  }

  await handleUpdateSession(updateSessionObj);
  isQTI && store.dispatch(updateIsRecalculating(false));
  if (isRecalculatedFailed) {
    store.dispatch(updateIsRecalculating(false));
  }
};

export const getCarrierObj = (lob, homeId, autoId, isBranch) => {
  switch (getLineOfBusiness(lob)) {
    case lineOfBusiness.HOME:
      return { homeCarrierId: isBranch ? qtiBranchId : homeId };

    case lineOfBusiness.AUTO:
      return { autoCarrierId: isBranch ? qtiBranchId : autoId };

    case lineOfBusiness.HOMEAUTO:
      return {
        homeCarrierId: isBranch ? qtiBranchId : homeId,
        autoCarrierId: isBranch ? qtiBranchId : autoId,
      };
    default:
      return null;
  }
};

export const handleRecalculateQuote = async (
  data,
  offerId,
  isBranch,
  isInitialRecalculate
) => {
  const session = store.getState().session;
  const contact = store.getState().contact;
  const property = store.getState().property;
  const coverages = store.getState().coverages;
  const isQTI = window.location.href.includes("QTI");
  try {
    isQTI && store.dispatch(updateIsRecalculating(true));

    const simplifiedLob = getLineOfBusiness(session.line_of_business || "");

    let branchPayload = {
      offerId: offerId ? offerId : session.qti_id,
      homeQuoteId: isAllowedLOBHome(session.line_of_business)
        ? contact.homeQuoteId
        : undefined,
      autoQuoteId: isAllowedLOBAuto(session.line_of_business)
        ? contact.autoQuoteId
        : undefined,
      lob: simplifiedLob,
      agentId: session.agent_sfid,
      state: property.administrative_area_level_1,
      isQTI,
      ...data,
      isInitialRecalculate: isInitialRecalculate,
    };

    let otherCarrierPayload = {
      homeQuoteId: contact.homeQuoteId,
      autoQuoteId: contact.autoQuoteId,
      lob: simplifiedLob,
    };

    let finalPayload = {
      branch: branchPayload,
      other: otherCarrierPayload,
      ...getCarrierObj(
        simplifiedLob,
        contact?.homeCarrierCheckedOut,
        contact?.autoCarrierCheckedOut,
        isBranch
      ),
    };

    if (
      !(
        (finalPayload.homeCarrierId &&
          finalPayload.homeCarrierId === qtiBranchId) ||
        (finalPayload.autoCarrierId &&
          finalPayload.autoCarrierId === qtiBranchId)
      )
    ) {
      delete finalPayload.branch;
    }

    const recalculatedQuoteResponse = await recalculateQuote(finalPayload);

    const recalculatedQuoteData = recalculatedQuoteResponse.data;

    if (recalculatedQuoteData.responses.length <= 0) {
      handleGetBranchQuoteResponses(false);
      return;
    }

    let updateSessionObj = {
      uuid: session.uuid,
      qti_id: recalculatedQuoteData.offerId,
      qti_option_id: recalculatedQuoteData.optionId,
    };

    const { mismatchedWindHailDeductible, mismatchedAopDeductible } =
      recalculatedQuoteData;

    if (
      (mismatchedWindHailDeductible || mismatchedAopDeductible) &&
      contact.homeQuoteId
    ) {
      let coverages_data = { ...coverages };
      let quoteRequestDataToUpdate = {};

      if (
        mismatchedWindHailDeductible &&
        !coverages.mismatchedWindHailDeductible
      ) {
        coverages_data = {
          ...coverages_data,
          mismatchedWindHailDeductible,
        };
        quoteRequestDataToUpdate = {
          ...quoteRequestDataToUpdate,
          wind_hail_deductible__c: mismatchedWindHailDeductible,
        };
      }

      if (mismatchedAopDeductible && !coverages.mismatchedAopDeductible) {
        coverages_data = {
          ...coverages_data,
          mismatchedAopDeductible,
        };
        quoteRequestDataToUpdate = {
          ...quoteRequestDataToUpdate,
          all_other_deductible__c: mismatchedAopDeductible,
        };
      }

      updateSessionObj = {
        ...updateSessionObj,
        coverages_data: JSON.stringify({
          ...coverages_data,
        }),
      };

      if (Object.keys(quoteRequestDataToUpdate).length > 0) {
        await updateBranchQuote(contact.homeQuoteId, {
          lob: "Home",
          data: quoteRequestDataToUpdate,
        });
      }
    }

    const responseDiscounts = parseDiscountsFromResponses(
      recalculatedQuoteData.responses
    );
    if (session.discounts || responseDiscounts) {
      const currentDiscounts = JSON.parse(session.discounts);
      updateSessionObj.discounts = JSON.stringify({
        ...currentDiscounts,
        ...responseDiscounts,
      });
    }
    await handleUpdateSession(updateSessionObj);
    store.dispatch(updateFinalQuotes(recalculatedQuoteData));
    isQTI && store.dispatch(updateIsRecalculating(false));
    return {
      qti_id: recalculatedQuoteData.offerId,
      optionId: recalculatedQuoteData.optionId,
    };
  } catch (error) {
    handleGetBranchQuoteResponses(true);
    console.log("Something went wrong", error);
    return null;
  }
};

// needed to pass branch validation: https://github.com/Goosehead-Insurance/client-rater-node/blob/9489c40abe94ad914ac45bb5bdab6bd6308ffdd2/validators/qti.validators.js#L51-L63
const isAllowedLOBHome = (lob) =>
  ["Home", "HomeAuto", "Renter", "Condo"].includes(lob);

const isAllowedLOBAuto = (lob) =>
  ["Auto", "HomeAuto", "RenterAuto", "CondoAuto"].includes(lob);
