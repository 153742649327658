import { handleUpdateSession } from "../services/utils";
import store from "./store";

export const updateSessionFromRedux = async () => {
  const { session, coverages, contact, property, drivers, vehicles } =
    store.getState();

  if (!session?.uuid) return;

  try {
    const updateSessionMap = {
      uuid: session.uuid,
      coverages_data: JSON.stringify(coverages),
      contact_data: JSON.stringify(contact),
      property_data: JSON.stringify(property),
      drivers_data: JSON.stringify(drivers),
      vehicles_data: JSON.stringify(vehicles),
    };
    Object.keys(updateSessionMap).forEach((key) => {
      if (
        !updateSessionMap[key] ||
        ["{}", "[]"].includes(updateSessionMap[key])
      ) {
        delete updateSessionMap[key];
      }
    });
    await handleUpdateSession(updateSessionMap);
  } catch (error) {
    console.log("Something went wrong", error);
  }
};
