import styled from "styled-components";

export const QuoteSeperator = styled.div`
  height: 0;
  border-bottom: 1px solid ${(props) => props.theme.tertiary};
  display: flex;
  justify-content: center;
  margin: 0 1rem;
  & > img {
    width: 26px;
    height: 26px;
    position: relative;
    transform: translateY(-50%);
  }
`;

export const ButtonSeparator = styled.div`
  height: 10px;
  border-left: 1px solid #2d424d;
`;

export const Seperator = styled.hr`
  margin-top: 40px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  color: ${(props) => props.theme.tertiary};
`;
