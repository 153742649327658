import axios from "axios";

export const generateResponse = (payload) =>
  axios.post("/api/chat-gpt/message", payload);

export const getMessagesInConversation = (conversationId) =>
  axios.get("/api/chat-gpt/messages/", { params: { conversationId } });

export const addFeedback = (payload) => {
  axios.post("/api/chat-gpt/feedback", payload);
};
