import styled from "styled-components";

const StyledHeading = styled.h4`
  text-align: ${(props) => props.align};
  white-space: pre-wrap;
  color: ${(props) =>
    props.lightgreen
      ? props.theme.green2
      : props.dark
      ? props.theme.white
      : props.theme.primary};
  font-weight: ${(props) =>
    props.bold ? "bold" : props.semibold ? "500" : "normal"};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.semibold && "22px"};
  font-family: ${(props) => props.theme.font_primary};
`;
const Heading = ({
  text,
  lightgreen,
  dark,
  bold,
  align = "center",
  semibold,
  fontSize,
  ...props
}) => {
  return (
    <StyledHeading
      bold={bold}
      dark={dark}
      align={align}
      lightgreen={lightgreen}
      semibold={semibold}
      fontSize={fontSize}
      {...props}
    >
      {text}
    </StyledHeading>
  );
};

export default Heading;
