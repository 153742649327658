import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Rectangle,
  Cell,
} from "recharts";
import { SingleBarChartProps } from "./types";

const formatTick = (value: string, maxLength: number) => {
  const trimmedText = value.replace(/\s/g, "");
  return trimmedText.length > maxLength
    ? `${trimmedText.slice(0, maxLength)}...`
    : trimmedText;
};

export const HorizontalSingleBar = ({
  data,
  barColor,
  labelColor = "#fff",
  displayGrid = true,
  displayToolTip = true,
  fill = "",
  stroke = "",
  cursor = "",
  yAxisStyle,
  barSize = 16,
  barWidth,
  margin = { top: 0, right: 0, left: 0, bottom: 0 },
  chartHeight,
  chartWidth,
  showAxisLine = true,
  showTickLine = true,
  showLabel = false,
  tickMaxLength = 10,
  yAxisKey,
  xAxisKey,
}: SingleBarChartProps) => {
  return (
    <BarChart
      layout="vertical"
      width={chartWidth}
      height={chartHeight}
      data={data}
      margin={margin}
    >
      {displayGrid && <CartesianGrid strokeDasharray="7 3" />}
      <XAxis
        type="number"
        hide
        domain={[0, "dataMax" + 500]}
        padding={{ right: 40 }}
      />
      <YAxis
        width={70}
        type="category"
        dataKey={yAxisKey}
        axisLine={showAxisLine}
        tickLine={showTickLine}
        interval={0}
        style={yAxisStyle ?? {}}
        textAnchor="start"
        transform="translate(-67, 0)"
        tick={{ fill: yAxisStyle?.color ?? "#222" }}
        tickFormatter={(value) => formatTick(value, tickMaxLength)}
      />
      {displayToolTip && <Tooltip cursor={{ fill: "#fff" }} />}
      <Bar
        label={
          showLabel
            ? {
                position: "right",
                fill: labelColor,

                color: yAxisStyle?.color,
                style: {
                  fontWeight: yAxisStyle?.fontWeight,
                  fontFamily: yAxisStyle?.fontFamily,
                  fontSize: yAxisStyle?.fontSize,
                  fill: yAxisStyle?.color,
                },
                formatter: (value: number) => {
                  if (value >= 1000) {
                    return `${Math.round(value / 100) / 10}K`;
                  }
                  return new Intl.NumberFormat("en").format(value);
                },
              }
            : ""
        }
        barSize={barSize}
        dataKey={xAxisKey}
        activeBar={<Rectangle fill={fill} stroke={stroke} />}
      >
        {data?.map((entry, index) => (
          <Cell
            cursor={cursor}
            fill={barColor && barColor[index]}
            key={`cell-${index}`}
            aria-label={entry.name}
          />
        ))}
      </Bar>
    </BarChart>
  );
};
