import { UPDATE_IS_FETCHING_QUOTES } from "../constants";
import produce from "immer";

const initialState = false;

export const fetchingQuotesReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_IS_FETCHING_QUOTES:
      draft = action.payload;
      return draft;
    default:
      return draft;
  }
}, initialState);
