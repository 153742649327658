import { Col } from "react-bootstrap";
import styled from "styled-components";

export const StyledContainer = styled(Col)`
  background-color: ${(props) =>
    props.dark ? undefined : props.theme.form_background};
  border-radius: 15px;
  margin-top: 20px;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
`;

const FormContainer = ({ children, big, ...props }) => {
  return (
    <StyledContainer xs={11} sm={big ? 10 : 8} md={big ? 9 : 7} {...props}>
      {children}
    </StyledContainer>
  );
};

export default FormContainer;
