// TODO: Change type comments to TS types

import {
  ADD_PACKAGE,
  ADD_QUOTES,
  ADD_RENT,
  UPDATE_QUOTE_RESPONSE,
} from "../constants";

import produce from "immer";

/**
 * @typedef {{
 *   autoQuote: import("../../types").Quote;
 *   homeQuote: import("../../types").Quote;
 * }} BundleQuote
 *
 * @typedef {{
 *   rent?: boolean;
 *   package?: boolean;
 *   quotes?: {
 *     auto?: import("../../types").Quote[];
 *     autoCoverages?: import("../../types").AutoCoverages;
 *     bundle?: BundleQuote[];
 *     flood?: import("../../types").Quote[];
 *     home?: import("../../types").Quote[];
 *     homeCoverages?: import("../../types").HomeCoverages;
 *     jewelry?: import("../../types").Quote[];
 *     umbrella?: import("../../types").Quote[];
 *     lifePrimary?: import("../../types").Quote[];
 *     lifeSpouse?: import("../../types").Quote[];
 *   };
 * }} State
 */

/** @type {State} */
const initialState = {
  rent: false,
  package: false,
};

export const quoteReducer = produce(
  /**
   * @typedef {{
   *   type: string;
   *   payload: unknown;
   * }} DefaultAction
   *
   * @typedef {DefaultAction & {
   *   type: import("../constants").UPDATE_QUOTE_RESPONSE;
   *   payload: {
   *     lob: string;
   *     heroku_id: string;
   *     quoteUpdates: Partial<import("../../types").Quote>;
   *   };
   * }} UpdateQuoteAction
   */

  /**
   * @param {State} draft
   * @param {DefaultAction | UpdateQuoteAction} action
   */
  (draft, action) => {
    switch (action.type) {
      case ADD_RENT:
        draft = { ...draft, rent: action.payload };
        return draft;
      case ADD_PACKAGE:
        draft = { ...draft, package: action.payload };
        return draft;
      case ADD_QUOTES:
        draft = { ...draft, quotes: action.payload };
        return draft;
      case UPDATE_QUOTE_RESPONSE: {
        const { lob, quoteResponseId, updates } = action.payload;
        if ([lob, quoteResponseId].some((x) => x == null)) {
          console.warn({
            fn: "quoteReducer",
            case: "UPDATE_QUOTE",
            msg: "missing required fields",
            fields: {
              lob: !lob,
              heroku_id: !quoteResponseId,
            },
          });
          break;
        }

        const quote = draft?.quotes?.[lob]?.find(
          (x) => x.heroku_id === quoteResponseId
        );
        if (!quote) {
          console.warn({
            fn: "quoteReducer",
            case: "UPDATE_QUOTE",
            msg: "quote not found",
            criteria: { lob, quoteResponseId, draft: { ...draft } },
            found: {
              lobQuotes: draft?.quote?.[lob],
            },
          });
          break;
        }
        Object.assign(quote, updates);

        // find and update bundled copies
        findBundledQuoteCopies(draft, quoteResponseId)?.forEach(
          (bundleQuote) => {
            Object.assign(bundleQuote, updates);
          }
        );

        break;
      }
      default:
        return draft;
    }
  },
  initialState
);

/**
 * @param {State} state
 * @param {import("../../types").Quote["heroku_id"]} heroku_id
 * @returns {import("../../types").Quote[]}
 */
export function findBundledQuoteCopies(state, heroku_id) {
  return state.quotes?.bundle?.reduce((acc, bundleQuote) => {
    if (bundleQuote.autoQuote?.heroku_id === heroku_id) {
      acc.push(bundleQuote.autoQuote);
    } else if (bundleQuote.homeQuote?.heroku_id === heroku_id) {
      acc.push(bundleQuote.homeQuote);
    }

    return acc;
  }, []);
}
