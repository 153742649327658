import {
  ADD_PACKAGE,
  ADD_QUOTES,
  ADD_RENT,
  UPDATE_QUOTE_RESPONSE,
} from "../constants";

export const addRent = (hasRent) => {
  return {
    type: ADD_RENT,
    payload: hasRent,
  };
};

export const addPackage = (hasPackage) => {
  return {
    type: ADD_PACKAGE,
    payload: hasPackage,
  };
};

export const addQuotes = (quotes) => {
  return { type: ADD_QUOTES, payload: quotes };
};

/**
 * @param {{
 *   lob: string;
 *   quoteResponseId: string;
 *   updates: object;
 *   meta?: object;
 * }} input
 */
export const updateQuoteResponse = ({
  lob,
  quoteResponseId,
  updates,
  meta,
}) => {
  return {
    type: UPDATE_QUOTE_RESPONSE,
    payload: { lob, quoteResponseId, updates },
    meta,
  };
};
