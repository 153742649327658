import styled, { css } from "styled-components";

import submitIcon from "../../../assets/images/progressBar/Submit.svg";

const StyledButton = styled.button`
  position: relative;
  z-index: 0;
  display: ${(props) => !props.reduced && "inline-flex"};
  align-items: center;
  cursor: pointer;
  color: ${(props) =>
    props.textColor
      ? props.textColor
      : props.modal
      ? props.theme.primary
      : props.light
      ? props.theme.tertiary
      : props.green
      ? props.theme.primary_accent
      : props.theme.white};
  font-family: ${(props) => props.theme.font_primary};

  justify-content: space-between;
  font-size: ${(props) => {
    let fontSize;
    if (props.fontSize) {
      fontSize = props.fontSize;
    } else if (props.modal) {
      fontSize = "16px";
    } else {
      fontSize = "18px";
    }
    return fontSize;
  }};
  font-weight: 500;
  border-radius: 35px;
  ${(props) =>
    !props.reduced
      ? css`
          padding: 4px 4px 4px 15px;
          padding-left: ${(props) => props.small && "12px"};
          width: ${(props) =>
            props.width ??
            (props.small ? "120px" : props.medium ? "180px" : "240px")};
          border-width: 2px;
          border-style: ${(props) =>
            props.disabled && props.light ? "dashed" : "solid"};
          border-color: ${(props) =>
            props.modal
              ? props.theme.primary
              : (props.dark || props.light) && !props.disabled
              ? props.theme.tertiary
              : props.theme.white};
        `
      : css`
          padding: 8px 10px;
          width: ${(props) =>
            props.small ? "120px" : props.medium ? "180px" : "230px"};
          border: none;
        `}
  ${(props) =>
    props.noBorder &&
    css`
      border: none;
    `}

  background-color: ${(props) =>
    props.color
      ? props.color
      : (props.dark && props.cancel) || props.bgTransparent
      ? props.theme.transparent
      : props.theme.tertiary};

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  :hover {
    color: ${(props) =>
      props.modal
        ? props.theme.primary
        : props.light
        ? props.theme.tertiary
        : props.green
        ? props.theme.tertiary
        : props.theme.white};
    transform: ${(props) => (props.disabled ? `scale(1)` : `scale(1.05)`)};
  }
  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 35px;
    background-color: ${(props) =>
      props.color
        ? props.color
        : props.modal || props.light
        ? props.theme.white
        : (props.cancel && props.dark) || props.bgTransparent
        ? props.theme.transparent
        : props.theme.tertiary};
    opacity: 1;
    z-index: -1;
  }
  & > span {
    color: ${(props) => props.theme.tertiary};
    margin-left: ${(props) => (props.small ? "8px" : "18px")};
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => (props.small ? "30px" : "40px")};
    height: ${(props) => (props.small ? "30px" : "40px")};
    border-radius: 50%;
    cursor: pointer;
    transition: 0.25s background-color;
    background-color: ${(props) =>
      props.modal ? props.theme.primary : props.theme.primary_accent};
    border: 2px solid
      ${(props) =>
        props.whiteIcon
          ? "none"
          : props.modal
          ? props.theme.primary
          : props.theme.primary_accent};

    ::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 35px;
      background-color: ${(props) =>
        props.whiteIcon
          ? props.theme.white
          : props.modal
          ? props.theme.primary
          : props.theme.primary_accent};
      opacity: 1;
      transition: 0.2s opacity;
    }

    & > span {
      ${(props) =>
        !props.confirmButton
          ? css`
              transform: translate(1px, 0.5px);
              display: block;
              position: relative;
              width: ${(props) => (props.small ? "12px" : "15px")};
              height: ${(props) => (props.small ? "12px" : "15px")};
              transition: transform 0.15s ease-out 0.2s;

              ${(props) =>
                props.cancel &&
                css`
                  -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                `}

              ::before {
                content: " ";
                position: absolute;
                right: 0;
                height: 100%;
                border-right: 2px solid
                  ${(props) =>
                    props.modal
                      ? props.theme.white
                      : props.color
                      ? props.color
                      : props.theme.tertiary};
                -webkit-transform-origin: 50% 100% 0;
                transform-origin: 50% 100% 0;
                -webkit-transition: -webkit-transform 0.15s ease-out;
                transition: transform 0.15s ease-out;
                transition-delay: 0.075s;
                top: -50%;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);

                ${(props) =>
                  (props.plus || props.cancel) &&
                  css`
                    content: " ";
                    border-right: 2px solid
                      ${(props) =>
                        props.color
                          ? props.color
                          : props.modal
                          ? props.theme.white
                          : props.theme.tertiary};
                    position: absolute;
                    top: -50%;
                    -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                  `}
              }
              ::after {
                content: " ";
                position: absolute;
                right: 0;
                height: 100%;
                border-right: 2px solid
                  ${(props) =>
                    props.color
                      ? props.color
                      : props.modal
                      ? props.theme.white
                      : props.plus || props.cancel
                      ? props.theme.primary_accent
                      : props.theme.tertiary};

                -webkit-transform-origin: 50% 100% 0;
                transform-origin: 50% 100% 0;
                -webkit-transition: -webkit-transform 0.15s ease-out;
                transition: transform 0.15s ease-out;
                transition-delay: 0.075s;
                top: calc(-50% + -1px);
                -webkit-transform: rotate(225deg);
                transform: rotate(225deg);

                ${(props) =>
                  (props.plus || props.cancel) &&
                  css`
                    content: " ";
                    border-bottom: 2px solid
                      ${(props) =>
                        props.modal ? props.theme.white : props.theme.tertiary};
                    width: 100%;
                    top: calc(-50%);
                    -webkit-transform: rotate(270deg);
                    transform: rotate(270deg);
                  `}
              }
            `
          : css`
              background-image: url(${submitIcon});
              width: 35px;
              height: 40px;
              position: relative;
              left: -3px;
            `}
    }
  }
`;

const CustomButton = (props) => {
  return (
    <StyledButton {...props} onClick={props.handleClick}>
      {props.title}
      {!props.reduced && (
        <span aria-hidden="true">
          <span aria-hidden="true"></span>
        </span>
      )}
    </StyledButton>
  );
};

export default CustomButton;
