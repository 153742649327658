import styled from "styled-components";
import { Spinner as BootstrapSpinner } from "react-bootstrap";

const SpinnerWrapper = styled.div`
  color: ${(props) => props.theme.tertiary};
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};
`;

const Spinner = ({ display = "", justifyContent = "" }) => (
  <SpinnerWrapper display={display} justifyContent={justifyContent}>
    <BootstrapSpinner animation="border" size="small" />
  </SpinnerWrapper>
);

export default Spinner;
