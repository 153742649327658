import ChatBox from "./ChatBox";
import { ChatWindowContainer } from "./styled";

const ChatBot = ({ isActive, close }) => {
  return (
    <ChatWindowContainer>
      {<ChatBox isActive={isActive} onClose={close} />}
    </ChatWindowContainer>
  );
};

export default ChatBot;
