import { isValidJSONString } from "../utils";

const JSON_KEYS = [
  "contact_data",
  "property_data",
  "drivers_data",
  "coverages_data",
  "vehicles_data",
  "final_quotes_data",
];

const parseSession = (session) => {
  const result = { ...session };
  for (const key of JSON_KEYS) {
    const json = session[key];
    if (json === undefined) continue;
    if (!isValidJSONString(json))
      throw new Error(`Error parsing session at key: ${key}`);
    const obj = JSON.parse(json);
    result[key] = obj;
  }
  return result;
};

export default parseSession;
