export function hasValues(obj) {
  return Object.values(obj).filter(Boolean).length > 0;
}

export function removeEmptyValues(object) {
  return Object.keys(object).reduce((acc, key) => {
    if (object[key] != null) {
      acc[key] = object[key];
    }

    return acc;
  }, {});
}
