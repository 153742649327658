import styled, { css } from "styled-components";

export const UnstyledButton = styled.button`
  background-color: white;
  outline: none;
  border: none;
  :focus-visible {
    border: 2px solid black;
  }
`;

export const LobButton = styled.button`
  position: relative;
  text-align: center;
  font-weight: 500;
  border: 2px solid ${(props) => props.theme.tertiary_accent};
  padding: 0.3rem;
  color: ${(props) => props.theme.tertiary_accent};
  font-size: 18px;
  margin: 0;
  cursor: pointer;
  background: none;
  width: 100%;
  ::before {
    content: "";
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    height: 15px;
    width: 15px;
    background: ${(props) => props.theme.white};
    box-sizing: border-box;
    transform: rotate(45deg) translate(-50%);
    border-bottom: 2px solid ${(props) => props.theme.tertiary_accent};
    border-right: 2px solid ${(props) => props.theme.tertiary_accent};
    box-shadow: inherit;
  }

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.tertiary_accent};
      color: ${(props) => props.theme.white};
      ::before {
        background: ${(props) => props.theme.tertiary_accent};
      }
    `}

  @media (max-width:767px) {
    font-size: 20px;
  }
`;

export const ImageIcon = styled.img`
  width: 25px;
  height: 25px;
`;

export const ImgButton = styled.button`
  background-color: white;
  outline: none;
  z-index: 999;
  border: none;
  :focus-visible {
    border: 2px solid black;
  }
`;

export const Consent = styled.div`
  margin: auto;
  font-size: 15px;
  text-align: center;
`;

export const EditIcon = styled.button`
  opacity: ${(props) => (props.disabled ? 0.5 : 1.0)};
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
`;
