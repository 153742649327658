import { useFormik } from "formik";
import { ChangeEvent } from "react";
import { addContact } from "../../../redux/actions/contact";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { PropertyLob } from "../../../types";
import {
  getCarrierListForContact,
  getFilterDropdownButtonStyles,
  peekCarrierNames,
  useDropdown,
} from "../../../utilsMisc";
import ToggleRadio from "../ToggleRadio";
import Filter from "./Filter";

type CarrierFilterProps = {
  lob: PropertyLob | "Auto"; // not the same as MonolineLob
};
const CarrierFilter = ({ lob }: CarrierFilterProps) => {
  const dispatch = useDispatch();
  const contact = useSelector((store) => store.contact);

  const placeholder = `${lob} Carriers`;

  const filteredCarriersInRedux =
    lob === "Auto"
      ? contact.filteredAutoCarriers
      : contact.filteredHomeCarriers;
  const allCarrierNamesForContact = getCarrierListForContact(lob);

  const initialValues = {
    carrierNames: filteredCarriersInRedux ?? allCarrierNamesForContact,
  };
  const handleSubmit = (values: typeof initialValues) => {
    const isAllChecked =
      values.carrierNames.length === allCarrierNamesForContact.length;
    const isNoneChecked = values.carrierNames.length === 0;

    const reduxFieldName =
      lob === "Auto" ? "filteredAutoCarriers" : "filteredHomeCarriers";

    // if none is checked, display all carriers
    const reduxFieldValue =
      isAllChecked || isNoneChecked ? undefined : values.carrierNames;

    dispatch(
      addContact({
        [reduxFieldName]: reduxFieldValue,
      })
    );
  };

  const { values, handleChange, setFieldValue, submitForm } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const toggleButtonText = peekCarrierNames({
    lob,
    defaultValue: placeholder,
    carrierNames: values.carrierNames,
    allCarrierNamesForContact,
  });

  const { backgroundColor, borderColor, color } = getFilterDropdownButtonStyles(
    toggleButtonText,
    placeholder
  );

  const handleClose = () => {
    if (values.carrierNames.length === 0) {
      setFieldValue("carrierNames", allCarrierNamesForContact);
    }
  };
  const { show, toggle, close, clickOutsideRef } = useDropdown({
    onClose: handleClose,
  });

  if (lob !== "Auto" && !contact.homeQuoteId) return null;
  if (lob === "Auto" && !contact.autoQuoteId) return null;

  const isAllChecked =
    values.carrierNames.length === allCarrierNamesForContact.length;
  const toggleAll = () => {
    setFieldValue(
      "carrierNames",
      isAllChecked ? [] : allCarrierNamesForContact
    );
    submitForm();
  };
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    submitForm();
  };

  return (
    <Filter
      toggleButtonText={toggleButtonText}
      header={`${lob} Carriers`}
      show={show}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      color={color}
      close={close}
      toggle={toggle}
      ref={clickOutsideRef}
    >
      <div className="carriers">
        <div className="all-carrier-row">
          <label htmlFor={`${lob}-all-carrier`}>All Carriers</label>
          <ToggleRadio
            id={`${lob}-all-carrier`}
            checked={isAllChecked}
            onChange={toggleAll}
          />
        </div>
        <form className="item-list">
          {allCarrierNamesForContact.map((carrierName) => (
            <div className="item-row" key={carrierName}>
              <input
                type="checkbox"
                name="carrierNames"
                id={`${lob}-${carrierName}`}
                value={carrierName}
                checked={values.carrierNames.includes(carrierName)}
                onChange={handleCheckboxChange}
              />
              <label htmlFor={`${lob}-${carrierName}`}>{carrierName}</label>
            </div>
          ))}
        </form>
      </div>
    </Filter>
  );
};

export default CarrierFilter;
