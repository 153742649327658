import "./tooltip.css";

import { CustomText } from "./styled/typography";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { SmartHomePackageContent } from "./SmartHomePackageContent";
import Tooltip from "react-bootstrap/Tooltip";
import exclamatoryIcon from "../assets/images/components/exclamationMark.SVG";
import infoIcon from "../assets/images/main/Info.svg";
import infoIconModal from "../assets/images/main/Info-neon-green.svg";
import { security } from "../constants";
import styled from "styled-components";
import { useSelector } from "react-redux";

const TooltipContainer = styled(Tooltip)`
  border-radius: 15px;
`;

const DetailsButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const CoverageMarginLeftNeg = styled.div`
  margin-left: -10px;
`;

const ExclIcon = styled.span`
  padding-right: 0.25rem;
`;

const CustomTooltip = ({
  lob,
  // home/auto/flood etc
  content,
  color,
  detailsText,
  coverages,
  companyQuoteNumber,
  termLength,
}) => {
  const coverages_data = useSelector((store) => store.coverages);

  const shouldDisplayTermLength = lob === "auto";

  return (
    <OverlayTrigger
      delay={{ show: 300, hide: 300 }}
      placement="auto"
      trigger={["click"]}
      rootClose={true}
      overlay={(props) => (
        <TooltipContainer {...props} id="tooltip-auto">
          <div className="d-flex flex-column m-4">
            {lob === security ? (
              <SmartHomePackageContent isTooltip />
            ) : content ? (
              content
            ) : (
              <>
                <div className="text-start mb-2 coverageRequested">
                  {lob !== "home" ? "Coverage Requested" : ""}
                </div>

                {!(lob.includes("renter") || lob.includes("condo")) &&
                  coverages.map((coverage, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <div className="me-auto text-start label">
                        {coverage.label}
                      </div>
                      <div className="ms-auto value">{coverage.value}</div>
                    </div>
                  ))}
                {!lob.includes("life") && (
                  <div className="d-flex align-items-center mb-2">
                    <div className="me-auto text-start label">Quote Number</div>
                    <div className="ms-auto value">{companyQuoteNumber}</div>
                  </div>
                )}

                {shouldDisplayTermLength &&
                  (termLength !== "12" ? (
                    <CoverageMarginLeftNeg>
                      <div className="d-flex align-items-center mb-2">
                        <div className="me-auto text-start label">
                          <p className="mb-0">
                            <ExclIcon>
                              <img
                                src={exclamatoryIcon}
                                alt=""
                                className="img-fluid"
                              />
                            </ExclIcon>
                            Term Length
                          </p>
                        </div>
                        <div className="ms-auto value">{termLength}</div>
                      </div>
                    </CoverageMarginLeftNeg>
                  ) : (
                    <div className="d-flex align-items-center mb-2">
                      <div className="me-auto text-start label">
                        <p className="mb-0">Term Length</p>
                      </div>
                      <div className="ms-auto value">{termLength}</div>
                    </div>
                  ))}

                {lob.includes("renter") && (
                  <div className="d-flex align-items-center mb-2">
                    <div className="me-auto text-start label">
                      Property Coverage
                    </div>
                    <div className="ms-auto value">
                      {coverages_data.personal_property}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </TooltipContainer>
      )}
    >
      {detailsText ? (
        <DetailsButton>
          <CustomText
            decoration={"underline"}
            fontFamily="font_secondary"
            fontSize="xsmall"
            tabIndex={0}
          >
            details
          </CustomText>
        </DetailsButton>
      ) : (
        <img
          className="ml-1"
          alt="info"
          src={color === "green" ? infoIcon : infoIconModal}
        />
      )}
    </OverlayTrigger>
  );
};
export default CustomTooltip;
