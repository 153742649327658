import { Col, Row } from "react-bootstrap";
import { useState } from "react";

import { Container } from "./styled/wrappers";
import CustomButton from "./styled/buttons/CustomButton";
import CustomInput from "./styled/inputs/CustomInput";
import CustomSelect from "./styled/inputs/CustomSelect";
import FormContainer from "./styled/forms/FormContainer";
import Heading from "./Heading";
import { USAStates } from "../constants";

const ManualAddress = ({
  addressData,
  setAddressData,
  errors,
  handleAddressData,
  setShowManualAddress,
  isSelectedAddressInValid,
  dark = true,
  includeCancel = true,
  includeContinue = true,
  Header = () => (
    <Heading dark text="We were unable to find your address." bold />
  ),
}) => {
  const [address, setAddress] = useState(addressData ?? {});

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "street_number") {
      setAddress({
        ...address,
        street_number: value,
        route: value.slice(value.indexOf(" ") + 1),
      });
    } else {
      setAddress({ ...address, [name]: value });
    }
  };
  const handleBlur = (e) => {
    let trimmedAddress = {};
    Object.keys(address).forEach((key) => {
      trimmedAddress[key] = address[key].trim();
    });
    setAddressData(trimmedAddress);
  };

  return (
    <form
      className="manual-address d-flex justify-content-center"
      onSubmit={(e) => {
        e.preventDefault();
        handleAddressData(addressData);
      }}
    >
      <Container className="mt-5" maxWidth="100%">
        <Header />
        <FormContainer big className="mx-auto" dark={dark}>
          <Row className="mx-auto mt-2 justify-content-center">
            <Col>
              <Row className="my-3">
                <Col xs={12} sm={9} className="pt-2">
                  <Row>
                    <CustomInput
                      dark={dark}
                      label="Address"
                      className="form-control"
                      id="street_number"
                      type="text"
                      name="street_number"
                      placeholder=""
                      aria-label="Street Name"
                      value={address.street_number || ""}
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.street_numberError || ""}
                    />
                  </Row>
                </Col>
                <Col xs={6} sm={3} className="pt-2">
                  <Row>
                    <CustomInput
                      dark={dark}
                      label="Unit"
                      className="form-control"
                      id="unitNumber"
                      type="text"
                      name="unitNumber"
                      placeholder=""
                      aria-label="Unit Number"
                      value={address.unitNumber || ""}
                      handleChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Row>
                </Col>
                <Col xs={12} sm={4} className="pt-2">
                  <Row>
                    <CustomInput
                      dark={dark}
                      label="City"
                      className="form-control"
                      id="locality"
                      type="text"
                      name="locality"
                      placeholder=""
                      aria-label="City"
                      value={address.locality || ""}
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.localityError || ""}
                    />
                  </Row>
                </Col>
                <Col xs={6} sm={4} className="pt-2">
                  <Row>
                    <CustomSelect
                      dark={dark}
                      disabled={false}
                      label="State"
                      className="form-control"
                      id="administrative_area_level_1"
                      type="text"
                      name="administrative_area_level_1"
                      value={address.administrative_area_level_1 || ""}
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      aria-label="State"
                    >
                      <option value=""> </option>
                      {USAStates.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </CustomSelect>
                    <Col xs={12}>
                      {errors.administrative_area_level_1Error && (
                        <small className="fw-light font-italic text-danger">
                          {errors.administrative_area_level_1Error}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} sm={4} className="pt-2">
                  <Row>
                    <CustomInput
                      label="Zip"
                      className="form-control"
                      id="postal_code"
                      type="text"
                      name="postal_code"
                      placeholder=""
                      value={address.postal_code || ""}
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      aria-label="Postal Code"
                      error={errors.postal_codeError || ""}
                      dark={dark}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </FormContainer>
        {isSelectedAddressInValid && (
          <Row>
            <Col xs={12} className="text-center">
              <small className="text-center fw-light font-italic text-danger">
                Invalid Address provided. Please update missing fields.
              </small>
            </Col>
          </Row>
        )}
        {includeContinue && (
          <Row className="justify-content-center mt-4">
            <CustomButton type="submit" title="Continue" dark />
          </Row>
        )}
        {includeCancel && (
          <Row className="justify-content-center mt-3">
            <CustomButton
              type="button"
              className="m-auto"
              dark={dark}
              title="Cancel"
              cancel
              handleClick={() => {
                setShowManualAddress(false);
              }}
            />
          </Row>
        )}
      </Container>
    </form>
  );
};

export default ManualAddress;
