import {
  openingProtectionOptions,
  roofDeckAttachmentOptions,
  roofWallConnectionOptions,
  secondaryWaterResistanceOptions,
  windSpeedOptions,
} from "../constants";
import CustomSelect from "./styled/inputs/CustomSelect";

const FloridaPropertyForm = ({ values, onChange }) => {
  return (
    <form className="d-flex flex-column gap-4 mb-4">
      <div className="d-flex gap-4">
        <div className="w-100">
          <CustomSelect
            className="form-control"
            id="roof_deck_attachment__c"
            type="text"
            name="roof_deck_attachment__c"
            value={values.roof_deck_attachment__c}
            handleChange={onChange}
            label="Roof Deck Attachment"
            aria-label="Roof Deck Attachment"
          >
            {roofDeckAttachmentOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </CustomSelect>
        </div>
        <div className="w-100">
          <CustomSelect
            className="form-control"
            id="roof_wall_connection__c"
            type="text"
            name="roof_wall_connection__c"
            value={values.roof_wall_connection__c}
            handleChange={onChange}
            label="Roof Wall Connection"
            aria-label="Roof Wall Connection"
          >
            {roofWallConnectionOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </CustomSelect>
        </div>
      </div>
      <div className="d-flex gap-4">
        <div className="w-100">
          <CustomSelect
            className="form-control"
            id="wind_speed__c"
            type="text"
            name="wind_speed__c"
            value={values.wind_speed__c}
            handleChange={onChange}
            label="Wind Speed"
            aria-label="Wind Speed"
          >
            {windSpeedOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </CustomSelect>
        </div>
        <div className="w-100">
          <CustomSelect
            className="form-control"
            id="opening_protection__c"
            type="text"
            name="opening_protection__c"
            value={values.opening_protection__c}
            handleChange={onChange}
            label="Opening Protection"
            aria-label="Opening Protection"
          >
            {openingProtectionOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </CustomSelect>
        </div>
        <div className="w-100">
          <CustomSelect
            className="form-control"
            id="secondary_water_resistance__c"
            type="text"
            name="secondary_water_resistance__c"
            value={values.secondary_water_resistance__c}
            handleChange={onChange}
            label="Secondary Water Resistance"
            aria-label="Secondary Water Resistance"
          >
            {secondaryWaterResistanceOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </CustomSelect>
        </div>
      </div>
    </form>
  );
};

export default FloridaPropertyForm;
