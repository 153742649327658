import { Label } from "./FormikComponents";
import styled from "styled-components";

const Input = styled.input`
  background: white;
  height: ${(props) => props.height ?? "50px"};
  width: ${(props) => props.width ?? "200px"};
  color: ${(props) =>
    props.color ??
    (props.value ? props.theme.input_txt : props.theme.light_gray_alt)};
  font-size: ${(props) => props.fontSize ?? "15px"};
  font-family: ${(props) => props.fontFamily ?? "Poppins, sans-serif"};
  font-weight: 400;
  border-radius: ${(props) =>
    props.straightBorder ? 0 : props.radius ?? "25px"};
  border: 1px solid ${(props) => props.borderColor ?? "#707070"};
  padding: 12px 10px !important;
  ::-ms-expand {
    display: none;
  }
  ::placeholder {
    font-weight: 220;
    color: ${(props) => props.color ?? "#6d6e71"};
  }
  &:focus {
    outline: none;
  }
`;

const borderDefault = "#707070";

const DatePicker = ({
  id,
  label = "",
  infoText = "",
  spouse = false,
  error,
  className,
  wrapperClassName,
  disabled = false,
  ...props
}) => {
  const borderColor = error
    ? props.errorBorder ?? borderDefault
    : props.borderColor ?? borderDefault;
  return (
    <div className={wrapperClassName}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Input
        id={id}
        type="date"
        max="9999-12-31"
        className={`form-control ${className}`}
        {...props}
        style={{ border: `1px solid ${borderColor}` }}
        disabled={disabled}
      />
      {!props.hideError && (
        <small className="fw-light font-italic text-danger">
          {error ?? ""}
        </small>
      )}
    </div>
  );
};

export default DatePicker;
