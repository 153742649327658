import axios from "axios";

export const getInitialBranchQuote = (payload) =>
  axios.post("/api/qti/getQuote", payload);

export const updateBranchQuote = (quoteId, payload) =>
  axios.put(`/api/qti/quote/${quoteId}`, payload);

export const getBranchQuoteResponses = (
  accountId,
  { homeCompanyId, autoCompanyId } = {}
) =>
  axios.get(`/api/qti/quote/quoteresponses/${accountId}`, {
    params: {
      homeCompanyId,
      autoCompanyId,
    },
  });

export const recalculateQuote = (payload) =>
  axios.post("/api/qti/quote/recalculateQuote", payload);

export const getBranchDrivers = (quoteId) =>
  axios.get(`/api/qti/drivers/${quoteId}`);
