import {
  CloseButton,
  ErrorText,
  FeedBackContainer,
  FeedbackForm,
  TextEntry,
} from "./styled";

import { FEEDBACK_QUESTION } from "./constants";
import FeedbackButton from "./FeedbackButton";
import { addFeedback } from "../../services/chat";
import { useFormik } from "formik";
import { useState } from "react";

const FeedbackModal = ({ messageId, setFeedbackMessageId }) => {
  const closeModal = () => setFeedbackMessageId(null);
  const [feedbackError, setFeedbackError] = useState("");
  const handleSubmit = () => {
    const feedback = {
      type: formik.values.feedbackType,
      description: formik.values.feedbackDescription,
      messageId,
    };
    try {
      if (!feedback.type) {
        setFeedbackError("Please select the feedback type");
        return;
      }
      setFeedbackError("");
      addFeedback(feedback);
      formik.resetForm();
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      feedbackType: "",
      feedbackDescription: "",
    },
    onSubmit: handleSubmit,
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    } else if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      formik.setFieldValue(
        "feedbackDescription",
        formik.values.feedbackDescription + "\n"
      );
    }
  };
  return (
    <FeedBackContainer>
      {messageId && (
        <div className="modal-background" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="top-bar">
              <CloseButton
                width="18px"
                height="18px"
                onClick={closeModal}
                className="me-3"
              />
            </div>
            <FeedbackForm onSubmit={formik.handleSubmit}>
              <legend>{FEEDBACK_QUESTION}</legend>
              <div className="buttons">
                <FeedbackButton
                  selected={formik.values.feedbackType === "Helpful"}
                  type="like"
                  handleClick={() =>
                    formik.setFieldValue("feedbackType", "Helpful")
                  }
                />
                <FeedbackButton
                  selected={formik.values.feedbackType === "Not Helpful"}
                  type="dislike"
                  handleClick={() => {
                    formik.setFieldValue("feedbackType", "Not Helpful");
                  }}
                />
              </div>
              {!!feedbackError && (
                <ErrorText className="mb-2">{feedbackError}</ErrorText>
              )}
              <TextEntry
                id="feedbackDescription"
                name="feedbackDescription"
                onChange={formik.handleChange}
                value={formik.values.feedbackDescription}
                placeholder="Tell me more."
                onKeyDown={handleKeyDown}
              />
            </FeedbackForm>
          </div>
        </div>
      )}
    </FeedBackContainer>
  );
};

export default FeedbackModal;
