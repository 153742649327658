import styled from "styled-components";

export const StyledText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  text-align: center;
  font-size: ${(props) =>
    props.theme.fontSize[props.fontSize] ?? props.fontSize};
  font-family: ${(props) => props.theme[props.fontFamily]};
  color: ${(props) => {
    if (props.color === "secondary") {
      return props.theme.secondary;
    } else if (props.color && props.color !== "primary") {
      return props.color;
    }
    return props.theme.primary;
  }};
  font-weight: ${(props) => props.fontWeight};
  text-decoration: ${(props) => (props.decoration ? props.decoration : "none")};
  text-align: ${(props) => props.align};
  white-space: pre-wrap;
  cursor: ${(props) => (props.decoration ? "pointer" : "")};
`;

export const CustomText = ({
  fontSize,
  fontFamily,
  color,
  fontWeight,
  decoration,
  align = "center",
  children,
  tabIndex,
  handleClick,
  ...props
}) => {
  return (
    <StyledText
      onClick={handleClick}
      tabIndex={tabIndex}
      fontSize={fontSize}
      fontFamily={fontFamily}
      color={color}
      fontWeight={fontWeight}
      decoration={decoration}
      align={align}
      {...props}
    >
      {children}
    </StyledText>
  );
};
