import dislikeGreen from "../../assets/images/chatIcons/dislike-green.svg";
import dislikeWhite from "../../assets/images/chatIcons/dislike-white.svg";
import likeGreen from "../../assets/images/chatIcons/like-green.svg";
import likeWhite from "../../assets/images/chatIcons/like-white.svg";

export const INPUT_MAX_CHARS = 500;

export const INTRO_MESSAGE = {
  id: 0,
  role: "assistant",
  hideReport: true,
  content:
    "Hello, I'm Ava, an experimental AI assistant specifically designed to address questions related to insurance. How may I assist you?",
};

export const ERROR_MESSAGE = {
  role: "assistant",
  hideReport: true,
  content:
    "An unforeseen error has occurred. Consider attempting again after a short while.",
};

export const LEGAL_DISCLOSURE =
  "Ava is a beta experimental AI assistant powered by OpenAI's GPT-3.5 technology. Responses may not always be complete and precise and may vary depending on the unique circumstances surrounding your client's assets and requested coverage. The responses provided by Ava are dependent on your questions, are not recommendations, should be used for general guidance only, and should not be a substitute for personalized, professional advice. Goosehead Insurance agency LLC is not responsible for any actions taken based on Ava's responses.";

export const TEXT_PLACEHOLDER = "How may I help you?";

export const FEEDBACK_PROMPT = "Give us your feedback";

export const FEEDBACK_QUESTION = "How's your chat going?";

export const EMPTY_INPUT_MESSAGE = "Please let Ava know what you want to ask.";

export const INPUT_TOO_LONG_MESSAGE = `Oops, Ava can only understand the first ${INPUT_MAX_CHARS} characters. Can you summarize a bit?`;

export const TIMEOUT_MESSAGE =
  "Oops, Ava is thinking too hard. Please try asking simpler questions, or try it later.";

export const BUTTON_TEXT_MAP = {
  like: "Great",
  dislike: "Not Good",
};

export const BUTTON_ICON_MAP = {
  like: {
    active: likeWhite,
    inactive: likeGreen,
  },
  dislike: {
    active: dislikeWhite,
    inactive: dislikeGreen,
  },
};
