import axios from "axios";
import { ManualQuoteResponse } from "../types";

// @ts-ignore TODO: update types
export const getUserCarrierSettings = (agentId, state) => {
  return axios.get(`/api/user-carrier/?agentId=${agentId}&state=${state}`);
};

export const getNonRaterCarriers = (
  lob: string,
  state: string,
  agentId: string
) => {
  return axios.get<ManualQuoteResponse[]>(
    `/api/nonratercarriers/${lob}/${state}/${agentId}`
  );
};
