// @ts-check
import pick from "lodash/pick";
import store from "../redux/store";

export const DRIVER_ERRORS = {
  invalidGenderOrDOB:
    "Please enter a valid Date of Birth and gender for all drivers you wish to select.",
  unableToEditDriver: "Unable to edit driver. Please try again later.",
};

export function createDriversFromContacts(
  sessionContact = /** @type {SessionContact} */ (store.getState().contact)
) {
  return getContactsFromSession(sessionContact).map(createDriverFromContact);
}

/**
 * @typedef {{
 *   accountId?: string;
 *   administrative_area_level_1?: string;
 *   administrative_area_level_2?: string;
 *   agentEmail?: string;
 *   agentName?: string;
 *   agentProfileId?: string;
 *   agentState?: null;
 *   agentUserId?: string;
 *   autoPropertyId?: string;
 *   autoQuoteId?: string;
 *   contactId: string;
 *   country?: string;
 *   dob: Date;
 *   firstName: string;
 *   gender: string;
 *   homeQuoteId?: string;
 *   language: string;
 *   lastName: string;
 *   leadSource?: string;
 *   locality?: string;
 *   maritalStatus: string;
 *   phone?: string;
 *   postal_code?: string;
 *   propertyId?: string;
 *   referredBy?: string;
 *   route?: string;
 *   selectedPolicy?: string;
 *   spouseDob?: Date;
 *   spousefirstName?: string;
 *   spouseGender?: string;
 *   spouseId?: string;
 *   spouseLanguage?: string;
 *   spouselastName?: string;
 *   spousePhone?: string;
 *   spouseSsn?: string;
 *   spouseSuffix?: string;
 *   ssn?: string;
 *   street_number?: string;
 *   suffix?: string;
 *   user?: string;
 * }} SessionContact
 */

/**
 * @param {object} object
 * @param {string[]} keys
 */
function hasValues(object, keys) {
  return keys.every((key) => object[key]);
}

/** @param {SessionContact} sessionContact */
function getContactsFromSession(sessionContact) {
  const contacts = [];

  if (
    hasValues(sessionContact, [
      "contactId",
      "dob",
      "firstName",
      "lastName",
      "gender",
      "language",
      "maritalStatus",
    ])
  ) {
    contacts.push({
      primary: true,
      ...pick(sessionContact, [
        "contactId",
        "dob",
        "firstName",
        "lastName",
        "gender",
        "language",
        "maritalStatus",
        "ssn",
        "suffix",
      ]),
    });
  }

  const spouseContact = getSpouseContact(sessionContact);
  const hasPrimaryContact = contacts.length > 0;
  if (spouseContact && hasPrimaryContact) {
    contacts.push({ ...spouseContact, spouse: true });
  }

  return contacts;
}

/** @param {SessionContact} sessionContact */
function getSpouseContact(sessionContact) {
  if (
    sessionContact.spouseId &&
    sessionContact.spouseDob &&
    sessionContact.spousefirstName &&
    sessionContact.spouselastName &&
    sessionContact.spouseGender &&
    sessionContact.spouseLanguage
  ) {
    return {
      contactId: sessionContact.spouseId,
      dob: sessionContact.spouseDob,
      firstName: sessionContact.spousefirstName,
      lastName: sessionContact.spouselastName,
      gender: sessionContact.spouseGender,
      language: sessionContact.spouseLanguage,
      maritalStatus: sessionContact.maritalStatus,
      ssn: sessionContact.spouseSsn,
      suffix: sessionContact.spouseSuffix,
    };
  }
}

/**
 * @typedef {{
 *   contactId: string;
 *   dLState?: string;
 *   dob: Date;
 *   driverLicense?: string;
 *   firstName: string;
 *   gender: string;
 *   language: string;
 *   lastName: string;
 *   maritalStatus: string;
 *   primary?: boolean;
 *   relationship: "Insured" | "Spouse" | "Child" | "Other" | "Relative"; // src: https://github.com/Goosehead-Insurance/client-rater-node/blob/73ff2a694dd03a15e73a86092d7726bf0ce9d2c4/services/qti/carriers/Clearcover/types/salesforce/index.ts#L187
 *   selected: boolean;
 *   spouse?: boolean;
 *   ssn?: string | null;
 *   suffix?: string;
 * }} Driver
 */

/**
 * @param {{
 *   contactId: string;
 *   dob: Date;
 *   firstName: string;
 *   gender: string;
 *   language: string;
 *   lastName: string;
 *   maritalStatus: string;
 *   primary?: boolean;
 *   selected?: boolean;
 *   spouse?: boolean;
 *   ssn?: string;
 *   suffix?: string;
 * }} input
 * @returns {Driver}
 */
function createDriverFromContact({
  contactId,
  dob,
  firstName,
  gender,
  language,
  lastName,
  maritalStatus,
  primary,
  selected = true,
  spouse,
  ssn,
  suffix,
}) {
  return {
    contactId,
    dob,
    firstName,
    gender,
    language,
    lastName,
    maritalStatus,
    primary,
    relationship: primary ? "Insured" : "Spouse",
    selected,
    spouse,
    ssn,
    suffix,
  };
}
