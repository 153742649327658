import {
  UPDATE_IS_FETCHING,
  UPDATE_IS_FETCHING_PRIOR_LIMITS,
} from "../constants";

export const updateIsFetching = (isFetching) => {
  return {
    type: UPDATE_IS_FETCHING,
    payload: isFetching,
  };
};

export const updateIsFetchingPriorLimits = (isFetching) => {
  return {
    type: UPDATE_IS_FETCHING_PRIOR_LIMITS,
    payload: isFetching,
  };
};
