import styled from "styled-components";
import React from "react";
const StyledErrorComponent = styled.div<{
  height: string;
  paddingTop?: string;
  showIcon?: boolean;
  singleLobError?: boolean;
}>`
  display: flex;
  min-height: 115px;
  flex-direction: column;
  justify-content: top;
  align-items: ${(props) => (props.showIcon ? "center" : "flex-start")};
  width: 100%;
  padding-top: ${(props) => props.paddingTop};
  height: ${(props) => props.height};
  & .error {
    font-size: ${(props) => (props.singleLobError ? "12px" : "14px")};
    font-weight: ${(props) => (props.singleLobError ? "normal" : "500")};
    text-align: center;
    color: ${(props) => (props.singleLobError ? "#2d2c2a" : "#626262")};
    white-space: pre-line;
  }
`;

type ErrorProps = {
  content: string;
  height: string;
  singleLobError?: boolean;
  icon?: React.ReactNode;
};

const Error = ({
  content,
  height,
  singleLobError = false,
  icon = null,
}: ErrorProps) => {
  return (
    <StyledErrorComponent
      height={height}
      paddingTop={singleLobError ? "0" : "42px"}
      showIcon={!singleLobError}
      singleLobError={singleLobError}
    >
      {!singleLobError && icon}
      <span className="error">{content}</span>
    </StyledErrorComponent>
  );
};
Error.defaultProps = {
  height: "50px",
};

export default Error;
