import { FormikInput, FormikSelect } from "../FormikComponents";
import { getDriverOptions, setFieldsEdited } from "../../utils";

import DatePicker from "../DatePicker";
import { DeleteButton } from "../styled/table";
import { Field } from "formik";
import { INCIDENT_TYPE_OPTIONS_MAP } from "../../constants";
import deleteIcon from "../../assets/images/main/delete-icon.svg";
import { deleteLoss } from "../../services/accidents";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { handleIntInputKeyDown } from "../../utils/misc";

const AccidentRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0.3rem;
    align-items: center;
  }
  & .calendar-error {
    grid-column: 2;
  }
  & .amount-error {
    grid-column: 5 / span 4;
  }
`;

const IncidentType = styled.div`
  min-width: 6em;
  font-weight: bold;
  transform: translateY(1rem);
`;

const ACCIDENT_INPUTS = [
  "date",
  "driver",
  "description",
  "propertyDamage",
  "bodilyInjury",
  "collision",
  "medPay",
];

const VIOLATION_INPUTS = ["date", "driver", "description"];

const COMP_LOSS_INPUTS = ["date", "driver", "description", "amount"];

const INCIDENT_INPUTS_MAP = {
  Accident: ACCIDENT_INPUTS,
  "Comp Loss": COMP_LOSS_INPUTS,
  Violation: VIOLATION_INPUTS,
};
const IncidentInputLine = ({ index, removeIncident }) => {
  const {
    values: { incidents },
    errors,
    setFieldValue,
    handleChange,
  } = useFormikContext();
  const incident = incidents[index];
  const { type } = incident;
  const inputs = INCIDENT_INPUTS_MAP[type];
  const incidentErrors = errors?.incidents?.[index] ?? {};
  const handleDelete = () => {
    // from the UI
    setFieldsEdited();
    removeIncident(index);
    // from the backend
    if (incident?.heroku_id) {
      deleteLoss(incident.heroku_id);
    }
  };
  // default empty input to 0
  const handleAmountFocus = (e) => {
    const { name, value } = e.target;
    if (value === "0") {
      setFieldValue(name, "");
    }
  };
  const handleAmountFocusOut = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setFieldValue(name, 0);
    }
  };

  const handleFieldChange = (event) => {
    setFieldsEdited();
    handleChange(event);
  };

  return (
    <AccidentRowWrapper>
      <div className="inputs">
        <IncidentType>{type}</IncidentType>
        {inputs.includes("date") && (
          <Field
            as={DatePicker}
            width="130px"
            noFocus
            id={`incidents[${index}].date_of_incident__c`}
            name={`incidents[${index}].date_of_incident__c`}
            label="Date"
            onChange={handleFieldChange}
          />
        )}
        {inputs.includes("driver") && (
          <FormikSelect
            id={`incidents[${index}].driver__c`}
            name={`incidents[${index}].driver__c`}
            className="form-control"
            label="Driver"
            noFocus
            showError={false}
            onChange={handleFieldChange}
          >
            <option value="">- Select -</option>
            {getDriverOptions().map((driver) => (
              <option key={driver.value} value={driver.value}>
                {driver.label}
              </option>
            ))}
          </FormikSelect>
        )}
        {inputs.includes("description") && (
          <FormikSelect
            className="form-control"
            noFocus
            width="200px"
            id={`incidents[${index}].description__c`}
            name={`incidents[${index}].description__c`}
            label="Description"
            onChange={handleFieldChange}
          >
            {INCIDENT_TYPE_OPTIONS_MAP[type]
              .sort((a, b) => a.value.localeCompare(b.value))
              .map((option, index) => (
                <option value={option.value} key={index}>
                  {option.display}
                </option>
              ))}
          </FormikSelect>
        )}
        {inputs.includes("propertyDamage") && (
          <FormikInput
            className="form-control"
            shouldDisplayError={false}
            noFocus
            id={`incidents[${index}].property_damage_amount__c`}
            name={`incidents[${index}].property_damage_amount__c`}
            type="number"
            label="PD Amount"
            onKeyDown={handleIntInputKeyDown}
            onFocus={handleAmountFocus}
            onBlur={handleAmountFocusOut}
            onChange={handleFieldChange}
          />
        )}
        {inputs.includes("bodilyInjury") && (
          <FormikInput
            className="form-control"
            shouldDisplayError={false}
            noFocus
            id={`incidents[${index}].bodily_injury_amount__c`}
            name={`incidents[${index}].bodily_injury_amount__c`}
            type="number"
            label="BI Amount"
            onKeyDown={handleIntInputKeyDown}
            onFocus={handleAmountFocus}
            onBlur={handleAmountFocusOut}
            onChange={handleFieldChange}
          />
        )}
        {inputs.includes("collision") && (
          <FormikInput
            className="form-control"
            shouldDisplayError={false}
            noFocus
            id={`incidents[${index}].collision_amount__c`}
            name={`incidents[${index}].collision_amount__c`}
            type="number"
            label="Coll Amount"
            onKeyDown={handleIntInputKeyDown}
            onFocus={handleAmountFocus}
            onBlur={handleAmountFocusOut}
            onChange={handleFieldChange}
          />
        )}
        {inputs.includes("medPay") && (
          <FormikInput
            className="form-control"
            shouldDisplayError={false}
            noFocus
            id={`incidents[${index}].medpay_amount__c`}
            name={`incidents[${index}].medpay_amount__c`}
            type="number"
            label="MP Amount"
            onKeyDown={handleIntInputKeyDown}
            onFocus={handleAmountFocus}
            onBlur={handleAmountFocusOut}
            onChange={handleFieldChange}
          />
        )}
        {inputs.includes("amount") && (
          <FormikInput
            className="form-control"
            shouldDisplayError={false}
            noFocus
            id={`incidents[${index}].amount__c`}
            name={`incidents[${index}].amount__c`}
            type="number"
            label="Amount"
            onKeyDown={handleIntInputKeyDown}
            onFocus={handleAmountFocus}
            onBlur={handleAmountFocusOut}
            onChange={handleFieldChange}
          />
        )}
        <small className="calendar-error text-danger">
          {incidentErrors.date_of_incident__c}
        </small>
        <small className="text-danger">{incidentErrors.driver__c}</small>
        <small className="amount-error text-danger">
          {incidentErrors.property_damage_amount__c || incidentErrors.amount__c}
        </small>
      </div>

      <DeleteButton
        id={`incidents[${index}].delete`}
        type="button"
        onClick={handleDelete}
        aria-label="delete accident"
      >
        <img src={deleteIcon} alt="delete" />
      </DeleteButton>
    </AccidentRowWrapper>
  );
};

export default IncidentInputLine;
