import { BUTTON_ICON_MAP, BUTTON_TEXT_MAP } from "./constants";

import { FeedbackSubmitButton } from "./styled";

const FeedbackButton = ({ type, handleClick, selected }) => {
  const svgUrl = BUTTON_ICON_MAP[type][selected ? "active" : "inactive"];
  return (
    <FeedbackSubmitButton
      type="button"
      onClick={handleClick}
      selected={selected}
    >
      <div>{BUTTON_TEXT_MAP[type]}</div>
      <img src={svgUrl} alt={type} />
    </FeedbackSubmitButton>
  );
};

export default FeedbackButton;
