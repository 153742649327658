import { StyledQuoteAlert, StyledQuoteAlertTooltip } from "../styled";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React from "react";

export const QuoteAlert = ({
  tooltipText,
  alertIcon,
  alertText,
  $backgroundColor,
  $color,
  $paddingX,
  children,
  trigger,
  show,
}: {
  tooltipText: string;
  alertIcon?: React.JSX.Element;
  alertText?: string;
  $backgroundColor: string;
  $color: string;
  $paddingX?: string;
  children?: React.ReactElement;
  trigger?: "hover" | "click" | "focus" | Array<"hover" | "click" | "focus">;
  show?: boolean;
}) => {
  return (
    <OverlayTrigger
      placement="top"
      show={show}
      {...(trigger && { trigger: trigger })}
      overlay={
        <StyledQuoteAlertTooltip
          className="required-tooltip d-inline"
          $backgroundColor={$backgroundColor}
          $color={$color}
        >
          {tooltipText}
        </StyledQuoteAlertTooltip>
      }
    >
      {children ? (
        children
      ) : (
        <StyledQuoteAlert
          $backgroundColor={$backgroundColor}
          $paddingX={$paddingX}
        >
          <div className="alert-icon">{alertIcon}</div>
          {alertText && <span className="alert-text">{alertText}</span>}
        </StyledQuoteAlert>
      )}
    </OverlayTrigger>
  );
};
