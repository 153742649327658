import "../../assets/css/SmartHomePackageSelectionModal.css";

import { CloseButton, Col, Row } from "react-bootstrap";
import {
  updateContactInPg,
  updateSfContactByAccountId,
} from "../../services/account";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../styled/buttons/CustomButton";
import CustomInput from "../styled/inputs/CustomInput";
import CustomRadio from "../styled/inputs/CustomRadio";
import { CustomText } from "../styled/typography";
import Modal from "react-bootstrap/Modal";
import { SmartHomePackageContent } from "../SmartHomePackageContent";
import { addContact } from "../../redux/actions/contact";
import { handleUpdateSession } from "../../services/utils";
import styled from "styled-components";
import { useFormik } from "formik";
import { useState } from "react";
import { vivintContactDataValidationSchema } from "../../utils/validation";

const SmartHomeContentWrapper = styled.div`
  background-color: #51a429;
  width: 50%;
  margin: auto;
  padding: 10px;
  border: 2px solid white;
  margin-top: 30px;
`;

const SmartHomePackageSelectionModal = ({
  showSmartHomePackageSelectionModal,
  setShowSmartHomePackageSelectionModal,
  setShowSmartHomeConsentModal,
}) => {
  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    contactId: contact.contactId,
    phone: contact.phone,
    email: contact.email,
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await updateContactInPg({
        account_id: contact.accountId,
        contactId: contactData.contactId,
        mobilephone: contactData.phone,
        email: contactData.email,
      });
      await updateSfContactByAccountId({
        accountId: contact.accountId,
        fieldsToSync: ["mobilephone", "email"],
      });
      let updatedContact = {
        packageSelected: "Vivint Package 1",
        referredContactId: contactData.contactId,
      };
      if (contactData.contactId === contact.contactId) {
        updatedContact = {
          ...updatedContact,
          phone: contactData.phone,
          email: contactData.email,
        };
      } else if (contactData.contactId === contact.spouseId) {
        updatedContact = {
          ...updatedContact,
          spousePhone: contactData.phone,
          spouseEmail: contactData.email,
        };
      }
      await handleUpdateSession({
        uuid: session.uuid,
        contact_data: JSON.stringify({
          ...contact,
          ...updatedContact,
        }),
      });
      dispatch(addContact({ ...contact, ...updatedContact }));
      setShowSmartHomePackageSelectionModal(false);
      setIsSubmitting(false);
      setShowSmartHomeConsentModal(true);
    } catch (error) {
      console.log("Error Selecting Smart Home Package", error);
      setIsSubmitting(false);
    }
  };

  const {
    values: contactData,
    handleChange,
    errors,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: vivintContactDataValidationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Modal
      show={showSmartHomePackageSelectionModal}
      centered
      className="vivintSmartHomeModal"
    >
      <Modal.Header className="text-center">
        <CustomText
          color="white"
          fontSize="medium"
          fontWeight="bold"
          fontFamily="font_primary"
        >
          Refer To Vivint Smart Home
        </CustomText>
      </Modal.Header>
      <CloseButton
        variant="white"
        onClick={() => setShowSmartHomePackageSelectionModal(false)}
        className="closeButton"
      />
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <SmartHomeContentWrapper>
            <SmartHomePackageContent />
          </SmartHomeContentWrapper>
          <div className="mx-4">
            {contact.spouseId && (
              <>
                <div className="mt-4 d-flex justify-content-start">
                  <CustomText
                    color="white"
                    fontSize="smaller"
                    fontWeight={300}
                    fontFamily="font_secondary"
                  >
                    Select a point of contact for the Vivnt installation
                  </CustomText>
                </div>
                <Row className="mt-2 d-flex justify-content-start" role="group">
                  <Col xs={5}>
                    <CustomRadio
                      name="contactId"
                      title={`${contact.firstName} ${contact.lastName}`}
                      value={contactData.contactId}
                      handleClick={(event) => {
                        formik.setValues({
                          [event.target.name]: contact.contactId,
                          phone: contact.phone,
                          email: contact.email,
                        });
                      }}
                      checked={contactData.contactId === contact.contactId}
                    />
                  </Col>
                  <Col xs={5}>
                    <CustomRadio
                      name="contactId"
                      title={`${contact.spousefirstName} ${contact.spouselastName}`}
                      value={contactData.contactId}
                      handleClick={(event) => {
                        formik.setValues({
                          [event.target.name]: contact.spouseId,
                          phone: contact.spousePhone,
                          email: contact.spouseEmail,
                        });
                      }}
                      checked={contactData.contactId === contact.spouseId}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Row className="mt-4 d-flex justify-content-between">
              <Col>
                <CustomInput
                  label="Mobile Number"
                  className="form-control"
                  type="phone"
                  name="phone"
                  id="phone"
                  aria-label="Mobile Number"
                  placeholder="(555) 123-4567"
                  value={contactData.phone ?? ""}
                  handleChange={handleChange}
                  error={errors.phone}
                  masked
                  mask="(999) 999-9999"
                  maskPlaceholder=""
                  inputMode="numeric"
                  dark
                />
              </Col>
              <Col>
                <CustomInput
                  label="Email Address"
                  className="form-control"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="username@email.com"
                  aria-label="Email Address"
                  value={contactData.email ?? ""}
                  handleChange={handleChange}
                  error={errors.email}
                  dark
                />
              </Col>
            </Row>
          </div>
          <div className="text-center mt-5 mb-4">
            <CustomButton
              title="Continue"
              type="submit"
              dark
              disabled={isSubmitting}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default SmartHomePackageSelectionModal;
