import { ReactComponent as FilterIcon } from "../../../assets/images/components/response-page/filter.svg";
import { isDwelling } from "../../../utils";
import { getSessionPropertyLob } from "../../../utilsMisc";
import CarrierFilter from "./CarrierFilter";
import ContactFilter from "./ContactFilter";
import { StyledFilterBar } from "./styled";

const FilterBar = () => {
  const propertyLob = getSessionPropertyLob();
  return (
    <StyledFilterBar>
      <span className="filter-label">
        <FilterIcon /> Filters
      </span>
      <ContactFilter />
      {!!propertyLob && <CarrierFilter lob={propertyLob} />}
      {!isDwelling() && <CarrierFilter lob="Auto" />}
    </StyledFilterBar>
  );
};
export default FilterBar;
