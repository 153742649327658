import { ReactNode, forwardRef } from "react";
import { CloseButton, Dropdown } from "react-bootstrap";
import { StyledDropdown } from "./styled";

type FilterWrapperProps = {
  toggleButtonText: string;
  header: string;
  children: ReactNode | ReactNode[];
  show: boolean;
  backgroundColor: string;
  borderColor: string;
  color: string;
  close: () => void;
  toggle: () => void;
};
const Filter = forwardRef<HTMLDivElement, FilterWrapperProps>(
  (
    {
      toggleButtonText,
      header,
      children,
      show,
      backgroundColor,
      borderColor,
      color,
      close,
      toggle,
    },
    clickOutsideRef
  ) => {
    return (
      <StyledDropdown
        show={show}
        ref={clickOutsideRef}
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
        $color={color}
      >
        <Dropdown.Toggle onClick={toggle}>
          <span>{toggleButtonText}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="header">
            <legend>{header}</legend> <CloseButton onClick={close} />
          </div>
          {children}
        </Dropdown.Menu>
      </StyledDropdown>
    );
  }
);
Filter.displayName = "Filter";

export default Filter;
