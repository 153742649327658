// @ts-check

import env from "../env";

export const environment =
  process.env.REACT_APP_ENV ?? env.APP_ENV ?? "development";

export const VENDOR_PREFIX =
  process.env.REACT_APP_VENDOR_PREFIX ?? "REACT_APP_";

/** @param {string} key */
export const getEnvKey = (key) => `${VENDOR_PREFIX}${key}`;

/** @param {string} key */
export const getEnvValue = (key) => process.env[getEnvKey(key)];

/**
 * @param {any} value
 * @param {boolean | null} [defaultValue=false] Default is `false`
 */
export function isTrue(value, defaultValue = false) {
  if (!value) {
    return defaultValue;
  }

  if (typeof value === "boolean") {
    return value;
  }

  return /^true$/i.test(value);
}

/**
 * @param {any} value
 * @param {number} [defaultValue=0] Default is `0`
 */
export function getNumber(value, defaultValue = 0) {
  if (!value) {
    return defaultValue;
  }

  try {
    const result = Number(value);
    return Number.isNaN(result) ? defaultValue : result;
  } catch (error) {
    return defaultValue;
  }
}
