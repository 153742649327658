import { ComponentProps } from "react";
import styled from "styled-components";

const StyledRadioContainer = styled.div`
  /* override Bootstrap styles */
  input:focus {
    border-color: #157f3a !important;
    box-shadow: 0 0 0 2px #018d3266 !important;
  }
  input:checked,
  input:active {
    background-color: #157f3a !important;
    border-color: #157f3a !important;
  }
  input:not(:checked),
  input:focus:not(:checked) {
    background-color: #ababab !important;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23ffffff'/></svg>");
  }
  input:focus:not(:checked) {
    box-shadow: 0 0 0 2px #85858566 !important;
    border-color: #858585 !important;
  }
`;

const ToggleRadio = (props: ComponentProps<"input">) => {
  return (
    <StyledRadioContainer className="form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        {...props}
      />
    </StyledRadioContainer>
  );
};

export default ToggleRadio;
