import axios from "axios";

export const getDriversAndVehicles = (payload) =>
  axios.post("/api/drivers", payload);

export const deleteDrivers = (driverId) =>
  axios.delete(`/api/drivers/${driverId}`);

export const saveDriversInContact = (payload) =>
  axios.post("/api/drivercontacts", payload);

export const saveDriversinPg = (payload) =>
  axios.post("/api/adddrivers", payload);
