import { CustomText } from "../styled/typography";
import { IconImage } from "../styled/images";
import styled from "styled-components";
import udrGreenIcon from "../../assets/images/components/udrGreenIcon.svg";
import udrRedIcon from "../../assets/images/components/udrRedIcon.svg";
import { useSelector } from "react-redux";

const AutoIndicatorsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  position: absolute;
  right: -30px;
  top: -20px;
  /* these values are due to FormContainer paddings */
  transform: translate(0, -150%);
`;

export const AutoIndicators = () => {
  const contact = useSelector((store) => store.contact);
  const hasAutoClaims = !contact.hasUDR && contact["claimCount"] != null;
  const hasMVRs = !contact.hasUDR && contact["violationCount"] != null;
  return (
    <AutoIndicatorsWrapper>
      {hasAutoClaims && (
        <div>
          <CustomText
            color={contact.claimCount > 0 ? "red" : "#358640"}
            fontWeight="600"
            fontSize="1rem"
            fontFamily="font_primary"
          >
            {`Auto Claims: ${
              contact.claimCount > 0
                ? contact.claimCount === 1
                  ? contact.claimCount + " Incident "
                  : contact.claimCount + " Incidents "
                : "Clear "
            }`}
          </CustomText>
          <IconImage
            src={contact.claimCount > 0 ? udrRedIcon : udrGreenIcon}
            size={20}
          />
        </div>
      )}
      {hasMVRs && (
        <div>
          <CustomText
            color={contact.violationCount > 0 ? "red" : "#358640"}
            fontWeight="600"
            fontFamily="font_primary"
            fontSize="1rem"
          >
            {`MVR: ${
              contact.violationCount > 0
                ? contact.violationCount === 1
                  ? contact.violationCount + " Incident "
                  : contact.violationCount + " Incidents "
                : "Clear "
            }`}
          </CustomText>
          <IconImage
            src={contact.violationCount > 0 ? udrRedIcon : udrGreenIcon}
            size={20}
          />
        </div>
      )}
    </AutoIndicatorsWrapper>
  );
};
