import "../../assets/css/ReshopModal.css";

import { CloseButton } from "react-bootstrap";
import CustomButton from "../styled/buttons/CustomButton";
import { CustomText } from "../styled/typography";
import Modal from "react-bootstrap/Modal";

const ReshopModal = ({ show, setShow, content }) => {
  return (
    <Modal show={show} centered size="lg" className="reshopModal">
      <Modal.Header>
        <CloseButton variant="white" onClick={() => setShow(false)} />
      </Modal.Header>
      <Modal.Body className="text-center">
        <CustomText
          color="white"
          fontSize="medium"
          fontFamily="font_primary"
          fontWeight={500}
        >
          {content}
        </CustomText>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          title="Ok"
          small
          reduced
          handleClick={() => setShow(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ReshopModal;
