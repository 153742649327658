import { ADD_TOKEN } from "../constants";
import produce from "immer";
const initialState = "";

export const tokenReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_TOKEN:
      draft = action.payload;
      return draft;
    default:
      return draft;
  }
}, initialState);
