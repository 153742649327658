import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { ReactComponent as AvaIcon } from "../assets/images/components/footer/Ava.svg";
import { ReactComponent as FeedbackIcon } from "../assets/images/components/footer/Feedback.svg";
import { ReactComponent as NotesIcon } from "../assets/images/components/footer/Notes.svg";
import { ReactComponent as MarketShareIcon } from "../assets/images/components/footer/CarrierMarketShare.svg";
import Notes from "./Notes";
import { ActiveTab } from "./types";
import AgentFeedback from "./AgentFeedback/AgentFeedback";
import Ava from "./ChatBot/ChatBot";
import MarketShare from "./MarketShare/MarketShare";
import { MarketShareType } from "./MarketShare/types";
import { isResponsesPage } from "../utilsMisc";

type StyledFooterButtonProps = {
  $isOpen?: boolean;
};
const StyledFooterButton = styled.button<StyledFooterButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  color: ${({ $isOpen }) => ($isOpen ? "white" : "#2d2c2a")};
  background-color: ${({ $isOpen }) => ($isOpen ? "#006b67" : "transparent")};
  font-family: "Rethink Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  gap: 13px;
  padding: 0 10px;

  &:focus,
  &:active,
  &:hover {
    color: ${({ $isOpen }) => ($isOpen ? "white" : "#006b67")};
  }

  & svg {
    color: ${({ $isOpen }) => ($isOpen ? "white" : "#006b67")};
  }
`;

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  $isOpen: boolean;
};

export const MarketShareButton = (props: ButtonProps) => {
  return (
    <StyledFooterButton {...props}>
      <MarketShareIcon />
      Market Share
    </StyledFooterButton>
  );
};

export const NotesButton = (props: ButtonProps) => {
  return (
    <StyledFooterButton {...props}>
      <NotesIcon />
      Notes
    </StyledFooterButton>
  );
};

export const FeedbackButton = (props: ButtonProps) => {
  return (
    <StyledFooterButton {...props}>
      <FeedbackIcon />
      Feedback
    </StyledFooterButton>
  );
};

export const AvaButton = (props: ButtonProps) => {
  return (
    <StyledFooterButton {...props}>
      <AvaIcon />
      Ava Chat
    </StyledFooterButton>
  );
};

const StyledPopup = styled.div`
  position: absolute;
  bottom: 31px;
`;

type PopupProps = {
  activeTab: ActiveTab;
  close: () => void;
  crmData?: MarketShareType;
};

export const Popup = ({ activeTab, close, crmData = {} }: PopupProps) => {
  return (
    <StyledPopup>
      <MarketShare
        isActive={activeTab === "marketShare" && isResponsesPage()}
        close={close}
        initialChartData={crmData}
      />
      <Notes isActive={activeTab === "notes"} close={close} />
      <AgentFeedback isActive={activeTab === "feedback"} close={close} />
      <Ava isActive={activeTab === "ava"} close={close} />
    </StyledPopup>
  );
};
