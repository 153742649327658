import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getGenderOptions, setFieldsEdited } from "../utils";

import CustomInput from "./styled/inputs/CustomInput";
import CustomSelect from "./styled/inputs/CustomSelect";
import DatePicker from "./DatePicker";
import FormContainer from "./styled/forms/FormContainer";
import Heading from "./Heading";
import { languagesList } from "../constants";
import styled from "styled-components";
import { useSelector } from "react-redux";

const ContactInput = styled.div`
  display: flex;
  margin-top: 0.5rem;
  justify-content: center;
  padding: 0 0.5rem;
  & .info-row {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    gap: 1.5rem;
    & > * {
      flex: 1;
    }
  }
`;

const ContactInfo = ({
  spouse,
  contactData,
  maritalStatus = undefined,
  setContactData,
  errors,
}) => {
  const contact = useSelector((store) => store.contact);
  const [isSSNHidden, setIsSSNHidden] = useState(false);

  const handleChange = (event) => {
    setContactData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      ...(event.target.name === "ssn"
        ? {
            ssn: isSSNHidden
              ? computeSSN(event.target.value)
              : event.target.value,
          }
        : {}),
      // TODO: this is a band-aid. use formik to handle values and errors
      ...(event.target.name === "email" && {
        email: event.target.value || undefined,
      }),
    }));
    setFieldsEdited();
  };

  const onBlurSSN = () => {
    if (contact.ssn && !contactData.ssn) {
      setIsSSNHidden(true);
      setContactData({
        ...contactData,
        ssn: spouse ? contact?.spouseSsn : contact?.ssn,
      });
    }
  };

  const onFocusSSN = () => {
    contact.ssn && setIsSSNHidden(false);
    setContactData({
      ...contactData,
      ssn: contact.ssn && isSSNHidden ? "" : contactData.ssn,
    });
  };

  const getMaskedSSNValue = (ssn) => {
    const lastFourDigits = ssn.slice(-4);
    const maskedSSN = ssn.slice(0, -4).replace(/\d/g, "*") + lastFourDigits;
    return maskedSSN;
  };

  const computeSSN = (value) => {
    if (value.length < contactData.ssn.length)
      return contactData.ssn.slice(0, value.length);
    else
      return (
        (contactData.ssn ?? "") + value.slice(contactData.ssn?.length ?? 0)
      );
  };

  useEffect(() => {
    if (Object.keys(contact).length) {
      if (spouse) {
        setContactData({
          firstName: contact.spousefirstName,
          lastName: contact.spouselastName,
          dob: contact.spouseDob ?? "",
          gender: contact.spouseGender ?? "",
          suffix: contact.spouseSuffix,
          ssn: contact.spouseSsn ? contact.spouseSsn : "",
          language: contact.spouseLanguage || "English",
          email: contact.spouseEmail,
          phone: contact.spousePhone,
        });
        contact.spouseSsn && setIsSSNHidden(true);
      } else {
        setContactData({
          firstName: contact.firstName ?? "",
          lastName: contact.lastName ?? "",
          dob: contact.dob ?? "",
          maritalStatus: contact.maritalStatus,
          gender: contact.gender ?? "",
          suffix: contact.suffix,
          ssn: contact.ssn ? contact.ssn : "",
          language: contact.language || "English",
          email: contact.email,
          phone: contact.phone,
        });
        contact.ssn && setIsSSNHidden(true);
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Heading
        text={spouse ? "Spouse Information" : "Enter your client's information"}
        bold
        className="mt-5"
      />
      <ContactInput>
        <FormContainer>
          <Row className="my-3">
            <Col xs={12} sm={5} className="pt-2">
              <CustomInput
                label="First Name"
                className="form-control"
                id={spouse ? "spousefirstName" : "firstName"}
                type="text"
                name="firstName"
                aria-label={spouse ? "Spouse First Name" : "First Name"}
                placeholder={!spouse ? "John" : "Jane"}
                value={contactData.firstName ?? ""}
                handleChange={handleChange}
                error={errors.firstNameError ?? ""}
              />
            </Col>
            <Col xs={12} sm={5} className="pt-4 pt-sm-2">
              <CustomInput
                label="Last Name"
                className="form-control"
                id={spouse ? "spouselastName" : "lastName"}
                type="text"
                name="lastName"
                aria-label={spouse ? "Spouse Last Name" : "Last Name"}
                placeholder="Smith"
                value={contactData.lastName ?? ""}
                handleChange={handleChange}
                error={errors.lastNameError ?? ""}
              />
            </Col>
            <Col xs={6} sm={2} className="pt-4 pt-sm-2">
              <CustomInput
                label="Suffix"
                className="form-control"
                id={spouse ? "spousesuffix" : "suffix"}
                type="text"
                name="suffix"
                aria-label={spouse ? "Spouse Suffix" : "Suffix"}
                placeholder="Jr"
                value={contactData.suffix ?? ""}
                handleChange={handleChange}
                error={errors.suffixError ?? ""}
              />
            </Col>
          </Row>
          <div className="info-row">
            <DatePicker
              id={spouse ? "spouseDob" : "dob"}
              name="dob"
              label="Date of Birth"
              width="100%"
              error={errors.dobError}
              value={contactData.dob}
              onChange={handleChange}
              data-private
            />
            <CustomSelect
              label="Gender"
              className="form-control"
              id={spouse ? "spouseGender" : "gender"}
              type="text"
              name="gender"
              isCentered={false}
              aria-label={spouse ? "Spouse Gender" : "Gender"}
              value={contactData.gender ?? ""}
              handleChange={handleChange}
              error={errors.genderError ?? ""}
            >
              {getGenderOptions().map(({ label, value }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </CustomSelect>
            <CustomSelect
              disabled={spouse}
              label="Marital Status"
              className="form-control"
              id={spouse ? "spouseMaritalStatus" : "maritalStatus"}
              type="text"
              name="maritalStatus"
              aria-label="Marital Status"
              isCentered={false}
              value={maritalStatus ?? contactData.maritalStatus ?? ""}
              handleChange={handleChange}
              error={errors.maritalStatusError ?? ""}
            >
              <option disabled aria-disabled value="">
                -Please Choose-
              </option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Widowed">Widowed</option>
              <option value="Domestic Partner">Domestic Partner</option>
            </CustomSelect>
          </div>

          <Row className="mb-4">
            <Col xs={12} sm={6} className="pt-4 pt-sm-2">
              <CustomInput
                label={"Mobile Number" + (spouse ? " (Optional)" : "")}
                className="form-control py-4 px-3"
                type="phone"
                name="phone"
                id={spouse ? "spousePhone" : "phone"}
                aria-label="Phone Number"
                placeholder="(555) 123-4567"
                value={contactData.phone ?? ""}
                handleChange={handleChange}
                error={errors.phoneError ?? ""}
                masked
                mask="(999) 999-9999"
                maskPlaceholder=""
                inputMode="numeric"
              />
            </Col>
            <Col xs={12} sm={6} className="pt-4 pt-sm-2">
              <CustomInput
                label="Email Address (Optional)"
                className="form-control py-4  px-3"
                type="text"
                name="email"
                id={spouse ? "spouseEmail" : "email"}
                placeholder="username@email.com"
                aria-label="Email Address"
                value={contactData.email ?? ""}
                handleChange={handleChange}
                error={errors.emailError ?? ""}
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs={12} sm={6} className="pt-4 pt-sm-2">
              <CustomInput
                data-private
                label="Social Security (Optional)"
                className="form-control"
                id={spouse ? "spouseSsn" : "ssn"}
                type="text"
                name="ssn"
                aria-label={spouse ? "Spouse SSN" : "SSN"}
                placeholder="123-12-1234"
                value={
                  isSSNHidden
                    ? getMaskedSSNValue(contactData.ssn ?? "")
                    : contactData.ssn ?? ""
                }
                handleChange={handleChange}
                onFocus={onFocusSSN}
                onBlur={onBlurSSN}
                masked
                mask="###-##-####"
                error={errors.ssnError ?? ""}
              />
            </Col>
            <Col xs={12} sm={6} className="pt-4 pt-sm-2">
              <CustomSelect
                label="Language"
                className="form-control"
                id={spouse ? "spouseLanguage" : "language"}
                type="text"
                name="language"
                aria-label={spouse ? "Spouse Language" : "Language"}
                value={contactData.language ?? ""}
                handleChange={handleChange}
                // error={errors.maritalStatusError ?? ""}
              >
                <option disabled aria-disabled value="">
                  -Please Choose-
                </option>
                {languagesList.map((language, i) => (
                  <option value={language} key={i}>
                    {language}
                  </option>
                ))}
              </CustomSelect>
            </Col>
          </Row>
        </FormContainer>
      </ContactInput>
    </>
  );
};

export default ContactInfo;
