import { ImgButton } from "./styled/buttons/misc";
import { PAST_SESSIONS_LIMIT } from "../constants";
import { getSessionsByAgentId } from "../services/session";
import nextIconSvg from "../assets/images/main/next-icon.svg";
import parseSession from "../utils/parseSession";
import styled from "styled-components";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

const SessionsTable = styled.table`
  width: 90%;
  th {
    padding: 1rem;
  }
  td {
    padding: 1rem;
  }
`;

const BorderedRow = styled.tr`
  border-bottom: ${(props) => (props.borderBottom ? "1px solid #A9EC7E" : "")};
`;

const SessionWrapper = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const PastSessions = ({ pastSessions, setPastSessions }) => {
  const [limit, setLimit] = useState(PAST_SESSIONS_LIMIT);

  const contact = useSelector((store) => store.contact);
  const agent = useSelector((store) => store.agent);
  const agentId = contact?.user ?? agent.sfid;

  const loadSessions = async () => {
    if (!agentId) return;
    const { sessions } = (await getSessionsByAgentId(agentId, limit)).data;
    const pastSessions = sessions
      ?.filter(
        ({ contact_data, property_data }) => contact_data && property_data
      )
      .map(parseSession);
    setPastSessions(pastSessions);
  };

  useEffect(() => {
    loadSessions();
    // eslint-disable-next-line
  }, [limit]);

  const redirectToResponses = ({ sessionUuid }) => {
    window.open(`/login?rs=${sessionUuid}`);
  };

  const allSessions = (pastSessions || []).slice(0, limit);

  return !allSessions ? null : (
    <>
      <SessionWrapper className="mt-5">
        <SessionsTable>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {allSessions.map(
              (
                { id, uuid, line_of_business, contact_data, property_data },
                index
              ) => {
                const clientName = `${contact_data.firstName} ${contact_data.lastName}`;
                const clientAddress = [
                  property_data.street_number,
                  property_data.locality,
                  property_data.administrative_area_level_1,
                ]
                  .filter(Boolean)
                  .join(", ");
                return (
                  <BorderedRow
                    key={id}
                    borderBottom={index !== pastSessions.length - 1}
                    borderTop={index !== 0}
                  >
                    <td className="name-row">{clientName}</td>
                    <td>{clientAddress}</td>
                    <td>
                      <ImgButton
                        aria-label="Go Back"
                        title="Go to Quote"
                        onClick={() =>
                          redirectToResponses({
                            sessionUuid: uuid,
                            line_of_business,
                          })
                        }
                      >
                        <img src={nextIconSvg} alt="back button" />
                      </ImgButton>
                    </td>
                  </BorderedRow>
                );
              }
            )}
          </tbody>
        </SessionsTable>
      </SessionWrapper>
      <div
        className="mt-3 text-center text-decoration-underline"
        onClick={() => {
          setLimit((previousValue) => previousValue + 5);
        }}
        role="button"
      >
        Load More...
      </div>
    </>
  );
};

export default PastSessions;
