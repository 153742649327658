import styled, { keyframes } from "styled-components";
import minimizeIcon from "../../assets/images/chatIcons/ava_minimize.svg";
import closeIcon from "../../assets/images/chatIcons/close.svg";
import sendIcon from "../../assets/images/chatIcons/send.svg";

export const ChatContainer = styled.div`
  border-radius: 15px;
  border: none;
  width: 350px;
  max-width: 35vw;
  box-shadow: 0 0 5px #c3c4c2;
  background: white;
  z-index: 999;
  display: ${({ $display }) => $display};
  @media (max-width: 768px) {
    width: 95vw;
  }

  & .chat-icon {
    height: 20px;
    width: 35px;
  }
`;

export const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const CloseButton = (props) => (
  <StyledButton {...props} aria-label={props["aria-label"] ?? "close"}>
    <img src={closeIcon} width="100%" height="100%" alt="close" />
  </StyledButton>
);

export const MinimizeButton = (props) => (
  <StyledButton
    type="button"
    {...props}
    aria-label={props["aria-label"] ?? "minimize"}
  >
    <img src={minimizeIcon} width="100%" height="100%" alt="close" />
  </StyledButton>
);

export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: white;
  border-radius: 10px 10px 0 0;
  height: 2.5rem;
  background: #006b67;

  & .header-name {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  & .close-button {
    background: none;
    border: none;
    padding: 0;
    width: 28px;
    height: 28px;
  }
`;

export const ScrollableContainer = styled.div`
  padding: 1.5rem;
  height: 350px;
  max-height: 90vh;
  overflow-y: auto;
  overscroll-behavior-y: contain;

  &::-webkit-scrollbar {
    width: 7px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    width: 10px;
    background-color: gray;
  }
`;

const JumpingDotsKeyFrames = keyframes`
  0%,
  100% {
    transform: translateY(1px);
  }
  50% {
    transform: translateY(-5px);
  }
`;

export const JumpingDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  width: fit-content;
  background-color: #eaf4ef;

  & span {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin: 0 2px;
    background-color: gray;
    border-radius: 50%;
    animation: ${JumpingDotsKeyFrames} 1.1s infinite ease-in-out;
  }

  & span:nth-child(2) {
    animation-delay: 0.2s;
  }

  & span:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

export const JumpingDots = () => (
  <JumpingDotsContainer>
    <span></span>
    <span></span>
    <span></span>
  </JumpingDotsContainer>
);

export const FeedBackContainer = styled.div`
  & .modal-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-radius: 10px;
  }

  & .modal-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: none;
    box-shadow: 0 0 1px;
  }

  & .top-bar {
    display: flex;
    align-items: center;
    justify-content: end;
    border-radius: 10px 10px 0 0;
    height: 45px;
    background: ${(props) => props.theme.green3};
  }
`;

export const ChatWindowContainer = styled.div`
  z-index: 1000;

  .chatbot-sub-heading {
    font-size: 12px;
    color: #358640;
  }
`;

export const AssistantBubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  max-width: 90%;
`;

export const AssistantBubble = styled.div`
  padding: 1rem;
  border-radius: 10px;
  background-color: #eaf4ef;
  color: ${(props) => props.theme.dark_green};
`;

export const ErrorBubble = styled.div`
  padding: 1rem;
  border-radius: 10px;
  background-color: salmon;
  color: white;
`;

export const ErrorText = styled.div`
  color: salmon;
`;

export const AssistantFeedback = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin-top: 0.5rem;
  align-self: end;
  font-size: 11px;
  text-decoration: underline;
  color: #358640;
`;

export const HumanBubble = styled.div`
  border-radius: 10px;
  background-color: #f4f7fa;
  max-width: 75%;
  color: #6d6e71;
  width: fit-content;
  padding: 1rem;
  margin: 1rem 1rem 1.5rem auto;
`;

export const TextEntryForm = styled.form`
  padding: 1rem;
  background: #f7f7f7;
`;

export const TextEntryStyled = styled.div`
  position: relative;
  height: 3rem;
  margin-bottom: 0.5rem;

  & textarea {
    width: 100%;
    height: 3rem;
    line-height: 25px;
    padding: 8px 12px;
    padding-right: 50px;
    border: 1px solid #0000001a;
    border-radius: 4px;
    box-shadow: inset;
    background-color: #ffffff;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333333;
    outline: none;
    resize: none;
    box-sizing: border-box;
    overflow: auto;
    transition: all 0.3s ease;
    font-size: 1rem;
    overflow: hidden;
  }

  & .submit-button {
    width: 27px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
  }
`;

export const TextEntry = (props) => (
  <TextEntryStyled>
    <textarea {...props} />
    <input type="image" alt="submit" src={sendIcon} className="submit-button" />
  </TextEntryStyled>
);

export const LegalDisClosure = styled.div`
  padding: 0 0.5rem;
  font-size: 0.7rem;
`;

export const FeedbackForm = styled.form`
  padding: 1rem;

  & .buttons {
    display: flex;
    margin: 1.5rem 0;
  }
`;

export const FeedbackSubmitButton = styled.button`
  background: ${(props) => (props.selected ? props.theme.green : "white")};
  border-radius: 25px;
  height: 45px;
  width: 150px;
  margin-right: 1rem;
  border: 1px solid #cbcbcb;
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => (props.selected ? "white" : props.theme.green)};
  display: flex;
  justify-content: space-around;
  align-items: center;

  & img {
    width: 2rem;
  }
`;
