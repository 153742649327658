const colors = {
  white: "#FFFFFF",
  dark_green: "#2D424D",
  green: "#358640",
  green2: "#51A520",
  green3: "#6CB545",
  light_green: "#51A520",
  pale_green: "#EAF4E3",
  lime: "#AAED7E",
  gray: "#6D6E71",
  red: "#DC3545",
  light_gray_alt: "#6d6e71",
  transparent: "transparent",
  disabled: "#999a9d",
  green_new: "#017429",
  placeholder_bg: "#006b67",
  placeholder_text: "#d8f3f2",
  carrier_name_text: "#2d2c2a",
  checkout_button_text: "#edfdd6",
  checkout_button_bg: "#157f3a",
  quote_info_text: "#2D2C2A",
  quote_footer_text: "#626262",
  quote_border: "#e6e6e6",
};

export const theme = {
  ...colors,
  primary: colors.dark_green,
  primary_accent: colors.lime,
  secondary: colors.green,
  tertiary: colors.light_green,
  tertiary_accent: colors.green3,
  form_background: colors.pale_green,
  font_secondary:
    "'Heebo', -apple-system,BlinkMacSystemFont,Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif", // these are imported into bootstrap as well
  font_primary:
    "'Montserrat', -apple-system, BlinkMacSystemFont, Roboto,Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
  fontSize: {
    xsmall: "11px",
    small: "15px",
    medium: "18px",
    large: "24px",
    xlarge: "36px",
  },
  disabled: colors.disabled,
};

export const BUTTON_BG_COLOR = {
  Next: {
    unclicked: "#6CB545",
    clicked: "#358640",
  },
  Bridge: {
    unclicked: "#A7C8E6",
    clicked: "#2D424D",
  },
  Update: {
    unclicked: "#358640",
    clicked: "#358640",
  },
  default: {
    unclicked: "#A7C8E6",
    clicked: "#2D424D",
  },
  Refer: {
    unclicked: "#6CB545",
    clicked: "#358640",
  },
  View: {
    unclicked: "#2D424D",
    clicked: "#2D424D",
  },
  Resume: {
    unclicked: "#358640",
    clicked: "#358640",
  },
};

export const BUTTON_TEXT_COLOR = {
  Next: {
    unclicked: "white",
    clicked: "white",
  },
  Bridge: {
    unclicked: "black",
    clicked: "white",
  },
  Update: {
    unclicked: "white",
    clicked: "white",
  },
  default: {
    unclicked: "black",
    clicked: "white",
  },
  Refer: {
    unclicked: "white",
    clicked: "white",
  },
  View: {
    unclicked: "white",
    clicked: "white",
  },
  Resume: {
    unclicked: "white",
    clicked: "white",
  },
};
