import * as yup from "yup";

import {
  AssistantBubble,
  AssistantBubbleContainer,
  AssistantFeedback,
  ChatContainer,
  ChatHeader,
  ErrorBubble,
  HumanBubble,
  JumpingDots,
  LegalDisClosure,
  MinimizeButton,
  ScrollableContainer,
  TextEntryForm,
  TextEntryStyled,
} from "./styled";
import {
  EMPTY_INPUT_MESSAGE,
  ERROR_MESSAGE,
  FEEDBACK_PROMPT,
  INPUT_MAX_CHARS,
  INPUT_TOO_LONG_MESSAGE,
  INTRO_MESSAGE,
  LEGAL_DISCLOSURE,
  TEXT_PLACEHOLDER,
  TIMEOUT_MESSAGE,
} from "./constants";
import { useEffect, useRef, useState } from "react";
import {
  generateResponse,
  getMessagesInConversation,
} from "../../services/chat";
import { useDispatch, useSelector } from "react-redux";

import FeedbackModal from "./FeedbackModal";
import { addContact } from "../../redux/actions/contact";
import { ReactComponent as Ava } from "../../assets/images/components/footer/Ava.svg";
import { handleUpdateSession } from "../../services/utils";
import { nanoid as randomId } from "nanoid";
import sendIcon from "../../assets/images/chatIcons/send.svg";
import { useFormik } from "formik";

const ChatBox = ({ isActive, onClose: handleClose }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackMessageId, setFeedbackMessageId] = useState(null);
  const contact = useSelector((store) => store.contact);
  const session = useSelector((store) => store.session);
  const scrollableContainerRef = useRef();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    try {
      const values = formik.values;
      formik.setFieldValue("prompt", "");
      setErrorMessage("");

      setMessages((previousState) => [
        ...previousState,
        { role: "user", content: values.prompt, id: randomId() },
      ]);
      setIsLoading(true);
      const chatGPTResponse = await generateResponse({
        conversationId: contact.conversationId,
        message: values.prompt,
      });
      if (!contact.conversationId) {
        await handleUpdateSession({
          uuid: session.uuid,
          contact_data: JSON.stringify({
            ...contact,
            conversationId: chatGPTResponse.data.conversationId,
          }),
        });
        dispatch(
          addContact({
            conversationId: chatGPTResponse.data.conversationId,
          })
        );
      }
      setMessages((previousState) => [
        ...previousState,
        chatGPTResponse.data.message,
      ]);
    } catch (error) {
      console.log(error.response);
      if (error.response?.status === 408) {
        setErrorMessage(TIMEOUT_MESSAGE);
      } else if (error.response?.status?.toString().startsWith(4)) {
        setErrorMessage(error.response.data.message);
      } else {
        setMessages((previousState) => [...previousState, ERROR_MESSAGE]);
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      prompt: "",
    },
    validationSchema: yup.object({
      prompt: yup.string().required(),
    }),
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const handleChange = (event) => {
    const { value } = event.target;
    if (value.length > INPUT_MAX_CHARS) {
      setErrorMessage(INPUT_TOO_LONG_MESSAGE);
      formik.setFieldValue("prompt", value.substring(0, INPUT_MAX_CHARS));
    } else {
      setErrorMessage("");
      formik.handleChange(event);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      scrollToBottom();
      formik.submitForm();
    } else if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      formik.setFieldValue("prompt", formik.values.prompt + "\n");
    }
  };

  const isEmptyErrorDisplayed = !!formik.errors.prompt && !formik.values.prompt;

  useEffect(() => {
    if (isEmptyErrorDisplayed) {
      setErrorMessage(EMPTY_INPUT_MESSAGE);
    } else {
      setErrorMessage("");
    }
  }, [isEmptyErrorDisplayed]);

  useEffect(() => {
    const fetchMessages = async () => {
      let messages = await getMessagesInConversation(contact.conversationId);
      messages = JSON.parse(messages.data);
      setMessages([INTRO_MESSAGE, ...messages]);
    };

    contact.conversationId && fetchMessages();
  }, [contact.conversationId]);

  const scrollToBottom = () => {
    if (!scrollableContainerRef.current) return;
    scrollableContainerRef.current.scrollTop =
      scrollableContainerRef.current.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [isLoading]);

  // display intro message on initial click
  useEffect(() => {
    if (!contact.conversationId && messages.length === 0) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setMessages((previousState) => [...previousState, INTRO_MESSAGE]);
        setIsLoading(false);
      }, 2500);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact.conversationId]);

  const display = isActive ? "block" : "none";

  return (
    <ChatContainer $display={display}>
      <FeedbackModal
        messageId={feedbackMessageId}
        setFeedbackMessageId={setFeedbackMessageId}
      />
      <ChatHeader>
        <div className="header-name">
          <Ava />
          Ava Chat
        </div>
        <MinimizeButton
          width="28px"
          height="28px"
          onClick={handleClose}
          aria-label="minimize chat box"
        />
      </ChatHeader>

      <ScrollableContainer ref={scrollableContainerRef}>
        {messages.map((message) => {
          if (message.role === "assistant") {
            return (
              <AssistantBubbleContainer key={message.id}>
                <AssistantBubble>{message.content}</AssistantBubble>
                {!message.hideReport && (
                  <AssistantFeedback
                    onClick={() => {
                      setFeedbackMessageId(message.id);
                    }}
                  >
                    {FEEDBACK_PROMPT}
                  </AssistantFeedback>
                )}
              </AssistantBubbleContainer>
            );
          } else {
            return (
              <HumanBubble key={message.id}>{message.content}</HumanBubble>
            );
          }
        })}
        {isLoading && <JumpingDots />}
        {!!errorMessage && <ErrorBubble>{errorMessage}</ErrorBubble>}
      </ScrollableContainer>

      <TextEntryForm onSubmit={formik.handleSubmit}>
        <TextEntryStyled>
          <textarea
            id="prompt"
            name="prompt"
            required
            onChange={handleChange}
            value={formik.values.prompt}
            placeholder={TEXT_PLACEHOLDER}
            onKeyDown={handleKeyDown}
          />
          <input
            type="image"
            alt="submit"
            disabled={isLoading}
            src={sendIcon}
            className="submit-button"
          />
        </TextEntryStyled>
        <LegalDisClosure>{LEGAL_DISCLOSURE}</LegalDisClosure>
      </TextEntryForm>
    </ChatContainer>
  );
};
export default ChatBox;
