import axios from "axios";
import { MarketShareApiInput } from "../components/MarketShare/types";
import { removeCountyText } from "../utils/strings";

// To get Carrier Market Share Data By State
export const getCarrierMarketShare = (apiInput: MarketShareApiInput) => {
  const {
    state,
    line_of_business,
    isForThirtyDays,
    isErroredPolicies,
    county = "",
  } = apiInput;
  return axios.get(`/api/marketshare`, {
    params: {
      state,
      line_of_business,
      isForThirtyDays,
      isErroredPolicies,
      ...(county ? { county: removeCountyText(county) } : {}),
    },
  });
};
