import styled from "styled-components";

export const StyledMarketShareTool = styled.div<{ height: string }>`
  font-family: Rethink sans Variable;
  box-shadow: -6px 0 15.7px 0 rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px #d8d7d4;
  min-width: 411px;
  & header {
    & .totalPoliciesFilter {
      & .dropdown-menu {
        padding: 0;
        min-width: 220px;
        font-size: 12px;
        transform: translate(-30px, 38px) !important;
        width: 238px !important;
        border: none !important;
        gap: 0;

        button:nth-child(even) {
          border-radius: 0 0 5px 5px;
        }

        & button {
          background: transparent;
          border: none;
          text-align: left;
          font-size: 12px;
          font-weight: 500;
          color: #2d2c2a;
          padding: 6px 15px 6px 15px;
          border: solid 1px #d5d5d5;
          border-top: none;
        }
      }
    }

    & .dropdown-toggle,
    & .dropdown-toggle:focus,
    & .dropdown-toggle:active,
    & .dropdown-toggle:hover {
      background-color: #006b67 !important;
      border: none !important;
      box-shadow: none !important;
      color: #fff !important;
      font-weight: 600;
      font-size: 16px;
      padding: 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;

      ::after {
        display: none;
      }
    }
  }

  & .body {
    font-size: 14px;
    font-weight: normal;
    color: #222;

    & .filter {
      & button {
        background: transparent;
        border: none;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      & > [class*="col-"] {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      & .location {
        padding: 10px 0 6px 20px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        font-weight: 500;
        font-size: 16px;
      }

      & .term {
        padding-right: 20px;
        align-items: flex-end;
        & .dropdown-toggle,
        & .dropdown-toggle:focus,
        & .dropdown-toggle:active,
        & .dropdown-toggle:hover {
          border: solid 1px #094d00 !important;
          font-size: 13px;
        }

        & .dropdown-menu {
          min-width: 100px;
          padding: 5px;
          font-size: 13px;
          border-radius: 8px;
          & button {
            font-weight: 500;
            color: #222222;
            border-color: #d6d6d6;
          }
        }
      }
    }

    & .administrativeLevel {
      border-bottom: solid 1px #d8d7d4;

      & button {
        height: 41px;
        width: 100%;
        color: #222222;
      }
      & .active {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 3px;
          background-color: #017429;
        }

        & button {
          font-weight: 500;
        }
      }
    }

    & .chart {
      margin: 10px 20px 0 20px;
      min-height: 158px;

      & .chartLabel {
        font-size: 15px;
        font-weight: 600;
        display: flex;
        gap: 5px;
      }

      & .graph {
        padding: 10px 0 10px 0;
      }
    }
  }

  & .footer {
    margin: 10px 20px;
    display: flex;
    justify-content: flex-end;

    & .salesForceCMS {
      font-family: "Rethink Sans Variable";
      font-size: 12px;
      font-weight: 500;
      color: #6d6d6d;
    }
  }
`;
