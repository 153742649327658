import raterTitleLogo from "../../assets/images/main/rater-title-logo.jpg";
import styled from "styled-components";

export default function TitleLogo() {
  return (
    <TitleLogoImg src={raterTitleLogo} alt="The many factors of insurance." />
  );
}

const TitleLogoImg = styled.img`
  max-height: 800px;
  max-width: 750px;
  width: 560px;
  height: 420px;
`;
