import { FieldArray, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  deleteVehicleById,
  saveVehicleUsage,
  saveVehiclesInPg,
} from "../services/vehicles";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../components/styled/buttons/CustomButton";
import { DEFAULT_BODILY_INJURY_MASSACHUSETTS } from "../constants";
import Heading from "../components/Heading";
import LoadingDots from "../components/LoadingDots";
import { VehicleInputs } from "../components/drivers-vehicles";
import { addCoverages } from "../redux/actions/coverages";
import { addVehicles } from "../redux/actions/vehicle";
import moment from "moment";
import { vehicleValidationSchema } from "../utils/validation";
import { createErrorLogger } from "../utils/errors";
import { mapVehiclesToInput } from "../utils";

const logError = createErrorLogger({ file: "Vehicles.js" });

const Vehicles = ({ handleNext }) => {
  const property = useSelector((store) => store.property);
  const contact = useSelector((store) => store.contact);
  const drivers = useSelector((store) => store.drivers);
  const vehicles = useSelector((store) => store.vehicles);
  const isFetching = useSelector((store) => store.isFetching);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const state = property.administrative_area_level_1;
  const isAddressInMassachusetts = state === "MA";
  useEffect(() => {
    if (isAddressInMassachusetts) {
      dispatch(
        addCoverages({ bodily_injury: DEFAULT_BODILY_INJURY_MASSACHUSETTS })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressInMassachusetts]);

  const dispatch = useDispatch();

  const vehiclesToDeleteRef = useRef(new Set());
  const defaultVehicle = {
    Vin: "",
    Year: "",
    Make: "",
    Model: "",
    comprehensive: 500,
    collision: 500,
    displayYearMakeModelEntry: false,
    towing: true,
    rental: true,
    // default purchase date to current date no matter what
    purchaseDate: moment().format("YYYY-MM-DD"),
    // true when in the selection
    // false/undefined when on the vehicles found section
    selected: true,
  };

  let initialVehicles = vehicles
    .filter(({ selected, isRemoved }) => selected && !isRemoved)
    .map(mapVehiclesToInput);
  initialVehicles = initialVehicles.length ? initialVehicles : [defaultVehicle];
  const vehiclesFound = vehicles
    .filter(({ selected, isRemoved }) => !selected && !isRemoved)
    .map(mapVehiclesToInput);
  const removedVecicles = vehicles
    .filter(({ selected, isRemoved }) => !selected && isRemoved)
    .map(mapVehiclesToInput);

  const handleSubmit = async (values) => {
    try {
      setSubmitButtonDisabled(true);
      const deleteVehiclePromises = [...vehiclesToDeleteRef.current].map(
        (vehicleId) => deleteVehicleById(vehicleId)
      );
      await Promise.all(deleteVehiclePromises);

      let vehiclesToSubmit = values.items;
      const savedVehicleIds = (
        await saveVehiclesInPg({
          autoQuoteId: contact.autoQuoteId,
          state: contact.administrative_area_level_1,
          accountId: contact.accountId,
          vehicles: vehiclesToSubmit,
        })
      ).data.vehicleData;
      vehiclesToSubmit = vehiclesToSubmit.map((vehicle, index) => ({
        ...vehicle,
        vehicleId: savedVehicleIds[index].vehicleId,
      }));

      const vehicleIds = vehiclesToSubmit.map(({ vehicleId }) => ({
        vehicleId,
      }));
      const driverIds = drivers
        .filter(({ driverId }) => driverId)
        .map(({ driverId }) => ({ driverId }));
      await saveVehicleUsage({
        quoteId: contact.autoQuoteId,
        vehicles: vehicleIds,
        drivers: driverIds,
      });
      const vehiclesSelected = vehiclesToSubmit.map((vehicle) => ({
        ...vehicle,
        isRemoved: false,
        selected: true,
      }));
      const vehiclesFound = values.itemsFound.map((vehicle) => ({
        ...vehicle,
        isRemoved: false,
        selected: false,
      }));
      const removedVehicles = values.removedItems.map((vehicle) => ({
        ...vehicle,
        isRemoved: true,
        selected: false,
      }));

      dispatch(
        addVehicles([...vehiclesSelected, ...vehiclesFound, ...removedVehicles])
      );
      handleNext();
    } catch (error) {
      logError({
        fn: "handleSubmit",
        message: "Something went wrong",
        error,
      });
    } finally {
      setSubmitButtonDisabled(false);
    }
  };

  return isFetching ? (
    <LoadingDots />
  ) : (
    <>
      <Heading text="Vehicles" bold className="mt-5" />
      <Formik
        initialValues={{
          items: initialVehicles,
          itemsFound: vehiclesFound,
          removedItems: removedVecicles,
        }}
        onSubmit={handleSubmit}
        validationSchema={vehicleValidationSchema}
      >
        {({ values }) => (
          <Form>
            <FieldArray name="items">
              {({ push, remove }) => {
                const removeVehicle = (index) => {
                  if (values.items[index].vehicleId) {
                    vehiclesToDeleteRef.current.add(
                      values.items[index].vehicleId
                    );
                  }
                  remove(index);
                };
                const addNewVehicle = (vehicle) => {
                  vehiclesToDeleteRef.current.delete(vehicle?.vehicleId);
                  push(vehicle ?? defaultVehicle);
                };
                return (
                  <VehicleInputs
                    addNewVehicle={addNewVehicle}
                    removeVehicle={removeVehicle}
                  />
                );
              }}
            </FieldArray>
            <div className="d-flex flex-column gap-2 align-items-center">
              <CustomButton
                type="submit"
                title="Continue"
                disabled={submitButtonDisabled}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Vehicles;
