import styled, { css } from "styled-components";

export const IconImg = styled.img`
  height: auto;
  width: ${(props) => props.width ?? "40px"};
  height: ${(props) => props.height ?? "30px"};
`;

export const LobIcon = styled(IconImg)`
  width: 40px;
`;

export const LobText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.2rem;
  font-weight: bold;
  font-size: 1.1rem;
  color: ${(props) => props.theme.green3};
`;

export const CarrierIconColumn = styled.div`
  min-width: 35px;
  min-height: 35px;
  padding-left: 0;
  display: flex;
  align-items: center;
`;

export const CarrierLogoImg = styled.img`
  height: 54px;
  width: 135px;
  object-fit: contain;
`;

export const StyledRateRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1rem;
  height: 90px;
  font-family: ${(props) => props.theme["font_secondary"]};
  & > .rate {
    text-align: right;
    color: ${(props) => props.theme.green2};
    & > .amount {
      font-size: 1.8rem;
      @media (max-width: 576px) {
        font-size: 1.5rem;
      }
    }
  }
  & > .name {
    justify-content: left;
  }
  & button.switch-primary {
    border: 1px solid ${(props) => props.theme.dark_green};
    color: ${(props) => props.theme.dark_green};
    font-size: 0.9rem;
    padding: 0.1rem 0.7rem;
    display: flex;
    align-items: center;
    gap: 1em;
    &::after {
      content: "❯";
      font-size: 1.2rem;
      transform: translateY(0.08em);
    }
    &:hover {
      transform: translateY(-1px);
    }
  }
`;

export const RateBox = styled.div`
  text-align: right;
  font-weight: bold;
  color: ${(props) => props.theme.green};
  & > .amount {
    font-size: 1.8rem;
    @media (max-width: 576px) {
      font-size: 1.5rem;
    }
  }
`;

export const ErrorMessage = styled.span`
  font-weight: bold;
  height: 25px;
  color: ${(props) => props.theme[[props.color]] ?? props.theme.red};
  text-align: right;
  font-size: clamp(14px, 1.5vw, 18px);
  font-family: ${(props) => props.theme["font_secondary"]};
  @media (max-width: 539px) {
    font-size: 0.8rem;
  }
`;

export const Name = styled.span`
  font-weight: 400;
  margin-left: 3rem;
  color: gray;
  font-size: 19px;
  font-family: ${(props) => props.theme["font_secondary"]};
  @media (max-width: 539px) {
    font-size: 0.8rem;
  }
`;

export const ButtonContainer = styled.span`
  border-left: 5px solid white;
`;

export const ButtonWrapper = styled.div`
  margin-left: 10px;
  ${(props) =>
    props.quoteToBind === "bundle" &&
    props.lob === "auto" &&
    css`
      visibility: hidden;
    `}
  ${(props) =>
    props.quoteToBind === "bundle" &&
    props.lob === "home" &&
    css`
      position: relative;
      top: 44px;
      /* cover the green line on the left */
      padding-left: 20px;
      margin-left: -10px;
      background-color: white;
    `}
`;

export const QuoteRow = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  padding: 5px 1rem;
  padding-left: 15px;
  gap: 1.5rem;
  position: relative;

  & .floodzone-icon {
    margin-left: 0.5rem;
  }
`;

export const QuotePlaceHolderText = styled.div`
  font-weight: bold;
  font-size: 1.6rem;
  color: ${(props) => props.theme.green3};
`;

export const SmartHomePackageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  text-align: start;

  & .promotion {
    text-align: center;
    font-style: italic;
  }

  & .price-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }

  & .disclaimer {
    text-align: center;
  }

  ${(props) =>
    props.isTooltip &&
    css`
      width: 250px;
    `}
  & .tooltipText {
    font-weight: 200;
    font-family: ${(props) => props.theme.font_secondary};
  }

  & sup {
    padding-top: 10px;
    font-weight: 200;
    font-size: x-small;
  }
`;
