import axios from "axios";

export const getAccidentsViolations = (quoteId) => {
  return axios.get(`/api/losses/${quoteId}`);
};

export const getIncidentsFromQuoteRequestId = (quoteRequestId) =>
  axios.get(`/api/incidents`, {
    params: { quoteRequestId },
  });

export const upsertLosses = (payload) => axios.post("/api/losses", payload);

export const deleteLoss = (lossId) => axios.delete(`/api/losses/${lossId}`);
