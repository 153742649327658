import { useFeatureFlag } from "../../services/featureFlag";
import { featureFlagsConst } from "../../constants";

/**
 * @param {{
 *   companyClientId?: string;
 *   lob?: string;
 * }} [input]
 */
export const useIsMonorepoQtiOverride = ({ companyClientId, lob } = {}) => {
  const featureFlags = useFeatureFlag();

  if (!companyClientId || lob !== "auto") {
    return false;
  }

  const carriersForMonorepoQti = featureFlags.getFlag(
    featureFlagsConst.useMonorepoQTI,
    []
  );

  const isMonorepoCarrier =
    carriersForMonorepoQti &&
    Array.isArray(carriersForMonorepoQti) &&
    carriersForMonorepoQti.includes(companyClientId);
  return isMonorepoCarrier;
};
