// @ts-check
import { produce } from "immer";
import curry from "lodash/curry";
import isEmpty from "lodash/isEmpty";
import remove from "lodash/remove";
import { PRIMARY, SPOUSE, auto, home } from "../../constants";
import { sortQuotes } from "../../utils";
import { createLogger } from "../../utils/logger";
// @ts-ignore
import * as Types from "./types.d.ts"; // eslint-disable-line no-unused-vars
// @ts-ignore
import * as AppTypes from "../../app.d.ts"; // eslint-disable-line no-unused-vars

/**
 * @param {string | number} type
 * @param {string | number} lob
 * @param {{ [x: string]: any }} initialValue
 * @param {any} updatedValue
 * @param {any} shouldAppendValues
 */
export const setStateHelper = (
  type,
  lob,
  initialValue,
  updatedValue,
  shouldAppendValues
) => {
  updatedValue = shouldAppendValues
    ? sortQuotes([...initialValue[type][lob], ...updatedValue])
    : updatedValue;

  return {
    ...initialValue,
    [type]: { ...initialValue[type], [lob]: updatedValue },
  };
};

/**
 * @param {string[]} spouseQuoteIds
 * @param {string} quoteRequestId
 */
export const _determineTypeBase = (spouseQuoteIds = [], quoteRequestId) => {
  if (spouseQuoteIds?.includes(quoteRequestId)) {
    return SPOUSE;
  }

  return PRIMARY;
};
export const _determineType = curry(_determineTypeBase, 2);

/**
 * @param {{
 *   determineType: (input: {
 *     quoteRequestId: string;
 *   }) => ReturnType<typeof _determineType>;
 *   reRunBundle: (type: Types.BundleType) => void;
 *   setHomeAutoQuoteResponses: React.Dispatch<
 *     React.SetStateAction<Types.HomeAutoGroupLists>
 *   >;
 *   setQuoteResponsesData: React.Dispatch<
 *     React.SetStateAction<Types.HomeAutoGroupLists>
 *   >;
 * }} config
 * @param {Pick<
 *   AppTypes.QuoteResponse,
 *   "heroku_id" | "line_of_business__c" | "quote_request_heroku_id"
 * >} quoteResponse
 */
export const _removeQuoteResponseBase = (
  {
    determineType,
    reRunBundle,
    setHomeAutoQuoteResponses,
    setQuoteResponsesData,
  },
  quoteResponse
) => {
  const type = determineType({
    quoteRequestId: quoteResponse.quote_request_heroku_id,
  });
  const lob = /auto/i.test(String(quoteResponse.line_of_business__c))
    ? auto
    : home;

  const removeInstances = _removeInstances({
    type,
    lob,
    quoteResponseId: quoteResponse.heroku_id,
  });

  setQuoteResponsesData(removeInstances);
  setHomeAutoQuoteResponses(removeInstances);
  reRunBundle(type);
};
export const _removeQuoteResponse = curry(_removeQuoteResponseBase);

/**
 * @param {{ type: Types.BundleType; lob: string; quoteResponseId: string }} config
 * @param {Types.HomeAutoGroupLists} previousState
 */
export const _removeInstancesBase = (
  { type, lob, quoteResponseId },
  previousState
) => {
  return produce(previousState, (draft) => {
    if (draft?.[type]?.[lob]) {
      remove(
        draft?.[type]?.[lob],
        (quoteResponse) => quoteResponse.heroku_id === quoteResponseId
      );
    }
  });
};
export const _removeInstances = curry(_removeInstancesBase);

/**
 * @param {{
 *   processNewQuoteResponses: (input: {
 *     quoteResponses: Types.ProcessableQuoteResponse[];
 *     quoteRequestId: string;
 *     shouldAppendValues: boolean;
 *   }) => void;
 *   handleRecommendedQuotes: () => void;
 * }} config
 * @param {{
 *   quoteRequestId?: string;
 *   quoteResponses?: Types.ProcessableQuoteResponse[];
 * }} input
 */
export const _insertNewQuoteResponsesBase = (
  { processNewQuoteResponses, handleRecommendedQuotes },
  { quoteResponses, quoteRequestId }
) => {
  const log = createLogger("pages/Quotes/utils.insertNewQuoteResponses");

  if (!quoteResponses || isEmpty(quoteResponses) || !quoteRequestId) {
    log.error({
      msg: "invalid input",
    });
    throw new Error(
      `Invalid input: ${JSON.stringify({ quoteResponses, quoteRequestId })}`
    );
  }

  processNewQuoteResponses({
    quoteResponses,
    quoteRequestId,
    shouldAppendValues: true,
  });
  handleRecommendedQuotes();
};
export const _insertNewQuoteResponses = curry(_insertNewQuoteResponsesBase);
