import moment from "moment/moment";
import store from "../redux/store";

export const getDaysDifference = (date1, date2) => {
  const msDifference = date2 - date1;
  const daysDifference = Math.floor(msDifference / 1000 / 60 / 60 / 24);
  return daysDifference;
};

export const getDaysAgo = (date) => {
  const now = new Date();
  return getDaysDifference(date, now);
};

export const formatIsoDate = (isoDate, locale = "en-US") =>
  new Intl.DateTimeFormat(locale, {
    month: "short",
    day: "2-digit",
    year: "numeric",
    localeMatcher: "best fit",
  }).format(new Date(isoDate));

// for details, check https://goosehead.atlassian.net/browse/HQ-1162
export const getLastSalesDate = () => {
  const {
    contact: { dob: contactDob },
    property,
    session,
  } = store.getState();

  const isNewPurchase = session.willpurchase;
  const effectiveDate = moment(property.effectiveDate);
  const lastSalesDate = getPropertyLastSalesDate(property);
  const { yearBuilt } = property;

  const newLastSalesDate = _getLastSalesDateMoment({
    lastSalesDate,
    isNewPurchase,
    effectiveDate,
    property,
    yearBuilt,
    contactDob,
  });

  if (newLastSalesDate.year() < yearBuilt) {
    return `${yearBuilt}-12-31`;
  }

  return newLastSalesDate.format("YYYY-MM-DD");
};

export const getDateFormat = (date) =>
  moment(date, "MM/DD/YYYY", true).isValid() ? "MM/DD/YYYY" : "YYYY-MM-DD";

export const getPropertyLastSalesDate = ({ lastSalesDate } = {}) => {
  if (!lastSalesDate) {
    return undefined;
  }

  // if last sales date is like "20230000", change it to Jan 31 2023
  if (lastSalesDate.endsWith("0000")) {
    return moment(lastSalesDate.slice(0, -4) + "0101").endOf("month");
  }

  // if last sales date is like "20230200", change it to Feb 28 2023
  if (lastSalesDate.endsWith("00")) {
    return moment(lastSalesDate.slice(0, -2) + "01").endOf("month");
  }

  return moment(lastSalesDate);
};

/**
 * @param {{
 *   lastSalesDate: moment.Moment;
 *   effectiveDate: moment.Moment;
 *   isNewPurchase?: boolean;
 *   property?: { lastSalesDate?: string };
 *   yearBuilt: string; // "2020"
 *   contactDob: string;
 * }} options
 */
export function _getLastSalesDateMoment({
  lastSalesDate,
  isNewPurchase,
  effectiveDate,
  property,
  yearBuilt,
  contactDob,
}) {
  if (isNewPurchase) {
    return effectiveDate;
  } else if (!property?.lastSalesDate) {
    const isBuiltWithin5Years =
      yearBuilt && yearBuilt >= moment()?.add(-5, "years").year();
    if (isBuiltWithin5Years) {
      return moment(`${yearBuilt}-12-31`);
    } else {
      return effectiveDate?.clone()?.add(-5, "years");
    }
  } else if (moment(contactDob).isAfter(lastSalesDate)) {
    const contactDobYear = moment(contactDob).format("YYYY");
    return moment(`${contactDobYear}-12-31`);
  } else {
    return lastSalesDate;
  }
}

/**
 * Validate a date string format to YYYY-MM-DD
 *
 * @param {string | null} date
 * @returns Boolean
 */
export const isValidEastAsianDate = (date) => {
  return moment(date, "YYYY-MM-DD", true).isValid();
};

/**
 * Get numbers of years from a date to the current date it returns an integer
 *
 * @param {string} date
 * @param {string} currentDate
 * @returns Integer
 */
export const getYearsFromCurrentDate = (
  date,
  currentDate = moment().format("YYYY-MM-DD").toString()
) => {
  if (!isValidEastAsianDate(date) || !isValidEastAsianDate(currentDate))
    return 0;

  return moment(currentDate).diff(date, "years");
};

/**
 * Get numbers of months from a date to the current date it returns an integer
 *
 * @param {string} date
 * @param {string} currentDate
 * @returns Integer
 */
export const getMonthsFromCurrentDate = (
  date,
  currentDate = moment().format("YYYY-MM-DD").toString()
) => {
  if (!isValidEastAsianDate(date) || !isValidEastAsianDate(currentDate))
    return 0;

  return moment(currentDate).diff(date, "months");
};
