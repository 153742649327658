// @ts-check
import { ToastContainer, toast } from "react-toastify";

export { toast };

export const AppToaster = () => (
  // @ts-ignore
  <ToastContainer position="bottom-left" />
);

/**
 * Why the import/export?
 *
 * Standardizes the api so that we could try swapping it in with other
 * libraries. Was useful when swapping back and forth between react-toastify and
 * react-hot-toast.
 *
 * Also allows us to set app defaults in one place.
 */
