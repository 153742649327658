import {
  FORM_ASSEMBLY_FEEDBACK,
  SESSION_RESPONSE_URL,
  isProduction,
  pageNames,
} from "../../constants";
import { useEffect, useState } from "react";

import { AgentFeedbackProps } from "./types/AgentFeedbackProps";
import { FeedbackContainer } from "./styled-components/FeedbackContainer";
import { ReactComponent as FeedbackIcon } from "../../assets/images/components/footer/Feedback.svg";
import { FormAssemblyMapping } from "./types/FormAssemblyMapping";
import { MinimizeButton } from "../ChatBot/styled";
import { StyledIframe } from "./styled-components/StyledIframe";
import { StyledPopupForm } from "./styled-components/StyledPopupForm";
import { getPageName } from "../../utils/strings";
import { useIsNewResponsePageDesign } from "../../utilsMisc";
import { useSelector } from "../../redux/hooks";

const formAssemblyMapping7: FormAssemblyMapping = {
  sessionId: "tfa_30",
  pageName: "tfa_31",
  state: "tfa_35",
  user: "tfa_32",
  account: "tfa_33",
  herokuId: "tfa_36",
  sessionResponseLink: "tfa_37",
  sessionRecordingLink: "tfa_38",
  case: "tfa_34",
};

const formAssemblyMapping8: FormAssemblyMapping = {
  sessionId: "tfa_27",
  pageName: "tfa_73",
  state: "tfa_72",
  user: "tfa_74",
  account: "tfa_71",
  herokuId: "tfa_134",
  sessionResponseLink: "tfa_135",
  sessionRecordingLink: "tfa_136",
  case: "tfa_76",
};

const AgentFeedback = ({ isActive, close }: AgentFeedbackProps) => {
  const session = useSelector((store) => store.session);
  const { page_stopped, line_of_business } = session;
  const contact = useSelector((store) => store.contact);
  const property = useSelector((store) => store.property);

  const [feedbackFormToShow, setFeedbackFormToShow] = useState(
    FORM_ASSEMBLY_FEEDBACK.FormBeforeResponses
  );

  const currentPage = getPageName(line_of_business, page_stopped);

  const isNewResponseDesign = useIsNewResponsePageDesign();
  const isNewResponsesPage =
    currentPage === pageNames[6] && isNewResponseDesign;

  const generateUrlParams = (jsonObject: Record<string, string>) => {
    let paramArray = [];
    for (let key in jsonObject) {
      paramArray.push(`${key}=${jsonObject[key]}`);
    }
    return `?${[paramArray.join("&")]}`;
  };

  const isBeforeQuotesPage =
    feedbackFormToShow === FORM_ASSEMBLY_FEEDBACK.FormBeforeResponses;

  const getFormAssemblyIframeUrl = (formAssembly: string) => {
    let formAssemblyUrl: string = "";
    const environment = (process.env.REACT_APP_ENV ||
      "development") as keyof typeof SESSION_RESPONSE_URL;
    const sessionUrlResponse = SESSION_RESPONSE_URL[environment];

    const formMapping: FormAssemblyMapping =
      formAssembly === FORM_ASSEMBLY_FEEDBACK.FormAfterResponses
        ? formAssemblyMapping8
        : formAssemblyMapping7;

    if (isProduction) {
      formAssemblyUrl = isBeforeQuotesPage
        ? "https://goosehead.tfaforms.net/7"
        : "https://goosehead.tfaforms.net/8";
    } else {
      formAssemblyUrl = isBeforeQuotesPage
        ? "https://goosehead.tfaforms.net/79"
        : "https://goosehead.tfaforms.net/78";
    }

    const formDataGenerated: Record<string, string> = {
      [formMapping.pageName]: isNewResponsesPage
        ? "Responses 2.0"
        : currentPage ?? "",
      [formMapping.user]: contact.user ?? "",
      [formMapping.state]: property.administrative_area_level_1 ?? "",
      [formMapping.sessionId]: session.uuid ?? "",
      [formMapping.account]: contact.sfAccountId ?? "",
      [formMapping.herokuId]: contact.accountId ?? "",
      [formMapping.sessionResponseLink]: encodeURIComponent(
        `${sessionUrlResponse}${session.uuid}`
      ),
      [formMapping.sessionRecordingLink]: session.session_replay ?? "",
    };
    return `${formAssemblyUrl}${generateUrlParams(formDataGenerated)}`;
  };

  useEffect(() => {
    if (currentPage === pageNames[6]) {
      setFeedbackFormToShow(FORM_ASSEMBLY_FEEDBACK.FormAfterResponses);
    } else {
      setFeedbackFormToShow(FORM_ASSEMBLY_FEEDBACK.FormBeforeResponses);
    }
  }, [currentPage]);

  return (
    <StyledPopupForm $display={isActive ? "block" : "none"}>
      <header>
        <div className="left">
          <FeedbackIcon />
          Feedback
        </div>
        <MinimizeButton
          width="28px"
          height="28px"
          onClick={close}
          aria-label="minimize agent feedback"
        />
      </header>
      <section className="feedback-body">
        <FeedbackContainer $height={isBeforeQuotesPage ? "400px" : "500px"}>
          <StyledIframe
            id="feedbackIframe"
            src={getFormAssemblyIframeUrl(feedbackFormToShow)}
          />
        </FeedbackContainer>
      </section>
    </StyledPopupForm>
  );
};

export default AgentFeedback;
