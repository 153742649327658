import axios from "axios";

export const getAgentDetails = (agentId) => {
  return axios.get(`/api/userinpg/${agentId}`);
};

export const getNewAgent = (state, zip, sqft) => {
  return axios.get(`/api/userinsf/${state}?zip=${zip}&sqft=${sqft}`);
};

export const getAgentLicense = (payload) =>
  axios.post("/api/agentlicense", payload);

export const getAgentReviews = (agentId) => {
  return axios.get(`/api/reviews/${agentId}`);
};

export const getProspectiveAgent = (payload) =>
  axios.post("/api/prospectiveagent", payload);

export const validateUser = () => axios.get("/api/validateUser");

export const validateUserHash = (hash, ip) => {
  const encodedHash = encodeURIComponent(hash);
  return axios.get(`/api/validateUserHash/?hash=${encodedHash}`, {
    headers: { "x-forwarded-for": ip },
  });
};

export const getAccessToken = (code) => axios.get(`api/accessToken/${code}`);

export const logoutUser = () => axios.get("api/logout");

export const sendEmailToAgent = (payload) =>
  axios.post("/api/sendemail", payload);
