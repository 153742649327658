import { SmartHomePackageContentWrapper } from "./styled/QuoteResponse";
import { VIVINT_SMART_HOME_QUOTE } from "../constants";
export const SmartHomePackageContent = ({ isTooltip }) => {
  return (
    <SmartHomePackageContentWrapper isTooltip={isTooltip}>
      <div className="promotion">$200 referral bonus on install!</div>
      <div className="price-line">
        <div className="label">Basic</div>
        <div className="value fs-5">
          <sup>$</sup>
          <span>{VIVINT_SMART_HOME_QUOTE.premium_total_ppm__c}/mo*</span>
        </div>
      </div>
      <div className="tooltipText">
        <p>Free installation of: </p>
        <ul>
          <li>1 Vivint Doorbell Camera Pro (Free Item GHSD)</li>
          <li>1 Vivint Outdoor Camera Pro</li>
          <li>1 Vivint Smart Hub</li>
          <li>1 Smart Thermostat</li>
          <li>1 Smart Lock</li>
          <li>3 Vivint Door/Window Sensors</li>
          <li>1 Vivint Water Sensor</li>
        </ul>
      </div>
      <div className="disclaimer">
        *This is just an estimate. Final monthly price will be based on credit,
        down payment, and final equipment list.
      </div>
    </SmartHomePackageContentWrapper>
  );
};
