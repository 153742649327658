import CustomPopover from "./CustomPopover";
import CustomTooltip from "./CustomTooltip";
import SelectArrowIcon from "../assets/images/main/SelectArrowIcon.svg";
import styled from "styled-components";
import { useField } from "formik";
import { useSelector } from "react-redux";

const Input = styled.input`
  background: white;
  height: 50px;
  width: ${(props) => (props.width ? props.width : "200px")};
  color: ${(props) =>
    props.value ? props.theme.input_txt : props.theme.light_gray_alt};
  font-size: 15px;
  font-weight: 400;
  border-radius: ${(props) => (props.straightBorder ? 0 : "25px")};
  border: 1px solid #707070;
  padding: 12px 10px !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  ::-ms-expand {
    display: none;
  }
  ::placeholder {
    font-weight: 220;
    color: #6d6e71;
  }
  &:focus {
    outline: none;
  }
`;

const Select = styled.select`
  display: block;
  background: white;
  height: 50px;
  width: ${(props) => (props.width ? props.width : "180px")};
  color: ${(props) =>
    props.value ? props.theme.input_txt : props.theme.light_gray_alt};
  font-size: 15px;
  font-weight: 400;
  border-radius: ${(props) => (props.straightBorder ? 0 : "25px")};
  border: 1px solid #707070;
  background-image: url(${SelectArrowIcon});
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  outline: none;
  padding-left: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  ::-ms-expand {
    display: none;
  }
  :invalid {
    font-weight: 220;
    color: #6d6e71;
  }
  &:focus {
    outline: none;
  }
`;

const Textarea = styled.textarea`
  background: white;
  height: 50px;
  border: none;
  width: ${(props) => (props.width ? props.width : "200px")};
  color: ${(props) =>
    props.value ? props.theme.input_txt : props.theme.light_gray_alt};
  font-size: 15px;
  font-weight: 400;
  border-radius: ${(props) => (props.straightBorder ? "0px" : "25px")};

  padding-top: 10px;
  padding-left: 15px;

  -moz-appearance: none;
  -webkit-appearance: none;
  ::-ms-expand {
    display: none;
  }
  ::placeholder {
    font-weight: 220;
    color: #6d6e71;
  }
  &:focus {
    outline: none;
    border: 2px solid #707070;
  }
`;

export const Label = styled.label`
  font-size: 15px;
  margin-left: 3px;
  margin-bottom: 10px;
`;

export const Error = styled.div`
  font-size: 15px;
  padding-left: 10px;
  color: #c65252;
`;

export const CancelIcon = styled.img`
  cursor: pointer;
`;

const CheckboxText = styled.span`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5px;
  align-items: center;
  max-width: 100px;
`;

export const FormikInput = ({
  infoText,
  label,
  hideLabel,
  centerInput,
  infoIconColor,
  shouldDisplayError = true,
  ...props
}) => {
  const isRecalculating = useSelector((store) => store.isRecalculating);
  const [field, meta] = useField(props);
  return (
    <InputWrapper>
      {label && (
        <div className={`align-self-start ${hideLabel ? "invisible" : ""}`}>
          <Label htmlFor={props.id || props.name}>
            {label}
            {infoText && (
              <CustomTooltip
                color={infoIconColor ? infoIconColor : "neon-green"}
                content={infoText}
              />
            )}
          </Label>
        </div>
      )}
      <Input disabled={isRecalculating} width="100%" {...field} {...props} />
      {shouldDisplayError && meta.error ? (
        <small className="text-left fw-light font-italic text-danger mt-1">
          {meta.error}
        </small>
      ) : null}
    </InputWrapper>
  );
};

export const FormikSelect = ({
  infoIconColor,
  infoText,
  label,
  ariaLabel,
  carousel,
  showError = true,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div className="d-flex flex-column align-items-center">
      {label && (
        <div className="align-self-start">
          <Label htmlFor={props.id || props.name}>
            {label}
            {infoText && (
              <CustomTooltip
                color={infoIconColor ? infoIconColor : "neon-green"}
                content={infoText}
              />
            )}
            {carousel && (
              <CustomPopover
                color={infoIconColor ? infoIconColor : "neon-green"}
                carousel={carousel}
              />
            )}
          </Label>
        </div>
      )}
      <Select aria-label={ariaLabel} {...field} {...props} />
      {showError && !!meta.error && (
        <small className="text-left fw-light font-italic text-danger mt-1">
          {meta.error}
        </small>
      )}
    </div>
  );
};

export const FormikTextArea = ({
  infoText,
  infoIconColor,
  label,
  hideLabel,
  centerInput,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div className="d-flex flex-column">
      <Label
        className={hideLabel && "invisible"}
        htmlFor={props.id || props.name}
      >
        {label}
        {infoText && (
          <CustomTooltip
            color={infoIconColor ? infoIconColor : "neon-green"}
            content={infoText}
          />
        )}
      </Label>
      <Textarea {...field} {...props} />
      {meta.error ? <Error className="error">{meta.error}</Error> : null}
    </div>
  );
};

export const FormikCheckbox = ({ infoText, checkboxText, ...props }) => {
  const [field] = useField(props);
  return (
    <label className="d-flex align-items-center mb-3">
      <input type="checkbox" {...field} {...props} />
      <CheckboxText className="ml-3">{checkboxText}</CheckboxText>
    </label>
  );
};
